import React from 'react';
import Chart from 'react-apexcharts';
import { splitFromFirstSpace } from '_helpers';


export const ReportChart = ({ data, subject }) => {
    const filteredData = data && data.filter((item) => item.subject.toLowerCase() === subject.toLowerCase());
    const quizTitles = filteredData.map((item) => splitFromFirstSpace(item.quiz_name));
    const obtainedMarks = filteredData.map((item) => item.user_percent_marks);
    const totalMarks = filteredData.map((item) => item.total_marks);
    const chartData = {
      options: {
        chart: {
          type: 'bar',
          height: 350,
        },
        xaxis: {
          categories: quizTitles,
          labels: {
            rotate: 0,
            style: {
              text: 'wrap',
              whiteSpace: 'normal',
              lineHeight: '1.2',
              overflow: 'hidden'
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
            borderRadius: 10,
          },
        },
        fill: {
          opacity: 0.9
        },
      },
      series: [
        {
          name: 'Total Marks',
          data: totalMarks,
          color: "#333"
        },
        {
          name: 'Obtained marks',
          data: obtainedMarks,
          color: "#d8cc43"
        },
      ],
    };
  
    return (
      <div>
        <div className='d-flex justify-content-between'>
        {/* <h2 className='my-auto'>Quiz Marks</h2> */}
        </div>
        <Chart options={chartData.options} series={chartData.series} type='bar' height={350} />
      </div>
    );
  };
  