import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Container,
  Grid,
  LinearProgress,
  CircularProgress,
} from '@mui/material';
import { Message } from './Message';
import OpenAI from 'openai';
import SendIcon from '@mui/icons-material/Send';

const VectorSearch = () => {
  const [isWaiting, setIsWaiting] = useState(false);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [thread, setThread] = useState(null);
  const [openai, setOpenai] = useState(null);
  const assistant = 'asst_Sor2JHvEpvPQBv3xf1kTDtUP';
  useEffect(() => {
    initChatBot();
  }, []);

  useEffect(() => {
    setMessages([
      {
        content: "Hi, I'm your NEET assistant. How can I help you?",
        isUser: false,
      },
    ]);
  }, [assistant]);

  const REACT_APP_OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;

  const initChatBot = async () => {
    const openai = new OpenAI({
      apiKey: REACT_APP_OPENAI_API_KEY,
      dangerouslyAllowBrowser: true,
    });
    // change instruction to like this if needed to generate question
    // instructions: `You are a NEET expert. You specialize in helping others learn about NEET. Use attachment and generate MCQ question. The response should be a JSON object in the following format: {"question": "question text","options": ["option1", "option2", "option3", "option4"], "answer": "correct option"}. Provide the response in this exact format.`,

    try {
      //   const assistant = await openai.beta.assistants.create({
      //     name: "NEET Expert",
      //     instructions: `You are a NEET expert who specializes in helping others learn about NEET. You will receive attachments containing NEET questions with mathematical equations, symbols, and special notations. Study these questions to understand their style, look, format, topics, and use of mathematical notation such as subscripts, superscripts, and special symbols.

      //     When generating new questions, ensure they closely resemble the provided questions in terms of style, format, difficulty, topics, and inclusion of mathematical notation and symbols. Maintain a high level of similarity to the provided questions, including the visual presentation and the use of figures, diagrams, or images where appropriate. The generated questions should accurately reflect NEET syllabus requirements with proper calculations that students can easily understand.

      //     **Formatting Instructions:**
      //     - All mathematical equations should be displayed using the "displaystyle" in LaTeX. This means that equations must be displayed on a separate line, centered, and surrounded by double dollar signs ("$$") backslash if not wanted as well if not used as math symbol. For example:

      //         a^2 + b^2 = c^2

      //     - Ensure all mathematical symbols, operators, and special notations are written using correct LaTeX syntax.

      //     - Each question should follow the same formatting as the examples provided in terms of visual layout, use of equations, and clarity.

      //     - When incorporating figures, diagrams, or images, include captions or explanations as needed for context.

      //     - All questions should be presented in a clear, concise manner with a focus on accuracy and alignment with NEET syllabus requirements.

      //     By following these format instructions, you will help students better understand the questions and their solutions. Thank you!`,
      //      model: 'gpt-3.5-turbo-0125',
      //     tools: [{ type: "file_search" }],
      //     tool_resources: {
      //         "file_search": {
      //             "vector_store_ids": ["vs_FX51jgwJZJxNPwyeUo21Jprq"]
      //         }
      //     }
      // });
      const thread = await openai.beta.threads.create({
        tool_resources: {
          file_search: {
            vector_store_ids: ['vs_FX51jgwJZJxNPwyeUo21Jprq'],
          },
        },
      });

      setOpenai(openai);
      setThread(thread);
    } catch (error) {
      console.error('Error initializing ChatBot:', error);
      if (error.message.includes('Type')) {
        console.error('SDK might not support the tool type "file_search".');
      } else {
        console.error('Check your API key and other configurations.');
      }
    }
  };

  const createNewMessage = (content, isUser) => {
    const newMessage = {
      isUser,
      content,
    };
    return newMessage;
  };

  const handleSendMessage = async () => {
    messages.push(createNewMessage(input, true));
    setMessages([...messages]);
    setInput('');

    await openai.beta.threads.messages.create(thread.id, {
      role: 'user',
      content: input,
    });

    const run = await openai.beta.threads.runs.create(thread.id, {
      assistant_id: assistant,
    });

    let response = await openai.beta.threads.runs.retrieve(thread.id, run.id);

    while (response.status === 'in_progress' || response.status === 'queued') {
      console.log('waiting...');
      setIsWaiting(true);
      await new Promise((resolve) => setTimeout(resolve, 5000));
      response = await openai.beta.threads.runs.retrieve(thread.id, run.id);
    }

    setIsWaiting(false);

    const messageList = await openai.beta.threads.messages.list(thread.id);

    const lastMessage = messageList.data
      .filter((message) => message.run_id === run.id && message.role === 'assistant')
      .pop();

    if (lastMessage) {
      setMessages([...messages, createNewMessage(lastMessage.content[0]['text'].value, false)]);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  return (
    <Container>
      <Grid container direction='column' spacing={2} paddingBottom={2}>
        {messages.map((message, index) => (
          <Grid item alignSelf={message.isUser ? 'flex-end' : 'flex-start'} key={index}>
            <Message key={index} message={message} />
          </Grid>
        ))}
      </Grid>
      <Grid container direction='row' paddingBottom={5} justifyContent={'space-between'}>
        <Grid item sm={11} xs={9}>
          <TextField
            label='Type your message'
            variant='outlined'
            disabled={isWaiting}
            fullWidth
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={handleKeyPress}
          />
          {isWaiting && <LinearProgress color='inherit' />}
        </Grid>
        <Grid item sm={1} xs={3}>
          <Button variant='text' size='large' onClick={handleSendMessage} disabled={isWaiting}>
            {isWaiting && <CircularProgress color='inherit' />}
            {!isWaiting && <SendIcon fontSize='large' sx={{ color: '#be4539' }} />}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export { VectorSearch };
