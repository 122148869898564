import React from 'react';
import './spinWords.css';

export const SpinWords = () => {
  return (
    <>
      <div className='home col-lg-12 col-md-12 col-sm-12 col-xs-12'>
        <h3 id='resizing-h3' className='varela-round-regular'>
          Classroom AI is the pre-trained AI models for
          <span>
            <div className='stage'>
              <div className='cubespinner'>
                <div className='face1'>CBSE</div>
                <div className='face2'>NEET</div>
                <div className='face3'>State Board</div>
                <div className='face4'>NEET</div>
              </div>
            </div>
          </span>
        </h3>
      </div>
    </>
  );
};
