import React, { useState, useRef } from 'react';
import ProductCard from './ProductCard';
import { Box, Grid } from '@mui/material';
import { ProductPayment } from './ProductPayment';
import { ProductDiscount } from './ProductDiscount';
import { ProductInteractiveModels } from './ProductInteractiveModels';
import { ProductAIGuide } from './ProductAIGuide';
import ProductBG from '../../../_assets/images/productBg.png';

export const Product = () => {
  const [selectedCategory, setSelectedCategory] = useState('NEET_PREPARATION'); // Default category

  // Create refs for the sections
  const discountRef = useRef(null);
  const paymentRef = useRef(null);

  // Function to handle category selection and scroll to the sections
  const handleCategorySelect = (category) => {
    setSelectedCategory(category);

    // Scroll to the discount section
    discountRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${ProductBG})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh', // Ensures full viewport height
        padding: '20px',
      }}
    >
      <ProductCard onCategorySelect={handleCategorySelect} selectedCategory={selectedCategory} />

      {/* Use Grid for layout */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} ref={discountRef}>
          {' '}
          {/* Reference for discount section */}
          <ProductDiscount selectedCategory={selectedCategory} />
        </Grid>
        <Grid
          sx={{
            marginTop: {
              md: '100px',
            },
          }}
          item
          xs={12}
          md={6}
          ref={paymentRef} // Reference for payment section
        >
          <ProductPayment selectedCategory={selectedCategory} />
        </Grid>
      </Grid>
      <Grid sx={{ padding: '10px' }}>
        <Grid item xs={12} md={6}>
          <ProductInteractiveModels />
        </Grid>
        <Grid item xs={12} md={6}>
          <ProductAIGuide />
        </Grid>
      </Grid>
    </Box>
  );
};
