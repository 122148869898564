import React from 'react';
import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';

const QuestionDisplay = ({
  currentQuestion,
  currentQuestionIndex,
  answers,
  handleAnswerChange,
}) => {
  if (!currentQuestion) {
    return null; // Ensure no rendering without a question
  }

  const { questions, options, question_image, question_type } = currentQuestion;

  const renderSingleChoiceQuestion = () => (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <FormControl component='fieldset'>
        <FormLabel component='legend'>Select your answer:</FormLabel>
        <RadioGroup
          aria-label='quiz'
          name={`quiz-${currentQuestionIndex}`}
          value={answers[currentQuestionIndex] || ''}
          onChange={(event) => handleAnswerChange(event, currentQuestionIndex)}
        >
          {options.map((option, index) => (
            <FormControlLabel
              key={index}
              value={String(index + 1)} // Store option index as value
              control={<Radio />}
              label={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {option?.match(/\.(jpeg|jpg|gif|png)$/) ? (
                    <>
                      <span>{`(${String.fromCharCode(49 + index)}).`}</span>
                      <img
                        src={option}
                        alt={`Option ${String.fromCharCode(49 + index)}`}
                        style={{ maxWidth: '75%', maxHeight: '75%', margin: '10px' }}
                      />
                    </>
                  ) : (
                    <span>{`(${String.fromCharCode(49 + index)}). ${option}`}</span>
                  )}
                </Box>
              }
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );

  const renderMatchingQuestion = () => {
    const tableStartIndex = questions.indexOf('<table>');
    const tableEndIndex = questions.indexOf('</table>') + '</table>'.length;
    const tableContent = questions.substring(tableStartIndex, tableEndIndex);

    const cssAppliedContent = (tableContent) => `
    <div>
      <style>
        table {
        background-color: #f2f2f2;
        }
        th, tr, td {
        border: 1px solid black; 
        }
      </style>
      ${tableContent}
    <div>
    `;

    const optionList = options.map((option, index) => (
      <FormControlLabel key={index} value={String(index + 1)} control={<Radio />} label={option} />
    ));

    return (
      <Box>
        <Typography variant='body1'>{`Q${currentQuestionIndex + 1}`}. Matching Question</Typography>
        <div dangerouslySetInnerHTML={{ __html: cssAppliedContent(tableContent) }} />
        <FormControl component='fieldset'>
          <FormLabel component='legend'>Select your answer:</FormLabel>
          <RadioGroup
            aria-label='quiz'
            name={`quiz-${currentQuestionIndex}`}
            value={answers[currentQuestionIndex] || ''}
            onChange={(event) => handleAnswerChange(event, currentQuestionIndex)}
          >
            {optionList}
          </RadioGroup>
        </FormControl>
      </Box>
    );
  };

  return (
    <Box sx={{ marginLeft: '20px', padding: '20px', backgroundColor: '#E5E4E2' }}>
      {question_type !== 'MATCH' && (
        <Typography variant='body1' gutterBottom>
          {`Q${currentQuestionIndex + 1}. ${questions}`}
        </Typography>
      )}
      {question_image && (
        <Box
          component='img'
          sx={{ maxWidth: '100%', maxHeight: '100%', margin: '10px' }}
          src={question_image}
          alt='Question related'
        />
      )}
      {question_type === 'MCQ' && renderSingleChoiceQuestion()}
      {question_type === 'MATCH' && renderMatchingQuestion()}
    </Box>
  );
};

export default QuestionDisplay;
