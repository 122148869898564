import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Chip,
  Container,
  FormControlLabel,
  LinearProgress,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import OpenAI from 'openai';
import QuizWrapper from '_components/chapters/QuizWrapper';
import { MarksCalculator } from '_components/common';
import { Loader } from '_components/navigation/Loader';
import { MathJax, MathJaxContext } from 'better-react-mathjax';
import { Fragment } from 'react';

const VectorAIQuestionGenerator = (props) => {
  const { topic } = props;
  const [isWaiting, setIsWaiting] = useState(false);
  const [thread, setThread] = useState(null);
  const [openai, setOpenai] = useState(null);
  const [level, setLevel] = useState('');
  const assistant = 'asst_Sor2JHvEpvPQBv3xf1kTDtUP';
  const REACT_APP_OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
  const [data, setData] = useState('');
  const [questionsList, setQuestionsList] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [answeredCount, setAnsweredCount] = useState(0);
  const [correctCount, setCorrectCount] = useState(0);
  const [answer, setAnswer] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [wrongCount, setWrongCount] = useState(0);
  const [showMarksCalculator, setShowMarksCalculator] = useState(false);
  const [showExplanation, setShowExplanation] = useState(false);
  const [explanation, setExplanation] = useState('');
  const [showNextButton, setShowNextButton] = useState(false);
  const [results, setResults] = useState({});
  console.log('res', results);
  useEffect(() => {
    initChatBot();
  }, []);

  const initChatBot = async () => {
    const openai = new OpenAI({
      apiKey: REACT_APP_OPENAI_API_KEY,
      dangerouslyAllowBrowser: true,
    });

    try {
      const thread = await openai.beta.threads.create({
        tool_resources: {
          file_search: {
            vector_store_ids: ['vs_FX51jgwJZJxNPwyeUo21Jprq'],
          },
        },
      });

      setOpenai(openai);
      setThread(thread);
    } catch (error) {
      console.error('Error initializing ChatBot:', error);
      if (error.message.includes('Type')) {
        console.error('SDK might not support the tool type "file_search".');
      } else {
        console.error('Check your API key and other configurations.');
      }
    }
  };

  const generateQuestion = async () => {
    const prompt = `You are a quiz master. Generate a single multiple-choice question for the NEET exam on the topic ${topic}. The question should include a numerical problem as well. Provide the question, options, and the correct answer separately in the following JSON format without any additional text: 
    {
      "question": "",
      "options": [],
      "answer": ""
    }`;

    setIsWaiting(true);

    await openai.beta.threads.messages.create(thread.id, {
      role: 'user',
      content: prompt,
    });

    const run = await openai.beta.threads.runs.create(thread.id, {
      assistant_id: assistant,
    });

    let response = await openai.beta.threads.runs.retrieve(thread.id, run.id);

    while (response.status === 'in_progress' || response.status === 'queued') {
      console.log('waiting...');
      await new Promise((resolve) => setTimeout(resolve, 5000));
      response = await openai.beta.threads.runs.retrieve(thread.id, run.id);
    }

    setIsWaiting(false);

    const messageList = await openai.beta.threads.messages.list(thread.id);

    const lastMessage = messageList.data
      .filter((message) => message.run_id === run.id && message.role === 'assistant')
      .pop();

    if (lastMessage) {
      const responseText = lastMessage.content[0]['text'].value.trim();

      // Clean the response text
      // eslint-disable-next-line no-control-regex
      const cleanedResponseText = responseText.replace(/[\u0000-\u001F\u007F-\u009F]/g, '');

      // Extract JSON data from the cleaned response text
      const jsonStart = cleanedResponseText.indexOf('{');
      const jsonEnd = cleanedResponseText.lastIndexOf('}') + 1;

      if (jsonStart !== -1 && jsonEnd !== -1) {
        const jsonDataStr = cleanedResponseText.substring(jsonStart, jsonEnd);
        try {
          const jsonData = JSON.parse(jsonDataStr);
          setData(jsonData);
          setQuestions(jsonData.question);
          setOptions(jsonData.options);
          setAnswer(jsonData.answer);
          setSelectedAnswers({});
          setResults({});
          setIsLoading(false);

          // Check if jsonData contains all required properties
          if (jsonData.question && jsonData.options && jsonData.answer) {
            if (questionsList.length < 10) {
              setQuestionsList((prevQue) => [...prevQue, jsonData]);
              setCurrentIndex((prevCount) => prevCount + 1);
            } else {
              setIsLoading(false);
              generateQuestion(level);
              setShowMarksCalculator(true);
            }
          } else {
            console.error('Invalid question data received:', jsonData);
          }
        } catch (error) {
          console.error('Failed to parse JSON response:', error);
        }
      } else {
        console.error('JSON data not found in the response text.');
      }
    }
  };
  const checkAnswer = async (question, answer, selectedAnswer, retries = 3, delay = 1000) => {
    const prompt = `The answer for the question "${question}" is not "${selectedAnswer}" from options but "${answer}". Provide an explanation for why "${answer}" is correct and "${selectedAnswer}" is incorrect. The explanation should be in JSON format with a single key 'explanation' and a string value. Ensure the JSON is valid and escape all special characters properly. For example:
    {
      "explanation": "Your explanation here, with special characters properly escaped. Mathematical expressions should use double backslashes, like this: \\\\(E = mc^2\\\\)"
    }`;

    setIsWaiting(true);

    try {
      await openai.beta.threads.messages.create(thread.id, {
        role: 'user',
        content: prompt,
      });

      const run = await openai.beta.threads.runs.create(thread.id, {
        assistant_id: assistant,
      });

      let response = await openai.beta.threads.runs.retrieve(thread.id, run.id);

      while (response.status === 'in_progress' || response.status === 'queued') {
        console.log('waiting...');
        await new Promise((resolve) => setTimeout(resolve, 5000));
        response = await openai.beta.threads.runs.retrieve(thread.id, run.id);
      }

      setIsWaiting(false);

      const messageList = await openai.beta.threads.messages.list(thread.id);

      const lastMessage = messageList.data
        .filter((message) => message.run_id === run.id && message.role === 'assistant')
        .pop();

      if (lastMessage) {
        let responseText = lastMessage.content[0]['text'].value.trim();

        // Clean the response text
        // eslint-disable-next-line no-control-regex
        responseText = responseText.replace(/[\u0000-\u001F\u007F-\u009F]/g, '');

        console.log('Cleaned Response Text:', responseText); // Log cleaned response text

        const jsonStart = responseText.indexOf('{');
        const jsonEnd = responseText.lastIndexOf('}') + 1;

        if (jsonStart === -1 || jsonEnd === 0) {
          throw new Error('Invalid JSON format in response');
        }

        let jsonDataStr = responseText.substring(jsonStart, jsonEnd);

        try {
          const jsonData = tryParseJSON(jsonDataStr); // Use the improved JSON parsing function
          const formattedExplanation = jsonData.explanation.replace(/\n/g, '<br />');
          setExplanation(formattedExplanation);

          setQuestionsList((prevQuestionsList) => {
            const updatedQuestionsList = [...prevQuestionsList];
            const currentQuestion = updatedQuestionsList[currentIndex];
            updatedQuestionsList[currentIndex] = {
              ...currentQuestion,
              selectedOption: selectedAnswer,
              explanation: formattedExplanation,
            };
            return updatedQuestionsList;
          });
          setShowNextButton(true);
        } catch (error) {
          console.error(
            'Failed to parse JSON response:',
            error,
            'Original Response Text:',
            responseText,
          );
        }
      } else {
        console.error('No response from assistant');
      }
    } catch (error) {
      console.error('Error checking answer:', error.message);
      if (retries > 0) {
        console.log(`Retrying in ${delay} ms...`);
        await new Promise((resolve) => setTimeout(resolve, delay));
        await checkAnswer(question, answer, selectedAnswer, retries - 1, delay * 2);
      } else {
        console.error('All retries exhausted');
        setShowNextButton(true);
      }
    }
  };

  // Improved JSON parsing function
  const tryParseJSON = (jsonString) => {
    try {
      return JSON.parse(jsonString);
    } catch (error) {
      console.error('Initial JSON parsing failed:', error);

      // Attempt to fix common issues
      // eslint-disable-next-line no-useless-escape
      jsonString = jsonString.replace(/\\(?!["\\\/bfnrt]|u[a-fA-F0-9]{4})/g, '\\\\'); // Double escape backslashes
      jsonString = jsonString.replace(/(\r\n|\n|\r)/gm, ' '); // Remove newlines
      jsonString = jsonString.replace(/(?<=:)"(.*?)(?<!\\)"/g, (match, p1) => {
        // Escape unescaped quotes inside string values
        return `"${p1.replace(/"/g, '\\"')}"`;
      });

      try {
        return JSON.parse(jsonString);
      } catch (error) {
        console.error('Second JSON parsing attempt failed:', error);
        throw error;
      }
    }
  };

  const handleStart = (selectedLevel) => {
    setLevel(selectedLevel);
    generateQuestion(selectedLevel);
  };

  const handlePreviousClick = () => {
    if (currentIndex > 0) {
      const previousIndex = currentIndex - 1;
      setCurrentIndex(previousIndex);
      const previousQuestion = questionsList[previousIndex];
      setQuestions(previousQuestion.question);
      setOptions(previousQuestion.options);
      setAnswer(previousQuestion.answer);
      setSelectedAnswers(previousQuestion.selectedOption || {});
      setShowExplanation(!!previousQuestion.explanation);
      setExplanation(previousQuestion.explanation || '');
    }
  };
  const handleNextClick = () => {
    if (currentIndex < questionsList.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
      const nextQuestion = questionsList[currentIndex + 1];
      setQuestions(nextQuestion.question);
      setOptions(nextQuestion.options);
      setAnswer(nextQuestion.answer);
      setSelectedAnswers(nextQuestion.selectedOption || {});
      setShowExplanation(!!nextQuestion.explanation);
      setExplanation(nextQuestion.explanation || '');
    } else {
      setExplanation();
      setShowExplanation(false);
      generateQuestion(level);
    }
  };

  const handleRestart = () => {
    setQuestions([]);
    setOptions([]);
    setAnswer([]);
    setShowExplanation(false);
    setExplanation('');
    setSelectedAnswers({});
    setResults({});
    setAnsweredCount(0);
    setCorrectCount(0);
    setWrongCount(0);
    setIsLoading(false);
    setLevel('');
    // setChatHistory([]);
    setQuestionsList([]);
    setCurrentIndex(-1);
    setShowNextButton(false);
    setShowMarksCalculator(false);
  };
  const handleAnswerChange = useCallback(
    async (question, selectedOption) => {
      setSelectedAnswers(selectedOption);
      setAnsweredCount((prevCount) => prevCount + 1);

      if (selectedOption == answer) {
        setQuestionsList((prevQuestionsList) => {
          const updatedQuestionsList = [...prevQuestionsList];
          const currentQuestion = updatedQuestionsList[currentIndex];
          updatedQuestionsList[currentIndex] = {
            ...currentQuestion,
            selectedOption: selectedOption,
          };
          return updatedQuestionsList;
        });
        setShowExplanation(false);
        setExplanation();
        setShowExplanation(false);
        setCorrectCount((prevCount) => prevCount + 1);
        setShowNextButton(true);
      } else {
        setShowNextButton(false);
        await checkAnswer(question, answer, selectedOption);
        setWrongCount((prevCount) => prevCount + 1);
        setShowExplanation(true);
      }
    },
    [questionsList, selectedAnswers],
  );
  const LevelSelector = () => (
    <div className='container'>
      <div>
        <div className='alert alert-danger'>Please select level</div>
      </div>
      <div className='row pt-2'>
        <div className='col-12 col-md-6 mb-3'>
          <div
            className='card btn text-white bg-success level-btn'
            style={{ width: '100%' }}
            onClick={() => handleStart('easy')}
          >
            <div className='card-body'>
              <h5 className='card-title'>Easy</h5>
            </div>
          </div>
        </div>
        <div className='col-12 col-md-6 mb-3'>
          <div
            className='card btn text-white bg-warning level-btn'
            style={{ width: '100%' }}
            onClick={() => handleStart('medium')}
          >
            <div className='card-body'>
              <h5 className='card-title'>Medium</h5>
            </div>
          </div>
        </div>
        <div className='col-12 col-md-6 mb-3'>
          <div
            className='card btn text-white bg-danger level-btn'
            style={{ width: '100%' }}
            onClick={() => handleStart('hard')}
          >
            <div className='card-body'>
              <h5 className='card-title'>Hard</h5>
            </div>
          </div>
        </div>
        <div className='col-12 col-md-6 mb-3'>
          <div
            className='card btn text-white bg-secondary level-btn'
            style={{ width: '100%' }}
            onClick={() => handleStart('real-world')}
          >
            <div className='card-body'>
              <h5 className='card-title'>Real-World Challenge</h5>
            </div>
          </div>
        </div>
        {location.pathname.includes('NEET') && (
          <div className='col-12 col-md-6 mb-3'>
            <div
              className='card btn text-white bg-primary level-btn'
              style={{ width: '100%' }}
              onClick={() => handleStart('custom-quiz')}
            >
              <div className='card-body'>
                <h5 className='card-title'>Customized Hard quiz</h5>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div>
      <Container>{isWaiting && <LinearProgress color='inherit' />}</Container>
      <div className='d-flex justify-content-between text-center align-items-center'>
        <div className='h4 text-success'>{topic}</div>
        {questions && questions.length < 1 ? (
          ''
        ) : (
          <div className='d-block text-end'>
            <button className='pushable mb-2'>
              <span className='shadow'></span>
              <span
                className='edge'
                style={{
                  background:
                    'linear-gradient(to left, ' +
                    'rgba(0, 0, 0, 0.16) 0%, ' + // 16% black
                    'rgba(0, 0, 0, 0.32) 8%, ' + // 32% black
                    'rgba(0, 0, 0, 0.32) 92%, ' + // 32% black
                    'rgba(0, 0, 0, 0.16) 100%)', // 16% black
                }}
              ></span>

              <span className='front bg-dark text-light' onClick={handleRestart}>
                Restart
              </span>
            </button>
          </div>
        )}
      </div>
      {showMarksCalculator ? (
        <MarksCalculator
          // board={board}
          totalQuestions={Number(questionsList.length)}
          answeredQuestion={answeredCount}
          wrongAnswers={wrongCount}
          rightAnswers={answeredCount - wrongCount}
          level={level}
          data={data}
        />
      ) : (
        <>
          {level && questions ? (
            <div className='d-flex justify-content-between'>
              {level !== 'custom-quiz' && (
                <>
                  <div>
                    <div disabled className=' btn btn-success border-rounded mx-2'>
                      Total Questions:{answeredCount}
                    </div>
                    <div disabled className=' btn btn-warning border-rounded mx-2'>
                      Correct:{correctCount}
                    </div>
                  </div>
                  <Chip label={`Level: ${level}`} variant='outlined' color='info' />
                </>
              )}
            </div>
          ) : (
            <div>
              <LevelSelector />
            </div>
          )}
          {questions &&
            level &&
            (level === 'custom-quiz' ? (
              <QuizWrapper />
            ) : (
              <Box mt={2}>
                <Paper elevation={3} style={{ padding: '10px', margin: '20px 10px' }}>
                  <MathJaxContext version={3}>
                    <MathJax>{questions}</MathJax>
                  </MathJaxContext>
                  <RadioGroup
                    onChange={(event) => handleAnswerChange(questions, event.target.value)}
                  >
                    {options.map((option, index) => (
                      <div
                        key={option + index}
                        className={
                          selectedAnswers && option !== answer && option === selectedAnswers
                            ? 'd-flex align-items-center'
                            : ''
                        }
                      >
                        <FormControlLabel
                          value={option}
                          control={<Radio />}
                          label={`(${String.fromCharCode(49 + index)}). ${option}`}
                          // disabled={selectedAnswers && selectedAnswers.length && option !== answer}
                          style={{
                            pointerEvents:
                              selectedAnswers && selectedAnswers.length && option === answer
                                ? 'none'
                                : 'all',
                          }}
                        />
                        {selectedAnswers && option !== answer && option === selectedAnswers && (
                          <div className='d-inline'>❌</div>
                        )}
                        {selectedAnswers && selectedAnswers.length && option === answer && (
                          <div className='d-inline'>✔️</div>
                        )}
                      </div>
                    ))}
                  </RadioGroup>
                  {showExplanation && explanation && (
                    <>
                      <Typography variant='body1'>
                        Correct:{' '}
                        {`(${String.fromCharCode(49 + options.indexOf(answer))}). ${answer}`}
                      </Typography>
                      <Typography variant='body1' style={{ color: 'red' }}>
                        Explanation:
                      </Typography>
                      <div>
                        <MathJaxContext version={3}>
                          <div style={{ backgroundColor: '#34ebb4', padding: '10px' }}>
                            <MathJax>
                              <div dangerouslySetInnerHTML={{ __html: explanation }} />
                            </MathJax>
                          </div>
                        </MathJaxContext>
                      </div>
                    </>
                  )}
                </Paper>
              </Box>
            ))}

          {questions && level && (
            <div className='d-flex justify-content-between'>
              {questionsList && questionsList.length && currentIndex > 0 ? (
                <Button variant='contained' onClick={handlePreviousClick}>
                  previous
                </Button>
              ) : (
                <div></div>
              )}
              {selectedAnswers.length && showNextButton && (
                <Button variant='contained' onClick={handleNextClick}>
                  Next
                </Button>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default VectorAIQuestionGenerator;
