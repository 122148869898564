import React from 'react';
import { Box, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const FeedbackQuestionDisplay = ({ currentQuestion, currentQuestionIndex, selectedAnswer }) => {
  if (!currentQuestion || currentQuestionIndex === undefined) {
    return null; // Ensure no rendering if question or index is undefined
  }

  const { questions, options, question_image, correct_answer, question_type } = currentQuestion;

  if (!options || options.length === 0) {
    console.error('Options array is missing or empty.');
    return null; // Ensure no rendering if options are not available
  }

  const selectedValue = selectedAnswer || '0';

  const renderMCQ = () => (
    <Box sx={{ marginLeft: '20px', padding: '20px', backgroundColor: '#E5E4E2' }}>
      <Typography variant='body1' gutterBottom>
        {`Q${currentQuestionIndex + 1}. ${questions}`}
      </Typography>
      {question_image && (
        <Box
          component='img'
          sx={{ maxWidth: '100%', maxHeight: '100%', margin: '10px' }}
          src={question_image}
          alt='Question related'
        />
      )}
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {options.map((option, index) => {
            const optionValue = String(index + 1);
            const isSelected = selectedValue === optionValue;
            const isCorrect = correct_answer === optionValue;
            let backgroundColor = '#E5E4E2'; // default color
            let icon = null;

            if (isSelected && !isCorrect) {
              backgroundColor = '#f44336'; // red if selected and incorrect
              icon = <CancelIcon sx={{ color: 'white', marginLeft: '10px' }} />;
            } else if (isSelected && isCorrect) {
              backgroundColor = '#4CAF50'; // green if selected and correct
              icon = <CheckCircleIcon sx={{ color: 'white', marginLeft: '10px' }} />;
            } else if (isCorrect && !isSelected) {
              backgroundColor = '#4CAF50'; // green if correct and not selected
              icon = <CheckCircleIcon sx={{ color: 'white', marginLeft: '10px' }} />;
            }

            return (
              <Box
                key={index}
                value={optionValue}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '10px',
                  padding: '5px',
                  borderRadius: '5px',
                  backgroundColor,
                }}
              >
                {option?.match(/\.(jpeg|jpg|gif|png)$/) ? (
                  <>
                    <span style={{ marginRight: '10px' }}>
                      {`(${String.fromCharCode(49 + index)})`}.
                    </span>
                    <img
                      src={option}
                      alt={`Option ${String.fromCharCode(49 + index)}`}
                      style={{ maxWidth: '75%', maxHeight: '75%', margin: '10px' }}
                    />
                  </>
                ) : (
                  <span style={{ marginRight: '10px' }}>
                    {`(${String.fromCharCode(49 + index)})`}. {option}
                  </span>
                )}
                {icon}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );

  const renderMatching = () => {
    const tableStartIndex = questions.indexOf('<table>');
    const tableEndIndex = questions.indexOf('</table>') + '</table>'.length;
    const tableContent = questions.substring(tableStartIndex, tableEndIndex);

    const cssAppliedContent = (tableContent) => `
    <div>
      <style>
        table {
        background-color: #f2f2f2;
        }
        th, tr, td {
        border: 1px solid black; 
        }
      </style>
      ${tableContent}
    <div>
    `;

    return (
      <Box sx={{ marginLeft: '20px', padding: '20px', backgroundColor: '#E5E4E2' }}>
        <Typography variant='body1'>{`Q${currentQuestionIndex + 1}`}. Matching Question</Typography>
        <div dangerouslySetInnerHTML={{ __html: cssAppliedContent(tableContent) }} />
        <br />
        {question_image && (
          <Box
            component='img'
            sx={{ maxWidth: '100%', maxHeight: '100%', margin: '10px' }}
            src={question_image}
            alt='Question related'
          />
        )}
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {options.map((option, index) => {
              const optionValue = String(index + 1);
              const isSelected = selectedValue === optionValue;
              const isCorrect = correct_answer === optionValue;
              let backgroundColor = '#E5E4E2'; // default color
              let icon = null;

              if (isSelected && !isCorrect) {
                backgroundColor = '#f44336'; // red if selected and incorrect
                icon = <CancelIcon sx={{ color: 'white', marginLeft: '10px' }} />;
              } else if (isSelected && isCorrect) {
                backgroundColor = '#4CAF50'; // green if selected and correct
                icon = <CheckCircleIcon sx={{ color: 'white', marginLeft: '10px' }} />;
              } else if (isCorrect && !isSelected) {
                backgroundColor = '#4CAF50'; // green if correct and not selected
                icon = <CheckCircleIcon sx={{ color: 'white', marginLeft: '10px' }} />;
              }

              return (
                <Box
                  key={index}
                  value={optionValue}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '10px',
                    padding: '5px',
                    borderRadius: '5px',
                    backgroundColor,
                  }}
                >
                  {option?.match(/\.(jpeg|jpg|gif|png)$/) ? (
                    <>
                      <span style={{ marginRight: '10px' }}>
                        {`(${String.fromCharCode(49 + index)})`}.
                      </span>
                      <img
                        src={option}
                        alt={`Option ${String.fromCharCode(49 + index)}`}
                        style={{ maxWidth: '75%', maxHeight: '75%', margin: '10px' }}
                      />
                    </>
                  ) : (
                    <span style={{ marginRight: '10px' }}>
                      {`(${String.fromCharCode(49 + index)})`}. {option}
                    </span>
                  )}
                  {icon}
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <>
      {question_type === 'MCQ' && renderMCQ()}
      {question_type === 'MATCH' && renderMatching()}
    </>
  );
};

export default FeedbackQuestionDisplay;
