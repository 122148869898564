import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { useState, useEffect } from 'react';
import { fetchWrapper } from '_helpers';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Button, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NcertChapterAnalysis = ({ grade }) => {
    const user = useSelector((x) => x.auth.value);
    const [aiDetail, setAiDetails] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [chapters, setChapters] = useState([]);
    const [sections, setSections] = useState([]);
    const [subsections, setSubsections] = useState([]);
    const [dropdownSubject, setDropdownSubject] = useState('');
    const [dropdownChapter, setDropdownChapter] = useState('');
    const [dropdownSection, setDropdownSection] = useState('');
    const [dropdownSubsection, setDropdownSubsection] = useState('');
    const [selectedLevel, setSelectedLevel] = useState([]);
    const [selectedAttempt, setSelectedAttempt] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
            try {
                let aiEmail = user.email;
                let board = "CBSE";
                const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;
                let response = await fetchWrapper.get(`${baseUrl}/getNeetAnalysisPerformance?email=${aiEmail}&board=${board}`);

                const filteredData = response.aiNeetPerformance.filter(item => item.class === grade);

                const performanceData = response.aiNeetPerformance || [];
                if (filteredData) {
                    setAiDetails(performanceData);

                    // Extract unique subjects, chapters, sections, and subsections
                    const uniqueSubjects = [...new Set(performanceData.map(item => item.subject))];
                    setSubjects(uniqueSubjects);

                    const uniqueChapters = [...new Set(performanceData.map(item => item.chapter))];
                    setChapters(uniqueChapters);

                    const uniqueSections = [...new Set(performanceData.map(item => item.section))];
                    setSections(uniqueSections);

                    const uniqueSubsections = [...new Set(performanceData.map(item => item.subsection))];
                    setSubsections(uniqueSubsections);
                }

            } catch (error) {
                console.error('Error fetching AI data:', error);
            }
        }
        if (aiDetail.length === 0) {
            fetchData();
        }

    }, [aiDetail.length, user.email, grade]);

    useEffect(() => {
        // Reset dependent dropdowns
        setDropdownChapter('');
        setDropdownSection('');
        setDropdownSubsection('');
    }, [dropdownSubject]);

    useEffect(() => {
        // Filter chapters based on the selected subject
        const filteredChapters = aiDetail
            .filter(item => item.subject === dropdownSubject)
            .map(item => item.chapter);
        setChapters([...new Set(filteredChapters)]); // Ensure uniqueness
        setDropdownChapter(''); // Reset chapter
        setDropdownSection(''); // Reset section
        setDropdownSubsection(''); // Reset subsection
    }, [dropdownSubject]);

    useEffect(() => {
        // Filter sections based on the selected chapter
        const filteredSections = aiDetail
            .filter(item => item.chapter === dropdownChapter)
            .map(item => item.section);
        setSections([...new Set(filteredSections)]); // Ensure uniqueness
        setDropdownSection(''); // Reset section
        setDropdownSubsection(''); // Reset subsection
    }, [dropdownChapter]);

    useEffect(() => {
        // Filter subsections based on the selected section
        const filteredSubsections = aiDetail
            .filter(item => item.section === dropdownSection)
            .map(item => item.subsection);
        setSubsections([...new Set(filteredSubsections)]); // Ensure uniqueness
        setDropdownSubsection(''); // Reset subsection
    }, [dropdownSection]);

    const handleSubjectChange = (event) => {
        setDropdownSubject(event.target.value);
    };

    const handleChapterChange = (event) => {
        setDropdownChapter(event.target.value);
    };

    const handleSectionChange = (event) => {
        setDropdownSection(event.target.value);
    };

    const handleSubsectionChange = (event) => {
        setDropdownSubsection(event.target.value);
    };

    const filteredSubsectionsWithQuiz = subsections.filter(subsection => subsection.toLowerCase().includes('quiz'));

    const handleAiPerformance = (aiDetail, selectedSubsectionId) => {
        const attempts = {};
        aiDetail
            .filter(detail => detail.subsection === selectedSubsectionId)
            .forEach(detail => {
                const { id, level, percent_score } = detail;
                if (!attempts[level]) {
                    attempts[level] = [];
                }
                attempts[level].push({ id, percent_score });
                console.log("ai-deatail", level)
                console.log("ai-deatail", percent_score)
            });

        for (const level in attempts) {
            attempts[level] = attempts[level].slice(-4);
        }
        return attempts;
    };

    const attemptsData = handleAiPerformance(aiDetail, dropdownSubsection);

    const attemptData = {};
    for (let i = 0; i < 4; i++) {
        const attemptNumber = i + 1;
        const attempt = {};
        for (const level in attemptsData) {
            if (attemptsData[level][i]) {
                attempt[level] = {
                    id: attemptsData[level][i].id,
                    percent_score: attemptsData[level][i].percent_score
                };
            }
        }
        attemptData[`Attempt ${attemptNumber}`] = attempt;
    }

    const examNames = Object.keys(attemptsData);
    const xLabels = ['Attempt 1', 'Attempt 2', 'Attempt 3', 'Attempt 4'];

    const levelColors = {
        'easy': '#198754',
        'medium': '#ffc107',
        'hard': '#dc3545',
        'real-world': '#6c757d',
        // 'custom-quiz': '#2196f3'
    };

    const series = examNames.map((examName) => ({
        data: attemptsData[examName].map(item => item.percent_score),
        label: examName,
        color: levelColors[examName.toLowerCase()] || '#2196f3'
    }));

    const handleLevelFilter = (level) => {
        if (selectedLevel.includes(level)) {
            setSelectedLevel(selectedLevel.filter((selected) => selected !== level));
        } else {
            setSelectedLevel([...selectedLevel, level]);
        }
    };

    const handleAttemptFilter = (attempt) => {
        setSelectedAttempt(attempt);
    };

    const handleHelpClick = (grade, chapterId, sectionId, subSectionId) => {

        const correctedSubSectionId = subSectionId.slice(0, -1) + 'p';
        const url = `/dashboard/standard/${grade}/subject/${dropdownSubject}/chapter/${chapterId}/section/${sectionId}/subsection/${correctedSubSectionId}`;
        navigate(url);
    };

    return (
        <>
            <div>
                <div className='container-fluid'>
                    <div className='mt-3'>
                        <div className='h3'>CBSE Performance Analysis</div>
                    </div>
                </div>
                <Box className="d-flex">
                    <FormControl fullWidth className='p-2'>
                        <InputLabel sx={{ color: "#fff" }}>Subject</InputLabel>
                        <Select sx={{ background: "#2bafec" }} value={dropdownSubject} onChange={handleSubjectChange}>
                            <MenuItem value=''>None</MenuItem>
                            {subjects.map((subject, index) => (
                                <MenuItem key={index} value={subject}>
                                    {subject}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth className='p-2'>
                        <InputLabel sx={{ color: "#fff" }}>Chapter</InputLabel>
                        <Select sx={{ background: "#2bafec" }} value={dropdownChapter} onChange={handleChapterChange}>
                            <MenuItem value=''>None</MenuItem>
                            {chapters.map((chapter, index) => (
                                <MenuItem key={index} value={chapter}>
                                    {chapter}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <Box className="d-flex">
                    <FormControl fullWidth className='p-2'>
                        <InputLabel sx={{ color: "#fff" }}>Section</InputLabel>
                        <Select sx={{ background: "#2bafec" }} value={dropdownSection} onChange={handleSectionChange}>
                            <MenuItem value=''>None</MenuItem>
                            {sections.map((section, index) => (
                                <MenuItem key={index} value={section}>
                                    {section}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth className='p-2'>
                        <InputLabel sx={{ color: "#fff" }}>Subsection</InputLabel>
                        <Select sx={{ background: "#2bafec" }} value={dropdownSubsection} onChange={handleSubsectionChange}>
                            <MenuItem value=''>None</MenuItem>
                            {filteredSubsectionsWithQuiz.map((subsection, index) => (
                                <MenuItem key={index} value={subsection}>
                                    {subsection}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </div>

            {dropdownSubject && dropdownChapter && dropdownSection && dropdownSubsection && (
                <Box sx={{ background: "#fff" }}>
                    <BarChart
                        width={1000}
                        height={300}
                        series={series}
                        xAxis={[{ data: xLabels, scaleType: 'band' }]}
                        yAxis={[{ min: 0, max: 100 }]}
                    />
                    <div className='d-flex m-2'>
                        {['easy', 'medium', 'hard', 'real-world'].map((level) => (
                            <Button size="small"
                                key={level}
                                variant={selectedLevel.includes(level) ? 'contained' : 'outlined'}
                                onClick={() => handleLevelFilter(level)}
                                sx={{ minWidth: 0, width: 100, padding: '2px 2px', height: 35, marginRight: '10px' }}
                            >
                                {level}
                            </Button>
                        ))}

                        <FormControl fullWidth className="w-50" size="small">
                            <InputLabel id="demo-simple-select-label">Attempt</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedAttempt}
                                label='Attempt'
                                style={{ textAlign: 'left' }}
                                onChange={(e) => handleAttemptFilter(e.target.value)}
                                sx={{ width: 140 }}
                            >
                                {xLabels.map((label) => (
                                    <MenuItem key={label} value={label}>
                                        {label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Button
                            sx={{
                                minWidth: 0,
                                width: 110,
                                padding: '2px 4px !important',
                                height: 35,
                                backgroundColor: selectedLevel.length === 0 && selectedAttempt.length === 0 ? '#dc3545' : '#fff',
                                color: selectedLevel.length === 0 && selectedAttempt.length === 0 ? '#fff' : '#dc3545',
                                borderColor: selectedLevel.length === 0 && selectedAttempt.length === 0 ? '#dc3545' : '#dc3545',
                                '&:hover': {
                                    backgroundColor: '#dc3545',
                                    color: '#fff',
                                    border: '#dc3545'
                                }
                            }}
                            variant={selectedLevel.length === 0 && selectedAttempt.length === 0 ? 'contained' : 'outlined'}
                            onClick={() => {
                                setSelectedLevel([]);
                                setSelectedAttempt('');
                            }}
                        >
                            Clear Filter
                        </Button>
                    </div>

                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Test ID</TableCell>
                                    <TableCell align="center">Quiz Name</TableCell>
                                    <TableCell align="center">Level</TableCell>
                                    <TableCell align="center">Attempt</TableCell>
                                    <TableCell align="center">Attempt Date</TableCell>
                                    <TableCell align="center">Obtained Marks</TableCell>
                                    <TableCell align="center">Total Correct Answers</TableCell>
                                    <TableCell align="center">Total Wrong Answers</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {aiDetail
                                    .filter(data => {
                                        const selectedAttemptData = attemptData[selectedAttempt];
                                        const isAttemptMatch = !selectedAttempt ||
                                            (selectedAttemptData && selectedAttemptData[data.level] && data.id === selectedAttemptData[data.level].id);
                                        const attemptKeys = Object.keys(attemptData);
                                        const isBarChartAttempt = attemptKeys.some(key => {
                                            const attempt = attemptData[key][data.level];
                                            return attempt && attempt.id === data.id;
                                        });
                                        return isAttemptMatch && isBarChartAttempt &&
                                            data.subsection === dropdownSubsection &&
                                            (selectedLevel.length === 0 || selectedLevel.includes(data.level));
                                    })
                                    .map((data, index) => {
                                        const attempt = Object.keys(attemptData).find(key => attemptData[key][data.level]?.id === data.id);
                                        return (
                                            <TableRow key={index}>
                                                <TableCell component="th" scope="row">{data.id}</TableCell>
                                                <TableCell align="center">{data.subsection}</TableCell>
                                                <TableCell align="center">{data.level}</TableCell>
                                                <TableCell align="center">{attempt}</TableCell>
                                                <TableCell align="center">{new Date(data.created_at).toLocaleString()}</TableCell>
                                                <TableCell align="center">{data.obtained_marks}</TableCell>
                                                <TableCell align="center">{data.right_answers}</TableCell>
                                                <TableCell align="center">{data.wrong_answers}</TableCell>
                                                <TableCell align="center"><Button onClick={() => handleHelpClick(data.class, data.chapter_id, data.section_id, data.sub_section_id)}>HELP ME!</Button></TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            )}
        </>
    );
};

export { NcertChapterAnalysis };
