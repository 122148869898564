import React from 'react';
import { Box, Grid } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ProductClass8_1 from '../../../_assets/images/productClass8_1.jpg';
import ProductClass8_2 from '../../../_assets/images/productClass8_2.jpg';
import ProductClass8_3 from '../../../_assets/images/productClass8_3.jpg';
import ProductClass8_4 from '../../../_assets/images/productClass8_4.jpg';
import ProductClass8_5 from '../../../_assets/images/productClass8_5.jpg';
import ProductClass9_1 from '../../../_assets/images/productClass9_1.jpg';
import ProductClass9_2 from '../../../_assets/images/productClass9_2.jpg';
import ProductClass9_3 from '../../../_assets/images/productClass9_3.jpg';
import ProductClass9_4 from '../../../_assets/images/productClass9_4.jpg';
import ProductClass9_5 from '../../../_assets/images/productClass9_5.jpg';
import ProductClass10_1 from '../../../_assets/images/productClass10_1.jpg';
import ProductClass10_2 from '../../../_assets/images/productClass10_2.jpg';
import ProductClass10_3 from '../../../_assets/images/productClass10_3.jpg';
import ProductClass10_4 from '../../../_assets/images/productClass10_4.jpg';
import ProductClass10_5 from '../../../_assets/images/productClass10_5.jpg';
import ProductNeet1 from '../../../_assets/images/productNeet1.jpg';
import ProductNeet2 from '../../../_assets/images/productNeet2.jpg';
import ProductNeet3 from '../../../_assets/images/productNeet3.jpg';
import ProductNeet4 from '../../../_assets/images/productNeet4.jpg';
import ProductNeet5 from '../../../_assets/images/productNeet5.jpg';
import ProductNeet6 from '../../../_assets/images/productNeet6.jpg';
import ProductNeet7 from '../../../_assets/images/productNeet7.jpg';

const productSlides = {
  CLASS_8: [
    { type: 'image', src: ProductClass8_1, alt: 'Product Class 8 1' },
    { type: 'image', src: ProductClass8_2, alt: 'Product Class 8 2' },
    { type: 'image', src: ProductClass8_3, alt: 'Product Class 8 3' },
    { type: 'image', src: ProductClass8_4, alt: 'Product Class 8 4' },
    { type: 'image', src: ProductClass8_5, alt: 'Product Class 8 5' },
  ],
  CLASS_9: [
    { type: 'image', src: ProductClass9_1, alt: 'Product Class 9 1' },
    { type: 'image', src: ProductClass9_2, alt: 'Product Class 9 2' },
    { type: 'image', src: ProductClass9_3, alt: 'Product Class 9 3' },
    { type: 'image', src: ProductClass9_4, alt: 'Product Class 9 4' },
    { type: 'image', src: ProductClass9_5, alt: 'Product Class 9 5' },
  ],
  CLASS_10: [
    { type: 'image', src: ProductClass10_1, alt: 'Product Class 10 1' },
    { type: 'image', src: ProductClass10_2, alt: 'Product Class 10 2' },
    { type: 'image', src: ProductClass10_3, alt: 'Product Class 10 3' },
    { type: 'image', src: ProductClass10_4, alt: 'Product Class 10 4' },
    { type: 'image', src: ProductClass10_5, alt: 'Product Class 10 5' },
  ],
  NEET_PREPARATION: [
    { type: 'image', src: ProductNeet1, alt: 'Product NEET 1' },
    { type: 'image', src: ProductNeet2, alt: 'Product NEET 2' },
    { type: 'image', src: ProductNeet3, alt: 'Product NEET 3' },
    { type: 'image', src: ProductNeet4, alt: 'Product NEET 4' },
    { type: 'image', src: ProductNeet5, alt: 'Product NEET 5' },
    { type: 'image', src: ProductNeet6, alt: 'Product NEET 6' },
    { type: 'image', src: ProductNeet7, alt: 'Product NEET 7' },
  ],
};

export const ProductDiscount = ({ selectedCategory }) => {
  const selectedSlides = productSlides[selectedCategory] || [];

  return (
    <Box
      sx={{
        maxWidth: { xs: '100%', sm: '90%', md: '650px' },
        margin: '0 auto',
        padding: { xs: '10px', md: '20px' }, // Add padding for smaller screens
      }}
    >
      <Grid container justifyContent='center'>
        <Grid item xs={12}>
          <Carousel showArrows={true} infiniteLoop={true} showThumbs={false} showStatus={false}>
            {selectedSlides.map((media, index) => (
              <div key={index}>
                {media.type === 'image' ? (
                  <img
                    src={media.src}
                    alt={media.alt}
                    style={{
                      width: '100%',
                      height: 'auto',
                      objectFit: 'cover',
                      maxHeight: { xs: '300px', sm: '400px', md: '600px' },
                    }} // Responsive image size
                  />
                ) : (
                  <video
                    src={media.src}
                    alt={media.alt}
                    controls
                    style={{
                      width: '100%',
                      height: 'auto',
                      maxHeight: { xs: '300px', sm: '400px', md: '600px' },
                    }} // Responsive video size
                  />
                )}
              </div>
            ))}
          </Carousel>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductDiscount;
