import React from 'react';
import { useSelector } from 'react-redux';
import IndividualHome from './../individualDashboard/individualHome';
import { NeetHome } from './../individualNeetDashboard/neetHome';

const Dashboard = () => {
    const user = useSelector((x) => x.auth.value);
    const { plan_courses } = user || {};

    return (
        <div>
            {plan_courses === 'NEET_PREPARATION' && <NeetHome />}
            {['CLASS_10', 'CLASS_9', 'CLASS_8'].includes(plan_courses) && <IndividualHome />}
            {!['NEET_PREPARATION', 'CLASS_10', 'CLASS_9', 'CLASS_8'].includes(plan_courses) && <div> invalid</div>}
        </div>
    );
};

export default Dashboard;
