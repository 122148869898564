import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Grid,
  TextField,
  Button,
  Box,
  IconButton,
  Stack,
  Typography,
  MenuItem,
} from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchWrapper } from '_helpers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Country, State, City } from 'country-state-city';
import { useRef } from 'react';
import dayjs from 'dayjs';

const validationSchema = Yup.object().shape({
  sch_name: Yup.string().required('School Name is required'),
  sch_admin_email: Yup.string().email('Invalid email').required('School Admin Email is required'),
  sch_password: Yup.string()
    .required('School Password is required')
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
      'Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, and one number',
    ),
  sch_admin_name: Yup.string().required('School Admin Name is required'),
  sch_address: Yup.string().required('School Address is required'),
  sch_type: Yup.string().required('School Type  is required'),
  sch_start_date: Yup.date(),
  sch_end_date: Yup.date(),
});

const AddSchool = ({ editMode, schoolData }) => {
  const navigate = useNavigate();
  const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;
  const [schoolTypes, setSchoolTypes] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedFileChange, setSelectedFileChange] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');

  const [phoneNumber, setPhoneNumber] = useState('');
  const [dialCode, setDialCode] = useState('');
  const fileInputRef = useRef(null);

  const handleBack = () => {
    navigate(-1); // This will navigate back to the previous route.
  };

  const countries = Country?.getAllCountries();
  const countryOptions = countries?.map((country) => ({
    label: country.name,
    value: country?.isoCode,
  }));

  const states = selectedCountry ? State.getStatesOfCountry(selectedCountry) : [];
  const stateOptions = states?.map((state) => ({
    label: state.name,
    value: state.isoCode,
  }));

  const cities = selectedState ? City.getCitiesOfState(selectedCountry, selectedState) : [];
  const cityOptions = cities?.map((city) => ({
    label: city.name,
    value: city.name,
  }));

  useEffect(() => {
    if (selectedCountry) {
      const countryData = Country.getAllCountries().find(
        (country) => country.isoCode === selectedCountry,
      );
      setDialCode(countryData?.phonecode ? `+${countryData.phonecode}` : '');
    }
  }, [selectedCountry]);
  useEffect(() => {
    if (editMode && schoolData) {
      setPhoneNumber(schoolData.sch_phone);
      const selectedCountryISOCode = countries?.find(
        (country) => country.name === schoolData.country,
      )?.isoCode;
      setSelectedCountry(selectedCountryISOCode || '');

      const selectedStateISOCode = selectedCountryISOCode
        ? State.getStatesOfCountry(selectedCountryISOCode)?.find(
            (state) => state.name === schoolData.state,
          )?.isoCode
        : '';
      setSelectedState(selectedStateISOCode || '');

      const selectedCityName = selectedStateISOCode
        ? City.getCitiesOfState(selectedCountryISOCode, selectedStateISOCode)?.find(
            (city) => city.name === schoolData.city,
          )?.name
        : '';
      setSelectedCity(selectedCityName || '');
      // startDate and end date
      const startDate = dayjs(new Date(schoolData.sch_start_date));
      setSelectedStartDate(startDate);

      // Parse and format the end date
      const endDate = dayjs(new Date(schoolData.sch_end_date));
      setSelectedEndDate(endDate);
    }
  }, [editMode, schoolData, countries]);

  useEffect(() => {
    getActivatedSchool();
  }, []);
  const getActivatedSchool = async () => {
    try {
      const response = await fetchWrapper.get(`${baseUrl}/getSchoolType`);
      if (response === null || response.length === 0) {
        toast.info('No school types found');
      } else {
        setSchoolTypes(response.data);
      }
    } catch (error) {
      toast.error(error.message || 'An error occurred');
    }
  };

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
    setSelectedState('');
    setSelectedCity('');
  };

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
    setSelectedCity('');
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };
  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFileChange(file);
  };
  const handlePhoneChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const [formValues, setFormValues] = useState(() => {
    if (editMode) {
      return {
        sch_admin_email: schoolData.sch_admin_email || '',
        sch_admin_name: schoolData.sch_admin_name || '',
        sch_password: schoolData.sch_password || '',
        sch_address: schoolData.sch_address || '',
        sch_start_date: schoolData.sch_start_date || '',
        sch_end_date: schoolData.sch_end_date || '',
        sch_active: schoolData.sch_active || '',
        sch_type: schoolData.sch_type || '',
        sch_confirm: schoolData.sch_confirm || '',
        sch_phone: schoolData.sch_phone || '',
        sch_name: schoolData.sch_name || '',
        sch_Image: schoolData.sch_Image || '',
        country: schoolData.country || '',
        state: schoolData.state || '',
        city: schoolData.city || '',
        sch_phone_code: schoolData.sch_phone_code || '',
      };
    } else {
      return {
        sch_admin_email: '',
        sch_admin_name: '',
        sch_password: '',
        sch_address: '',
        selectedStartDate: '',
        selectedEndDate: '',
        sch_active: '',
        sch_type: '',
        sch_confirm: '',
        sch_phone: '',
        sch_name: '',
        selectedFileChange: '',
        selectedCountry: '',
        selectedState: '',
        selectedCity: '',
        dialCode: '',
      };
    }
  });

  const handleSubmit = async (values, actions) => {
    try {
      if (!selectedStartDate) {
        throw new Error('Start Date is required');
      }
      if (!selectedEndDate) {
        throw new Error('End Date is required');
      }

      if (selectedEndDate <= selectedStartDate) {
        throw new Error('End Date must be greater than Start Date');
      }

      if (!selectedCountry) {
        throw new Error('Country is required');
      }
      if (!selectedState) {
        throw new Error('State is required');
      }
      if (!selectedCity) {
        throw new Error('City is required');
      }
      if (!phoneNumber) {
        throw new Error('phone is required');
      }
      // Remove the file validation check for new submissions
      // if (!editMode && !selectedFileChange) {
      //   throw new Error('File is required');
      // }
  
      const countryData = Country.getAllCountries().find(
        (country) => country.isoCode === selectedCountry,
      );
      const countryName = countryData ? countryData.name : '';
      const stateData = State.getStatesOfCountry(selectedCountry).find(
        (state) => state.isoCode === selectedState,
      );
      const stateName = stateData ? stateData.name : ''; 

      const formData = new FormData();
      formData.append('sch_admin_email', values.sch_admin_email);
      formData.append('sch_password', values.sch_password);
      formData.append('sch_admin_name', values.sch_admin_name);
      formData.append('sch_address', values.sch_address);
      formData.append('sch_start_date', selectedStartDate?.format('YYYY-MM-DD'));
      formData.append('sch_end_date', selectedEndDate?.format('YYYY-MM-DD'));
      formData.append('sch_active', values.sch_active);
      formData.append('sch_type', values.sch_type);
      formData.append('sch_confirm', values.sch_confirm);
      formData.append('sch_phone', phoneNumber);
      formData.append('sch_phone_code', dialCode);
      formData.append('sch_name', values.sch_name);
      formData.append('country', countryName);
      formData.append('state', stateName);
      formData.append('city', selectedCity);
      // Handle null selectedFileChange case for editMode
      if (selectedFileChange) {
        formData.append('sch_img', selectedFileChange);
      }
  
      if (editMode && formData) {
        delete values['school_password'];
        await fetchWrapper.put(`${baseUrl}/updateSchool/${schoolData['sch_id']}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      } else {
        await fetchWrapper.post(`${baseUrl}/admin/createSchool`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      }
      if (editMode) {
        setFormValues({
          sch_admin_email: values.sch_admin_email,
          sch_admin_name: values.sch_admin_name,
          sch_password: values.sch_password,
          sch_address: values.sch_address,
          sch_active: values.sch_active,
          sch_type: values.sch_type,
          sch_confirm: values.sch_confirm,
          sch_phone: phoneNumber,
          sch_name: values.sch_name,
          sch_Image: selectedFileChange ,
          country: selectedCountry,
          state: selectedState,
          city: selectedCity,
          sch_start_date: selectedStartDate,
          sch_end_date: selectedEndDate,
          sch_phone_code: dialCode,
        });
      } else {
        setFormValues({
          sch_admin_email: '',
          sch_admin_name: '',
          sch_password: '',
          sch_address: '',
          sch_active: '',
          sch_type: '',
          sch_confirm: '',
          sch_phone: '',
          sch_name: '',
          sch_Image: '',
          country: '',
          state: '',
          city: '',
          sch_start_date: '',
          sch_end_date: '',
          sch_phone_code: '',
          selectedFileChange: null, // Reset selectedFileChange state
        });
        actions.resetForm();
        setSelectedCountry('');
        setSelectedState('');
        setSelectedCity('');
        setPhoneNumber('');
        setSelectedStartDate(null);
        setSelectedEndDate(null);
        setSelectedFileChange(null);
      }
   
   

      toast.success('School ' + (editMode ? 'updated' : 'created') + ' successfully');
    } catch (error) {
      toast.error(error.message || 'An error occurred');
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <div>
      <Helmet>
        <title> Add New School </title>
      </Helmet>
      <Stack direction='row' alignItems='center' mb={5}>
        <IconButton aria-label='back' size='large' onClick={handleBack}>
          <ArrowBackIcon fontSize='inherit' style={{ color: '#092141' }} />
        </IconButton>
        <Typography variant='h4' gutterBottom>
          {editMode ? 'Edit school' : 'Add New school'}
        </Typography>
      </Stack>
      <div>
        <Box sx={{ padding: 5 }}>
          <Formik
            initialValues={formValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <div className='d-flex'>
                {editMode && (
                  <Box
                    sx={{
                      minWidth: '200px',
                      border: '1px solid #f6f6f6',
                      background: 'white',
                      borderRadius: '10px',
                      margin: '10px',
                      padding: '10px',
                    }}
                  >
                    <span className='component-image MuiBox-root css-fnjgej'>
                      <span className='component-image-wrapper lazy-load-image-background blur lazy-load-image-loaded d-block'>
                      {selectedFileChange && ( // Conditional rendering here
                      <img
                        src={URL.createObjectURL(selectedFileChange)}
                        alt='School Image'
                        style={{
                          maxHeight: '200px',
                          maxWidth: '150px',
                          objectFit: 'contain',
                          margin: ' 20px auto ',
                        }}
                       />
                     )}
                  {!selectedFileChange && ( // Render previous image if no new image selected
                  <img
                    className='MuiBox-root css-3j6ntj  rounded'
                    alt='avatar'
                    style={{
                      maxHeight: '200px',
                      maxWidth: '150px',
                      objectFit: 'contain',
                      margin: ' 20px auto ',
                    }}
                   src={
                     editMode
                        ? `${schoolData.sch_img}`
                        : 'https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_25.jpg'
                    }
                  />
                 )}
                      </span>
                    </span>
                    <label
                      htmlFor='image-upload'
                      className='custom-upload-button'
                      style={{
                        display: 'inlineBlock',
                        padding: '10px 15px',
                        backgroundColor: '#4caf50',
                        color: '#fff',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        marginTop: '100px',
                      }}
                    >
                      Upload Image
                    </label>
                    <input
                      id='image-upload'
                      accept='image/*'
                      type='file'
                      style={{ display: 'none', backgroundColor: '#45a049', objectFit: 'cover' }}
                      onChange={handleFileChange}
                      ref={fileInputRef}
                    />
                  </Box>
                )}

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Field
                      as={TextField}
                      label='School Name'
                      variant='outlined'
                      fullWidth
                      name='sch_name'
                    />
                    <ErrorMessage name='sch_name' component='div' className='error' />
                  </Grid>
                  {editMode ? (
                    <Grid item xs={6}>
                      <Field
                        as={TextField}
                        label='School Email'
                        variant='outlined'
                        fullWidth
                        name='sch_admin_email'
                        disabled
                      />
                      <ErrorMessage name='sch_admin_email' component='div' className='error' />
                    </Grid>
                  ) : (
                    <Grid item xs={6}>
                      <Field
                        as={TextField}
                        label='School Email'
                        variant='outlined'
                        fullWidth
                        name='sch_admin_email'
                      />
                      <ErrorMessage name='sch_admin_email' component='div' className='error' />
                    </Grid>
                  )}
                  <Grid item xs={6}>
                    <Field
                      as={TextField}
                      label='School Password'
                      type='password'
                      variant='outlined'
                      fullWidth
                      name='sch_password'
                    />
                    <ErrorMessage name='sch_password' component='div' className='error' />
                  </Grid>

                  <Grid item xs={6}>
                    <Field
                      as={TextField}
                      label='School Admin Name'
                      variant='outlined'
                      fullWidth
                      name='sch_admin_name'
                    />
                    <ErrorMessage name='sch_admin_name' component='div' className='error' />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      as={TextField}
                      label='School Address'
                      variant='outlined'
                      fullWidth
                      name='sch_address'
                    />
                    <ErrorMessage name='sch_address' component='div' className='error' />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      as={TextField}
                      label='School Type'
                      variant='outlined'
                      fullWidth
                      name='sch_type'
                      select
                    >
                      {schoolTypes.map((option) => (
                        <MenuItem key={option.id} value={option.name}>
                          {option.school_type}
                        </MenuItem>
                      ))}
                    </Field>
                    <ErrorMessage name='sch_type' component='div' className='error' />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label='Select a Country'
                      variant='outlined'
                      fullWidth
                      select
                      value={selectedCountry}
                      onChange={handleCountryChange}
                    >
                      {countryOptions &&
                        countryOptions.length &&
                        countryOptions.map((option, index) => (
                          <MenuItem key={index} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label='Select a State'
                      variant='outlined'
                      fullWidth
                      select
                      value={selectedState}
                      onChange={handleStateChange}
                      // disabled={stateOptions.length === 0}
                    >
                      {stateOptions.length === 0 ? (
                        <MenuItem disabled>No states available</MenuItem>
                      ) : (
                        stateOptions &&
                        stateOptions.length &&
                        stateOptions.map((option, index) => (
                          <MenuItem key={index} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))
                      )}
                    </TextField>
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      label='Select a City'
                      variant='outlined'
                      fullWidth
                      select
                      value={selectedCity}
                      onChange={handleCityChange}
                      disabled={!selectedState || cityOptions.length === 0} // Disable if no state selected or no cities available
                    >
                      {cityOptions.length === 0 ? (
                        <MenuItem disabled>No cities available</MenuItem>
                      ) : (
                        cityOptions &&
                        cityOptions.length &&
                        cityOptions.map((option, index) => (
                          <MenuItem key={index} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))
                      )}
                    </TextField>
                  </Grid>

                  <Grid container spacing={1} alignItems='flex-end' item xs={6}>
                    <Grid item xs={6}>
                      {dialCode && (
                        <Field
                          as={TextField}
                          label='country Phone code '
                          variant='outlined'
                          fullWidth
                          name='sch_phone'
                          value={dialCode}
                          onChange={handlePhoneChange}
                          sx={{ width: '100%' }}
                        />
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        as={TextField}
                        label='School Phone Number'
                        variant='outlined'
                        fullWidth
                        name='sch_phone'
                        value={phoneNumber}
                        onChange={handlePhoneChange}
                        sx={{ width: '100%' }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ErrorMessage name='sch_phone' component='div' className='error' />
                    </Grid>
                  </Grid>

                  <Grid item xs={6}>
                    <Field
                      as={TextField}
                      label='School Active'
                      variant='outlined'
                      fullWidth
                      name='sch_active'
                      select
                    >
                      <MenuItem value='1'>Active</MenuItem>
                      <MenuItem value='0'>InActive</MenuItem>
                    </Field>
                    <ErrorMessage name='sch_active' component='div' className='error' />
                  </Grid>
                  {!editMode && (
                    <Grid item xs={6}>
                      <Field
                        as={TextField}
                        type='file'
                        id='sch_img'
                        name='sch_img'
                        accept='image/png, image/jpeg, image/jpg, image/svg+xml'
                        sx={{ width: '100%' }}
                        // value={selectedFileChange ? selectedFileChange.name : ''}
                        onChange={handleFileChange}
                        ref={fileInputRef}
                      />
                      {selectedFileChange ? null : (
                        <ErrorMessage name='sch_img' component='div' className='error' />
                      )}
                    </Grid>
                  )}
                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label='School Start Date'
                        variant='outlined'
                        fullWidth
                        name='sch_start_date'
                        value={selectedStartDate}
                        onChange={handleStartDateChange}
                        renderInput={(params) => <TextField {...params} />}
                        sx={{ width: '100%' }}
                      />
                    </LocalizationProvider>
                    {selectedStartDate ? null : (
                      <ErrorMessage name='sch_start_date' component='div' className='error' />
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label='School End Date'
                        variant='outlined'
                        fullWidth
                        name='sch_end_date'
                        value={selectedEndDate}
                        onChange={handleEndDateChange}
                        renderInput={(params) => <TextField {...params} />}
                        sx={{ width: '100%' }}
                      />
                    </LocalizationProvider>
                    {selectedEndDate ? null : (
                      <ErrorMessage name='sch_end_date' component='div' className='error' />
                    )}
                  </Grid>
                </Grid>
              </div>
              <Button
                type='submit'
                variant='contained'
                sx={{ marginTop: 4, padding: '10px 20px', backgroundColor: '#fe6f61' }}
              >
                Submit
              </Button>
            </Form>
          </Formik>
        </Box>
      </div>
      <ToastContainer />
    </div>
  );
};

export { AddSchool };
