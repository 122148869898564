import React from 'react';
import { Card, CardContent, Typography, Grid, Divider } from '@mui/material';

export const QuizSummary = ({
  totalQuestions,
  wrongAnswers,
  rightAnswers,
  totalMarks,
  obtainedMarks,
}) => (
  <Card variant='outlined' sx={{ maxWidth: 450, margin: '20px auto' }}>
    <CardContent>
      <Typography variant='h6' gutterBottom>
        Quiz Summary
      </Typography>
      <Divider sx={{ marginY: 1 }} />
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Typography variant='body2' color='textSecondary'>
            Total Questions:
          </Typography>
          <Typography variant='body2' fontWeight='bold'>
            {totalQuestions}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='body2' color='textSecondary'>
            Answered Questions:
          </Typography>
          <Typography variant='body2' fontWeight='bold'>
            {wrongAnswers + rightAnswers}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='body2' color='textSecondary'>
            Unanswered Questions:
          </Typography>
          <Typography variant='body2' fontWeight='bold'>
            {totalQuestions - wrongAnswers - rightAnswers}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='body2' color='textSecondary'>
            Wrong Answers:
          </Typography>
          <Typography variant='body2' fontWeight='bold' color='error'>
            {wrongAnswers}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='body2' color='textSecondary'>
            Right Answers:
          </Typography>
          <Typography variant='body2' fontWeight='bold' color='success.main'>
            {rightAnswers}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body2' color='textSecondary'>
            Total Marks:
          </Typography>
          <Typography variant='body2' fontWeight='bold'>
            {totalMarks}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body2' color='textSecondary'>
            Obtained Marks:
          </Typography>
          <Typography variant='body2' fontWeight='bold'>
            {obtainedMarks}
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);
