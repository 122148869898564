import React, { useState, useEffect, useCallback } from 'react';
import {
  Drawer,
  Chip,
  List,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  useMediaQuery,
  IconButton,
} from '@mui/material';
import { Outlet, useLocation, useParams, useNavigate } from 'react-router-dom';
import {
  ExpandMore as ExpandMoreIcon,
  Menu as MenuIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { store } from '_store'; // Assuming this imports your auth store
import { Loader } from '_components';
import NEETFeedbackQuiz from '../neetFeedback/NEETFeedbackQuiz';
import './style.css';
import { useSelector } from 'react-redux';

const FeedbackPanel = () => {
  const { chapterid } = useParams();
  const navigate = useNavigate();
  const auth = useSelector((x) => x.auth.value);
  const [open, setOpen] = useState(true);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [testLevels, setTestLevels] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState([]);
  const NEETFeedbackLocation = useLocation(); // Corrected typo in variable name
  const isNEETFeedbackPath = NEETFeedbackLocation.pathname === '/feedback';
  const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const drawerWidth = isSmallScreen ? '75vw' : '25vw'; // Drawer width based on screen size
  const drawerType = isSmallScreen ? 'temporary' : 'persistent'; // Change drawer type
  const token = authToken(); // Assuming this function retrieves your auth token

  function authToken() {
    return store.getState().auth.value?.token;
  }

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleDrawerClose = useCallback(() => {
    setOpen(false);
  }, []);

  window.handleDrawerClose = handleDrawerClose;

  useEffect(() => {
    const fetchTestLevels = async () => {
      setIsLoading(true);

      try {
        const url = `${baseUrl}/neetTestLevel`;
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          const errorMessage = await response.text();
          throw new Error(errorMessage || 'Failed to fetch test levels');
        }

        const responseData = await response.json();
        // Check if responseData.data is an array and contains expected structure
        if (Array.isArray(responseData.data)) {
          setTestLevels(responseData.data); // Update testLevels state with fetched data
        } else {
          throw new Error('Invalid data format received');
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTestLevels();
  }, [isSmallScreen, selectedLevel]);

  const handleLevelClick = (level) => {
    setSelectedLevel(level);
  };
  if (isLoading) {
    return <Loader />;
  }
  if (error) {
    return (
      <div className='alert alert-danger m-2' role='alert'>
        Please select Mock Test
      </div>
    );
  }

  const handleGotoMockTest = () => {
    navigate('/dashboard/NEET_TEST/subject/All_Subjects');
  };

  return (
    <div>
      {isSmallScreen && (
        <IconButton onClick={handleDrawerToggle} sx={{ position: 'absolute', top: 16, left: 16 }}>
          <MenuIcon sx={{ color: '#fe6f61' }} />
        </IconButton>
      )}
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          top: '20%',
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            top: '8vh',
            height: '92vh',
            zIndex: '100',
          },
        }}
        variant={drawerType}
        anchor='left'
        open={open}
        onClose={handleDrawerToggle}
      >
        <div
          style={{
            display: 'flex',
            paddingTop: '16px',
            justifyContent: 'end',
          }}
        >
          <div className={isSmallScreen ? 'd-grid w-100' : 'd-flex'}>
            {auth.board && (
              <>
                {location.pathname.includes('NEET') && (
                  <Chip label='NEET' color='primary' sx={{ marginRight: '5px' }} />
                )}
                {location.pathname.includes('NEET_TEST') && (
                  <Chip label='NEET_TEST' color='primary' sx={{ marginRight: '5px' }} />
                )}
                {location.pathname.includes('SAT') && (
                  <Chip label='SAT' color='primary' sx={{ marginRight: '5px' }} />
                )}
                {location.pathname.includes('feedback') && (
                  <Chip label='Mock Test Feedback' color='primary' sx={{ marginRight: '5px' }} />
                )}
                {location.pathname.includes('feedback') && (
                  <Chip
                    label='Goto Mock Test'
                    color='warning'
                    sx={{ marginRight: '5px' }}
                    onClick={handleGotoMockTest}
                  />
                )}
              </>
            )}
            <IconButton onClick={handleDrawerToggle}>
              {open ? (
                <CloseIcon
                  sx={{
                    marginLeft: '30px',
                    color: '#fe6f61',
                    fontSize: '26px',
                  }}
                />
              ) : (
                <MenuIcon sx={{ color: '#fe6f61' }} />
              )}
            </IconButton>
          </div>
        </div>
        <>
          {isNEETFeedbackPath && (
            <List>
              {testLevels &&
                testLevels.length > 0 &&
                testLevels?.map((level) => (
                  <Accordion key={level.id} sx={{ backgroundColor: '#fe6f61', color: 'white' }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}>
                      <Typography>
                        {level.id}. {level.test_level} Feedback
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: '0 !important' }}>
                      <Accordion sx={{ backgroundColor: '#092141', color: 'white' }}>
                        <AccordionSummary
                          style={{ borderBottom: '1px solid #fff' }}
                          onClick={() => handleLevelClick(level.test_level)}
                        >
                          <Typography>Open - {level.test_level} Feedback</Typography>
                        </AccordionSummary>
                      </Accordion>
                    </AccordionDetails>
                  </Accordion>
                ))}
            </List>
          )}
        </>
      </Drawer>
      <div
        style={{
          marginLeft: open ? drawerWidth : 0,
          width: '75%',
          transition: 'margin-left 0.3s',
        }}
      >
        {open ? null : (
          <IconButton onClick={handleDrawerToggle}>
            <MenuIcon sx={{ color: '#fe6f61', fontSize: '30px' }} />
          </IconButton>
        )}
        {chapterid ? (
          <Outlet /> // Render nested routes if chapterid is present
        ) : (
          <>
            {isNEETFeedbackPath && selectedLevel && (
              <NEETFeedbackQuiz board={'NEET'} selectedLevel={selectedLevel} />
            )}
            {!isNEETFeedbackPath && ( // Consolidated error message
              <div className='alert alert-danger m-2' role='alert'>
                Please select Chapter
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default FeedbackPanel;
