import React, { useState } from 'react';
import { Grid, Card, CardContent, Typography, Box, Button, Paper } from '@mui/material';
import {  useNavigate } from 'react-router-dom';
import './individualHome.css'; // Import the stylesheet
import science10 from '_assets/images/class-10-science.jpg';
import science9 from '_assets/images/class_9_science.jpg';
import science8 from '_assets/images/class_8_science.jpg';
import maths8 from '_assets/images/class_8_maths.jpg';
import maths9 from '_assets/images/class_9_maths.jpg';
import maths10 from '_assets/images/class_10_maths.jpg';
import english8 from '_assets/images/class_8_english.jpg';
import english9 from '_assets/images/class_9_english.jpg';
import english10 from '_assets/images/class_10_english.jpg';
import { NcertChapterAnalysis } from './NcertChapterAnalysis';
import { useSelector } from 'react-redux';
import bookIcon from '_assets/images/interactive_book.png';
import performanceIcon from '_assets/images/performance_analysis.png';

const subjects = [
  { grade: 10, subject: 'Science', image: science10 },
  { grade: 10, subject: 'Maths', image: maths10 },
  { grade: 10, subject: 'English', image: english10 },
  { grade: 9, subject: 'Science', image: science9 },
  { grade: 9, subject: 'Maths', image: maths9 },
  { grade: 9, subject: 'English', image: english9 },
  { grade: 8, subject: 'Science', image: science8 },
  { grade: 8, subject: 'Maths', image: maths8 },
  { grade: 8, subject: 'English', image: english8 },
];

const IndividualHome = () => {
  const [activeSection, setActiveSection] = useState('Interactive Books');
  const navigate = useNavigate();
 
  const user = useSelector((x) => x.auth.value);
  const { plan_courses, plan_start_date, plan_end_date } = user || {};

  const grade = parseInt(plan_courses.match(/\d+/)?.[0], 10) || 10;

  const plan_course_name = plan_courses.replace(/_/g, ' ');

  // Filter subjects based on the selected grade
  const filteredSubjects = subjects.filter((item) => item.grade === grade);

  const handleBookClick = (grade, subject) => {
    navigate(`/dashboard/standard/${grade}/subject/${subject.toLowerCase()}`);
  };

  return (
    <Box
      className='containerBox'
      sx={{
        '@media (max-width: 600px)': {
          flexDirection: 'column',
        },
      }}
    >
      {/* Left Panel */}
      <Paper className='leftPanel'>
        <Typography variant='h6' className='mb-3'>
          {plan_course_name || 'Course Details'} {/* Dynamically displaying the class */}
        </Typography>
        <Typography variant='body1' className='mb-3'>
          Course Duration
          <br />
          {plan_start_date ? new Date(plan_start_date).toLocaleDateString() : 'Start Date'} -
          {plan_end_date ? new Date(plan_end_date).toLocaleDateString() : 'End Date'}
        </Typography>

        <Button
          className='leftPanelButton'
          variant={activeSection === 'Interactive Books' ? 'contained' : 'outlined'}
          sx={{
            backgroundColor: activeSection === 'Interactive Books' ? '#e74c3c' : 'transparent',
            color: '#fff',
          }}
          onClick={() => setActiveSection('Interactive Books')}
        >
          <img src={bookIcon} />
          Interactive Books
        </Button>
        <Button
          className='leftPanelButton mb-4'
          variant={activeSection === 'Performance Analysis' ? 'contained' : 'outlined'}
          sx={{
            backgroundColor: activeSection === 'Performance Analysis' ? '#e74c3c' : 'transparent',
            color: '#fff',
          }}
          onClick={() => setActiveSection('Performance Analysis')}
        >
          <img src={performanceIcon} />
          Performance Analysis
        </Button>
      </Paper>

      {/* Main Content */}
      <Box sx={{ flexGrow: 1, padding: 6 }}>
        {activeSection === 'Interactive Books' ? (
          <Grid container spacing={4} justifyContent='center'>
            {filteredSubjects.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index} sx={{ marginTop: '5px' }}>
                <Card className='cardBox' onClick={() => handleBookClick(item.grade, item.subject)}>
                  <CardContent sx={{ textAlign: 'center' }}>
                    <Box>
                      <img src={item.image} alt={item.subject} className='cardImage' />
                    </Box>
                    <Box className='cardInfo'>
                      <Typography variant='h6' className='cardTitle'>
                        GRADE : {item.grade}
                      </Typography>
                      <Typography variant='h6' className='cardTitle'>
                        SUBJECT : {item.subject.toUpperCase()}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography variant='h4' color='#fff'>
            <NcertChapterAnalysis grade={grade} />
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default IndividualHome;
