import React from 'react';
import {
  Card,
  CardContent,
  TextField,
  Typography,
  FormControl,
  InputLabel,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { fetchWrapper } from '_helpers';
import { LoadingButton } from '@mui/lab';

const ForgotPassword = () => {
  const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;

  const handlePasswordReset = async (values) => {
    try {
      const response = await fetchWrapper.post(`${baseUrl}/forgotPassword`, {
        email: values.email,
      });

      toast.success(response.message);
    } catch (error) {
      toast.error('Failed to reset password. Please try again.');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
      }}
    >
      <Card
        style={{
          width: '500px',
          backgroundColor: 'white',
          className: 'loginBox',
          position: 'relative',
          zIndex: 2,
          overflow: 'hidden',
          borderRadius: '5px',
        }}
      >
        <CardContent>
          <center>
            <Typography variant='h5' color='#092141' gutterBottom>
              Forgot Password
            </Typography>
            <LockIcon fontSize='large' color='#092141' />
          </center>
          <Formik
            initialValues={{ email: '' }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Invalid email').required('Email is required'),
            })}
            onSubmit={handlePasswordReset}
          >
            {({ values, handleChange, handleBlur, isSubmitting }) => (
              <Form>
                <FormControl margin='normal' fullWidth style={{ paddingTop: '50px' }}>
                  <InputLabel htmlFor='email' className='fw-bold'>
                    Email
                  </InputLabel>
                  <Field
                    as={TextField}
                    label='Email'
                    type='email'
                    variant='outlined'
                    name='email'
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                  />
                  <ErrorMessage name='email' component='div' style={{ color: 'red' }} />
                </FormControl>

                <FormControl margin='normal' fullWidth style={{ paddingTop: '20px' }}>
                  <LoadingButton
                    fullWidth
                    size='large'
                    type='submit'
                    variant='contained'
                    disabled={isSubmitting}
                    sx={{ backgroundColor: '#092141' }}
                  >
                    {isSubmitting ? 'Resetting...' : 'Reset Password'}
                  </LoadingButton>
                </FormControl>
                <br />
                <Link to='/account/login'>Go to Login?</Link>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </div>
  );
};

export default ForgotPassword;
