import React, { useState } from 'react';
import PropTypes from 'prop-types';

const RadioButtonComponent = ({ handleChange }) => {
    const [selectedValue, setSelectedValue] = useState('text');
  
    const handleRadioChange = (event) => {
      setSelectedValue(event.target.value);
      handleChange(event.target.value); // Call the callback function with the selected value
    };
  
  return (
    <div className='container mt-4 d-flex col-12'>
      <div className='form-check mx-3'>
        <input
          className='form-check-input'
          type='radio'
          name='radios'
          id='text'
          value='text'
          checked={selectedValue === 'text'}
          onChange={handleRadioChange}
        />
        <label className='form-check-label' htmlFor='Text'>
          Text
        </label>
      </div>
      <div className='form-check mx-3'>
        <input
          className='form-check-input'
          type='radio'
          name='radios'
          id='radio2D'
          value='2D'
          checked={selectedValue === '2D'}
          onChange={handleRadioChange}
        />
        <label className='form-check-label' htmlFor='radio2D'>
          2D
        </label>
      </div>
      <div className='form-check mx-3'>
        <input
          className='form-check-input'
          type='radio'
          name='radios'
          id='radio3D'
          value='3D'
          checked={selectedValue === '3D'}
          onChange={handleRadioChange}
        />
        <label className='form-check-label' htmlFor='radio3D'>
          3D
        </label>
      </div>
    </div>
  );
};

export { RadioButtonComponent };

RadioButtonComponent.propTypes = {
    handleChange: PropTypes.function,
  };