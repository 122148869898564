import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MathJaxContext } from 'better-react-mathjax';
import './Messages.css';

const Messages = ({ message, onThumbUp, onThumbDown, thumbsUp, thumbsDown }) => {
  const [activeThumb, setActiveThumb] = useState(thumbsUp ? 'up' : thumbsDown ? 'down' : null);
  const [showThumbs, setShowThumbs] = useState(false);
  const [contentLoaded, setContentLoaded] = useState(false);

  useEffect(() => {
    // Show content immediately
    setContentLoaded(true);

    // Show thumbs after a 5-second delay from when content is loaded
    const timer = setTimeout(() => {
      setShowThumbs(true);
    }, 12000);

    // Clean up the timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  const hasContent =
    typeof message.content === 'string' ||
    React.isValidElement(message.content) ||
    (message.content && message.content.__html);

  const renderContent = () => {
    if (typeof message.content === 'string') {
      return (
        <MathJaxContext>
          {message.content.split('\n').map((text, index) => (
            <Fragment key={index}>
              {text}
              <br/>
            </Fragment>
          ))}
        </MathJaxContext>
      );
    } else if (React.isValidElement(message.content)) {
      return message.content;
    } else if (message.content && message.content.__html) {
      return <div dangerouslySetInnerHTML={message.content} />;
    } else {
      return <div>Unsupported message format</div>;
    }
  };

  const handleThumbUp = () => {
    setActiveThumb('up');
    if (onThumbUp) onThumbUp();
  };

  const handleThumbDown = () => {
    setActiveThumb('down');
    if (onThumbDown) onThumbDown();
  };

  return (
    <div className={`message-container ${message.isUser ? 'user-message' : 'bot-message'}`}>
      <div className={`message-content ${message.isUser ? 'user-content' : 'bot-content'}`}>
        {contentLoaded && hasContent && renderContent()}
        {message.isUser &&
          contentLoaded &&
          hasContent &&
          showThumbs && (
            <div className="thumbs-container">
              {activeThumb !== 'down' && (
                <button
                  onClick={handleThumbUp}
                  aria-label="Thumbs up"
                  className={`thumb-button ${activeThumb === 'up' ? 'active' : ''}`}
                >
                  👍
                </button>
              )}
              {activeThumb !== 'up' && (
                <button
                  onClick={handleThumbDown}
                  aria-label="Thumbs down"
                  className={`thumb-button ${activeThumb === 'down' ? 'active' : ''}`}
                >
                  👎
                </button>
              )}
            </div>
          )}
      </div>
    </div>
  );
};

Messages.propTypes = {
  message: PropTypes.shape({
    content: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
      PropTypes.shape({ __html: PropTypes.string }),
    ]),
    isUser: PropTypes.bool,
  }).isRequired,
  onThumbUp: PropTypes.func,
  onThumbDown: PropTypes.func,
  thumbsUp: PropTypes.bool,
  thumbsDown: PropTypes.bool,
};

export { Messages };
