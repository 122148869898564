import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Card, CardHeader } from '@mui/material';
import { fetchWrapper } from '_helpers';
import { useSelector } from 'react-redux';
import GradeWisePieChart from './GradeWisePieChart.js';

TeacherProgress.propTypes = {
  color: PropTypes.string,
};

export default function TeacherProgress() {
  const [chartDataList, setChartDataList] = useState([]);
  const user = useSelector((state) => state.auth['value']);
  const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;

  useEffect(() => {
    async function fetchData() {
      try {
        const req = { "user": user.relatedData.sch_id };
        const res = await fetchWrapper.post(`${baseUrl}/progressSummary`, req);
        const gradeWiseSummary = res.grade_wise_summary;

        // Transform data to have grade and subject-wise charts
        const charts = Object.keys(gradeWiseSummary).map(grade => {
          const gradeData = gradeWiseSummary[grade];
          return {
            grade: grade,
            charts: gradeData.map(subjectData => ({
              subject: subjectData.subject,
              data: [{
                label: "Completed Chapters",
                value: subjectData.completed_chapters
              }, {
                label: "In Progress Chapters",
                value: subjectData.inprogress_chapters
              }, {
                label: "Remaining Chapters",
                value: subjectData.total_chapters - subjectData.completed_chapters - subjectData.inprogress_chapters
              }]
            }))
          };
        });

        setChartDataList(charts);
      } catch (error) {
        console.error('Error fetching progress data:', error);
      }
    }
    fetchData();
  }, [user, baseUrl]);

  return (
    <div className="d-flex" style={{margin:"10px"}}>
      {chartDataList.map(gradeData => (
        <Card key={gradeData.grade} className="mb-3 mr-3" style={{ width: '50%', marginRight: '10px', marginBottom: '10px' }}>
          <CardHeader title={`Grade ${gradeData.grade} Progress`} />
          <div className="">
            {gradeData.charts.map(chart => (
              <div key={chart.subject} className="mr-3 mb-3">
                {
                  chart.data &&
                <GradeWisePieChart gradeData={chart.data} subject={chart.subject}/>
                }
              </div>
            ))}
          </div>
        </Card>
      ))}
    </div>
  );
}
