/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback, createContext } from 'react';
import {
  Drawer,
  List,
  Divider,
  IconButton,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  useMediaQuery,
  ListItem,
  ListItemText,
  Menu,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Menu as MenuIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { useNavigate, useLocation, useParams, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Assistant from '_assets/images/Assistent.svg';
import { Chatbot } from '_components/chatbot/Chatbot';
import { fetchWrapper, capitalizeFirstLetter } from '_helpers';
import { Loader } from '_components/navigation/Loader';
import { store } from '_store';

import './style.css';
import NEETTestQuiz from '../neetTest/NEETTestQuiz';
import MockPerformanceAnalysis from '../neetTest/MockPerformanceAnalysis';
export const MyContext = createContext();

export const Chapter = () => {
  const { standard, subject, chapterid, sectionid, subsectionid, activityid } = useParams();
  const auth = useSelector((x) => x.auth.value);
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedParent, setSelectedParent] = useState(null);
  const [data, setData] = useState('');
  const [sectionData, setSectionData] = useState('');
  const [hideChatbot, setHideChatbot] = useState(false);
  const [subSectionData, setSubSectionData] = useState(''); // State for storing selected subsection data
  const [message, setMessage] = useState('');
  const [sectionType, setSelectionType] = useState('chapter');
  const [selectedChapter, setSelectedChapter] = useState();
  const [selectedSection, setSelectedSection] = useState();
  const [open, setOpen] = useState(true);
  const [chapters, setChapters] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [chatbotOpen, setChatbotOpen] = useState(false);
  const [showAssistant, setshowAssistant] = useState(true);
  const [showMockPerformance, setShowMockPerformance] = useState(false);
  const [showMockQuiz, setShowMockQuiz] = useState(false);
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const [expandedSectionIndex, setExpandedSectionIndex] = useState(-1);
  const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;

  const isSmallScreen = useMediaQuery('(max-width: 768px)');

  const drawerWidth = isSmallScreen ? '75vw' : '25vw'; // Drawer width based on screen size
  const drawerType = isSmallScreen ? 'temporary' : 'persistent'; // Change drawer type

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const [error, setError] = useState(null);
  const [testLevels, setTestLevels] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState([]);
  const NEETTestlocation = useLocation();
  const isNEETTestPath = NEETTestlocation.pathname === '/dashboard/NEET_TEST/subject/All_Subjects';

  const handleLevelClick = (level) => {
    setSelectedLevel(level);
    handleMockQuizClick();
  };

  const token = authToken();
  function authToken() {
    return store.getState().auth.value?.token;
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        let url;
        let options = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        };

        if (isNEETTestPath) {
          url = `${baseUrl}/neetTestLevel`;
        } else {
          const urlData = location.pathname.includes('NEET')
            ? { board: 'NEET', subject: subject }
            : location.pathname.includes('NEET_TEST')
            ? { board: 'NEET_TEST', subject: subject }
            : location.pathname.includes('SAT')
            ? { board: 'SAT', subject: subject }
            : {
                grade: standard || '',
                subject: subject,
                board: auth.board,
                state: auth.state || location.state,
              };

          url = `${baseUrl}/getAllChapters`;
          options = {
            ...options,
            method: 'POST',
            body: JSON.stringify(urlData),
          };
        }

        const response = await fetch(url, options);

        if (!response.ok) {
          const errorMessage = await response.text();
          throw new Error(errorMessage || 'Failed to fetch data');
        }

        const responseData = await response.json();
        if (isNEETTestPath) {
          if (Array.isArray(responseData.data)) {
            setTestLevels(responseData.data);
          } else {
            throw new Error('Invalid data format received');
          }
        } else {
          setChapters(responseData);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [isSmallScreen]);

  const handleParentItemClick = useCallback(
    (parentLink, chapter) => {
      setData(chapter);
      setSelectedChapter(chapter.chapter_id);
      setSelectionType('chapter');
      if (selectedParent === parentLink) {
        setSelectedParent(null);
      } else {
        setSelectedParent(parentLink);
      }
      if (expandedIndex === parentLink) {
        setExpandedSectionIndex(-1);
        setExpandedIndex(-1);
      } else {
        setExpandedIndex(parentLink);
      }
      navigate(`chapter/${chapter.chapter_id}`);
    },
    [expandedIndex],
  );

  const handleSectionItemClick = useCallback(
    async (index, clickedSection) => {
      let newSelectedChapter = selectedChapter;
      const updatedChapters = chapters.map((chapter) => {
        if (chapter.link === selectedParent) {
          const updatedSection = chapter.section.map((section) => {
            if (section.section_id === clickedSection.section_id) {
              return {
                ...section,
                open: !section.open,
              };
            }
            return {
              ...section,
              open: false,
            };
          });
          return {
            ...chapter,
            section: updatedSection,
          };
        }
        return chapter;
      });
      setSelectionType('section');
      setData(clickedSection.section[index]);
      setSelectedSection(clickedSection.section[index].section_id);
      setSectionData(clickedSection.section[index]);
      setChapters(updatedChapters);
      const currentExpandedSectionIndex = expandedSectionIndex;
      if (currentExpandedSectionIndex === index) {
        setExpandedSectionIndex(-1);
      } else {
        setExpandedSectionIndex(index);
      }
      newSelectedChapter = clickedSection.chapter_id;

      navigate(`chapter/${newSelectedChapter}/section/${clickedSection.section[index].section_id}`);
      setShowMockPerformance(false);
    },
    [baseUrl, chapters, selectedParent, selectedChapter, navigate, expandedSectionIndex],
  );

  const handleSubSectionItemClick = (subSection) => {
    setData(subSection);
    setSelectionType('subSection');
    setSubSectionData({ ...subSection, grade: standard, subject: subject }); // Store selected subsection data
    setSelectionType('subSection');
    navigate(`chapter/${chapterid}/section/${sectionid}/subsection/${subSection.sub_section_id}`);
    setShowMockPerformance(false);
  };

  const handlePerformanceAnalysisClick = () => {
    setShowMockPerformance(true);
    setShowMockQuiz(false);
  };

  const handleMockQuizClick = () => {
    setShowMockQuiz(true);
    setShowMockPerformance(false);
  };

  const handleDrawerClose = useCallback(() => {
    setOpen(false);
  }, []);
  window.handleDrawerClose = handleDrawerClose;

  const handleOpenChatbot = useCallback(() => {
    setMessage('');
    setChatbotOpen((prevState) => !prevState);
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  const handleGotoFeedback = () => {
    navigate('/feedback');
  };

  const imageUrl =
    auth && auth.relatedData ? `${process.env.REACT_APP_API_URL}/${auth.relatedData.sch_img}` : '';
  return (
    <MyContext.Provider
      value={{
        chapters,
        setChapters,
        open,
        setOpen,
        chatbotOpen,
        setChatbotOpen,
        message,
        setMessage,
        hideChatbot,
        setHideChatbot,
        showAssistant,
        setshowAssistant,
      }}
    >
      <div>
        {isSmallScreen && (
          <IconButton onClick={handleDrawerToggle} sx={{ position: 'absolute', top: 16, left: 16 }}>
            <MenuIcon sx={{ color: '#fe6f61' }} />
          </IconButton>
        )}
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            top: '20%',
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              top: '8vh',
              height: '92vh',
              zIndex: '100',
            },
          }}
          variant={drawerType}
          anchor='left'
          open={open}
          onClose={handleDrawerToggle}
        >
          {imageUrl && (
            <img
              src={imageUrl}
              alt='school Logo'
              style={{
                maxHeight: '100px',
                width: '50%',
                display: 'block',
                margin: 'auto',
              }}
              onError={(e) => {
                console.warn('Error loading image:', e);
                e.target.style.display = 'none';
              }}
            />
          )}
          <div
            style={{
              display: 'flex',
              paddingTop: '16px',
              justifyContent: 'end',
            }}
          >
            <div className={isSmallScreen ? 'd-grid w-100' : 'd-flex'}>
              {auth.board && (
                <>
                  {location.pathname.includes('NEET') && (
                    <Chip label='NEET' color='primary' sx={{ marginRight: '5px' }} />
                  )}
                  {location.pathname.includes('NEET_TEST') && (
                    <Chip label='TEST' color='primary' sx={{ marginRight: '5px' }} />
                  )}
                  {location.pathname.includes('SAT') && (
                    <Chip label='SAT' color='primary' sx={{ marginRight: '5px' }} />
                  )}
                  {!location.pathname.includes('NEET') &&
                    !location.pathname.includes('NEET_TEST') &&
                    !location.pathname.includes('SAT') && (
                      <Chip label={auth.board} color='primary' sx={{ marginRight: '5px' }} />
                    )}
                </>
              )}
              {standard && (
                <Chip label={`Standard: ${standard}`} color='primary' sx={{ marginRight: '5px' }} />
              )}
              {subject && (
                <Chip label={`Subject: ${capitalizeFirstLetter(subject)}`} color='secondary' />
              )}
              {location.pathname.includes('NEET_TEST') && (
                <Chip
                  label='Feedback'
                  color='warning'
                  sx={{ marginLeft: '5px' }}
                  onClick={handleGotoFeedback}
                />
              )}
            </div>
            <IconButton onClick={handleDrawerToggle}>
              {open ? (
                <CloseIcon
                  sx={{
                    color: '#fe6f61',
                    fontSize: '26px',
                  }}
                />
              ) : (
                <MenuIcon sx={{ color: '#fe6f61' }} />
              )}
            </IconButton>
          </div>
          <List>
            {chapters
              .sort((a, b) => a.chapter_id - b.chapter_id)
              .map((chapter, index) => (
                <Accordion
                  key={index + chapter.chapter_id}
                  sx={{ backgroundColor: '#fe6f61', color: 'white' }}
                  expanded={chapter.chapter_id == chapterid}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                    onClick={() => handleParentItemClick(index, chapter)}
                  >
                    <Typography>
                      {chapter.chapter_id}. {capitalizeFirstLetter(chapter.name)}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: '0 !important' }}>
                    {chapter.section
                      .sort((a, b) => a.section_id - b.section_id)
                      .map((section, subIndex) => (
                        <Accordion
                          key={`level2-${index}-${subIndex}`}
                          sx={{ backgroundColor: '#092141', color: 'white' }}
                          expanded={section.section_id === sectionid && expandedSectionIndex !== -1}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                            aria-controls={`level2-content-${index}-${subIndex}`}
                            id={`level2-header-${index}-${subIndex}`}
                            onClick={() => handleSectionItemClick(subIndex, chapter)}
                            style={{ borderBottom: ' 1px solid #fff ' }}
                          >
                            <Typography>
                              {section.section_id}. {capitalizeFirstLetter(section.section_title)}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails sx={{ padding: '0' }}>
                            {section.subsection &&
                              section.subsection
                                .reduce((uniqueSubSections, subSection) => {
                                  if (
                                    !uniqueSubSections.some(
                                      (uniqueSubSection) =>
                                        uniqueSubSection.sub_section_id ===
                                        subSection.sub_section_id,
                                    )
                                  ) {
                                    uniqueSubSections.push(subSection);
                                  }
                                  return uniqueSubSections;
                                }, [])
                                .sort((a, b) => a.sub_section_id.localeCompare(b.sub_section_id))
                                .map((subSection) => (
                                  <ListItem
                                    key={subSection.sub_section_id}
                                    onClick={() => handleSubSectionItemClick(subSection)}
                                    sx={{
                                      backgroundColor: '#fff',
                                      color: '#000',
                                      padding: '10px',
                                    }}
                                  >
                                    <ListItemText
                                      primary={
                                        <>
                                          {subSection.sub_section_type !== 'quiz' &&
                                           subSection.sub_section_type !== 'PrepAI'&&
                                           subSection.sub_section_type !== 'vectorQuiz'
                                            ? subSection.sub_section_id + ' '
                                            : ''}
                                          {subSection.sub_section_title}
                                        </>
                                      }
                                    />
                                  </ListItem>
                                ))}
                          </AccordionDetails>
                        </Accordion>
                      ))}
                  </AccordionDetails>
                </Accordion>
              ))}
          </List>
          {isNEETTestPath && (
            <List>
              {testLevels &&
                testLevels.length > 0 &&
                testLevels?.map((level, index) => (
                  <Accordion key={level.id} sx={{ backgroundColor: '#fe6f61', color: 'white' }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}>
                      <Typography>
                        {level.id}. {level.test_level}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: '0 !important' }}>
                      <Accordion sx={{ backgroundColor: '#092141', color: 'white' }}>
                        <AccordionSummary
                          style={{ borderBottom: '1px solid #fff' }}
                          onClick={() => handleLevelClick(level.test_level)}
                        >
                          <Typography>Take - {level.test_level}</Typography>
                        </AccordionSummary>
                      </Accordion>
                    </AccordionDetails>
                  </Accordion>
                ))}
              <Accordion sx={{ backgroundColor: '#fe6f61', color: 'white' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}>
                  <Typography>Mockup Performance Analysis</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: '0 !important' }}>
                  <Accordion sx={{ backgroundColor: '#092141', color: 'white' }}>
                    <AccordionSummary
                      style={{ borderBottom: '1px solid #fff' }}
                      onClick={handlePerformanceAnalysisClick}
                    >
                      <Typography>{<p>My Performance Analysis</p>}</Typography>
                    </AccordionSummary>
                  </Accordion>
                </AccordionDetails>
              </Accordion>
            </List>
          )}
          ;
        </Drawer>
        <div
          style={{
            marginLeft: open ? drawerWidth : 0,
            width: '75%%',
            transition: 'margin-left 0.3s',
          }}
        >
          {open ? null : (
            <IconButton onClick={handleDrawerToggle}>
              <MenuIcon
                sx={{
                  color: '#fe6f61',
                  fontSize: '30px',
                }}
              />
            </IconButton>
          )}
          {chapterid ? (
            <Outlet /> // Render nested routes if chapterid is present
          ) : (
            <>
              {isNEETTestPath && selectedLevel && showMockQuiz && (
                <NEETTestQuiz board={'NEET'} selectedLevel={selectedLevel} />
              )}
              {showMockPerformance && <MockPerformanceAnalysis />}
              {!isNEETTestPath && ( // Consolidated error message
                <div className='alert alert-danger m-2' role='alert'>
                  Please select Chapter
                </div>
              )}
            </>
          )}
          {/* Chatbot and assistant image */}
          {chatbotOpen && !hideChatbot && chapterid ? (
            <Chatbot
              className='col'
              message={message}
              style={{ display: !chatbotOpen && 'none' }}
              setChatbotOpen={setChatbotOpen}
              chapterId={chapterid}
              chapterTitle={chapters && chapters[chapterid - 1].name}
              subSection={subSectionData}
              drawerStatus={open}
            />
          ) : !hideChatbot && chapterid && showAssistant ? (
            <img
              src={Assistant}
              alt='chatAssistant'
              className='chatbot'
              onClick={() => handleOpenChatbot(true)}
              onError={(e) => {
                console.warn('Error loading image:', e);
                e.target.style.display = 'none';
              }}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </MyContext.Provider>
  );
};
