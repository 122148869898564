// Import the AddTeacher component

import React, { useState, useEffect } from 'react';
import { AddTeacher } from './AddTeacher';
import { AddSchool } from '../_schoolAdmin/AddSchool';
import { AddStudent } from './AddStudent';
import { fetchWrapper } from '_helpers';
import { useSelector } from 'react-redux';
import { Loader } from '_components/navigation/Loader';

// In your parent component or route, use the AddTeacher component for editing
export const EditUser = () => {
  const [data, setData] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector((state) => state.auth['value']);
  const baseUrl = `${process.env.REACT_APP_API_URL}`;

  useEffect(() => {
    setIsLoading(true);
    async function fetchData() {
      try {
        let url =
          user.role === 'teacher'
            ? `${baseUrl}/api/v1/getSingleTeacherData?teacher_email=${user['relatedData']['teacher_email']}`
            : user.role === 'student'
            ? `${baseUrl}/api/v1/getSingleStudent?student_email=${user['relatedData']['student_email']}`
            : `${baseUrl}/api/v1/getSingleSchool?sch_id=${user['relatedData']['sch_id']}`;

        const res = await fetchWrapper.get(url);
        res && setData(res);
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setIsLoading(false);
      }
    }

    if (!data.length || data['email'] !== user['relatedData']['teacher_email']) {
      fetchData();
    }
  }, [user['relatedData']['teacher_email']]);

  // Mock teacher data for editing (replace with actual data)
  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      {user.role === 'teacher' && data && <AddTeacher editMode teacherData={data} />}
      {user.role === 'school_admin' && data && <AddSchool editMode schoolData={data} />}
      {user.role === 'student' && data && <AddStudent editMode studentData={data} />}
    </>
  );
};
