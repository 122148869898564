import React from 'react';
import './Ch3f3115.css';
import '../../../colors.css';
export { Ch3f317 };

const Ch3f317 = () => {
  const handleFamilyChange = (event) => {
    const selectedFamily = event.target.value;
    const rows = document.querySelectorAll('table tbody tr');

    rows.forEach((row, index) => {
      if (index === 0) {
        // First row (header row)
        row.style.background = 'none';
      } else {
        // Remaining rows
        if (selectedFamily === 'metalFamily') {
          if (row.textContent.includes('Metal') || row.textContent.includes('Element')) {
            row.style.background = 'yellow';
          } else {
            row.style.background = 'none';
          }
        } else if (selectedFamily === 'nonMetalFamily') {
          if (row.textContent.includes('Non-metal') || row.textContent.includes('Element')) {
            row.style.background = 'yellow';
          } else {
            row.style.background = 'none';
          }
        } else if (selectedFamily === 'none') {
          row.style.background = '#ffddb1';
        }
      }
    });
  };

  return (
    <div>
      <div className='drop-down-div'>
        <label className='family-name' htmlFor='family'>
          Choose a Family:{' '}
        </label>
        <select className='drop-down' name='family' id='family' onChange={handleFamilyChange}>
          <option className='drop-down' value='none'>
            Choose a Family
          </option>
          <option className='drop-down' value='metalFamily'>
            Metal Family
          </option>
          <option className='drop-down' value='nonMetalFamily'>
            Non-metal Family
          </option>
        </select>
      </div>
      <div className='table-div'>
        <table>
          <tbody>
            <tr>
              <th>Element</th>
              <th>Symbol</th>
              <th>Element Type</th>
              <th>Hardness</th>
              <th>Malleability</th>
              <th>Ductility</th>
              <th>Conducts Electricity</th>
              <th>Sonority</th>
              <th>Surface Electricity</th>
            </tr>
            <tr>
              <td>Carbon</td>
              <td>C</td>
              <td>Non-metal</td>
              <td>Varies depending on form</td>
              <td>Not malleable</td>
              <td>Not ductile</td>
              <td>Generally does not conduct electricity.</td>
              <td>Not sonorous</td>
              <td>Does not exhibit surface elecricity.</td>
            </tr>
            <tr>
              <td>Graphite</td>
              <td>C</td>
              <td>Non-metal</td>
              <td>Soft</td>
              <td>Not malleable</td>
              <td>Not ductile</td>
              <td>Yes (as graphene), Conductor of electricity.</td>
              <td>Not sonorous</td>
              <td>Does not exhibit surface elecricity.</td>
            </tr>
            <tr>
              <td>Coal</td>
              <td>C</td>
              <td>Non-metal</td>
              <td>Soft</td>
              <td>Not malleable</td>
              <td>Not ductile</td>
              <td>Does not conduct electricity.</td>
              <td>Not sonorous</td>
              <td>Does not exhibit surface elecricity.</td>
            </tr>
            <tr>
              <td>Sulphur</td>
              <td>S</td>
              <td>Non-metal</td>
              <td>Soft</td>
              <td>Not malleable</td>
              <td>Not ductile</td>
              <td>Does not conduct electricity.</td>
              <td>Not sonorous</td>
              <td>Does not exhibit surface elecricity.</td>
            </tr>
            <tr>
              <td>Iodine</td>
              <td>I</td>
              <td>Non-metal</td>
              <td>Not applicable</td>
              <td>Not applicable</td>
              <td>Not applicable</td>
              <td>Does not conduct electricity.</td>
              <td>Not sonorous</td>
              <td>Does not exhibit surface elecricity.</td>
            </tr>
            <tr>
              <td>Silver</td>
              <td>Ag</td>
              <td>Metal</td>
              <td>Relativey soft</td>
              <td>Highly malleable</td>
              <td>Highly ductile</td>
              <td>Excellent conductor of electricity.</td>
              <td>Highly sonorous</td>
              <td>Exhibits surface elecricity.</td>
            </tr>
            <tr>
              <td>Gold</td>
              <td>Au</td>
              <td>Metal</td>
              <td>Very soft</td>
              <td>Highly malleable</td>
              <td>Highly ductile</td>
              <td>Excellent conductor of electricity.</td>
              <td>Highly sonorous</td>
              <td>Exhibits surface elecricity.</td>
            </tr>
            <tr>
              <td>Copper</td>
              <td>Cu</td>
              <td>Metal</td>
              <td>Relativey soft</td>
              <td>Very malleable</td>
              <td>Very ductile</td>
              <td>Excellent conductor of electricity.</td>
              <td>Sonorous</td>
              <td>Exhibits surface elecricity.</td>
            </tr>
            <tr>
              <td>Magnesium</td>
              <td>Mg</td>
              <td>Alkaline Earth Metal</td>
              <td>Relatively soft</td>
              <td>Highly malleable</td>
              <td>Good ductile</td>
              <td>Good conductor of electricity.</td>
              <td>Sonorous</td>
              <td>Exhibits surface elecricity.</td>
            </tr>
            <tr>
              <td>Iron</td>
              <td>Fe</td>
              <td>Transition Metal</td>
              <td>Relativey hard</td>
              <td>Moderately malleable</td>
              <td>Good ductile</td>
              <td>Good conductor of electricity.</td>
              <td>Sonorous</td>
              <td>Exhibits surface elecricity.</td>
            </tr>
            <tr>
              <td>Zinc</td>
              <td>Zn</td>
              <td>Transition Metal</td>
              <td>Relatively soft</td>
              <td>Highly malleable</td>
              <td>Good ductile</td>
              <td>Good conductor of electricity.</td>
              <td>Sonorous</td>
              <td>Exhibits surface elecricity.</td>
            </tr>
            <tr>
              <td>Aluminium</td>
              <td>Al</td>
              <td>Post-Transition Metal</td>
              <td>Relatively soft</td>
              <td>Highly malleable</td>
              <td>Good ductile</td>
              <td>Good conductor of electricity.</td>
              <td>Sonorous</td>
              <td>Exhibits surface elecricity.</td>
            </tr>
            <tr>
              <td>Lead</td>
              <td>Pb</td>
              <td>Post-Transition Metal</td>
              <td>Relatively soft</td>
              <td>Highly malleable</td>
              <td>Good ductile</td>
              <td>Good conductor of electricity.</td>
              <td>Sonorous</td>
              <td>Exhibits surface elecricity.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
