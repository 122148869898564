import React from 'react';
import './Ch4f412.css';
import '../../../colors.css';
export { Ch4f412 };

const Ch4f412 = () => {
  const handleFamilyChange = (event) => {
    const selectedFamily = event.target.value;
    const rows = document.querySelectorAll('table tbody tr');

    rows.forEach((row, index) => {
      if (index === 0) {
        row.style.background = 'none';
      } else {
        if (selectedFamily === 'alcoholFamily') {
          if (row.textContent.includes('Alcohol') || row.textContent.includes('Compound')) {
            row.style.background = 'yellow';
          } else {
            row.style.background = 'none';
          }
        } else if (selectedFamily === 'aldehydeFamily') {
          if (row.textContent.includes('Aldehyde') || row.textContent.includes('Compound')) {
            row.style.background = 'yellow';
          } else {
            row.style.background = 'none';
          }
        } else if (selectedFamily === 'ketoneFamily') {
          if (row.textContent.includes('Ketone') || row.textContent.includes('Compound')) {
            row.style.background = 'yellow';
          } else {
            row.style.background = 'none';
          }
        } else if (selectedFamily === 'carboxylicAcidFamily') {
          if (row.textContent.includes('Carboxylic Acid') || row.textContent.includes('Compound')) {
            row.style.background = 'yellow';
          } else {
            row.style.background = 'none';
          }
        } else if (selectedFamily === 'none') {
          row.style.background = '#ffddb1';
        }
      }
    });
  };

  return (
    <div>
      <div className='drop-down-div'>
        <label className='family-name' htmlFor='family'>
          Choose a Homologous Series:{' '}
        </label>
        <select className='drop-down' name='family' id='family' onChange={handleFamilyChange}>
          <option className='drop-down' value='none'>
            Choose a Homologous Series
          </option>
          <option className='drop-down' value='alcoholFamily'>
            Alcohol
          </option>
          <option className='drop-down' value='aldehydeFamily'>
            Aldehyde
          </option>
          <option className='drop-down' value='ketoneFamily'>
            Ketone
          </option>
          <option className='drop-down' value='carboxylicAcidFamily'>
            Carboxylic Acid
          </option>
        </select>
      </div>
      <div className='table-div'>
        <table>
          <tbody>
            <tr>
              <th>Compound</th>
              <th>Formula</th>
              <th>
                Molecular Mass <br />
                (g/mol)
              </th>
              <th>
                Functional <br /> Group
              </th>
              <th>
                Homologous <br />
                Series
              </th>
              <th>
                Similarity with <br />
                Carbons
              </th>
            </tr>
            <tr>
              <td>Methanol</td>
              <td>
                CH<sub>3</sub>OH
              </td>
              <td>32.04</td>
              <td>Alcohol(-OH)</td>
              <td>Alcohol</td>
              <td>-</td>
            </tr>
            <tr>
              <td>Ethanol</td>
              <td>
                C<sub>2</sub>H<sub>5</sub>OH
              </td>
              <td>46.07</td>
              <td>Alcohol(-OH)</td>
              <td>Alcohol</td>
              <td>1carbon</td>
            </tr>
            <tr>
              <td>Propanol</td>
              <td>
                C<sub>3</sub>H<sub>7</sub>OH
              </td>
              <td>60.10</td>
              <td>Alcohol(-OH)</td>
              <td>Alcohol</td>
              <td>1carbon</td>
            </tr>
            <tr>
              <td>Butanol</td>
              <td>
                C<sub>4</sub>H<sub>9</sub>OH
              </td>
              <td>74.12</td>
              <td>Alcohol(-OH)</td>
              <td>Alcohol</td>
              <td>1carbon</td>
            </tr>
            <tr>
              <td>Formaldehyde</td>
              <td>
                CH<sub>2</sub>O
              </td>
              <td>30.03</td>
              <td>Aldehyde(RCHO)</td>
              <td>Aldehyde</td>
              <td>-</td>
            </tr>
            <tr>
              <td>Acetaldehyde</td>
              <td>
                CH<sub>3</sub>CHO
              </td>
              <td>44.05</td>
              <td>Aldehyde(RCHO)</td>
              <td>Aldehyde</td>
              <td>1carbon</td>
            </tr>
            <tr>
              <td>Propionaldehyde</td>
              <td>
                C<sub>2</sub>H<sub>5</sub>CHO
              </td>
              <td>58.08</td>
              <td>Aldehyde(RCHO)</td>
              <td>Aldehyde</td>
              <td>1carbon</td>
            </tr>
            <tr>
              <td>Butyraldehyde</td>
              <td>
                C<sub>3</sub>H<sub>7</sub>CHO
              </td>
              <td>72.11</td>
              <td>Aldehyde(RCHO)</td>
              <td>Aldehyde</td>
              <td>1carbon</td>
            </tr>
            <tr>
              <td>Propanone</td>
              <td>
                C<sub>3</sub>H<sub>6</sub>O
              </td>
              <td>58.08</td>
              <td>Ketone(`RCOR`)</td>
              <td>Ketone</td>
              <td>-</td>
            </tr>
            <tr>
              <td>Butanone</td>
              <td>
                C<sub>4</sub>H<sub>8</sub>O
              </td>
              <td>72.11</td>
              <td>Ketone(`RCOR`)</td>
              <td>Ketone</td>
              <td>1carbon</td>
            </tr>
            <tr>
              <td>Propanone</td>
              <td>
                C<sub>5</sub>H<sub>10</sub>O
              </td>
              <td>86.14</td>
              <td>Ketone(`RCOR`)</td>
              <td>Ketone</td>
              <td>1carbon</td>
            </tr>
            <tr>
              <td>Ethanoic Acid</td>
              <td>
                CH<sub>3</sub>COOH
              </td>
              <td>60.05</td>
              <td>Carboxylic Acid(RCOOH)</td>
              <td>Carboxylic Acid</td>
              <td>-</td>
            </tr>
            <tr>
              <td>Propanoic Acid</td>
              <td>
                C<sub>2</sub>H<sub>5</sub>COOH
              </td>
              <td>74.08</td>
              <td>Carboxylic Acid(RCOOH)</td>
              <td>Carboxylic Acid</td>
              <td>1carbon</td>
            </tr>
            <tr>
              <td>Butanoic Acid</td>
              <td>
                C<sub>3</sub>H<sub>7</sub>COOH
              </td>
              <td>88.77</td>
              <td>Carboxylic Acid(RCOOH)</td>
              <td>Carboxylic Acid</td>
              <td>1carbon</td>
            </tr>
            <tr>
              <td>Pentanoic Acid</td>
              <td>
                C<sub>4</sub>H<sub>9</sub>COOH
              </td>
              <td>102.13</td>
              <td>Carboxylic Acid(RCOOH)</td>
              <td>Carboxylic Acid</td>
              <td>1carbon</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
