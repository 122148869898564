import React from 'react';
import './Ch2f2113.css';
import '../../../colors.css';
export { Ch2f2113 };

const Ch2f2113 = () => {
  const handleFamilyChange = (event) => {
    const selectedFamily = event.target.value;
    const rows = document.querySelectorAll('table tbody tr');

    rows.forEach((row, index) => {
      if (index === 0) {
        row.style.background = 'none';
      } else {
        if (selectedFamily === 'sulphateFamily') {
          if (row.textContent.includes('Sulphate') || row.textContent.includes('SALTS')) {
            row.style.background = 'yellow';
          } else {
            row.style.background = 'none';
          }
        } else if (selectedFamily === 'sodiumFamily') {
          if (row.textContent.includes('Sodium') || row.textContent.includes('SALTS')) {
            row.style.background = 'yellow';
          } else {
            row.style.background = 'none';
          }
        } else if (selectedFamily === 'chlorideFamily') {
          if (row.textContent.includes('Chloride') || row.textContent.includes('SALTS')) {
            row.style.background = 'yellow';
          } else {
            row.style.background = 'none';
          }
        } else if (selectedFamily === 'none') {
          row.style.background = '#fed4a8';
        }
      }
    });
  };

  return (
    <div>
      <div className='drop-down '>
        <label className='family-name' htmlFor='family'>
          Choose a Family:{' '}
        </label>
        <select
          className='drop-down bg-light text-dark m-2'
          name='family'
          id='family'
          onChange={handleFamilyChange}
        >
          <option className='drop-down' value='none'>
            Choose a Family
          </option>
          <option className='drop-down' value='sulphateFamily'>
            Sulphate Family
          </option>
          <option className='drop-down' value='sodiumFamily'>
            Sodium Family
          </option>
          <option className='drop-down' value='chlorideFamily'>
            Chloride Family
          </option>
        </select>
      </div>
      <div className='table-div m-2'>
        <table style={{ width: '100%', height: '100%', textAlign: 'center' }}>
          <tbody>
            <tr className='p-2'>
              <th style={{ padding: '15px' }}>SALTS</th>
              <th style={{ padding: '15px' }}>CHEMICAL FORMULAE</th>
              <th style={{ padding: '15px' }}>BASE</th>
              <th style={{ padding: '15px' }}>ACID</th>
            </tr>
            <tr>
              <td style={{ padding: '15px' }}>Potassium Sulphate</td>
              <td style={{ padding: '15px' }}>
                K<sub>2</sub>SO<sub>4</sub>
              </td>
              <td style={{ padding: '15px' }}>KOH</td>
              <td style={{ padding: '15px' }}>
                H<sub>2</sub>SO<sub>4</sub>
              </td>
            </tr>
            <tr>
              <td style={{ padding: '15px' }}>Sodium Sulphate</td>
              <td style={{ padding: '15px' }}>
                Na<sub>2</sub>SO<sub>4</sub>
              </td>
              <td style={{ padding: '15px' }}>NaOH</td>
              <td style={{ padding: '15px' }}>
                H<sub>2</sub>SO<sub>4</sub>
              </td>
            </tr>
            <tr>
              <td style={{ padding: '15px' }}>Calcium Sulphate</td>
              <td style={{ padding: '15px' }}>
                CaSO<sub>4</sub>
              </td>
              <td style={{ padding: '15px' }}>
                Ca(OH)<sub>2</sub>
              </td>
              <td style={{ padding: '15px' }}>
                H<sub>2</sub>SO<sub>4</sub>
              </td>
            </tr>
            <tr>
              <td style={{ padding: '15px' }}>Magnesium Sulphate</td>
              <td style={{ padding: '15px' }}>
                MgSO<sub>4</sub>
              </td>
              <td style={{ padding: '15px' }}>
                Mg(OH)<sub>2</sub>
              </td>
              <td style={{ padding: '15px' }}>
                H<sub>2</sub>SO<sub>4</sub>
              </td>
            </tr>
            <tr>
              <td style={{ padding: '15px' }}>Copper Sulphate</td>
              <td style={{ padding: '15px' }}>
                CuSO<sub>4</sub>
              </td>
              <td style={{ padding: '15px' }}>
                Cu(OH)<sub>2</sub>
              </td>
              <td style={{ padding: '15px' }}>
                H<sub>2</sub>SO<sub>4</sub>
              </td>
            </tr>
            <tr>
              <td style={{ padding: '15px' }}>Sodium Chloride</td>
              <td style={{ padding: '15px' }}>NaCl</td>
              <td style={{ padding: '15px' }}>NaOH</td>
              <td style={{ padding: '15px' }}>HCl</td>
            </tr>
            <tr>
              <td style={{ padding: '15px' }}>Sodium Nitrate</td>
              <td style={{ padding: '15px' }}>
                NaNO<sub>3</sub>
              </td>
              <td style={{ padding: '15px' }}>NaOH</td>
              <td style={{ padding: '15px' }}>
                HNO<sub>3</sub>
              </td>
            </tr>
            <tr>
              <td style={{ padding: '15px' }}>Sodium Carbonate</td>
              <td style={{ padding: '15px' }}>
                Na<sub>2</sub>CO<sub>3</sub>
              </td>
              <td style={{ padding: '15px' }}>NaOH</td>
              <td style={{ padding: '15px' }}>
                H<sub>2</sub>CO<sub>3</sub>
              </td>
            </tr>
            <tr>
              <td style={{ padding: '15px' }}>Ammonium Chloride</td>
              <td style={{ padding: '15px' }}>
                NH<sub>4</sub>Cl
              </td>
              <td style={{ padding: '15px' }}>
                NH<sub>4</sub>OH
              </td>
              <td style={{ padding: '15px' }}>HCl</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
