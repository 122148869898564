import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Card, Stack, Button, Container, Typography } from '@mui/material';
import { Loader } from '_components/navigation/Loader';
import { ListTable } from '_components';
import { fetchWrapper } from '_helpers';
import { toast } from 'react-toastify';
import { getStudentAccounts, studentAccounts } from '_store/student.slice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import 'colors.css';
import Pagination from '@mui/material/Pagination';
import { Page404 } from '../Page404';
// import { debounce } from 'lodash';

export const StudentList = () => {
  const [isLoading, setisLoading] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const _studentAccount = useSelector(studentAccounts);
  const pageSize = 10;

  const fileInputRef = React.useRef();
  const user = useSelector((x) => x.auth.value);

  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };

  const [totalPages, setTotalPages] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;
  const [fileUploaded, setFileUploaded] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedGrades, setSelectedGrades] = useState([]);
  const [selectedSections, setSelectedSections] = useState([]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    handleFileUpload(selectedFile);
  };
  const handlePageChange = (event, n) => {
    setCurrentPage(n);
    setSearchTerm('');
    dispatch(getStudentAccounts(n, pageSize, '', selectedGrades, selectedSections));
  };

  useEffect(() => {
    dispatch(
      getStudentAccounts(currentPage, pageSize, searchTerm, selectedGrades, selectedSections),
    );
  }, [fileUploaded]);

  const handleFileUpload = async (selectedFile) => {
    if (!selectedFile) {
      alert('Please select a file to upload.');
      return;
    }

    const formData = new FormData();
    formData.append('StudentExcel', selectedFile);
    formData.append('sch_id', user.relatedData.sch_id);

    try {
      const response = await fetchWrapper.post(`${baseUrl}/uploadStudentExcel`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.success) {
        toast.success('Excel file uploaded and processed successfully.');
        setFileUploaded(true);
      } else {
        toast.error('Error creating student');
      }
    } catch (error) {
      console.error('Error uploading Excel file:', error);
    }
  };

  useEffect(() => {
    if (_studentAccount && _studentAccount.length !== 0) {
      setTotalPages(_studentAccount.pagination.totalPages);
      setisLoading(false);
    } else {
      setisLoading(true);
    }
  }, [_studentAccount, currentPage]);

  const handleSearch = (term) => {
    const trimedValue = term.trim();
    setCurrentPage(0);
    if (!term) {
      dispatch(getStudentAccounts(0, 10, trimedValue, selectedGrades, selectedSections)); // Pass selectedGrade to fetch filtered data
    }
    trimedValue &&
      dispatch(getStudentAccounts(0, 10, trimedValue, selectedGrades, selectedSections)); // Pass selectedGrade to fetch filtered data
  };

  if (!_studentAccount || _studentAccount.length === 0) {
    return (
      <>
        <Helmet>
          <title> Student List </title>
        </Helmet>
        <Container>
          <Typography variant='h4' gutterBottom>
            <Page404 message='No Student found ' name={'NoDataFound'} />
          </Typography>
        </Container>
      </>
    );
  }
  const debouncedHandleSearch = (value) => {
    setSearchTerm(value);
    handleSearch(value);
  };

  const handleGradeFilter = (grade) => {
    const updatedGrades = selectedGrades.includes(grade)
      ? selectedGrades.filter((g) => g !== grade)
      : [...selectedGrades, grade];
    setSelectedGrades(updatedGrades);
    setCurrentPage(0);
    dispatch(getStudentAccounts(0, 10, searchTerm, updatedGrades, selectedSections));
  };

  const handleSectionFilter = (section) => {
    const updatedSections = selectedSections.includes(section)
      ? selectedSections.filter((s) => s !== section)
      : [...selectedSections, section];
    setSelectedSections(updatedSections);
    setCurrentPage(0);
    dispatch(getStudentAccounts(0, 10, searchTerm, selectedGrades, updatedSections));
  };

  if (isLoading) {
    return <Loader />;
  }
  const totalStudents = _studentAccount['pagination'].totalItems;

  return (
    <>
      <Helmet>
        <title> Student List </title>
      </Helmet>

      <Container maxWidth='xl'>
        <Typography variant='h4' gutterBottom>
          Student List
        </Typography>
        <Stack direction='row' alignItems='center' justifyContent='space-between' mb={5}>
          <div className='d-flex' style={{ justifyContent: 'flex-start' }}>
            <input
              type='text'
              placeholder='Search...'
              value={searchTerm}
              onChange={(e) => debouncedHandleSearch(e.target.value)}
            />
          </div>

          <div className='d-flex'>
            <Button
              className='mx-2'
              startIcon=''
              sx={{
                backgroundColor: `var(--Peach)`,
                '&:hover': {
                  backgroundColor: 'var(--Blue)',
                },
                color: `var(--White)`,
              }}
              onClick={() => navigate('/addStudent')}
            >
              New Student
            </Button>
            <input
              type='file'
              accept='.xlsx, .xls'
              onChange={handleFileChange}
              style={{ display: 'none' }}
              ref={fileInputRef}
              name='StudentExcel'
            />
            <Button
              startIcon=''
              sx={{
                backgroundColor: `var(--Peach)`,
                '&:hover': {
                  backgroundColor: 'var(--Blue)',
                },
                color: `var(--White)`,
              }}
              onClick={() => handleFileInputClick()}
            >
              Upload Excel
            </Button>
          </div>
        </Stack>
        <div className='d-flex'>
          <button type='button' className='btn ' style={{ backgroundColor: '#b5e3b2' }}>
            Total Students: {totalStudents}
          </button>
          {[8, 9, 10, 11, 12].map((grade) => (
            <Button
              key={grade}
              className='mx-2'
              variant={selectedGrades.includes(grade) ? 'contained' : 'outlined'}
              onClick={() => handleGradeFilter(grade)}
              sx={{ minWidth: 0, p: 1 }}
            >
              {grade}
            </Button>
          ))}
          {['A', 'B', 'C'].map((section) => (
            <Button
              key={section}
              className='mx-2'
              variant={selectedSections.includes(section) ? 'contained' : 'outlined'}
              onClick={() => handleSectionFilter(section)}
              sx={{ minWidth: 0, p: 1 }}
            >
              {section}
            </Button>
          ))}
          <Button
            className='mx-2'
            variant={
              selectedGrades.length === 0 && selectedSections.length === 0
                ? 'contained'
                : 'outlined'
            }
            onClick={() => {
              setSelectedGrades([]);
              setSelectedSections([]);
              setCurrentPage(0);
              dispatch(getStudentAccounts(0, 10, searchTerm, [], []));
            }}
          >
            Clear Filter
          </Button>
        </div>

        <Card sx={{ boxShadow: '0px 6px 6px rgba(0.4, 0.4, 0.4, 0.4)' }} className='my-2'>
          <ListTable
            data={_studentAccount['results']}
            headers={[
              'student_id',
              'student_name',
              'student_email',
              'grade',
              'section',
              'student_phone',
              'student_progress',
            ]}
            columnHeaders={[
              'Student Id ',
              'Student Name',
              'Student Email',
              'Grade',
              'Section',
              'Student Phone',
              'Student Progress',
            ]}
          />

          <Container align='center' justify='center' p={10} w='full'>
            <Pagination
              count={totalPages}
              shape='rounded'
              className={`controls ${currentPage}`}
              sx={{ justifyContent: 'center' }}
              onChange={(e, n) => handlePageChange(e, n)}
            />
          </Container>
        </Card>
      </Container>
    </>
  );
};
