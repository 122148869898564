import React from 'react';
import { TableRow, Button } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';

const colors = ['linear-gradient(270deg, #f8ddda, #e6e2dd)', '#dcb9b6']; // Add more colors as needed
const StyledTableRow = styled(TableRow)(({ rowIndex }) => ({
  background: colors[rowIndex % colors.length],
  '&:hover': {
    backgroundColor: '#f0f0f0',
    cursor: 'pointer',
  },
  '&:last-child td, &:last-child th': {},
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: grey[500],
    border: '0px',
    color: theme.palette.common.white,
  },
  textAlign: 'center',
  border: '0px',
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const ListRow = ({ row, headers, rowIndex }) => {
  const navigate = useNavigate();
  const handleProgress = (id, grade) => {
    const data = { id, grade };

    navigate(`/progress-report/${id}`, { state: data });
  };

  const handleQuizDetail = (
    quizId,
    quiz_name,
    grade,
    subject,
    section_id,
    subsection_id,
    chapter_id,
    imageUrl,
    board,
  ) => {
    const rowData = {
      quizId,
      quiz_name,
      grade,
      subject,
      section_id,
      subsection_id,
      chapter_id,
      imageUrl,
      board,
    };
    console.log('row', row);
    // navigate(`/QuizDetails/${quizId}`);
    navigate(`/quizDetails/${quizId}`, { state: { rowData } });
  };

  return (
    <StyledTableRow key={rowIndex} rowIndex={rowIndex}>
      {headers.map((header, colIndex) => (
        <StyledTableCell key={colIndex} scope='row'>
          {
            row[header] && header === 'student_progress' && (
              <Button
                color='primary'
                onClick={() => handleProgress(row['student_email'], row['grade'])}
              >
                {row[header]}
              </Button>
            )
            // : (
            //   row[header] && row[header].toString()
          }
          {header === 'product_Image' && row[header] && (
            <img
              alt='Product'
              width={150} // Adjust dimensions as needed
              height={150}
              style={{
                objectFit: 'contain',
                marginTop: '10px',
              }}
              src={row[header]} // Display the image from the URL
            />
          )}

          {/* {header === 'quiz_name' && <u>{row.quiz_name}</u>} */}
          {header === 'quiz_name' && (
            <Button
              color='primary'
              onClick={() =>
                handleQuizDetail(
                  row['id'],
                  row['quiz_title'],
                  row['grade'],
                  row['subject'],
                  row['section_id'],
                  row['subsection_id'],
                  row['chapter_id'],
                  row['imageUrl'],
                  row['board'],
                )
              }
            >
              <u>{row.quiz_name}</u>
            </Button>
          )}

          {row[header] && header !== 'student_progress' && header !== 'quiz_name'
            ? row[header].toString()
            : null}
        </StyledTableCell>
      ))}
    </StyledTableRow>
  );
};

export { ListRow };
