import React from 'react';
import { Box, Grid } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const WatchItNow = ({ videoSlides }) => {
  return (
    <Grid>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Carousel showThumbs={false} showArrows={true} infiniteLoop>
          {videoSlides.map((video, index) => (
            <Box key={index}>
              <Box
                component='iframe'
                width='100%'
                height={{ xs: '250px', sm: '350px', md: '450px', lg: '600px' }}
                src={video.url}
                title={video.title}
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              />
            </Box>
          ))}
        </Carousel>
      </Box>
    </Grid>
  );
};

export default WatchItNow;
