import React from 'react';
import PropTypes from 'prop-types';

export { CategoryDropdown };

const CategoryDropdown = ({ options, value, onClickHandler }) => {
  return (
    <select
      className='drop-down form-select'
      name='color'
      id='right-color'
      value={value}
      onChange={(e) => onClickHandler(e.target.value)}
    >
      <option value='' disabled>
        select
      </option>
      {options.map((category, i) => {
        return (
          <option value={category} key={i}>
            {' '}
            {category}
          </option>
        );
      })}
    </select>
  );
};

CategoryDropdown.propTypes = {
  options: PropTypes.any.isRequired,
  value: PropTypes.string,
  onClickHandler: PropTypes.any.isRequired,
};
