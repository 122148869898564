import React from 'react';
import { Box, Button } from '@mui/material';

const FeedbackQuestionActions = ({ currentQuestionIndex, onNextQuestion, onPrevQuestion }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '20px' }}>
      <Button
        variant='contained'
        color='primary'
        onClick={onPrevQuestion}
        disabled={currentQuestionIndex === 0}
      >
        Previous
      </Button>
      <Button variant='contained' color='primary' onClick={onNextQuestion}>
        Next
      </Button>
    </Box>
  );
};

export default FeedbackQuestionActions;
