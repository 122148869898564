import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { alertActions } from '_store';
import { history, fetchWrapper } from '_helpers';

// create slice

const name = 'auth';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, reducers });

// exports

export const authActions = { ...slice.actions, ...extraActions };
export const authReducer = slice.reducer;

// implementation

function createInitialState() {
  return {
    // initialize state from local storage to enable user to stay logged in
    value: JSON.parse(localStorage.getItem('auth')),
  };
}

function createReducers() {
  return {
    setAuth,
  };

  function setAuth(state, action) {
    state.value = action.payload;
  }
}

function createExtraActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;

  return {
    login: login(),
    logout: logout(),
    signup: signup(),
  };

  function login() {
    return createAsyncThunk(
      `${name}/login`,
      async function ({ email, password, state, board, role }, { dispatch }) {
        dispatch(alertActions.clear());
        try {
          const user = await fetchWrapper.post(`${baseUrl}/login`, {
            email,
            password,
            state,
            board,
            role,
          });
          const expirationTime = new Date().getTime() + 3600 * 1000 * 24;
          user.tokenExpirationTime = expirationTime;
          // set auth user in redux state
          user['state'] = state;
          user['board'] = board;
          dispatch(authActions.setAuth(user));
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('auth', JSON.stringify(user));

          // get return url from location state or default to home page
          const { from } = history.location.state || { from: { pathname: '/' } };
          history.navigate(from);
          setTimeout(() => {
            localStorage.removeItem('auth');
            window.location.href = '/account/login';
          }, expirationTime - new Date().getTime());
        } catch (error) {
          if (error === 401) {
            dispatch(
              alertActions.error('Invalid credentials. Please check your email and password.'),
            );
          } else if (error === 403) {
            dispatch(alertActions.error('You do not have permission to access this resource.'));
          } else if (error === 404) {
            dispatch(alertActions.error(' User not found '));
          } else if (error === 419) {
            dispatch(alertActions.error(' Your ID has expired '));
          } else {
            const errorMessage = error.message || 'An error occurred';
            dispatch(alertActions.error(errorMessage));
          }
        }
      },
    );
  }

  function logout() {
    return createAsyncThunk(`${name}/logout`, function (arg, { dispatch, getState }) {
      let admin = getState().auth.value.role;
      const trimmedAdmin = admin.trim();
      dispatch(authActions.setAuth(null));
      localStorage.removeItem('auth');
      const redirectUrl = trimmedAdmin === 'admin' ? '/account/adminLogin' : '/account/login';
      history.navigate(redirectUrl);
    });
  }
  function signup() {
    return createAsyncThunk(`${name}/signup`, async function (data, { dispatch }) {
      // dispatch(alertActions.clear());
      try {
        const user = await fetchWrapper.post(`${baseUrl}/signup`, data);
        console.log('login APi data', data);
        console.log('user', user);
        // history.navigate('/payment', { user: user });
      } catch (error) {
        handleAuthError(dispatch, error);
      }
    });
    function handleAuthError(dispatch, error) {
      if (error === 401) {
        dispatch(alertActions.error('Invalid credentials. Please check your details.'));
      } else if (error === 403) {
        dispatch(alertActions.error('You do not have permission to access this resource.'));
      } else if (error === 404) {
        dispatch(alertActions.error('User not found'));
      } else if (error === 419) {
        dispatch(alertActions.error('Your ID has expired'));
      } else {
        const errorMessage = error.message || 'An error occurred';
        dispatch(alertActions.error(errorMessage));
      }
    }
  }
}
