import { Modal1 } from './chapter1/Modal1.js';
import { Modal2 } from './chapter1/Modal2.js';
import { Modal3 } from './chapter1/Modal3.js';
import { Modal4 } from './chapter1/Modal4.js';
import { Modal5 } from './chapter1/Modal5.js';
import { Modal6 } from './chapter1/Modal6.js';
import { Modal7 } from './chapter1/Modal7.js';
import { Ch2Modal1 } from './chapter2/Modal1.js';
import { Ch2Modal2 } from './chapter2/Modal2.js';
import { Ch2Modal3 } from './chapter2/Modal3.js';
import { Ch2Modal7 } from './chapter2/Modal7.js';
import { Ch2Modal12A } from './chapter2/Model12A.js';
import { Ch2Modal12B } from './chapter2/Model12B.js';
import { Ch2Modal13 } from './chapter2/Modal13.js';
import { Ch2f2113 } from './chapter2/Ch2f2113.js';
import { Ch3Modal1A } from './chapter3/Modal1A.js';
import { Ch3Modal1B } from './chapter3/Modal1B.js';
import { Ch3Modal2 } from './chapter3/Modal2.js';
import { Ch3Modal3 } from './chapter3/Modal3.js';
import { Ch3Modal4 } from './chapter3/Modal4.js';
import { Ch3f3115 } from './chapter3/Ch3f3115.js';
import { Ch3f317 } from './chapter3/Ch3f317.js';
import { Ch3f314 } from './chapter3/Ch3f314.js';
import { Ch4f412 } from './chapter4/Ch4f412.js';

const MODALS = {
  chapter1: {
    Modal1,
    Modal2,
    Modal3,
    Modal4,
    Modal5,
    Modal6,
    Modal7,
  },
  chapter2: {
    Ch2Modal1,
    Ch2Modal2,
    Ch2Modal3,
    Ch2Modal7,
    Ch2Modal12A,
    Ch2Modal12B,
    Ch2Modal13,
    Ch2f2113,
  },
  chapter3: {
    Ch3Modal1A,
    Ch3Modal1B,
    Ch3Modal2,
    Ch3Modal3,
    Ch3Modal4,
    Ch3f3115,
    Ch3f317,
    Ch3f314,
  },
  chapter4: {
    Ch4f412,
  },
};

export default MODALS;
