import React from 'react';
import './Ch3f3115.css';
import '../../../colors.css';
export { Ch3f314 };

const Ch3f314 = () => {
  const handleFamilyChange = (event) => {
    const selectedFamily = event.target.value;
    const rows = document.querySelectorAll('table tbody tr');

    rows.forEach((row, index) => {
      if (index === 0) {
        // First row (header row)
        row.style.background = 'none';
      } else {
        // Remaining rows
        if (selectedFamily === 'highlyDuctile') {
          if (row.textContent.includes('Highly') || row.textContent.includes('Name')) {
            row.style.background = 'yellow';
          } else {
            row.style.background = 'none';
          }
        } else if (selectedFamily === 'quiteDuctile') {
          if (row.textContent.includes('Quite') || row.textContent.includes('Name')) {
            row.style.background = 'yellow';
          } else {
            row.style.background = 'none';
          }
        } else if (selectedFamily === 'lessDuctile') {
          if (row.textContent.includes('Less') || row.textContent.includes('Name')) {
            row.style.background = 'yellow';
          } else {
            row.style.background = 'none';
          }
        } else if (selectedFamily === 'moderatelyDuctile') {
          if (row.textContent.includes('Moderately') || row.textContent.includes('Name')) {
            row.style.background = 'yellow';
          } else {
            row.style.background = 'none';
          }
        } else if (selectedFamily === 'fairlyDuctile') {
          if (row.textContent.includes('Fairly') || row.textContent.includes('Name')) {
            row.style.background = 'yellow';
          } else {
            row.style.background = 'none';
          }
        } else if (selectedFamily === 'none') {
          row.style.background = 'none';
        }
      }
    });
  };

  return (
    <div>
      <div className='drop-down-div'>
        <label className='family-name' htmlFor='family'>
          Choose a Ductility Level:{' '}
        </label>
        <select className='drop-down' name='family' id='family' onChange={handleFamilyChange}>
          <option className='drop-down' value='none'>
            Choose a Ductility Level
          </option>
          <option className='drop-down' value='highlyDuctile'>
            Highly Ductile
          </option>
          <option className='drop-down' value='quiteDuctile'>
            Quite Ductile
          </option>
          <option className='drop-down' value='lessDuctile'>
            Less Ductile
          </option>
          <option className='drop-down' value='moderatelyDuctile'>
            Moderately Ductile
          </option>
          <option className='drop-down' value='fairlyDuctile'>
            Fairly Ductile
          </option>
        </select>
      </div>
      <div className='table-div'>
        <table>
          <tbody>
            <tr>
              <th>Wire Name</th>
              <th>Usage</th>
              <th>Ductility Level</th>
            </tr>
            <tr>
              <td>Copper</td>
              <td>
                Copper wires are widely used in electrical applications due to their excellent
                electrical conductivity. They are commonly found in electrical wiring, power cables,
                household appliances, and electronics.
              </td>
              <td>Highly ductile</td>
            </tr>
            <tr>
              <td>Aluminium</td>
              <td>
                Aluminum wires are also used in electrical applications, particularly in power
                transmission and distribution lines. They are lightweight and have good
                conductivity, making them suitable for long-distance transmission.
              </td>
              <td>Quite ductile</td>
            </tr>
            <tr>
              <td>Steel</td>
              <td>
                Steel wires are used in a variety of applications, including construction,
                automotive, and industrial sectors. They are often used for reinforcing structures,
                such as in concrete or as support cables in bridges.
              </td>
              <td>Less ductile</td>
            </tr>
            <tr>
              <td>Iron</td>
              <td>
                Iron wires are less common in daily life compared to other metals. However, they may
                be found in certain applications such as fencing, artwork, or DIY projects.
              </td>
              <td>Less ductile</td>
            </tr>
            <tr>
              <td>Nickel</td>
              <td>
                Nickel wires are used in specialized applications, including heating elements,
                thermocouples, and certain electronic components.
              </td>
              <td>Moderately ductile</td>
            </tr>
            <tr>
              <td>Brass</td>
              <td>
                Brass wires, which are composed of copper and zinc, are used in jewelry making,
                craftwork, and musical instruments.
              </td>
              <td>Fairly ductile</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
