import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';

const TestResultsDialog = ({
  resultDialogOpen,
  handleResultDialogClose,
  result,
  onNEETFeedback,
}) => {
  return (
    <Dialog open={resultDialogOpen} onClose={handleResultDialogClose} fullWidth maxWidth='sm'>
      <DialogTitle variant='h5' align='center'>
        Test Results
      </DialogTitle>
      <DialogContent>
        {result ? (
          <>
            <DialogContentText>
              <Typography variant='h6'>Correct Answers: {result.correct}</Typography>
              <Typography variant='h6'>Wrong Answers: {result.wrong}</Typography>
              <Typography variant='h6'>Attempted Questions: {result.attempted}</Typography>
              <Typography variant='h6'>Unattempted Questions: {result.unattempted}</Typography>
              <Typography variant='h6'>Total Score: {result.totalScore}%</Typography>
            </DialogContentText>
          </>
        ) : (
          <DialogContentText>No results available.</DialogContentText>
        )}
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button variant={'contained'} color={'primary'} onClick={onNEETFeedback}>
          Go to feedback
        </Button>
        <Button variant={'contained'} color={'error'} onClick={handleResultDialogClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TestResultsDialog;
