import React, { useEffect, useState } from 'react';
import { QuizSummary } from './QuizSummary';
import { AnswerDistribution } from './AnswerDistribution';
export { MarksCalculator };

const MarksCalculator = ({
  board,
  totalQuestions,
  answeredQuestion,
  wrongAnswers,
  rightAnswers,
}) => {
  const [rightMarks, setRightMarks] = useState(0);
  const [totalMarks, setTotalMarks] = useState(0);
  const [obtainedMarks, setObtainedMarks] = useState(0);

  useEffect(() => {
    let totalScoredMarks;
    if (board === 'NEET') {
      setRightMarks(4);
      totalScoredMarks = rightAnswers * rightMarks - wrongAnswers;
    } else {
      setRightMarks(1);
      totalScoredMarks = rightAnswers * rightMarks;
    }
    setObtainedMarks(totalScoredMarks);
    setTotalMarks(totalQuestions * rightMarks);
  }, [board, rightAnswers, wrongAnswers, rightMarks, totalQuestions]);

  const chartData = {
    series: [rightAnswers, wrongAnswers],
    options: {
      chart: {
        type: 'pie',
      },
      labels: ['Right Answers', 'Wrong Answers'],
      colors: ['#4CAF50', '#F44336'],
      legend: {
        position: 'bottom',
        fontSize: '12px',
        fontWeight: 'bold',
      },
      tooltip: {
        y: {
          formatter: (val) => `${val} answers`,
        },
      },
    },
  };

  return (
    <div className='marks-calculator'>
      <QuizSummary
        totalQuestions={totalQuestions}
        answeredQuestion={answeredQuestion}
        wrongAnswers={wrongAnswers}
        rightAnswers={rightAnswers}
        totalMarks={totalMarks}
        obtainedMarks={obtainedMarks}
      />
      <AnswerDistribution chartData={chartData} />
    </div>
  );
};
