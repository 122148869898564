import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { fetchWrapper } from '_helpers';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { Grid } from '@mui/material';
import { Page404 } from '../teacherDashboard/dashboard/pages/Page404';
import { AppWebsiteVisits } from 'pages/teacherDashboard/sections/@dashboard/app';
export { StudentReport };

function StudentReport() {
  const [subject, setSubject] = useState('');
  const [report, setReport] = useState('');
  const location = useLocation();
  const data = location.state;
  const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;

  useEffect(() => {
    async function fetchData() {
      let res = await fetchWrapper.get(
        `${baseUrl}/fetchStudentReport?studentId=${data['id']}&studentGrade=${data['grade']}&subject=${subject}`,
      );

      res && setReport(res);
    }
    subject && fetchData();
  }, [subject]);

  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };

  const chartLabels =
    report.results &&
    report.results.map((result) => {
      const words = result.quiz_name.split(' ');

      // Split after 2 words
      const splitLabels = [];
      for (let i = 0; i < words.length; i += 2) {
        splitLabels.push(words.slice(i, i + 2).join(' '));
      }

      return splitLabels;
    });

  const chartData = report.results && report.results.map((result) => result.user_percent_marks);
  const colors = ['linear-gradient(270deg, #f8ddda, #e6e2dd)', '#dcb9b6']; // Add more colors as needed

  return (
    <>
      <div className='h1'>Students Progress report</div>
      {!subject && (
        <div className='alert alert-danger' role='alert' style={{ marginLeft: '1vw' }}>
          Please select subject
        </div>
      )}
      <FormControl className='px-2' fullWidth>
        <InputLabel id='demo-simple-select-label'>Subject</InputLabel>
        <Select
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          value={subject}
          label='Subject'
          onChange={handleSubjectChange}
        >
          <MenuItem value={'science'}>Science</MenuItem>
          <MenuItem value={'maths'}>Maths</MenuItem>
          <MenuItem value={'english'}>English</MenuItem>
        </Select>
      </FormControl>

      {subject && report.results && report.results.length > 0 && (
        <Grid item xs={12} md={6} lg={8}>
          <AppWebsiteVisits
            title='Student Report'
            subheader={`${data.id}`}
            chartLabels={chartLabels}
            chartData={[
              {
                name: `${data.id}`,
                type: 'column',
                fill: 'solid',
                data: chartData,
              },
            ]}
          />
        </Grid>
      )}
      {subject && report && report.results && report.results.length > 0 ? (
        <Grid item xs={12} md={6} lg={8} padding={6}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow
                  sx={{ background: 'linear-gradient(270deg, #FE7061, #E5A978)', margin: 11 }}
                >
                  <TableCell>Quiz Name</TableCell>
                  <TableCell align='center'>Score</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {report.results.map((result, index) => (
                  <TableRow key={index} sx={{ background: colors[index % colors.length] }}>
                    <TableCell>{result.quiz_name}</TableCell>
                    <TableCell align='center'>{result.user_percent_marks}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      ) : (
        subject && report && <Page404 message='No report found ' name={'NoDataFound'} />
      )}
    </>
  );
}
