// component
import SvgColor from '../svg-color/SvgColor';
import React from 'react';
// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const teacherConfig = [
  {
    title: 'Dashboard',
    path: '/teacherDashboard',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Students List',
    path: '/students',
    icon: icon('ic_cart'),
  },
  {
    title: 'Report',
    path: '/report',
    icon: icon('ic_blog'),
  },
  {
    title: 'Interactive Book',
    path: '/textbook',
    icon: icon('ic_lock'),
  },
  {
    title: 'Quiz Panel',
    path: '/quizList',
    icon: icon('ic_lock'),
  },
  {
    title: ' Mock Quiz Panel',
    path: '/getQuizSummary',
    icon: icon('ic_lock'),
  },
];
const schoolAdminConfig = [
  {
    title: 'Dashboard',
    path: '/teacherDashboard',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Students List',
    path: './students',
    icon: icon('ic_cart'),
  },
  {
    title: 'Teachers List',
    path: './teachers',
    icon: icon('ic_cart'),
  },
];

const individualConfig = [
  {
    title: 'Dashboard',
    path: '/individualDashboard',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Interactive Book',
    path: '/Neet_textbook',
    icon: icon('ic_lock'),
  },
];

const adminConfig = [
  {
    title: 'Dashboard',
    path: '/adminDashboard',
    icon: icon('ic_analytics'),
  },
  {
    title: 'School List',
    path: '/Schools',
    icon: icon('ic_cart'),
  },
  // {
  //   title: 'Products',
  //   path: '/AddProduct',
  //   icon: icon('ic_cart'),
  // },
  {
    title: 'NEET ASSISTANT',
    path: '/neetAssistant',
    icon: icon('ic_cart'),
  },
];
const boardConfig = [
  {
    title: 'CBSE',
  },
  {
    title: 'Matric',
  },
  {
    title: 'SAT',
  },
];

export { teacherConfig, schoolAdminConfig, boardConfig, adminConfig, individualConfig };
