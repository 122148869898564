import React, { useEffect } from 'react';
import { Card, CardContent, Typography, FormControl, InputLabel, TextField } from '@mui/material';
import { withFormik, Form, Field, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { fetchWrapper } from '_helpers';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LockIcon from '@mui/icons-material/Lock';
import { LoadingButton } from '@mui/lab';

const UpdatePasswordForm = (props) => {
  const { values, handleChange, handleBlur, setFieldValue } = props;
  const [searchParams] = useSearchParams();
  const verificationCode = searchParams.get('token');

  useEffect(() => {
    setFieldValue('token', verificationCode);
  }, [verificationCode]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
      }}
    >
      <Card
        style={{
          width: '500px',
          backgroundColor: 'white',
          className: 'loginBox',
          position: 'relative',
          zIndex: 2,
          overflow: 'hidden',
          borderRadius: '5px',
        }}
      >
        <CardContent>
          <center>
            <Typography variant='h5' color='#092141' gutterBottom>
              Update Password
            </Typography>
            <LockIcon fontSize='large' color='#092141' />
          </center>
          <Form>
            <FormControl margin='normal' fullWidth style={{ paddingTop: '50px' }}>
              <InputLabel htmlFor='password' className='fw-bold'>
                New password
              </InputLabel>
              <Field
                as={TextField}
                label='Password'
                type='password'
                variant='outlined'
                name='password'
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <ErrorMessage name='password' component='div' style={{ color: 'red' }} />
            </FormControl>

            <FormControl margin='normal' fullWidth style={{ paddingTop: '50px' }}>
              <InputLabel htmlFor='confirmPassword' className='fw-bold'>
                Confirm password
              </InputLabel>
              <Field
                as={TextField}
                label='Confirm Password'
                type='password'
                variant='outlined'
                name='confirmPassword'
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <ErrorMessage name='confirmPassword' component='div' style={{ color: 'red' }} />
            </FormControl>

            <FormControl margin='normal' fullWidth style={{ paddingTop: '20px' }}>
              <LoadingButton
                fullWidth
                size='large'
                type='submit'
                variant='contained'
                sx={{ backgroundColor: '#092141' }}
              >
                Update Password
              </LoadingButton>
            </FormControl>
          </Form>
        </CardContent>
      </Card>
    </div>
  );
};

UpdatePasswordForm.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

const UpdatePassword = withFormik({
  mapPropsToValues: () => ({ password: '', confirmPassword: '' }),
  validationSchema: Yup.object().shape({
    password: Yup.string().required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
  }),
  handleSubmit: async (values, { resetForm }) => {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;

    if (values.password !== values.confirmPassword) {
      toast.error('Passwords do not match.');
      return;
    }

    try {
      const response = await fetchWrapper.post(`${baseUrl}/updatePassword`, {
        token: values.token,
        password: values.password,
      });

      toast.success(response.message);
      resetForm();
    } catch (error) {
      toast.error('Failed to update password. Please try again.');
    }
  },
  displayName: 'UpdatePasswordForm',
})(UpdatePasswordForm);

export default UpdatePassword;
