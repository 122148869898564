import { createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '_helpers';
// let user = JSON.parse(localStorage.getItem('auth'));

const ProductReducer = createSlice({
  name: 'products',
  initialState: {
    ProductAccount: [],
    fetchingProduct: false,
    totalItems: -1,
  },
  reducers: {
    setProductAccounts: (state, action) => {
      state.ProductAccount = action.payload;
    },

    setFetchingProduct: (state, action) => {
      state.fetchingProduct = action.payload;
    },

    setTotalItems: (state, action) => {
      state.totalItems = action.payload;
    },
  },
});

export const getProductAccounts = () => async (dispatch) => {
  const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;
  const apiURL = `${baseUrl}/fetchProduct`;
  try {
    const response = await fetchWrapper.get(apiURL);
    const data = await response;
    dispatch(setProductAccounts(data));
    // console.log(data.data, 'data');
  } catch (error) {
    console.error('Error fetching Product accounts:', error);
  }
};

export const { setProductAccounts, setFetchingProduct, setTotalItems } = ProductReducer.actions;

export const ProductAccounts = (state) => state.products.ProductAccount;
export const fetchingProduct = (state) => state.products.fetchingProduct;

export const totalProduct = (state) => state.products.totalItems;

export default ProductReducer.reducer;
