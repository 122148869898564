// GradeSelector.js
import React, { useState, useEffect } from 'react';
import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Chip,
  Typography,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';

import { fetchWrapper } from '_helpers';
const baseUrl = `${process.env.REACT_APP_API_URL}`;

const GradeSelector = ({ onAdd, onRemove, grades, editMode }) => {
  const [standard, setStandard] = useState('');
  const [section, setSection] = useState('');
  const [gradesList, setGradesList] = useState([]);
  // const [uniquePairs, setUniquePairs] = useState([]);
  useEffect(() => {
    async function fetchData() {
      try {
        let res;
        res = await fetchWrapper.get(`${baseUrl}/api/v1/grades`);
        setGradesList(res);
      } catch (error) {
        console.error('Error:', error);
      }
    }
    fetchData();
  }, []);
  const handleAdd = () => {
    if (standard && section) {
      onAdd({ standard, section });
      setStandard('');
      setSection('');
    }
  };

  const handleRemove = (pair) => {
    onRemove(pair);
  };

  return (
    <div>
      {!editMode ? (
        <>
          <Grid item style={{ display: 'flex' }}>
            <Grid item xs={5}>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>Standard</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={standard}
                  label='Standard'
                  style={{ textAlign: 'left' }}
                  onChange={(e) => setStandard(e.target.value)}
                >
                  <MenuItem value=''>select</MenuItem>
                  {Object.values(gradesList).map((grade) => (
                    <MenuItem key={grade.grade_id} value={grade.grade_name}>
                      {grade.grade_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={5}>
              <TextField
                label='Section'
                variant='outlined'
                value={section}
                onChange={(e) => setSection(e.target.value)}
              />
            </Grid>
            <Grid item sx={{ margin: 'auto' }}>
              {' '}
              <Button variant='contained' color='primary' onClick={handleAdd}>
                +
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{ paddingTop: '20px' }}>
            {Array.from(new Set(grades && grades.map((pair) => JSON.stringify(pair))))
              .map((pairString) => JSON.parse(pairString))
              .map((pair, index) => (
                <Grid item xs={2} key={index}>
                  <Chip
                    label={pair.standard + pair.section}
                    color='secondary'
                    onDelete={() =>
                      handleRemove({ standard: pair.standard, section: pair.section })
                    }
                    variant='outlined'
                    style={{ margin: '0px' }}
                    disabled={editMode}
                  />
                </Grid>
              ))}
          </Grid>
        </>
      ) : (
        <div className='d-flex align-items-center pt-3'>
          <Typography>Grade:</Typography>
          <Grid container spacing={1}>
            {Array.from(new Set(grades && grades.map((pair) => JSON.stringify(pair))))
              .map((pairString) => JSON.parse(pairString))
              .map((pair, index) => (
                <Grid item xs={3} key={index}>
                  <Chip
                    label={pair.standard ? pair.standard : pair.grade + pair.section}
                    color='secondary'
                    onDelete={() =>
                      handleRemove({ standard: pair.standard, section: pair.section })
                    }
                    variant='outlined'
                    style={{ margin: '0px' }}
                    disabled={editMode}
                  />
                </Grid>
              ))}
          </Grid>
        </div>
      )}
    </div>
  );
};

export { GradeSelector };
