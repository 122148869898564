import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import pageNotFound from '_assets/svg/404.svg';
import emptyBox from '_assets/svg/noDataFound.svg';

// Import your custom illustration or use an existing one

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  padding: theme.spacing(12, 2),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(6, 0),
  },
}));

const StyledIllustration = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 260,
  margin: 'auto',
  [theme.breakpoints.up('md')]: {
    height: 360,
  },
}));

// ----------------------------------------------------------------------

export function Page404(props) {
  return (
    <>
      <Helmet>{/* <title>404 Page Not Found | Your App Name</title> */}</Helmet>

      <Container sx={{ height: '80vh' }} maxWidth='sm'>
        <StyledContent>
          {props.message ? (
            <Typography variant='h3' paragraph>
              {props.message}
            </Typography>
          ) : (
            <Typography variant='h3' paragraph sx={{ paddingTop: '50px' }}>
              Sorry, page not found!
            </Typography>
          )}

          <div>
            <Typography sx={{ color: 'text.secondary', mb: 3 }}>
              Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL?
              Be sure to check your spelling.
            </Typography>

            <StyledIllustration>
              {props.name === 'NoDataFound' ? (
                <img src={emptyBox} alt='loading...' style={{ width: '100%' }} />
              ) : (
                <img src={pageNotFound} alt='loading...' style={{ width: '100%' }} />
              )}
            </StyledIllustration>
            {!props.message ? (
              <Button
                className='mt-5'
                to='/'
                size='large'
                variant='contained'
                component={RouterLink}
              >
                Go to Home
              </Button>
            ) : (
              ''
            )}
          </div>
        </StyledContent>
      </Container>
    </>
  );
}
