import React, { useEffect, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';

const NavigationButtons = ({
  totalQuestions,
  onQuestionClick,
  selectedSubject,
  questionStates,
  isTestSubmitted,
}) => {
  const [buttonColors, setButtonColors] = useState(Array(totalQuestions).fill('default'));

  useEffect(() => {
    const newColors = questionStates.map((state) => {
      switch (state) {
        case 'answered':
          return 'green';
        case 'marked':
          return 'purple';
        case 'visited':
          return 'red';
        default:
          return 'default';
      }
    });
    setButtonColors(newColors);
  }, [questionStates, isTestSubmitted]);

  const handleQuestionClick = (questionId) => {
    onQuestionClick(questionId);
  };

  return (
    <Box
      sx={{ marginRight: '20px', backgroundColor: '#E5E4E2', height: '300px', overflowY: 'auto' }}
    >
      <Box>
        <Typography
          variant='h6'
          sx={{ padding: '10px', display: 'flex', justifyContent: 'center' }}
        >
          {selectedSubject ? selectedSubject : 'All Subjects'}
        </Typography>
        <Box sx={{ padding: '10px' }}>
          {[...Array(totalQuestions).keys()].map((questionId) => (
            <Button
              key={questionId}
              variant={'outlined'}
              sx={{ margin: '3px', backgroundColor: buttonColors[questionId], color: 'black' }}
              onClick={() => handleQuestionClick(questionId)}
            >
              Q{questionId + 1}
            </Button>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default NavigationButtons;
