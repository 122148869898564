import React from 'react';
import { TextField, Container, Paper, Input } from '@mui/material';
import { withFormik, Form } from 'formik';
import * as Yup from 'yup';
// import axios from 'axios'; // Import axios library
// import { fetchWrapper } from '_helpers';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { LoadingButton } from '@mui/lab';
import { fetchWrapper } from '_helpers';

const UploadStudentExcel = ({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  errors,
  touched,
  setFieldValue,
}) => {
  return (
    <>
      <Container maxWidth='lg'>
        <Paper elevation={3} style={{ padding: '20px' }}>
          <Form onSubmit={handleSubmit}>
            <TextField
              label='School Email'
              variant='outlined'
              fullWidth
              margin='normal'
              id='schoolEmail'
              name='schoolEmail'
              value={values.schoolEmail}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.schoolEmail && Boolean(errors.schoolEmail)}
              helperText={touched.schoolEmail && errors.schoolEmail}
            />

            <TextField
              label='Teacher Email'
              variant='outlined'
              fullWidth
              margin='normal'
              id='teacherEmail'
              name='teacherEmail'
              value={values.teacherEmail}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.teacherEmail && Boolean(errors.teacherEmail)}
              helperText={touched.teacherEmail && errors.teacherEmail}
            />
            <Input
              type='file'
              fullWidth
              id='StudentExcel'
              name='StudentExcel'
              onChange={(event) => {
                setFieldValue('StudentExcel', event.currentTarget.files[0]);
              }}
              onBlur={handleBlur}
              error={touched.StudentExcel && Boolean(errors.StudentExcel)}
            />
            {touched.StudentExcel && errors.StudentExcel && (
              <div style={{ color: 'red' }}>{errors.StudentExcel}</div>
            )}
            <LoadingButton
              fullWidth
              size='large'
              type='submit'
              variant='contained'
              style={{ marginTop: '20px' }}
            >
              Submit
            </LoadingButton>
          </Form>
        </Paper>
      </Container>
    </>
  );
};

export const UploadStudentExcelWithFormik = withFormik({
  mapPropsToValues: () => ({
    schoolEmail: '',
    teacherEmail: '',
    StudentExcel: '',
  }),
  validationSchema: Yup.object().shape({
    // schoolEmail: Yup.string().email('Invalid email').required('Required'),
    // teacherEmail: Yup.string().email('Invalid email').required('Required'),
    StudentExcel: Yup.mixed().required('File required'),
  }),
  handleSubmit: async (values, { setSubmitting, resetForm }) => {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;
    // let token = localStorage.getItem('token');
    try {
      const formData = new FormData();
      formData.append('schoolEmail', values.schoolEmail);
      formData.append('teacherEmail', values.teacherEmail);
      formData.append('StudentExcel', values.StudentExcel);
      console.log('Form data:', formData);

      const response = await fetchWrapper.post(`${baseUrl}/uploadStudentExcel`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('API response:', response.data);
      toast.success('Student created successfully');
      resetForm();
      // Handle successful response
    } catch (error) {
      console.error('API error:', error);
      toast.error('Error creating student');
      // Handle error or show an error message
    }

    setSubmitting(false);
  },
})(UploadStudentExcel);
