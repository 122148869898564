import React, { useState } from 'react';
import { IconButton, InputAdornment, TextField, Container } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { styled } from '@mui/material';
import { authActions } from '_store';

export { AdminLogin };

function AdminLoginForm({ onSubmit }) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        email: Yup.string().required('Username is required'),
        password: Yup.string().required('Password is required'),
      }),
    ),
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleClick = (data) => {
    
    data.role = 'admin';
    onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(handleClick)}>
      <TextField
        className='login-field'
        name='email'
        label='Email address'
        variant='outlined'
        fullWidth
        required
        {...register('email')}
        error={!!errors.email}
        helperText={errors.email?.message}
        sx={{ marginTop: '10px' }}
      />

      <TextField
        name='password'
        label='Password'
        type={showPassword ? 'text' : 'password'}
        variant='outlined'
        fullWidth
        required
        {...register('password')}
        error={!!errors.password}
        helperText={errors.password?.message}
        sx={{ marginTop: '10px' }}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton onClick={() => setShowPassword(!showPassword)} sx={{ padding: 0 }}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <LoadingButton
        fullWidth
        size='large'
        type='submit'
        variant='contained'
        loading={isSubmitting}
        sx={{ backgroundColor: '#092141', marginTop: '10px' }}
      >
        Login
      </LoadingButton>
    </form>
  );
}

function AdminLogin() {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const alert = useSelector((x) => x.alert.value);
  const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      backgroundColor: '#eaeef2',
      height: '92vh',
      overflow: 'hidden',
    },
    [theme.breakpoints.down('sm')]: {
      overflow: 'hidden',
    },
  }));

  const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(0, 0),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '60%',
      minHeight: '20vh',
    },
  }));

  const handleSubmit = (data) => {
    const { email, password, role } = data;

    dispatch(authActions.login({ email, password, role }));
  };

  
  return (
    <StyledRoot>
      <Container
        maxWidth='sm'
        sx={{
          backgroundColor: '#eaeef2',
          height: 'auto',
          '@media (max-width: 600px)': {
            height: '420px',
          },
        }}
      >
        <StyledContent>
          {alert && alert.message && (
            <div className={`alert ${alert && alert.type}`}>{alert && alert.message}</div>
          )}
          <h1 style={{ margin: '20px' }}>Admin Login Form</h1>

          <AdminLoginForm onSubmit={handleSubmit} />
        </StyledContent>
      </Container>
    </StyledRoot>
  );
}
