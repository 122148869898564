import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';

const TimerAndEndTest = ({ isTestStarted, handleSubmitTest }) => {
  const [timeLeft, setTimeLeft] = useState(180 * 60 * 1000); // milliseconds (3 minutes in ms)

  useEffect(() => {
    if (isTestStarted) {
      const intervalId = setInterval(() => {
        if (timeLeft > 0) {
          setTimeLeft((prevTimeLeft) => prevTimeLeft - 1000); // Decrement every second
        } else {
          clearInterval(intervalId); // Clear interval when time is up
          handleSubmitTest(); // Handle submitting the test
        }
      }, 1000);

      return () => clearInterval(intervalId); // Cleanup function to stop timer on unmount
    }
  }, [isTestStarted, timeLeft, handleSubmitTest]); // Update timer when isTestStarted or timeLeft changes

  const formattedTime = new Date(timeLeft).toISOString().slice(11, 19); // Format time as HH:MM:SS

  return (
    <Box sx={{ paddingLeft: '50px', display: 'flex', alignItems: 'center' }}>
      <Button
        variant='contained'
        color='error'
        sx={{ marginRight: '20px' }}
        onClick={handleSubmitTest}
      >
        End Test
      </Button>
      <Typography variant='h6'>Time Left: {formattedTime}</Typography>
    </Box>
  );
};

export default TimerAndEndTest;
