import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './style.css';
import { useNavigate } from 'react-router-dom';
import { fetchWrapper } from '_helpers';
import { Button } from '@mui/material';
import { splitFromFirstSpace } from '_helpers';
import { ReportTable } from './ReportTable';
import { ReportChart } from './ReportChart';
import { Loader } from '_components/navigation/Loader';
import PerformanceAnalysis from '_components/common/PerformanceAnalysis';
export { SectionDisplay };

function SectionDisplay({
  data,
  chapterid,
  subject,
  askGPT,
  user,
  board,
  sectionid,
  subsectionid,
  sectionTitle,
  subsectionTitle,
  quizTitle,
  analysisChartData,
}) {
  const [report, setReport] = useState([]);
  const [showHelp, setShowHelp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;
  const handleDynamicClick = (event) => {
    const clickedElement = event.target;
    const action = clickedElement.getAttribute('data-action');
    if (action && action.startsWith('askGPT(')) {
      const dynamicData = action.slice(8, -2).replace('-', ' ');
      askGPT(dynamicData);
    } else if (action && action.startsWith('link(')) {
      const dynamicData = action.slice(6, -2).replace('-', ' ');
      navigate(dynamicData);
    }
  };
  async function fetchData() {
    let filteredData;
    let res = await fetchWrapper.get(
      `${baseUrl}/fetchStudentReport?studentId=${user['email']}&studentGrade=${user.relatedData['grade']}&chapter_id=${chapterid}`,
    );
    filteredData =
      res && res.results.filter((item) => item.subject.toLowerCase() === subject.toLowerCase());
    res && setReport(filteredData);

    function compareByObtainedMarks(a, b) {
      return a.user_obtained_marks - b.user_obtained_marks;
    }
    filteredData.sort(compareByObtainedMarks);
    let titles = [];
    filteredData.map((item) => titles.push(splitFromFirstSpace(item.quiz_name)));
    setIsLoading(false);
  }

  useEffect(() => {
    document.addEventListener('click', handleDynamicClick);
    return () => {
      document.removeEventListener('click', handleDynamicClick);
    };
  }, []);

  useEffect(() => {
    if (data.section_type === 'analysis') {
      setIsLoading(true);
      fetchData();
    }
  }, [data.section_id]);
  const handleHelpeMeClick = async (quiz_name, quiz_title) => {
    try {
      let req = {
        quiz_title: quiz_title,
      };
      console.log(quiz_name, setShowHelp);
      let res = await fetchWrapper.post(`${baseUrl}/getQuizChapter`, req);
      navigate(
        `/dashboard/standard/${res.grade}/subject/${res.subject.toLowerCase()}/chapter/${
          res.chapter_id
        }/section/${res.section_id}/subsection/${res.sub_section_id}`,
      );
    } catch (error) {
      console.error('Error fetching quiz chapter:', error);
      alert('Please contact Administration.');
    }
  };
  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className='container-fluid'>
      <div className='m-3'>
        <div className='h3'>{data.section_id + ' ' + data.section_title}</div>
        {data && data.description && (
          <>
            <div
              className='h6 intro'
              dangerouslySetInnerHTML={{ __html: data?.description }}
              style={{ height: '100%', background: '#ccf2ff' }}
            ></div>
          </>
        )}
        {data && data.section_type === 'analysis' && (
          <>
            <div className='d-flex justify-content-between'>
              <h3 className='my-auto'>{showHelp ? 'Questions' : 'Results'}</h3>
              {showHelp && (
                <Button sx={{ fontSize: '18px' }} onClick={handleHelpeMeClick}>
                  Results
                </Button>
              )}
            </div>
            {report && !showHelp && board != 'NEET' ? (
              report.length > 0 ? (
                <>
                  <ReportChart data={report} subject={subject} />
                  <ReportTable TestData={report} onHandleClick={handleHelpeMeClick} />
                </>
              ) : report && !isLoading && !report.length ? (
                <div className='mt-3 alert alert-danger'>
                  Please take a test to see your progress.
                </div>
              ) : (
                ''
              )
            ) : (
              <div>
                <PerformanceAnalysis
                  subject={subject}
                  chapterId={chapterid}
                  subsectionId={subsectionid}
                  sectionId={sectionid}
                  sectionTitle={sectionTitle}
                  subsectionTitle={subsectionTitle}
                  quizTitle={quizTitle}
                  analysisChartData={analysisChartData}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

SectionDisplay.propTypes = {
  data: PropTypes.object.isRequired,
  figure: PropTypes.any,
  askGPT: PropTypes.func,
  user: PropTypes.object.isRequired,
};
