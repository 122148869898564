import React from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Nav, PrivateRoute, SubSections, SearchResult, VectorSearch, Steps } from './_components';
import {
  Home,
  Report,
  Page404,
  Chapter,
  DashboardLayout,
  DashboardAppPage,
  AddStudent,
  AddTeacher,
  StudentList,
  TeacherList,
  StudentReport,
  EditUser,
  AddSchool,
  SchoolList,
  NEET,
  NCERT,
  QuizList,
  AddQuiz,
  QuizDetails,
  AddMockQuiz,
  GetQuizSummary,
  ManualQuizEntry,
} from 'pages';
import MockPerformanceAnalysis from './pages/neetTest/MockPerformanceAnalysis'; // Direct import
import { AccountLayout } from 'account';
import { history } from '_helpers';
import FeedbackPanel from 'pages/chapter/FeedbackPanel';
import PlanLayout from 'account/individual/PlanLayout';
import PaymentPage from 'account/individual/payment';
import PaymentSuccess from 'account/individual/PaymentSuccess';
import Main from './_components/new-home/main';
import AddProduct from 'account/individual/Product/AddProduct';
import { Product } from 'account/individual/Product/Product';
import Dashboard from './_components/Dashboard/Dashboard';


export { App };
// deployment testing
function App() {
  const navigate = useNavigate();
  const location = useLocation();
  history.navigate = navigate;
  history.location = location;

  const user = useSelector((x) => x.auth.value);
  const { plan_courses, plan_start_date, plan_end_date } = user || {};

  // Check if any of the plan details are empty
  const isPlanEmpty = !plan_courses || !plan_start_date || !plan_end_date;

  const rootElement = (() => {
    
    if (!user) {
      return <Main />;
    } else if (user.role === 'student') {
      return <Home />;
    } else if (user.role === 'NEET') {
      if (isPlanEmpty) {
        return <PaymentPage />;
      } else {
        if (user.plan_courses) {
          return <Dashboard state={user.plan_courses} />
        }    
      }
    } else {
      return <DashboardLayout user={user} />;
    }
  })();

  return (
    <div className='app-container bg-light h-100'>
      <Nav />
      <Routes>
        {/* open routes start */}
        <Route path='/' element={rootElement} />
        <Route path='/home' element={<Main />} />
        <Route path='/NEET' element={<NEET />} />
        <Route path='/NCERT' element={<NCERT />} />
         <Route path='/Dashboard' element={<Dashboard />} />
        <Route path='/AddMockQuiz' exact element={<AddMockQuiz />} />
        <Route path='/ManualQuizEntry' exact element={<ManualQuizEntry />} />
        <Route path='/getQuizSummary' exact element={<GetQuizSummary />} />
        <Route path='/PlanLayout' exact element={<PlanLayout />} />
        {/* <Route path='/PaymentGatewayPage' exact element={<PaymentGatewayPage />} /> */}
        <Route path='/payment' exact element={<PaymentPage />} />
        <Route path='/PaymentSuccess' exact element={<PaymentSuccess />} />
        {/* open routes ends */}
        {/* Private routes start*/}
        <Route element={<PrivateRoute />}>
          {user && (user.role === 'teacher' || user.role === 'school_admin') && (
            <Route path='/' element={<DashboardLayout user={user} />}>
              <Route index element={<Navigate to='/teacherDashboard' replace />} />
              <Route path='teacherDashboard' element={<DashboardAppPage />} />
              <Route path='addStudent' element={<AddStudent />} />
              <Route path='addTeacher' element={<AddTeacher />} />
              <Route path='edit/:role/:id' element={<EditUser />} />
              <Route path='/students' exact element={<StudentList />} />
              <Route path='/report' exact element={<Report />} />
              <Route path='/dashboard' element={<Chapter />} />
              <Route path='/teachers' exact element={<TeacherList />} />
              <Route path='/progress-report/:studentId' exact element={<StudentReport />} />
              <Route path='/quizList' exact element={<QuizList />} />
              <Route path='/AddQuiz' exact element={<AddQuiz />} />
              <Route path='/QuizDetails/:id' exact element={<QuizDetails />} />
            </Route>
          )}
          {user && user.role === 'admin' && (
            <Route path='/' element={<DashboardLayout user={user} />}>
              <Route index element={<Navigate to='/adminDashboard' replace />} />
              <Route path='adminDashboard' element={<DashboardAppPage />} />
              <Route path='addSchool' element={<AddSchool />} />
              <Route path='/schools' exact element={<SchoolList />} />
              <Route path='neetAssistant' element={<VectorSearch />} />
              <Route path='/AddProduct' element={<AddProduct />} />
            </Route>
          )}
          <Route path='/search/:query?' element={<SearchResult />} />
          <Route path='/dashboard/NEET/subject/:subject' element={<Chapter />}>
            <Route path='chapter/:chapterid/*' element={<SubSections />} />
            <Route
              path='chapter/:chapterid/section/:sectionid?/:activityid?'
              element={<SubSections />}
            />
            <Route
              path='chapter/:chapterid/section/:sectionid/subsection/:subsectionid/*'
              element={<SubSections />}
            />
            <Route
              path='chapter/:chapterid/section/:sectionid/subsection/:subsectionid/:activityid/*'
              element={<SubSections />}
            />
          </Route>
          <Route path='/dashboard/NEET_TEST/subject/:subject' element={<Chapter />}>
            <Route path='chapter/:chapterid/*' element={<SubSections />} />
            <Route
              path='chapter/:chapterid/section/:sectionid?/:activityid?'
              element={<SubSections />}
            />
            <Route
              path='chapter/:chapterid/section/:sectionid/subsection/:subsectionid/*'
              element={<SubSections />}
            />
            <Route
              path='chapter/:chapterid/section/:sectionid/subsection/:subsectionid/:activityid/*'
              element={<SubSections />}
            />
          </Route>
          <Route path='/dashboard/SAT/subject/:subject' element={<Chapter />}>
            <Route path='chapter/:chapterid/*' element={<SubSections />} />
            <Route
              path='chapter/:chapterid/section/:sectionid?/:activityid?'
              element={<SubSections />}
            />
            <Route
              path='chapter/:chapterid/section/:sectionid/subsection/:subsectionid/*'
              element={<SubSections />}
            />
            <Route
              path='chapter/:chapterid/section/:sectionid/subsection/:subsectionid/:activityid/*'
              element={<SubSections />}
            />
          </Route>
          <Route path='/dashboard/standard/:standard/subject/:subject/' element={<Chapter />}>
            <Route path='chapter/:chapterid/*' element={<SubSections />} />
            <Route
              path='chapter/:chapterid/section/:sectionid?/:activityid?'
              element={<SubSections />}
            />
            <Route
              path='chapter/:chapterid/section/:sectionid/subsection/:subsectionid/*'
              element={<SubSections />}
            />
            <Route
              path='chapter/:chapterid/section/:sectionid/subsection/:subsectionid/:activityid/*'
              element={<SubSections />}
            />
          </Route>
          <Route path='/textbook' element={<Home />} />{' '}
          <Route path='/Neet_textbook' element={<Steps isNEET={true} />} />{' '}
          <Route path='/Ncert_textbook' element={<Steps isNCERT={true} />} />
        </Route>
        {/* Private routes ends*/}
        <Route path='account/*' element={<AccountLayout />} />
        <Route path='/MockPerformanceAnalysis' element={<MockPerformanceAnalysis />} />{' '}
        <Route path='feedback' element={<FeedbackPanel />} />
        <Route path='product' element={<Product />} />
        {/* This is the route for your component */}
        <Route path='*' element={<Page404 />} />
      </Routes>
    </div>
  );
}
