import React, { useState } from 'react';
import { Button, Box } from '@mui/material';

const SubjectButtons = ({ onSelectSubject }) => {
  const [selectedSubject, setSelectedSubject] = useState(null);

  const handleSubjectClick = (subject) => {
    setSelectedSubject(subject);
    onSelectSubject(subject); // Pass selected subject to parent component
  };

  return (
    <Box>
      <Button
        variant={selectedSubject === null ? 'contained' : 'outlined'}
        sx={{ margin: '5px' }}
        onClick={() => handleSubjectClick(null)}
      >
        All Subjects
      </Button>
      <Button
        variant={selectedSubject === 'Physics' ? 'contained' : 'outlined'}
        sx={{ margin: '5px' }}
        onClick={() => handleSubjectClick('Physics')}
      >
        Physics
      </Button>
      <Button
        variant={selectedSubject === 'Chemistry' ? 'contained' : 'outlined'}
        sx={{ margin: '5px' }}
        onClick={() => handleSubjectClick('Chemistry')}
      >
        Chemistry
      </Button>
      <Button
        variant={selectedSubject === 'Biology' ? 'contained' : 'outlined'}
        sx={{ margin: '5px' }}
        onClick={() => handleSubjectClick('Biology')}
      >
        Biology
      </Button>
    </Box>
  );
};

export default SubjectButtons;
