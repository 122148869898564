import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PaymentSuccess = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const referenceNum = searchParams.get('reference');
  const user = useSelector((x) => x.auth.value);
  const { plan_courses } = user || {};

  useEffect(() => {
    if (plan_courses) {
      navigate('/Dashboard', { state: { plan_courses } });
    } else {
      navigate('/account/signUp'); // Redirect to a default page or show an error
    }
  }, [plan_courses, navigate]);

  return (
    <div>
      <h1>Order Successfully</h1>
      <p>Reference No: {referenceNum}</p>
    </div>
  );
};

export default PaymentSuccess;