import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Typography, Stack, Button, TextField, Card, Grid } from '@mui/material';
import { fetchWrapper } from '_helpers';
import { ListTable } from '_components';
import Pagination from '@mui/material/Pagination';
import { getProductAccounts, ProductAccounts } from '../../../_store/product.slice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { useNavigate } from 'react-router-dom';

const AddProduct = () => {
  //   const [searchTerm, setSearchTerm] = React.useState('');
  const [image, setImage] = React.useState(null);
  const [ProductName, setProductName] = React.useState('');
  const [resultImage, setResultImage] = React.useState([]);
  const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;
  const dispatch = useDispatch();
  const _ProductAccount = useSelector(ProductAccounts);

  useEffect(() => {
    dispatch(getProductAccounts());
  }, [resultImage]);
  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };
  const handleInputChange = (e) => {
    setProductName(e.target.value); // Update state with the new input value
  };
  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append('image', image); // Ensure 'image' is a File object
    formData.append('product_class_name', ProductName);

    try {
      // Step 1: Upload image to Azure Blob Storage
      const uploadResponse = await fetchWrapper.post(`${baseUrl}/InsertProductImage`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const imageUrl = uploadResponse.urls; // Assuming the response contains the uploaded image URL

      console.log('Image uploaded successfully, URL:', imageUrl);

      // Step 2: Store the image URL in your database
      const insertResponse = await fetchWrapper.post(`${baseUrl}/InsertProduct`, {
        product_Image: imageUrl,
        // Add any other fields that need to be stored with the product
      });
      setResultImage(insertResponse.data);
      toast.success('Quiz stored successfully');

      document.getElementById('imageInput').value = '';
      console.log('Image URL stored successfully in database:', insertResponse.data);

      // Handle success, e.g., navigate to another page or show a success message
      // navigate('/some-other-page');
    } catch (error) {
      // toast.error('Error during form submission');
      console.error('Error during form submission:', error);
      // Handle error, e.g., show an error message
    }
  };

  return (
    <div>
      <Helmet>
        <title>School List</title>
      </Helmet>
      <Container maxWidth='xl'>
        <Typography variant='h4' gutterBottom>
          School List
        </Typography>
        <Stack direction='column' spacing={2} mb={5}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label='Product Class Name'
                variant='outlined'
                type='text'
                name='product_class_name'
                // value={product_class_name}
                onChange={handleInputChange}
                margin='normal'
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                sx={{ marginTop: '14px' }}
                type='file'
                variant='outlined'
                onChange={handleImageChange}
                inputProps={{ accept: 'image/*' }}
                fullWidth
              />
            </Grid>
          </Grid>

          <Button
            variant='contained'
            sx={{
              backgroundColor: `var(--Peach)`,
              '&:hover': {
                backgroundColor: 'var(--Blue)',
              },
              color: `var(--White)`,
            }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Stack>
      </Container>
      <Card sx={{ boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' }}>
        <ListTable
          data={_ProductAccount['results']}
          headers={['product_id', 'product_class_name', 'product_Image']}
          columnHeaders={['Product Id ', 'product_class_name', 'Product Image ']}
        />

        <Container align='center' justify='center' p={10} w='full'>
          <Pagination
            // count={totalPages}
            shape='rounded'
            // className={`controls ${currentPage}`}
            sx={{ justifyContent: 'center' }}
            // onChange={(e, n) => handlePageChange(e, n)}
          />
        </Container>
      </Card>
    </div>
  );
};

export default AddProduct;
