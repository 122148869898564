import { createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '_helpers';
// let user = JSON.parse(localStorage.getItem('auth'));

const schoolReducer = createSlice({
  name: 'school',
  initialState: {
    schoolAccount: [],
    fetchingSchool: false,
    totalItems: -1,
  },
  reducers: {
    setSchoolAccounts: (state, action) => {
      state.schoolAccount = action.payload;
    },

    setFetchingSchool: (state, action) => {
      state.fetchingSchool = action.payload;
    },

    settotalItems: (state, action) => {
      state.totalItems = action.payload;
    },
  },
});

export const getSchoolAccounts =
  (pageNo, pageSize, search = '') =>
  async (dispatch) => {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;
    const apiURL = `${baseUrl}/getallSchool?pageNo=${pageNo ? pageNo : 1}&pageSize=${
      pageSize ? pageSize : 10
    }&search=${search}`;
    try {
      const response = await fetchWrapper.get(apiURL);
      const data = await response;
      dispatch(setSchoolAccounts(data));
      // console.log(data.data, 'data');
    } catch (error) {
      console.error('Error fetching school accounts:', error);
    }
  };

export const { setSchoolAccounts, setFetchingSchool, setTotalItems } = schoolReducer.actions;

export const schoolAccounts = (state) => state.school.schoolAccount;
export const fetchingSchool = (state) => state.school.fetchingSchool;

export const totalSchool = (state) => state.school.totalItems;

export default schoolReducer.reducer;
