import React from 'react';
import { Grid, Typography, Box, Button, CardContent, Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Carousel as CustomCarousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import the CSS
import backgroundImage from '_assets/images/background.svg';
import BookSection from '_assets/images/booksection.png';
import BookSectionsvg from '_assets/images/Booksectionsvg.svg';
import watchImage from '_assets/images/watchimage.png';
import icon1 from '_assets/images/icon_1.png';
import icon2 from '_assets/images/icon_2.png';
import icon3 from '_assets/images/icon_3.png';
import icon4 from '_assets/images/icon_4.png';
import icon5 from '_assets/images/icon_5.png';
import icon6 from '_assets/images/icon_6.png';
import ncertBoy from '_assets/images/BOY_NEW.png';
import neetGirl from '_assets/images/GIRL_1.png';
import spiderImage from '_assets/images/spiderImage.svg';
import testimonal1 from '_assets/images/testimonal1.png';
import testimonal2 from '_assets/images/testimonal2.png';
import ScanQR from '_assets/images/scanQR.png';
import GooglePlayIcon from '_assets/images/googlePlayIcon.png';
import AppleIcon from '_assets/images/appleIcon.png';
import ClassroomAILogo from '_assets/images/classroom-ai.gif';
import HowToUseAIGuideNEET from '_assets/images/howToUseAIGuideNEET.mp4';
import { useNavigate } from 'react-router-dom';
import { HomepageAnimation } from './HomepageAnimation';

// Styled components
const Section1 = styled('section')(() => ({
  width: '100%', // Ensuring the section takes full width
  background: `url(${backgroundImage}) no-repeat center center`,
  backgroundSize: 'cover',
  padding: '50px 0 20px 0',
  color: '#fff',
  textAlign: 'start',
}));

const Section3 = styled('section')(() => ({
  width: '100%',
  background: `url(${BookSection}) no-repeat center center`,
  backgroundSize: 'cover',
  padding: '10px 0',
  color: '#fff',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center', // Center vertically if needed
}));

const CustomButton = styled(Button)(() => ({
  backgroundColor: 'white',
  color: 'black',
  '&:hover': {
    backgroundColor: '#f0f0f0', // Adjust as needed
  },
}));

// Styled components for testimonials
const Section2 = styled('section')(() => ({
  padding: '40px 0',
  background: `url(${spiderImage}) no-repeat center center`,
  backgroundSize: 'cover',
  textAlign: 'center',
}));

const CircularImage = styled('img')(() => ({
  height: '70px',
  borderRadius: '100%',
  border: '2px solid #f95d50',
  margin: '0 auto 16px',
  width: '30% !important',
}));

const TestimonialCard = styled(Card)(() => ({
  margin: '20px',
  flex: '0 0 auto',
  width: '300px',
  textAlign: 'center',
  border: '2px solid #f95d50',
  backgroundColor: 'none !important',
  boxShadow: '16px 32px 56px rgba(143, 174, 207, 0.25)',
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'scale(1.05)', // Slightly enlarge on hover
  },
}));

const TestimonialsContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  paddingBottom: '20px',
}));

const TestimonialsTitle = styled(Typography)(() => ({
  marginBottom: '25px',
  fontWeight: 'bold',
  fontSize: '2rem',
  color: '#092141',
}));

const StyledStar = styled('div')(() => ({
  color: '#ffd700',
  fontSize: '20px',
  marginBottom: '10px',
}));
const testimonials = [
  {
    name: 'Customer 1',
    image: testimonal2, // Replace with actual paths
    quote: 'Amazing experience! Highly recommend their services.',
    rating: 5,
  },
  {
    name: 'Customer 2',
    image: testimonal1, // Replace with actual paths
    quote: 'Their purpose was to tell stories of their beliefs, experiences, or mythology.',
    rating: 4,
  },
  {
    name: 'Customer 3',
    image: testimonal1, // Replace with actual paths
    quote: 'Amazing experience! Highly recommend their services.',
    rating: 4.5,
  },
  {
    name: 'Customer 4',
    image: testimonal1, // Replace with actual paths
    quote: 'Amazing experience! Highly recommend their services.',
    rating: 5,
  },
  {
    name: 'Customer 5',
    image: testimonal2, // Replace with actual paths
    quote: 'A wonderful journey into the world of learning!',
    rating: 4.8,
  },
  {
    name: 'Customer 5',
    image: testimonal1, // Replace with actual paths
    quote: 'A wonderful journey into the world of learning!',
    rating: 4.8,
  },
];

const HomeNew = ({
  scrollToSection,
  homeRef,
  aiTutorRef,
  interactiveModelsRef,
  learningProgramsRef,
}) => {
  const navigate = useNavigate();

  const handleProductPage = () => {
    navigate('/product');
  };

  const handleNavClick = (section) => {
    if (scrollToSection) {
      scrollToSection(section);
    } else {
      navigate('/home');
    }
  };

  return (
    <>
      <Section1 ref={homeRef}>
        <Grid container spacing={0} sx={{ padding: '0px 40px' }}>
          <Grid item xs={12} md={6} sx={{ padding: '40px 20px 20px ' }}>
            <Typography variant='h4' sx={{ marginTop: { xs: '5px', md: '150px' } }}>
              Classroom AI is the world’s first AI (Artificial Intelligence) tutor for NEET, 8 to
              12th grade students.
            </Typography>
            <Typography variant='body1' sx={{ padding: '20px 0px 20px' }}>
              It is trained on NCERT, various state board syllabi, and previous years’ NEET
              questions. It brings the power of AI technology to the education world.
              Students/teachers engage with interactive models and simulations to visualize every
              topic and reinforce their understanding.....
            </Typography>
            <CustomButton
              variant='contained'
              sx={{ padding: '13px 20px', marginLeft: '20px', borderRadius: '25px' }}
              onClick={() => handleNavClick('AI Tutor')}
            >
              Read More
            </CustomButton>
          </Grid>
          <Grid item xs={12} md={6}>
            <HomepageAnimation />
          </Grid>
        </Grid>
      </Section1>

      <Section1 ref={aiTutorRef} id='ai-tutor'>
        <Grid container spacing={4} sx={{ padding: '0px 40px' }}>
          <Grid item xs={12} md={6} sx={{ padding: '40px 20px 20px ' }}>
            <Typography variant='h4' sx={{ padding: '20px' }}>
              AI Chatbot/Tutor
            </Typography>
            <Typography variant='body1' sx={{ padding: '40px 20px 20px' }}>
              Classroom AI chatbots are advanced systems designed to simulate human-like
              conversations (question and answer) on NEET exam and other school grades. It has
              expert knowledge on NCERT/various state boards syllabi and previous year questions. It
              works as tutor for you.
            </Typography>
            <Typography variant='body1' sx={{ padding: '0px 20px 40px' }}>
              In addition to general questions, it generates Competency based focused questions and
              real-world applications for each topic. NEET focused advanced topics from grade 8, 9
              and 10.
            </Typography>
            <Typography variant='h4' sx={{ padding: '20px' }}>
              AI generated exercise and NEET questions
            </Typography>
            <Typography variant='body1' sx={{ padding: '40px 20px 20px' }}>
              Classroom AI interactively generates dynamically almost infinite number of possible
              questions on all types as it knows syllabus and previous year questions.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <video width='100%' height='100%' controls muted autoPlay>
              <source src={HowToUseAIGuideNEET} type='video/mp4' />
            </video>
          </Grid>
        </Grid>
      </Section1>

      <Section1 ref={interactiveModelsRef} id='interactive-models'>
        <Grid container spacing={4} sx={{ padding: '0px 40px' }}>
          <Grid item xs={12} md={6} sx={{ padding: '40px 20px 20px ' }}>
            <Typography variant='h4' sx={{ padding: '20px' }}>
              Interactive models
            </Typography>
            <Typography variant='body1' sx={{ padding: '40px 20px 20px' }}>
              Packed with interactive models for every concept for Science and Math for NCERT and
              various state board syllabi. With the interactive models and simulations to visualize
              every topic and help reinforce your understanding.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              component='iframe'
              width='100%'
              height={{ xs: '250px', sm: '350px', md: '400px', lg: '500px' }}
              src={'https://www.youtube.com/embed/ICnIKjfQ_ko'}
              title={'Interactive models'}
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
            />
          </Grid>
        </Grid>
      </Section1>

      <Box
        sx={{
          background: `url(${watchImage}) no-repeat center center`,
          backgroundSize: 'cover',
          color: '#fff',
          padding: '40px 0',
        }}
      >
        <Typography
          variant='h4'
          align='center'
          sx={{ fontWeight: 'bold', textTransform: 'uppercase', marginBottom: 2 }}
        >
          Learn More about Interactive Models
        </Typography>
        <Typography
          variant='h6'
          align='center'
          sx={{ textTransform: 'uppercase', marginBottom: 4 }}
        >
          Watch it
        </Typography>

        <Grid container justifyContent='space-around' spacing={4}>
          <Grid item xs={12} md={4} textAlign='center'>
            <div className='d-flex justify-content-start'>
              <img src={icon1} alt='Icon' style={{ width: 50, height: 50 }} />
              <Typography variant='body1' sx={{ padding: '14px 8px !important' }}>
                How to interact with the models?
              </Typography>
            </div>
            <div className='d-flex justify-content-start'>
              <img src={icon2} alt='Icon 2' style={{ width: 50, height: 50 }} />
              <Typography variant='body1' sx={{ padding: '14px 8px !important' }}>
                Models have sliders to interact with variables.
              </Typography>
            </div>
            <div className='d-flex justify-content-start'>
              <img src={icon3} alt='Icon 3' style={{ width: 50, height: 50 }} />
              <Typography variant='body1' sx={{ padding: '14px 8px !important' }}>
                You can have 3D view of the 3D models.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className='d-flex justify-content-start'>
              <img src={icon4} alt='Icon 4' style={{ width: 50, height: 50 }} />
              <Typography variant='body1' sx={{ padding: '14px 8px !important' }}>
                Watch teacher interacts with the models in the classroom.
              </Typography>
            </div>
            <div className='d-flex justify-content-start'>
              <img src={icon5} alt='Icon 5' style={{ width: 50, height: 50 }} />
              <Typography variant='body1' sx={{ padding: '14px 8px !important' }}>
                Students interact with the models in the computer lab of school.
              </Typography>
            </div>
            <div className='d-flex justify-content-start'>
              <img src={icon6} alt='Icon 6' style={{ width: 50, height: 50 }} />
              <Typography variant='body1' sx={{ padding: '14px 8px !important' }}>
                Students interact with the models at home/remote learning setup.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          background: `url(${spiderImage}) no-repeat center center`,
          backgroundSize: 'cover',
          padding: '40px 0',
        }}
        ref={learningProgramsRef}
        id='learning-programs'
      >
        <Typography
          variant='h4'
          align='center'
          sx={{ fontWeight: 'bold', textTransform: 'uppercase', marginBottom: 4 }}
        >
          Explore our learning Programs
        </Typography>

        <Grid container justifyContent='center' spacing={4}>
          <Grid item xs={12} md={5} sx={{ padding: '10px' }}>
            <Box
              sx={{
                backgroundColor: '#0b3045',
                color: '#fff',
                borderRadius: '15px',
                padding: '25px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                variant='h6'
                sx={{ fontWeight: 'bold', fontSize: '1.8rem', lineHeight: '1.2' }}
              >
                Ignite Your Curiosity with
                <br />
                <span style={{ color: '#f95d50' }}>Interactive Models for NCERT</span>
                <br />
                Science and math!
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box>
                  <Typography variant='body2' sx={{ padding: '8px 12px' }}>
                    CBSE State boards
                  </Typography>
                  <Button
                    variant='contained'
                    sx={{
                      backgroundColor: '#fff',
                      color: '#000',
                      textTransform: 'none',
                      width: '140px',
                      borderRadius: '10px',
                    }}
                    onClick={handleProductPage}
                  >
                    Explore now
                  </Button>
                </Box>
                <Box sx={{ textAlign: 'center' }}>
                  <img
                    src={ncertBoy}
                    alt='Boy'
                    style={{ borderRadius: '10px', width: '200px', height: '120px' }}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={5} sx={{ padding: '10px' }}>
            <Box
              sx={{
                backgroundColor: '#f95d50',
                color: '#fff',
                borderRadius: '15px',
                padding: '25px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                variant='h6'
                sx={{ fontWeight: 'bold', color: '#041731', fontSize: '1.8rem', lineHeight: '1.2' }}
              >
                All-In-One <span style={{ color: '#fff' }}>NEET Preparation</span>
                <br />
                Syllabus, Past Papers, and
                <br />
                AI Expertise!
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Button
                  variant='contained'
                  sx={{
                    backgroundColor: '#000',
                    color: '#fff',
                    textTransform: 'none',
                    width: '140px',
                    borderRadius: '10px',
                  }}
                  onClick={handleProductPage}
                >
                  Explore now
                </Button>
                <Box sx={{ textAlign: 'center', marginLeft: 2 }}>
                  <img
                    src={neetGirl}
                    alt='Girl'
                    style={{ borderRadius: '10px', width: '200px', height: '120px' }}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Section3>
        <img src={BookSectionsvg} alt='Book Section' style={{ flex: 1, maxWidth: '40%' }} />
        <CustomButton variant='contained' sx={{ padding: '10px 20px', justifyContent: 'center' }}>
          Book Your Free Trial Now
        </CustomButton>
      </Section3>

      <Box
        sx={{
          backgroundColor: '#041731',
          backgroundImage: `url(${spiderImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover', // Use 'cover' to maintain aspect ratio and fill the Box
          backgroundPosition: 'center',
          padding: '50px 0', // Adjust padding as needed
          height: 'auto', // Automatically adjust based on content
          // minHeight: '500px', // Minimum height based on content needs
          // maxHeight: '600px', // Maximum height to prevent overflow
          overflow: 'hidden', // Hide anything that overflows the box
        }}
      >
        <Grid container spacing={4}>
          {/* Left Side: QR Code and Download Buttons */}
          <Grid item xs={12} md={6} sx={{ textAlign: { xs: 'center', md: 'center' } }}>
            <Typography variant='h4' color='white' sx={{ mb: 4 }}>
              Scan the QR code to download our app
            </Typography>
            <Box
              m={{ xs: 2, md: 10 }}
              sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}
              gap={2}
            >
              <Box>
                <img
                  src={ScanQR} // Replace with actual path to QR code image
                  alt='Scan QR Code'
                  style={{ width: '150px', marginLeft: '16px' }}
                />
              </Box>
              <Box
                mt={3}
                sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}
              >
                {/* Google Play Button */}
                <Button
                  variant='contained'
                  sx={{
                    backgroundColor: '#fff',
                    color: '#000',
                    borderRadius: '50px',
                    padding: '10px 20px',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    width: { xs: '200px', md: '200px' }, // Full width on mobile
                    '&:hover': { backgroundColor: '#f5f5f5' },
                  }}
                >
                  <img
                    src={GooglePlayIcon} // Replace with actual path to Google Play icon
                    alt='Google Play'
                    style={{ width: '30px', marginRight: '10px' }}
                  />
                  <Box sx={{ textAlign: 'left' }}>
                    <Typography variant='caption' sx={{ fontSize: '10px', color: '#9e9e9e' }}>
                      GET IT ON
                    </Typography>
                    <Typography variant='subtitle2' sx={{ fontWeight: 'bold' }}>
                      Google Play
                    </Typography>
                  </Box>
                </Button>

                {/* App Store Button */}
                <Button
                  variant='contained'
                  sx={{
                    backgroundColor: '#fff',
                    color: '#000',
                    borderRadius: '50px',
                    padding: '10px 20px',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    width: { xs: '200px', md: '200px' }, // Full width on mobile
                    '&:hover': { backgroundColor: '#f5f5f5' },
                  }}
                >
                  <img
                    src={AppleIcon} // Replace with actual path to App Store icon
                    alt='App Store'
                    style={{ width: '30px', marginRight: '10px' }}
                  />
                  <Box sx={{ textAlign: 'left' }}>
                    <Typography variant='caption' sx={{ fontSize: '10px', color: '#9e9e9e' }}>
                      DOWNLOAD ON THE
                    </Typography>
                    <Typography variant='subtitle2' sx={{ fontWeight: 'bold' }}>
                      App Store
                    </Typography>
                  </Box>
                </Button>
              </Box>
            </Box>
          </Grid>

          {/* Right Side: Phone Mockup */}
          <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
            <Box
              sx={{
                width: { xs: '200px', md: '300px' }, // Smaller phone size on mobile
                height: { xs: '400px', md: '600px' }, // Adjust height on mobile
                border: '16px solid #333',
                borderRadius: '40px',
                backgroundColor: 'white',
                position: 'relative',
                overflow: 'hidden',
                mx: 'auto',
              }}
            >
              <img
                src={ClassroomAILogo} // Replace with the path to your logo
                alt='App Logo'
                style={{
                  width: '75%',
                  height: '75%',
                  objectFit: 'contain',
                  padding: '20px',
                }}
              />
              <Typography
                variant='h6'
                sx={{
                  position: 'absolute',
                  bottom: { xs: '100px', md: '200px' },
                  width: '100%',
                  textAlign: 'center',
                  color: 'black',
                }}
              >
                CLASSROOM AI
                <br />
                Transforming Education with AI
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Section2>
        <TestimonialsTitle variant='h4'>Client Feedback and Testimonials</TestimonialsTitle>
        <CustomCarousel
          showThumbs={false}
          showStatus={false}
          infiniteLoop
          useKeyboardArrows
          autoPlay
          interval={5000} // Set the time for automatic transition (5000ms = 5 seconds)
          transitionTime={500} // Set the transition time for slide
          stopOnHover={true} // Stop auto play when hovered
          showArrows={false} // Adjust based on your design
          renderIndicator={(onClickHandler, isSelected, index) => {
            return (
              <button
                onClick={onClickHandler}
                style={{
                  background: isSelected ? '#f95d50' : '#d1d1d1', // Change the colors as per your design
                  border: 'none',
                  borderRadius: '50%',
                  width: '10px',
                  height: '10px',
                  margin: '0 5px',
                  cursor: 'pointer',
                  paddingTop: '10px',
                }}
                aria-label={`Go to slide ${index + 1}`}
              />
            );
          }}
        >
          {Array.from({ length: Math.ceil(testimonials.length / 3) }).map((_, index) => (
            <TestimonialsContainer key={index}>
              {testimonials.slice(index * 3, index * 3 + 3).map((testimonial, i) => (
                <TestimonialCard key={i} sx={{ backgroundColor: 'none !important' }}>
                  <CardContent>
                    <CircularImage src={testimonial.image} alt={testimonial.name} />

                    <Typography variant='h6'>{testimonial.name}</Typography>
                    <Typography variant='body1' gutterBottom>
                      {testimonial.quote}
                    </Typography>
                    <StyledStar>
                      {'★'.repeat(Math.floor(testimonial.rating))}
                      {testimonial.rating % 1 !== 0 ? '☆' : ''}
                    </StyledStar>
                  </CardContent>
                </TestimonialCard>
              ))}
            </TestimonialsContainer>
          ))}
        </CustomCarousel>
      </Section2>
    </>
  );
};

export default HomeNew;
