import { createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '_helpers';
let user = JSON.parse(localStorage.getItem('auth'));

const teacherReducer = createSlice({
  name: 'teacher',
  initialState: {
    teacherAccount: [],
    fetchingTeacher: false,
    totalItems: -1,
  },
  reducers: {
    setTeacherAccounts: (state, action) => {
      state.teacherAccount = action.payload; // Update the teacher account state
    },

    setFetchingTeacher: (state, action) => {
      state.fetchingTeacher = action.payload; // Corrected typo
    },

    setTotalItems: (state, action) => {
      state.totalItems = action.payload;
    },
  },
});

export const getTeacherAccounts =
  (pageNo, pageSize, search = '') =>
  async (dispatch) => {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;
    const apiURL = `${baseUrl}/fetchTeacherInfo?pageNo=${pageNo ? pageNo : 1}&pageSize=${
      pageSize ? pageSize : 10
    }&search=${search}`;

    try {
      const school_id = user.relatedData.sch_id;
      const req = { school_id: school_id };
      const response = await fetchWrapper.post(apiURL, req);
      const data = await response;
      dispatch(setTeacherAccounts(data));
    } catch (error) {
      console.error('Error fetching student accounts:', error);
    }
  };

export const { setTeacherAccounts, setFetchingTeacher, setTotalItems } = teacherReducer.actions;

export const teacherAccounts = (state) => state.teacher.teacherAccount;
export const fetchingTeacher = (state) => state.teacher.fetchingTeacher;

export const totalTeacher = (state) => state.teacher.totalItems;

export default teacherReducer.reducer;
