import { createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '_helpers';
// let user = JSON.parse(localStorage.getItem('auth'));

const quizReducer = createSlice({
  name: 'quiz',
  initialState: {
    quizAccount: [],
    fetchingQuiz: false,
    totalItems: -1,
  },
  reducers: {
    setQuizAccounts: (state, action) => {
      state.quizAccount = action.payload;
    },

    setFetchingQuiz: (state, action) => {
      state.fetchingQuiz = action.payload;
    },

    setTotalItems: (state, action) => {
      state.totalItems = action.payload;
    },
  },
});

export const getQuizAccounts = (pageNo, pageSize) => async (dispatch) => {
  const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;
  const apiURL = `${baseUrl}/allQuizzes?pageNo=${pageNo ? pageNo : 1}&pageSize=${
    pageSize ? pageSize : 10
  }`;

  try {
    // const school_id = user && user.relatedData && user.relatedData.sch_id;
    const response = await fetchWrapper.get(apiURL);
    const data = await response;
    console.log(data, 'data');
    dispatch(setQuizAccounts(data));
  } catch (error) {
    console.error('Error fetching student accounts:', error);
  }
};

export const { setQuizAccounts, setFetchingQuiz, setTotalItems } = quizReducer.actions;

export const quizAccounts = (state) => state.quiz.quizAccount;
export const fetchingQuiz = (state) => state.quiz.fetchingQuiz;

export const totalQuiz = (state) => state.quiz.totalItems;

export default quizReducer.reducer;
