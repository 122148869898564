// File: ManualQuizEntry.js
import React, { useState } from 'react';
import { Box, Button, Grid, TextField, Typography, IconButton, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';
import { fetchWrapper } from '_helpers';
import { useNavigate } from 'react-router-dom';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import * as XLSX from 'xlsx';

const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;

const SUBJECTS = ['Physics', 'Chemistry', 'Biology'];
const TEST_LEVELS = Array.from({ length: 10 }, (_, i) => `Mockup-test${i + 1}`);
const GRADE = '11';
const BOARD = 'NEET';

export const ManualQuizEntry = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.value);
  const teacher_email = user && user.relatedData.teacher_email;

  const [quizData, setQuizData] = useState([
    {
      id: '',
      grade: GRADE,
      created_by: teacher_email,
      created_at: moment().format('YYYY-MM-DD HH:mm:ss'),
      imageUrl: '',
      subject: '',
      board: BOARD,
      section_name: '',
      subsection_name: '',
      question_type: 'MCQ',
      questions: '',
      option_1: '',
      option_2: '',
      option_3: '',
      option_4: '',
      correct_answer: '',
      test_level: '',
      feedback: '',
      question_image: '',
      option_1_image: '',
      option_2_image: '',
      option_3_image: '',
      option_4_image: '',
      correct_answer_image: '',
      feedback_image: '',
      previews: {
        question_image: '',
        option_1_image: '',
        option_2_image: '',
        option_3_image: '',
        option_4_image: '',
        correct_answer_image: '',
        feedback_image: '',
      }
    },
  ]);

  const handleInputChange = (index, field, value) => {
    const newQuizData = [...quizData];
    newQuizData[index][field] = value;
    setQuizData(newQuizData);
  };

  const handleAddQuestion = () => {
    setQuizData([
      ...quizData,
      {
        id: '',
        grade: GRADE,
        created_by: teacher_email,
        created_at: moment().format('YYYY-MM-DD HH:mm:ss'),
        imageUrl: '',
        subject: '',
        board: BOARD,
        section_name: '',
        subsection_name: '',
        question_type: 'MCQ',
        questions: '',
        option_1: '',
        option_2: '',
        option_3: '',
        option_4: '',
        correct_answer: '',
        test_level: '',
        feedback: '',
        question_image: '',
        option_1_image: '',
        option_2_image: '',
        option_3_image: '',
        option_4_image: '',
        correct_answer_image: '',
        feedback_image: '',
        previews: {
          question_image: '',
          option_1_image: '',
          option_2_image: '',
          option_3_image: '',
          option_4_image: '',
          correct_answer_image: '',
          feedback_image: '',
        }
      },
    ]);
  };

  const handleRemoveQuestion = (index) => {
    const newQuizData = quizData.filter((_, i) => i !== index);
    setQuizData(newQuizData);
  };

  const resizeImage = (file, callback) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
  
    reader.onloadend = () => {
      const img = new Image();
      img.src = reader.result;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const maxWidth = 250;
        const scaleSize = maxWidth / img.width;
        canvas.width = maxWidth;
        canvas.height = img.height * scaleSize;
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  
        canvas.toBlob((blob) => {
          callback(blob);
        }, file.type, 1);
      };
    };
  }

  const handleImageFileChange = async (e, index, type) => {
    const file = e.target.files[0];
    if (!file) return;

    resizeImage(file, async (resizedBlob) => {
      const previewUrl = URL.createObjectURL(resizedBlob);
      const newQuizData = [...quizData];
      newQuizData[index].previews[`${type}_image`] = previewUrl;
      setQuizData(newQuizData);

      const formData = new FormData();
      formData.append('file', resizedBlob, file.name);

      try {
        const response = await fetchWrapper.post(`${baseUrl}/Mockupload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response && response.success) {
          const imageUrl = response.urls.file; 
          newQuizData[index][`${type}_image`] = imageUrl;
          setQuizData(newQuizData);
          toast.success('Image uploaded successfully');
        } else {
          console.error('Upload response error:', response);
          toast.error('Failed to upload image');
        }
      } catch (error) {
        toast.error('Failed to upload image');
        console.error('Image upload error:', error);
      }
    });
  }

  const handleSaveToExcel = () => {
    const filteredQuizData = quizData.map(item => ({
      id: item.id,
      grade: item.grade,
      subject: item.subject,
      board: item.board,
      section_name: item.section_name,
      subsection_name: item.subsection_name,
      question_type: item.question_type,
      questions: item.questions,
      option_1: item.option_1,
      option_2: item.option_2,
      option_3: item.option_3,
      option_4: item.option_4,
      correct_answer: item.correct_answer,
      test_level: item.test_level,
      feedback: item.feedback,
      question_image: item.question_image,
      option_1_image: item.option_1_image,
      option_2_image: item.option_2_image,
      option_3_image: item.option_3_image,
      option_4_image: item.option_4_image,
      correct_answer_image: item.correct_answer_image,
      feedback_image: item.feedback_image,
    }));

    const worksheet = XLSX.utils.json_to_sheet(filteredQuizData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'QuizData');
    XLSX.writeFile(workbook, 'quizData.xlsx');
    toast.success('Data saved to Excel file');
  };

  const handleSubmit = async () => {
    try {
      const requestBody = { quizData };
      await fetchWrapper.post(`${baseUrl}/createManualQuizEntry`, requestBody);
      toast.success('Quiz created successfully');
      navigate('/AddMockQuiz');
    } catch (error) {
      toast.error(error.message || 'An error occurred');
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Manual Quiz Entry
      </Typography>
      {quizData.map((quiz, index) => (
        <Grid container spacing={2} key={index} sx={{ marginBottom: 2 }}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Subject</InputLabel>
              <Select
                value={quiz.subject}
                onChange={(e) => handleInputChange(index, 'subject', e.target.value)}
              >
                {SUBJECTS.map((subject) => (
                  <MenuItem key={subject} value={subject}>
                    {subject}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Test Level</InputLabel>
              <Select
                value={quiz.test_level}
                onChange={(e) => handleInputChange(index, 'test_level', e.target.value)}
              >
                {TEST_LEVELS.map((level) => (
                  <MenuItem key={level} value={level}>
                    {level}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Section Name"
              fullWidth
              value={quiz.section_name}
              onChange={(e) => handleInputChange(index, 'section_name', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Subsection Name"
              fullWidth
              value={quiz.subsection_name}
              onChange={(e) => handleInputChange(index, 'subsection_name', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Question Type"
              fullWidth
              value={quiz.question_type}
              onChange={(e) => handleInputChange(index, 'question_type', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Question"
              fullWidth
              value={quiz.questions}
              onChange={(e) => handleInputChange(index, 'questions', e.target.value)}
            />
            <input
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              id={`question_image_${index}`}
              onChange={(e) => handleImageFileChange(e, index, 'question')}
            />
            <label htmlFor={`question_image_${index}`}>
              <IconButton component="span">
                <PhotoCameraIcon />
              </IconButton>
            </label>
            {quiz.previews.question_image && (
              <img src={quiz.previews.question_image} alt="Question preview" width={250} />
            )}
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Option 1"
              fullWidth
              value={quiz.option_1}
              onChange={(e) => handleInputChange(index, 'option_1', e.target.value)}
            />
            <input
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              id={`option_1_image_${index}`}
              onChange={(e) => handleImageFileChange(e, index, 'option_1')}
            />
            <label htmlFor={`option_1_image_${index}`}>
              <IconButton component="span">
                <PhotoCameraIcon />
              </IconButton>
            </label>
            {quiz.previews.option_1_image && (
              <img src={quiz.previews.option_1_image} alt="Option 1 preview" width={250} />
            )}
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Option 2"
              fullWidth
              value={quiz.option_2}
              onChange={(e) => handleInputChange(index, 'option_2', e.target.value)}
            />
            <input
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              id={`option_2_image_${index}`}
              onChange={(e) => handleImageFileChange(e, index, 'option_2')}
            />
            <label htmlFor={`option_2_image_${index}`}>
              <IconButton component="span">
                <PhotoCameraIcon />
              </IconButton>
            </label>
            {quiz.previews.option_2_image && (
              <img src={quiz.previews.option_2_image} alt="Option 2 preview" width={250} />
            )}
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Option 3"
              fullWidth
              value={quiz.option_3}
              onChange={(e) => handleInputChange(index, 'option_3', e.target.value)}
            />
            <input
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              id={`option_3_image_${index}`}
              onChange={(e) => handleImageFileChange(e, index, 'option_3')}
            />
            <label htmlFor={`option_3_image_${index}`}>
              <IconButton component="span">
                <PhotoCameraIcon />
              </IconButton>
            </label>
            {quiz.previews.option_3_image && (
              <img src={quiz.previews.option_3_image} alt="Option 3 preview" width={250} />
            )}
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Option 4"
              fullWidth
              value={quiz.option_4}
              onChange={(e) => handleInputChange(index, 'option_4', e.target.value)}
            />
            <input
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              id={`option_4_image_${index}`}
              onChange={(e) => handleImageFileChange(e, index, 'option_4')}
            />
            <label htmlFor={`option_4_image_${index}`}>
              <IconButton component="span">
                <PhotoCameraIcon />
              </IconButton>
            </label>
            {quiz.previews.option_4_image && (
              <img src={quiz.previews.option_4_image} alt="Option 4 preview" width={250} />
            )}
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Correct Answer"
              fullWidth
              value={quiz.correct_answer}
              onChange={(e) => handleInputChange(index, 'correct_answer', e.target.value)}
            />
            <input
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              id={`correct_answer_image_${index}`}
              onChange={(e) => handleImageFileChange(e, index, 'correct_answer')}
            />
            <label htmlFor={`correct_answer_image_${index}`}>
              <IconButton component="span">
                <PhotoCameraIcon />
              </IconButton>
            </label>
            {quiz.previews.correct_answer_image && (
              <img src={quiz.previews.correct_answer_image} alt="Correct Answer preview" width={250} />
            )}
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Feedback"
              fullWidth
              value={quiz.feedback}
              onChange={(e) => handleInputChange(index, 'feedback', e.target.value)}
            />
            <input
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              id={`feedback_image_${index}`}
              onChange={(e) => handleImageFileChange(e, index, 'feedback')}
            />
            <label htmlFor={`feedback_image_${index}`}>
              <IconButton component="span">
                <PhotoCameraIcon />
              </IconButton>
            </label>
            {quiz.previews.feedback_image && (
              <img src={quiz.previews.feedback_image} alt="Feedback preview" width={250} />
            )}
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="secondary" onClick={() => handleRemoveQuestion(index)}>
              Remove Question
            </Button>
          </Grid>
          <Grid item xs={12}>
            <hr />
          </Grid>
        </Grid>
      ))}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
        <Button variant="contained" color="primary" onClick={handleAddQuestion}>
          Add Question
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit Quiz
        </Button>
        <Button variant="contained" color="primary" onClick={handleSaveToExcel}>
          Save to Excel
        </Button>
      </Box>
    </Box>
  );
};
