import React, { useRef } from 'react';
import HomeNew from './HomeNew';
import Footer from './Footer';
import { Nav } from '../navigation/Nav';

function Main() {
  const homeRef = useRef(null);
  const aiTutorRef = useRef(null);
  const interactiveModelsRef = useRef(null);
  const learningProgramsRef = useRef(null);

  const scrollToSection = (section) => {
    if (section === 'Home' && homeRef.current) {
      homeRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (section === 'AI Tutor' && aiTutorRef.current) {
      aiTutorRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (section === 'Interactive Models' && interactiveModelsRef.current) {
      interactiveModelsRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (section === 'Learning Programs' && learningProgramsRef.current) {
      learningProgramsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <Nav scrollToSection={scrollToSection} />
      <HomeNew
        scrollToSection={scrollToSection}
        homeRef={homeRef}
        aiTutorRef={aiTutorRef}
        interactiveModelsRef={interactiveModelsRef}
        learningProgramsRef={learningProgramsRef}
      />
      <div style={{ position: 'sticky', overflow: 'hidden' }}>
        <Footer />
      </div>
    </>
  );
}

export default Main;
