import React from 'react';
import PropTypes from 'prop-types';
import MODALS from '_components/modals';

function EquationModal({ data, chapter_id }) {
  const ModalComponent = () => {
    if (chapter_id == data.chapter_id) {
      const Component = MODALS[`chapter${data.chapter_id}`][data.url];
      if (Component) {
        return <Component />;
      }
    }
    return null;
  };

  return (
    <div style={{ height: '100vh' }}>
      {/* modal display according to url */}
      <ModalComponent />
    </div>
  );
}

EquationModal.propTypes = {
  data: PropTypes.any.isRequired,
  chapter_id: PropTypes.any.isRequired,
};

export { EquationModal };
