import React, { useEffect, useState } from 'react';
import { fetchWrapper } from '_helpers';
import { useParams, useNavigate } from 'react-router-dom';
import { ListItemText } from '@mui/material';
import { Loader } from '_components/navigation/Loader';
import { useSelector } from 'react-redux';

import './style.css';

function SearchResult() {
  const { query } = useParams();
  const navigate = useNavigate();
  const [results, setResults] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;
  const user = useSelector((x) => x.auth.value);
  let searchTimer;

  const fetchData = async () => {
    setisLoading(true);

    try {
      const req = { "state": user.state, "board": user.board }
      const result = await fetchWrapper.post(`${baseUrl}/search?query=${query}`, req);

      setisLoading(false);
      setResults(result);
    } catch (error) {
      console.error(error);
      setisLoading(false);
    }
  };

  useEffect(() => {
    clearTimeout(searchTimer);

    searchTimer = setTimeout(() => {
      fetchData();
    }, 300);

    return () => {
      clearTimeout(searchTimer);
    };
  }, [query, user.state, user.board]);

  function handleClick(link) {
    navigate(link);
  }
  if (isLoading) {
    return <Loader />;
  }
  return (
    <div style={{ marginTop: '100px' }}>
      <div className='h3 mx-3 bold'>
        {query ? `Showing results for: ${query}` : 'Start typing to search...'}
      </div>
      {results.length === 0 ? (
        <div className='h4 mx-3'>No results found.</div>
      ) : (
        Object.keys(results).map((e) => {
          return Object.values(results[e]).map((sub, index) => {
            let title, link;
            const board = sub.board; // get the board from the result

            if (e === 'subsectionResults') {
              link = board === 'NEET'
                ? `/dashboard/NEET/subject/${sub.subject}/chapter/${sub.chapter_id}/section/${sub.section_id}/subsection/${sub.sub_section_id}`
                : `/dashboard/standard/${sub.grade}/subject/${sub.subject.toLowerCase()}/chapter/${sub.chapter_id}/section/${sub.section_id}/subsection/${sub.sub_section_id}`;
              title = sub['sub_section_title'];
            } else if (e === 'sectionResults') {
              link = board === 'NEET'
                ? `/dashboard/NEET/subject/${sub.subject}/chapter/${sub.chapter_id}/section/${sub.section_id}`
                : `/dashboard/standard/${sub.grade}/subject/${sub.subject.toLowerCase()}/chapter/${sub.chapter_id}/section/${sub.section_id}`;
              title = sub['section_title'];
            } else {
              link = board === 'NEET'
                ? `/dashboard/NEET/subject/${sub.subject}/chapter/${sub.chapter_id}`
                : `/dashboard/standard/${sub.grade}/subject/${sub.subject.toLowerCase()}/chapter/${sub.chapter_id}`;
              title = sub['name'];
            }
            return (
              <div key={index} className='listItem link' onClick={() => handleClick(link)}>
                <ListItemText primary={title} />
              </div>
            );
          });
        })
      )}
    </div>
  );
}

export { SearchResult };
