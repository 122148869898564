import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Grid, TextField, Button, Box, IconButton, Stack, Typography } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchWrapper } from '_helpers';

const validationSchema = Yup.object().shape({
  sch_id: Yup.string().required('School Name is required'),
  // teacher_id: Yup.string().email('Invalid email').required('Teacher Email is required'),
  student_email: Yup.string().email('Invalid email').required('Student Email is required'),
  student_password: Yup.string()
  .required(' Password is required')
  .matches(
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
    'Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, and one number',
  ),
  student_name: Yup.string().required('Student Name is required'),
  grade: Yup.string().required('Grade is required'),
  section: Yup.string().required('Section is required'),
  student_phone: Yup.string()
  .matches(/^[+\d]+$/, 'Invalid phone number format. Use numbers and "+" only.')
  .required('Student Phone Number is required'),
});

const AddStudent = () => {
  const user = useSelector((x) => x.auth.value);
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // This will navigate back to the previous route.
  };

  const [formValues, setFormValues] = useState({
    sch_id: user.relatedData.sch_id,
    board: user.board,
    matric_state: user.matric_state,
    student_email: '',
    student_password: '',
    student_name: '',
    grade: '',
    section: '',
    student_phone: '',
    role: 'scl_admin',
  });

  const handleSubmit = async (values, actions) => {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;
    try {
      // API integration
      await fetchWrapper.post(`${baseUrl}/teacher/createStudent`, values);
      // Reset form values and show success toast
      setFormValues({
        student_email: '',
        student_password: '',
        student_name: '',
        grade: '',
        section: '',
        student_phone: '',
      });
      actions.resetForm();
      toast.success('Student created successfully');
    } catch (error) {
      console.error('An error occurred', error);
      toast.error(error);
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <div>
      <Helmet>
        <title> Add New Student </title>
      </Helmet>
      <center>
        {/* <Stack direction='row' alignItems='center' justifyContent='space-between' mb={5}>
          <Typography variant='h4' gutterBottom>
            Blog
          </Typography>
        </Stack> */}
      </center>
      <Stack direction='row' alignItems='center' mb={5}>
        <IconButton aria-label='back' size='large' onClick={handleBack}>
          <ArrowBackIcon fontSize='inherit' style={{ color: '#092141' }} />
        </IconButton>
        <Typography variant='h4' gutterBottom>
          Add New Student
        </Typography>
      </Stack>
      <div>
        <Box sx={{ padding: 5 }}>
          <Formik
            initialValues={formValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <Grid container spacing={2}>
                {/* <Grid item xs={6}>
                  <Field
                    as={TextField}
                    label='School Name'
                    variant='outlined'
                    fullWidth
                    name='sch_id'
                  />
                  <ErrorMessage name='sch_id' component='div' className='error' />
                </Grid> */}
                {/* <Grid item xs={6}>
                  <Field
                    as={TextField}
                    label='Teacher Email'
                    variant='outlined'
                    fullWidth
                    name='teacher_id'
                  />
                  <ErrorMessage name='teacher_id' component='div' className='error' />
                </Grid> */}
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    label='Student Email'
                    variant='outlined'
                    fullWidth
                    name='student_email'
                  />
                  <ErrorMessage name='student_email' component='div' className='error' />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    label='Student Password'
                    type='password'
                    variant='outlined'
                    fullWidth
                    name='student_password'
                  />
                  <ErrorMessage name='student_password' component='div' className='error' />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    label='Student Name'
                    variant='outlined'
                    fullWidth
                    name='student_name'
                  />
                  <ErrorMessage name='student_name' component='div' className='error' />
                </Grid>
                <Grid item xs={6}>
                  <Field as={TextField} label='Grade' variant='outlined' fullWidth name='grade' />
                  <ErrorMessage name='grade' component='div' className='error' />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    label='Section'
                    variant='outlined'
                    fullWidth
                    name='section'
                  />
                  <ErrorMessage name='section' component='div' className='error' />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    label='Student Phone Number'
                    variant='outlined'
                    fullWidth
                    name='student_phone'
                  />
                  <ErrorMessage name='student_phone' component='div' className='error' />
                </Grid>
              </Grid>

              <Button
                type='submit'
                variant='contained'
                sx={{ marginTop: 4, padding: '10px 20px', backgroundColor: '#fe6f61' }}
              >
                Submit
              </Button>
            </Form>
          </Formik>
        </Box>
      </div>
      <ToastContainer />
    </div>
  );
};

export { AddStudent };
