import React, { useState, useEffect } from 'react';
import { Steps } from '_components';
import NEET_logo from '_assets/images/NEET_logo.png';
import NEET_TEST_logo from '_assets/images/NEET_TEST_logo.png';
import NCERT_logo from '_assets/images/NCERT.png';
import SAT_logo from '_assets/images/SAT_logo.png';
import { useSelector } from 'react-redux';

function Home() {
  const [showSteps, setShowSteps] = useState(false);
  const [isNEET, setIsNEET] = useState(false);
  const [isNEETTEST, setIsNEETTEST] = useState(false);
  const [isSAT, setIsSat] = useState(false);
  const auth = useSelector((x) => x.auth.value);

  useEffect(() => {
    if (auth.board === 'SAT') {
      setIsSat(true);
    }
  }, [auth]);

  const handleBookClick = (board) => {
    setShowSteps(true);
    if (board === 'NEET') {
      setIsNEET(true);
      setIsNEETTEST(false);
      setIsSat(false);
    } else if (board === 'NEET_TEST') {
      setIsNEETTEST(true);
      setIsNEET(false);
      setIsSat(false);
    } else if (board === 'SAT') {
      setIsSat(true);
      setIsNEET(false);
      setIsNEETTEST(false);
    } else {
      setIsNEET(false);
      setIsSat(false);
      setIsNEETTEST(false);
    }
  };

  const handleBack = () => {
    setShowSteps(false);
  };

  return (
    <div
      style={{
        minHeight: '92vh',
        height: '100%',
        backgroundColor: '#fff',
        width: '100vw',
        justifyContent: 'center',
      }}
    >
      {!showSteps && (
        <div className='book-selection-container d-flex justify-content-evenly'>
          {auth.board === 'SAT' ? (
            <img
              src={SAT_logo}
              alt='SAT'
              onClick={() => handleBookClick('SAT')}
              className='book-logo'
            />
          ) : (
            <>
              <img
                src={NEET_logo}
                alt='NEET'
                onClick={() => handleBookClick('NEET')}
                className='book-logo'
              />
              <img
                src={NEET_TEST_logo}
                width={'25%'}
                alt='NEET_TEST'
                onClick={() => handleBookClick('NEET_TEST')}
                className='book-logo'
              />
              <img
                src={NCERT_logo}
                alt={auth.board}
                onClick={() => handleBookClick()}
                className='book-logo'
              />
            </>
          )}
        </div>
      )}
      {showSteps && (
        <Steps onBack={handleBack} isNEET={isNEET} isNEETTEST={isNEETTEST} isSAT={isSAT} />
      )}
    </div>
  );
}

export { Home };
