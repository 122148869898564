import { jsPDF } from 'jspdf';
export { capitalizeFirstLetter, replaceEqualsToArrow, checkEmptyData, splitFromFirstSpace, downloadConversation, parseLatexContent};

function capitalizeFirstLetter(input) {
  if (typeof input === 'string') {
    return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
  } else if (Array.isArray(input)) {
    return input.map((item) => capitalizeFirstLetter(item));
  } else {
    return input;
  }
}

function replaceEqualsToArrow(inputString) {
  return inputString.split('=').join('→');
}
function checkEmptyData(data) {
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key) && data[key] !== '') {
      return true;
    }
  }
  return false;
}

function splitFromFirstSpace(inputString) {
  const [, ...rest] = inputString.split(' ');
  const secondPart = rest.join(' ');
  return  secondPart;
}

function downloadConversation (chats, title) {
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  try {
    const doc = new jsPDF();
    const textWidth = 180;

    let yOffset = 10;
    chats.forEach(chat => {
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(12); 
      const roleText = `${capitalizeFirstLetter(chat.role)}:`;
      const roleLines = doc.splitTextToSize(roleText, textWidth);
      
      const spaceLeft = doc.internal.pageSize.height - (yOffset + (roleLines.length + 1) * 5);
      const contentLines = doc.splitTextToSize(chat.content, textWidth);
      const totalLines = roleLines.length + contentLines.length;
      
      if (spaceLeft < totalLines * 5) {
        doc.addPage();
        yOffset = 10; 
      }
      
      roleLines.forEach((line, index) => {
        doc.text(line, 10, yOffset + index * 5);
      });
      
      doc.setFont('helvetica', 'normal');
      
      contentLines.forEach((line, index) => {
        doc.text(line, 20, yOffset + (roleLines.length + index) * 5);
      });
      
      yOffset += (roleLines.length + contentLines.length + 1) * 5; // Add extra space between chats
    });
    
    let fileName = title.replaceAll(' ', '');
    doc.save(`${fileName}Conversation.pdf`);
  } catch (error) {
    console.error('Error generating PDF:', error);
  }
}
function parseLatexContent(content) {
  // Function to replace LaTeX commands with plain text
  const replaceLatexCommands = (text) => {
    const latexCommands = {
      '\\text': '',               // Converts to plain text 
      '\\frac': (num, den) => `<span style="display: inline-block; vertical-align: middle; text-align: center;">${num}<br><span style="border-top: 1px solid #000;">${den}</span></span>`, // Fractions
      '\\times': '×',            // Multiplication sign
      '\\cdot': '⋅',             // Dot product
      '\\div': '÷',              // Division sign
      '\\pm': '±',               // Plus-minus sign
      '\\sqrt': (arg) => `<span style="border-bottom: 1px solid #000;">√${arg}</span>`, // Square root
      '\\left(': '(',            // Left parenthesis
      '\\right)': ')',          // Right parenthesis
      '\\left[': '[',            // Left square bracket
      '\\right]': ']',          // Right square bracket
      '\\left\\{': '{',         // Left curly brace
      '\\right\\}': '}',        // Right curly brace
      '\\sum': (content) => `∑${content}`, // Summation
      '\\int': (content) => `∫${content}`, // Integral
      '\\prod': (content) => `∏${content}`, // Product
      '\\lim': (content) => `lim${content}`, // Limit
      '\\infty': '∞',           // Infinity
      '\\angle': '∠',           // Angle
      '\\rightarrow': '→',     // Right arrow for reactions
      '\\leftarrow': '←',      // Left arrow for reactions
      '\\leftrightarrow': '↔', // Double arrow for reactions
    };

    return text.replace(/\\([a-zA-Z]+)\{(.*?)\}/g, (match, command, params) => {
      if (latexCommands[command]) {
        if (typeof latexCommands[command] === 'function') {
          const args = params.split(',');
          return latexCommands[command](...args);
        } else {
          return latexCommands[command];
        }
      } else {
        return match;
      }
    });
  };

  // Function to handle Greek letters
  const handleGreekLetters = (text) => {
    const greekLetters = {
      '\\alpha': 'α',
      '\\beta': 'β',
      '\\gamma': 'γ',
      '\\delta': 'δ',
      '\\epsilon': 'ε',
      '\\zeta': 'ζ',
      '\\eta': 'η',
      '\\theta': 'θ',
      '\\iota': 'ι',
      '\\kappa': 'κ',
      '\\lambda': 'λ',
      '\\mu': 'μ',
      '\\nu': 'ν',
      '\\xi': 'ξ',
      '\\omicron': 'ο',
      '\\pi': 'π',
      '\\rho': 'ρ',
      '\\sigma': 'σ',
      '\\tau': 'τ',
      '\\upsilon': 'υ',
      '\\phi': 'φ',
      '\\chi': 'χ',
      '\\psi': 'ψ',
      '\\omega': 'ω',
      '\\Gamma': 'Γ',
      '\\Delta': 'Δ',
      '\\Theta': 'Θ',
      '\\Lambda': 'Λ',
      '\\Xi': 'Ξ',
      '\\Pi': 'Π',
      '\\Sigma': 'Σ',
      '\\Upsilon': 'Υ',
      '\\Phi': 'Φ',
      '\\Psi': 'Ψ',
      '\\Omega': 'Ω',
    };

    return text.replace(/\\([a-zA-Z]+)(?=\b)/g, (match, command) => greekLetters[command] || match);
  };

  // Function to handle superscript (^) notation
  const handleSuperscript = (text) => {
    return text.replace(/\^(\d+)/g, (match, exponent) => {
      return `<sup>${exponent}</sup>`;
    });
  };

  // Function to handle subscript (_) notation
  const handleSubscript = (text) => {
    return text.replace(/_(.*?)_/g, (match, subscript) => {
      return `<sub>${subscript}</sub>`;
    });
  };

  // Function to handle chemical formulas
  const handleChemicalFormulas = (text) => {
    return text.replace(/([A-Z][a-z]*)(\d*)/g, (match, element, subscript) => {
      return `${element}<sub>${subscript}</sub>`;
    });
  };

  // Function to handle specific words dynamically and apply bold styling
  const handleAnyWord = (text) => {
    return text.replace(/\*\*(.*?)\*\*/g, (match, words) => {
      return `<span style="font-weight: bold;">${words}</span>`;
    });
  };

  // Function to handle inline LaTeX math environments
  const handleInlineMathEnvironments = (text) => {
    return text.replace(/\\\((.*?)\\\)/g, (match, content) => {
      return `<span style="display: inline-block;">${replaceLatexCommands(content)}</span>`;
    });
  };

  // Function to handle block LaTeX math environments
  const handleBlockMathEnvironments = (text) => {
    return text.replace(/\\\[(.*?)\\\]/g, (match, content) => {
      return `<div style="display: block; text-align: center;">${replaceLatexCommands(content)}</div>`;
    });
  };

  // Main parsing logic
  return handleChemicalFormulas(
    handleGreekLetters(
      handleBlockMathEnvironments(
        handleInlineMathEnvironments(
          handleSubscript(
            handleSuperscript(
              handleAnyWord(
                replaceLatexCommands(content)
              )
            )
          )
        )
      )
    )
  );
}

