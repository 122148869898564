
import React, { useState } from "react";
import PropTypes from 'prop-types';

export { CountButton };
    

function CountButton  ({ length, onClickHandler, text }) {
    const [selectedButton, setSelectedButton] = useState(1);
    const handleButtonClick = (index) => {
        setSelectedButton(index+1);
        onClickHandler(index + 1);
      };
  return (
      <div className='d-flex mt-2'>
          <p className='me-2 col-4'>{text}</p>
          <div className="btn-group w-100" role="group" aria-label="Basic outlined example" >
              {Array(length).fill(1).map((e, i) => {
                  return (
                      <button 
                      key={i} 
                      type="button" 
                      className={`btn btn-outline-primary btn-custom mx-1 ${selectedButton === i+1 ? 'text-light btn-primary ' : ''}`}
                      style={{borderRadius: "5px"}}
                      onClick={() => handleButtonClick(i)}
                      >
                        {i + 1}
                      </button>
                  )
              })}
          </div>
      </div>
  )
}

CountButton.propTypes = {
    length: PropTypes.number.isRequired,
    onClickHandler: PropTypes.any.isRequired,
    text: PropTypes.string.isRequired
  }