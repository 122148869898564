import React from "react";
export { Register };

function Register() {
    return (
        <div className="card m-3">
            <h4 className="card-header">Register</h4>
        </div>
    )
}
