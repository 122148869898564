import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { fetchWrapper } from '_helpers';
import { Figure } from './Figure';
import { Loader } from '_components/navigation/Loader';
// eslint-disable-next-line no-unused-vars
import { gsap } from 'gsap';
import { useNavigate } from 'react-router-dom';
// import { useParams } from 'react-router-dom';

export { Animation };

function Animation({ figure }) {
  const [animation, setAnimation] = useState();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  // const { standard } = useParams();

  const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;
  useEffect(() => {
    async function fetchData() {
      window.stopAnimation();
      if(figure.url){
        try {
          let req = [
            {
              sub_section_id: figure.sub_section_id,
              url: figure.url,
            },
          ];
          let res = await fetchWrapper.post(`${baseUrl}/getAnimation`, req);
          setAnimation(res);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching animation data:', error);
          if (error.response && error.response.status === 403) {
            navigate('/account/login');
          }
          setLoading(false);
        }
      }else{
        setLoading(false)
      }
    }
    fetchData();
  }, [figure]);

  // if change in color for specific animation/grade/subject
  // useEffect(() => {
  //   // if (standard && standard == 8) {
  //     if (animation && animation[0] && animation[0].HTML) {
  //       const animationBgElement = document.querySelector('.animation-bg');
  //       const checkboxBgElement = document.querySelector('.checkBox-background');
  //       if (animationBgElement) {
  //         animationBgElement.id = 'animation-light';
  //       }
  //       if (checkboxBgElement) {
  //       checkboxBgElement.id = 'checkBox-light'; 
  //       }
  //     // }
  //   }
  // }, [animation]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {animation && <Figure data={animation} id='figure' info={figure.definition} />}
    </>
  );
}

Animation.propTypes = {
  figure: PropTypes.object,
};
