import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  //   width: '50%',
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#34d158' : '#308fe8',
  },
}));

const LinearChartProgress = ({ progress }) => {
  // eslint-disable-next-line no-unused-vars
  const [progressPercentage, setProgressPercentage] = useState(0);
  //   useEffect(() => {
  //     if (htmlRef && htmlRef.current) {
  //       const inputTags = htmlRef.current.querySelectorAll('.form-check-input');
  //       const handleClick = () => {
  //         const newStep = activeStep + 1;
  //         setActiveStep(newStep);
  //         const newProgressPercentage = (newStep / inputLength) * 100;
  //         setProgressPercentage(newProgressPercentage);
  //         inputTags.forEach((inputTag) => {
  //           inputTag.removeEventListener('click', handleClick);
  //         });
  //       };

  //       inputTags.forEach((inputTag) => {
  //         inputTag.addEventListener('click', handleClick);
  //       });

  //       setInputLength(inputTags.length);

  //       return () => {
  //         inputTags.forEach((inputTag) => {
  //           inputTag.removeEventListener('click', handleClick);
  //         });
  //       };
  //     }
  //   }, [messages]);

  //   console.log(messages, 'messages');
  return (
    <Box className='py-4 ' sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ flex: 1, paddingRight: '20px' }}>
        <BorderLinearProgress variant='determinate' value={progress} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant='body2' color='text.secondary'>{`${Math.round(progress)}%`}</Typography>
      </Box>
      <Box sx={{ position: 'relative', display: 'inline-flex', marginLeft: '20px' }}></Box>
    </Box>
  );
};

export { LinearChartProgress };
