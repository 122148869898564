import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Card, Stack, Button, Container, Typography } from '@mui/material';
import { Loader } from '_components/navigation/Loader';
import { ListTable } from '_components';
import { getSchoolAccounts, schoolAccounts } from '_store/school.slice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import 'colors.css';
import Pagination from '@mui/material/Pagination';
import { Page404 } from '../Page404';
// import { debounce } from 'lodash';

export const SchoolList = () => {
  const [isLoading, setisLoading] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const _schoolAccount = useSelector(schoolAccounts);

  const [totalPages, setTotalPages] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  let pageSize = 10;
  const handlePageChange = (event, n) => {
    setCurrentPage(n);
    dispatch(getSchoolAccounts(n, pageSize, searchTerm));
  };

  useEffect(() => {
    if (_schoolAccount && _schoolAccount.length !== 0) {
      setTotalPages(_schoolAccount.pagination.totalPages);
      setisLoading(false);
    } else {
      setisLoading(true);
    }
  }, [_schoolAccount, currentPage]);

  useEffect(() => {
    dispatch(getSchoolAccounts(currentPage, pageSize, searchTerm));
  }, [currentPage]);

  const handleSearch = (term) => {
    setSearchTerm(term);
    const trimedValue = term.trim()
    setCurrentPage(0); 
    if(!term){
      dispatch(getSchoolAccounts(0,10, trimedValue));
    }
    trimedValue && dispatch(getSchoolAccounts(0,10, trimedValue));
  };

  if (!_schoolAccount || _schoolAccount.length === 0) {
    return (
      <>
        <Helmet>
          <title> School List </title>
        </Helmet>
        <Container>
          <Typography variant='h4' gutterBottom>
            <Page404 message='No  School found ' name={'NoDataFound'} />
          </Typography>
        </Container>
      </>
    );
  }
  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <Helmet>
        <title> School List </title>
      </Helmet>
      <Container maxWidth="xl">
        <Typography variant='h4' gutterBottom>
          School List
        </Typography>
        <Stack direction='row' alignItems='center' justifyContent='space-between' mb={5}>
          <div className='d-flex' style={{ justifyContent: 'flex-start' }}>
            <input
              type='text'
              placeholder='Search...'
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
          <div className='d-flex'>
            <Button
              className='mx-2'
              startIcon=''
              sx={{
                backgroundColor: `var(--Peach)`,
                '&:hover': {
                  backgroundColor: 'var(--Blue)',
                },
                color: `var(--White)`,
              }}
              onClick={() => navigate('/addSchool')}
            >
              New School
            </Button>
          </div>
        </Stack>

        <Card sx={{ boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' }}>
          <ListTable
            data={_schoolAccount['results']}
            headers={[
              'sch_id',
              'sch_name',
              'sch_admin_email',
              'sch_admin_name',
              'sch_address',
              'sch_phone',
              'sch_start_date',
              'sch_end_date',
            ]}
            columnHeaders={[
              'Id ',
              'Name',
              'Admin Email',
              'Admin Name',
              'Address',
              'Phone',
              'Start Date',
              'End Date',
            ]}
          />

          <Container align='center' justify='center' p={10} w='full'>
            <Pagination
              count={totalPages}
              shape='rounded'
              className={`controls ${currentPage}`}
              sx={{ justifyContent: 'center' }}
              onChange={(e, n) => handlePageChange(e, n)}
            />
          </Container>
        </Card>
      </Container>
    </>
  );
};
