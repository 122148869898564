import React, { useState, useCallback, useEffect } from 'react';
import { CountButton } from '_components';
import { CategoryDropdown } from '_components';
import { getAtoms, getMolecules, convertToSubscript } from '../script';
import { doBalance } from '_helpers';
import * as Images from '../images';

import { RadioButtonComponent } from '../../common/RadioButtonComponent';
import Checkbox from '@mui/material/Checkbox';
import { replaceEqualsToArrow } from '_helpers';
import { getMarginTop } from '../script';
import '../style.css';
import { Ch2_Modal1 } from '_constants/modal_constants';

export { Ch2Modal1 };

const Ch2Modal1 = () => {
  const [selectedImage, setSelectedImage] = useState('');
  const [selectedImage2, setSelectedImage2] = useState('');
  const [selectedImage3, setSelectedImage3] = useState('');
  const [selectedImage4, setSelectedImage4] = useState('');

  const [selectedOption, setSelectedOption] = useState('');
  const [selectedOption2, setSelectedOption2] = useState('');
  const [selectedOption3, setSelectedOption3] = useState('');
  const [selectedOption4, setSelectedOption4] = useState('');
  const [marginTop, setMarginTop] = useState('');

  const [atom, setAtom] = useState('');
  const [atom2, setAtom2] = useState('');
  const [atom3, setAtom3] = useState('');
  const [atom4, setAtom4] = useState('');

  const [symbol, setSymbol] = useState('');
  const [symbol2, setSymbol2] = useState('');
  const [symbol3, setSymbol3] = useState('');
  const [symbol4, setSymbol4] = useState('');

  const [count, setCount] = useState(1);
  const [count2, setCount2] = useState(1);
  const [count3, setCount3] = useState(1);
  const [count4, setCount4] = useState(1);

  const [LHS1, setLHS1] = useState({});
  const [LHS2, setLHS2] = useState({});
  const [RHS1, setRHS1] = useState({});
  const [RHS2, setRHS2] = useState({});

  const [LHSMoleculeList, setLHSMoleculeList] = useState([]);
  const [RHSMoleculeList, setRHSMoleculeList] = useState([]);
  const [isBalanced, setIsBalanced] = useState(false);
  const [showIsBalance, setShowIsBalance] = useState(false);
  const [balance, setBalance] = useState(false);
  const [type, setType] = useState('text');

  const { CATEGORY_ONE, CATEGORY_TWO, CATEGORY_THREE, CATEGORY_FOUR } = Ch2_Modal1;
  // const CATEGORY_ONE = ['Hydrochloric Acid', 'Sulphuric Acid'];
  // const CATEGORY_TWO = ['Magnesium', 'Zinc', 'Aluminum ', 'Iron', 'Sodium'];
  // const CATEGORY_THREE = [
  //   'Magnesium chloride',
  //   'Zinc sulfate',
  //   'Aluminum Sulfate',
  //   'Zinc Chloride',
  //   'Ferric Chloride',
  //   'Sodium Sulphate',
  // ];

  // const CATEGORY_FOUR = ['Hydrogen'];
  let result = selectedOption && selectedOption2 && selectedOption3 ? true : false;
  let chemicalEquation =
    atom && atom2 && atom3 && atom4
      ? (count > 1 ? count + convertToSubscript(atom) : convertToSubscript(atom)) +
        '+ ' +
        (count2 > 1 ? count2 + convertToSubscript(atom2) : convertToSubscript(atom2)) +
        '= ' +
        (count3 > 1 ? count3 + convertToSubscript(atom3) : convertToSubscript(atom3)) +
        '+ ' +
        (count4 > 1 ? count4 + convertToSubscript(atom4) : convertToSubscript(atom4))
      : '';

  let checkBalancer = atom + '+ ' + atom2 + ' = ' + atom3 + '+ ' + atom4;

  useEffect(() => {
    const req = { value: checkBalancer };
    chemicalEquation && balance ? doBalance(req) : '';
  }, [chemicalEquation, balance]);

  useEffect(() => {
    const margin = getMarginTop(type, count, count2, count3, count4);
    setMarginTop(margin);
  }, [count, count2, count3, count4, type]);

  useEffect(() => {
    if (chemicalEquation) {
      setShowIsBalance(false);
      setBalance(false);
    }
  }, [checkBalancer]);

  useEffect(() => {
    // let result = true;
    for (let key in LHSMoleculeList) {
      if (LHSMoleculeList[key] !== RHSMoleculeList[key]) {
        setIsBalanced(false);
        break;
      } else {
        setIsBalanced(true);
      }
    }
  }, [showIsBalance, RHS1[symbol3], RHS2[symbol4], LHS1[symbol], LHS2[symbol2], checkBalancer]);

  useEffect(() => {
    let selection = {};
    if (atom) {
      getMolecules(atom)
        .then((result) => {
          selection = result.result;

          Object.keys(selection).map((e) => {
            selection[e] = selection[e] * count;
            setLHS1(selection);
          });
        })
        .then(() => setLHSMoleculeList({ ...selection, ...LHS2 }))
        .then(() => {
          setLHSMoleculeList((prevState) => {
            const updatedMoleculeList = { ...selection, ...prevState };
            if (LHS2) {
              Object.keys(selection).forEach((key) => {
                if (Object.prototype.hasOwnProperty.call(LHS2, key)) {
                  updatedMoleculeList[key] = LHS2[key] + selection[key];
                } else {
                  updatedMoleculeList[key] = selection[key];
                }
              });
            }
            return updatedMoleculeList;
          });
        })
        // .then(() => setLHSMoleculeList({ ...selection, ...LHS2 }))
        .catch((error) => console.log(error));
    }
  }, [atom, count]);

  useEffect(() => {
    let selection = {};
    if (atom2) {
      getMolecules(atom2)
        .then((result) => {
          selection = result.result;
          Object.keys(selection).map((e) => {
            selection[e] = selection[e] * count2;
            setLHS2(selection);
          });
        })
        .then(() => setLHSMoleculeList({ ...LHS1, ...selection }))
        .then(() => {
          setLHSMoleculeList((prevState) => {
            const updatedMoleculeList = { ...prevState, ...selection };
            Object.keys(selection).forEach((key) => {
              if (Object.prototype.hasOwnProperty.call(LHS1, key)) {
                updatedMoleculeList[key] = LHS1[key] + selection[key];
              }
            });
            return updatedMoleculeList;
          });
        })
        .catch((error) => console.log(error));
    }
  }, [atom2, count2]);

  useEffect(() => {
    let selection = {};
    if (atom3) {
      getMolecules(atom3)
        .then((result) => {
          selection = result.result;
          Object.keys(selection).map((e) => {
            selection[e] = selection[e] * count3;
            setRHS1(selection);
          });
        })
        .then(() => {
          setRHSMoleculeList((prevState) => {
            const updatedMoleculeList = { ...selection, ...prevState };
            if (RHS2) {
              Object.keys(selection).forEach((key) => {
                if (Object.prototype.hasOwnProperty.call(RHS2, key)) {
                  updatedMoleculeList[key] = RHS2[key] + selection[key];
                } else {
                  updatedMoleculeList[key] = selection[key];
                }
              });
            }
            return updatedMoleculeList;
          });
        })
        .catch((error) => console.log(error));
    }
  }, [atom3, count3]);
  useEffect(() => {
    let selection = {};
    if (atom4) {
      getMolecules(atom4)
        .then((result) => {
          selection = result.result;
          Object.keys(selection).map((e) => {
            selection[e] = selection[e] * count4;
            setRHS2(selection);
          });
        })
        .then(() => {
          setRHSMoleculeList((prevState) => {
            const updatedMoleculeList = { ...prevState, ...selection };
            Object.keys(selection).forEach((key) => {
              if (Object.prototype.hasOwnProperty.call(RHS1, key)) {
                updatedMoleculeList[key] = RHS1[key] + selection[key];
              }
            });
            return updatedMoleculeList;
          });
        })
        .catch((error) => console.log(error));
    }
  }, [atom4, count4]);

  const onClickFirstCategory = useCallback(async (value) => {
    setSelectedOption(value);
    setSelectedImage(value);
    //  get the atom from DB
    getAtoms(value)
      .then((result) => {
        setAtom(result.formula);
        setSymbol(result.symbol);
      })
      .catch((error) => console.log(error));
    //  check the atom counts
  }, []);

  const onClickButtonLeftSideOne = (num) => {
    setCount(num);
  };

  const onClickSecondCategory = (value) => {
    setSelectedOption2(value);
    setSelectedImage2(value);
    getAtoms(value)
      .then((result) => {
        setAtom2(result.formula);
        setSymbol2(result.symbol);
      })
      .catch((error) => console.log(error));
  };
  const onClickButtonLeftSideTwo = (num) => {
    setCount2(num);
  };

  const onClickThirdCategory = (value) => {
    setSelectedOption3(value);
    setSelectedImage3(value);
    setSelectedOption4('');
    setSelectedImage4('');

    getAtoms(value)
      .then((result) => {
        setAtom3(result.formula);
        setSymbol3(result.symbol);
      })
      .catch((error) => console.log(error));
  };

  const onClickButtonRightSideOne = (num) => {
    setCount3(num);
  };

  const onClickForthCategory = (value) => {
    if (value) {
      setSelectedOption4(value);
      setSelectedImage4(value);
      getAtoms(value)
        .then((result) => {
          setAtom4(result.formula);
          setSymbol4(result.symbol);
        })
        .catch((error) => console.log(error));
    } else {
      setSelectedOption4();
      setSelectedImage4();
      setAtom4('');
    }
  };
  const onClickButtonRightSideTwo = (num) => {
    setCount4(num);
  };

  const handleIsBalance = () => {
    setShowIsBalance((prevState) => {
      let newState = !prevState;
      if (!newState) {
        setBalance(false);
      }
      return newState;
    });
  };

  const handleShowIsBalance = () => {
    if (isBalanced || showIsBalance) {
      setBalance(!balance);
    }
  };
  const FormulaImage = useCallback(
    (count, title) => {
      title = title ? title.replace(/\s/g, '') : '';
      return (
        <div>
          {Array.from({ length: count }, (value, index) => index + 1).map((value, index) => (
            <div key={index} className='formula-image'>
              {type &&
              type !== 'text' &&
              title &&
              (type === '3D' ? Images[title + type] : Images[title]) ? (
                <img src={type === '3D' ? Images[title + type] : Images[title]} alt={index}></img>
              ) : (
                <h6>{title} </h6>
              )}
            </div>
          ))}
        </div>
      );
    },
    [type],
  );

  const FormulaTable = () => {
    return (
      <div
        className='m-5'
        style={{ marginTop: '5%', marginLeft: '10%', width: '80%', border: '1px solid' }}
      >
        <table className='table-model'>
          <thead className='thead-light text-center'>
            <tr>
              <th>Elements</th>
              <th>No of atoms in reactants (LHS)</th>
              <th>No of atoms in products (RHS)</th>
              <th>Balanced</th>
            </tr>
          </thead>
          <tbody className='text-center'>
            {Object.keys(LHSMoleculeList).map((e, i) => {
              return (
                <tr key={i}>
                  <td>{e}</td>
                  <td>{LHSMoleculeList[e]}</td>
                  <td>{RHSMoleculeList[e]}</td>
                  <td>{LHSMoleculeList[e] === RHSMoleculeList[e] ? 'True' : 'False'}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };
  return (
    <div className='container card my-4 bg-light' style={{ borderRadius: '5px' }}>
      <RadioButtonComponent handleChange={setType} />
      <div className='my-3 d-flex'>
        <div className='col-4 m-2 px-2'>
          <p className='title text-capitalize font-weight-bold '>
            Acids react with metals {type && type !== 'text' ? ': ' + type : ''}
          </p>
          <hr className='line' />
          <p className='fw-bold' style={{ fontSize: '1rem' }}>
            Left side{' '}
          </p>
          <div className='d-flex'>
            <code className=' me-2 col-4 fw-bold' style={{ fontSize: '1rem' }}>
              Acid
            </code>
            <CategoryDropdown
              options={CATEGORY_ONE}
              value={selectedOption}
              onClickHandler={onClickFirstCategory}
            />
          </div>
          <CountButton length={6} onClickHandler={onClickButtonLeftSideOne} text='Counts' />
          <span>+</span>
          <div className='d-flex'>
            <code className=' me-2 col-4 fw-bold' style={{ fontSize: '1rem' }}>
              Metal
            </code>
            <CategoryDropdown
              options={CATEGORY_TWO}
              value={selectedOption2}
              onClickHandler={onClickSecondCategory}
            />
          </div>
          <CountButton length={4} onClickHandler={onClickButtonLeftSideTwo} text='Counts' />
          <hr className='line' />
          <p className='fw-bold' style={{ fontSize: '1rem' }}>
            Right Side
          </p>
          <div className='d-flex'>
            <code className=' me-2 col-4 fw-bold' style={{ fontSize: '1rem' }}>
              Salt
            </code>
            <CategoryDropdown
              options={CATEGORY_THREE}
              value={selectedOption3}
              onClickHandler={onClickThirdCategory}
            />
          </div>
          <CountButton length={4} onClickHandler={onClickButtonRightSideOne} text='counts' />
          <span>+</span>
          <div className='d-flex'>
            <code className=' me-2 col-4 fw-bold' style={{ fontSize: '1rem' }}>
              Hydrogen gas
            </code>
            <CategoryDropdown
              options={CATEGORY_FOUR}
              value={selectedOption4 || ''}
              onClickHandler={onClickForthCategory}
            />
          </div>
          <CountButton length={4} onClickHandler={onClickButtonRightSideTwo} text='counts' />
          <div>
            <Checkbox checked={showIsBalance} onClick={handleIsBalance} />
            is Balanced ?
          </div>
          <div>
            <Checkbox checked={balance} onClick={handleShowIsBalance} />
            Show Balanced Equation
          </div>
        </div>
        <div className='right card ms-3 col m-2' style={{ width: '146%' }}>
          <div className='right-div d-flex mt-2 ' style={{ width: '100%' }}>
            {type !== 'text' ? (
              FormulaImage(count, selectedImage)
            ) : (
              <div>{(count > 1 ? count : '') + ' ' + selectedOption}</div>
            )}
            <h2 className='plus'>
              <br />+
            </h2>
            {type !== 'text' ? (
              FormulaImage(count2, selectedImage2)
            ) : (
              <div>{(count2 > 1 ? count2 : '') + ' ' + selectedOption2}</div>
            )}
            <h2>
              <br />
              <svg
                width='24'
                height='24'
                xmlns='http://www.w3.org/2000/svg'
                fillRule='evenodd'
                clipRule='evenodd'
              >
                <path d='M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z' />
              </svg>
            </h2>
            {type !== 'text' ? (
              FormulaImage(count3, selectedImage3)
            ) : (
              <div>{(count3 > 1 ? count3 : '') + ' ' + selectedOption3}</div>
            )}
            <h2 className='plus'>
              <br />+
            </h2>
            {type !== 'text' ? (
              FormulaImage(count4, selectedImage4)
            ) : (
              <div>{(count4 > 1 ? count4 : '') + ' ' + selectedOption4}</div>
            )}
          </div>
          {result && (
            <div>
              <div
                className='col-2'
                style={{
                  marginTop: marginTop,
                  marginLeft: '5%',
                  width: '100%',
                }}
              >
                <div className='font-weight-bold h4'>
                  Equation :{' '}
                  <span
                    className={
                      showIsBalance && isBalanced == false ? 'text-danger' : 'text-primary'
                    }
                  >
                    {replaceEqualsToArrow(chemicalEquation)}
                  </span>
                </div>
                {showIsBalance && (
                  <div className='h4'>Is Balanced? : {isBalanced === false ? 'No' : 'Yes'}</div>
                )}
              </div>
              {balance && (
                <div style={{ marginLeft: '5%' }} className='h4'>
                  Balanced Equation :<output id='balanced' className='h4 px-2'></output>
                  <output id='message'></output>
                  <output>
                    <output>
                      <code id='codeOutput'>&#xA0;</code>
                    </output>
                  </output>
                </div>
              )}
              <FormulaTable />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
