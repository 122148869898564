import React from 'react';
import { Box, Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import NEET_logo from '_assets/images/NEET_logo.png';
import NCERT_logo from '_assets/images/NCERT.png';
import { Carousel } from 'react-responsive-carousel';
import carousal1Image from '_assets/images/carousel_1.png';
import carousal2Image from '_assets/images/carousel_2.png';
import carousal3Image from '_assets/images/carousel_3.png';
import carousal4Image from '_assets/images/carousel_4.jpg';
import carousal5Image from '_assets/images/carousel_5.jpg';
import wizard1Image from '_assets/images/wizard_1.jpg';
import wizard2Image from '_assets/images/wizard_2.jpg';
import wizard3Image from '_assets/images/wizard_3.jpg';
import wizard4Image from '_assets/images/wizard_4.jpg';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useNavigate } from 'react-router-dom';

const PlanLayout = () => {
  const navigate = useNavigate();
  let NEETPrice = 22000;
  let NCERTPrice = 20000;
  const cards = [
    {
      image: carousal1Image,
    },
    {
      image: carousal2Image,
    },
    {
      image: carousal3Image,
    },
    {
      image: carousal4Image,
    },
    {
      image: carousal5Image,
    },
  ];

  const handleBookClick = (title, price) => {
    navigate('/payment', {
      state: { title, price },
    });
  };

  return (
    <>
      <Box
        display='flex-start'
        justifyContent='center'
        alignItems='center'
        height='auto'
        bgcolor='#f0f0f0'
        margin={'2px'}
        padding={2}
      >
        <Box
          display='flex'
          justifyContent='center'
          width='100%'
          maxWidth='100%'
          sx={{
            '.carousel .slider-wrapper': {
              // Adjust height of carousel wrapper
              height: 'auto !important',
            },
            '.carousel .slide img': {
              // Ensure image scales properly
              width: '100%',
              height: 'auto',
              objectFit: 'cover',
            },
          }}
        >
          <Carousel
            showThumbs={false}
            showStatus={false}
            infiniteLoop
            autoPlay
            interval={3000}
            dynamicHeight
            swipeable
            style={{ width: '100%', maxWidth: '100%' }}
          >
            {cards.map((card) => (
              <>
                <Card>
                  <CardContent>
                    <img
                      src={card.image}
                      alt={card.title}
                      style={{
                        width: '100%',
                        height: 'auto',
                        objectFit: 'cover',
                      }}
                    />
                  </CardContent>
                </Card>
              </>
            ))}
          </Carousel>
        </Box>
        {/* </Card> */}
      </Box>
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        height='auto'
        bgcolor='#f0f0f0'
        padding={2}
      >
        <Grid container spacing={2} maxWidth='90%'>
          {[
            { image: wizard1Image, alt: 'Wizard 1' },
            { image: wizard2Image, alt: 'Wizard 2' },
            { image: wizard3Image, alt: 'Wizard 3' },
            { image: wizard4Image, alt: 'Wizard 4' },
          ].map((wizard, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card>
                <CardActionArea>
                  <CardMedia component='img' height='auto' image={wizard.image} alt={wizard.alt} />
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        // height='100vh'
        bgcolor='#f0f0f0'
        padding={2}
      >
        {' '}
        <Card sx={{ maxWidth: '90%', width: '90%' }}>
          <Typography variant='h4' component='div' sx={{ textAlign: 'center', padding: 2 }}>
            Upgrade your plan
          </Typography>
          <Divider />
          <Box
            display='flex'
            gap={2}
            marginTop={'20px'}
            flexDirection={{ xs: 'column', sm: 'row' }}
          >
            <Card sx={{ width: '100%', maxWidth: 700, height: 'auto', padding: 2 }}>
              <CardContent>
                <Typography
                  variant='h5'
                  component='div'
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  <img
                    src={NEET_logo}
                    alt='NEET'
                    onClick={() => handleBookClick('NEET', NEETPrice)}
                    className='book-logo'
                    style={{ width: '100%', maxWidth: '300px', cursor: 'pointer' }}
                  />
                </Typography>
                <Typography
                  variant='body2'
                  color='text.secondary'
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 2,
                    textAlign: 'center',
                  }}
                >
                  <h2>
                    {' '}
                    <b>
                      Price &#x20b9; {NEETPrice} = &#x20b9; {NEETPrice}
                    </b>
                  </h2>
                </Typography>
              </CardContent>
            </Card>
            <Card sx={{ width: '100%', maxWidth: 700, height: 'auto' }}>
              <CardContent>
                <Typography
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  <img
                    src={NCERT_logo}
                    // alt={auth.board}
                    onClick={() => handleBookClick('NCERT', NCERTPrice)}
                    className='book-logo'
                    style={{ width: '100%', maxWidth: '300px', cursor: 'pointer' }}
                  />
                </Typography>
                <Typography
                  variant='body2'
                  color='text.secondary'
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 2,
                    textAlign: 'center',
                  }}
                >
                  <h2>
                    {' '}
                    <b>
                      Price &#x20b9; {NCERTPrice} = &#x20b9; {NCERTPrice}
                    </b>
                  </h2>
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </Card>
      </Box>
    </>
  );
};

export default PlanLayout;
