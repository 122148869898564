import React from 'react';
import VideoQuiz from './video/videoQuiz.mp4';

export const NCERT = () => {
  return (
    <div>
      <video controls autoPlay loop muted>
        <source src={VideoQuiz} type='video/mp4' />
      </video>
    </div>
  );
};