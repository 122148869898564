import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import './style.css';
import { Animation } from './Animation';
import { EquationModal } from './EquationModal';
import PluralChecker from '_helpers/english/PluralChecker';
import { Chatbot } from '_components/chatbot/Chatbot';
import { Loader } from '_components/navigation/Loader';
import QuizWrapper from './QuizWrapper';
import { MyContext } from 'pages/chapter/Chapter';

const RenderContent = (props) => {
  const [type, setType] = useState('');
  const [isNEET, setIsNEET] = useState(false);
  const [chatProp, setChatProp] = useState('');
  const [questionChatPrompt, setQuestionChatPrompt] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const sharedData = useContext(MyContext);
  const {
    section_type,
    sub_section_title,
    description,
    iframe,
    sub_section_id,
    sub_section_type,
    quiz_title,
    tutorial_link,
    definition,
    information,
    url,
  } = props && props.data;
  const [suggestedQuestionList, setSuggestedQuestionList] = useState([
    `explain "${quiz_title ? quiz_title : sub_section_title}" in 
      ${props.chapterTitle}`,
    `Ask a question regarding ${quiz_title} in 
    ${props.chapterTitle}`,
    `Provide examples of real-world applications for ${quiz_title} in 
    ${props.chapterTitle}`,
    `Generate "competency focused practical questions" for <span class="hide-phrase">CBSE grade 10</span>
      ${quiz_title} in ${props.chapterTitle} with detailed solution
      <span class="hide-phrase">similar to questions found in the vector store "CFPQ_Science10.pdf.</span>`,
    `Example for Assertion and Reason question involving ${
      quiz_title.replace(/^\d+\.\d+\.\d+\s*/, '')?.split(':')[0]
    } in ${
      props.chapterTitle
    } with a detailed solution<span class="hide-phrase">in neat HTML table.</span>`,
    `Generate any match the following type question involving ${
      quiz_title.replace(/^\d+\.\d+\.\d+\s*/, '')?.split(':')[0]
    } in ${
      props.chapterTitle
    } with detailed solution <span class="hide-phrase">in neat HTML table.</span>`,
  ]);
  useEffect(() => {
    if (props.data.section_type || props.data.sub_section_type) {
      setType(
        section_type === 'iframe' || sub_section_type === 'iframe'
          ? 'iframe'
          : sub_section_type === 'animation'
          ? 'animation'
          : sub_section_type === 'quiz' || section_type === 'quiz'
          ? 'quiz'
          : sub_section_type === 'exercise'
          ? 'exercise'
          : sub_section_type === 'PrepAI'
          ? 'PrepAI'
          : sub_section_type === 'paper'
          ? 'paper'
          : sub_section_type === 'vectorQuiz' || section_type === 'vectorQuiz'
          ? 'vectorQuiz'
          : 'modal',
      );
    }
  }, []);

  useEffect(() => {
    props.setModalOpen(false);

    if (location.pathname.includes('NEET')) {
      setIsLoading(true);
      setIsNEET(true);
      setChatProp(
        `As you are NEET syllabus guide, provide precise answers. stick to the NEET syllabus and 11,12 ${props.subject}.make it readable by providing styling. Structure your responses as a JSON object with the following keys:
      {
        "answer": "Your concise answer here. For example:**Keyframes** this should be bold.The <span style='background-color: yellow;'>velocity</span> of the object is calculated using the formula <span style='background-color: yellow;'>v = u + at</span>."
      }`,
      );
      setSuggestedQuestionList([
        `Define ${
          quiz_title ? quiz_title : sub_section_title.replace(/^\d+\.\d+\.\d+\s*/, '').split(':')[0]
        } in ${props.subject} in NEET`,
      ]);
      setQuestionChatPrompt(
        `Create 2 questions aligned with NEET ${props.subject} topics.avoid defining 1 from  NEET previous year question papers without answer and other with concept clearing question from syllabus in NEET ${sub_section_title} in ${props.subject}.The format should be: {"question": ["question1", "question2"]}.`,
      );
      setIsLoading(false);
    }
    return () => {
      sharedData && sharedData.setHideChatbot(false);
    };
  }, [props.sub_section_id]);

  const divElement = document.getElementById('animationInfo');
  useEffect(() => {
    if (divElement) {
      window.handleTabClick('tab1-link', 100);
      sharedData.setHideChatbot(false);
    }
  }, [divElement]);

  const onIframeLoad = () => {};
  const handleQuizLoad = () => {
    props && sharedData.setHideChatbot(true);
    sharedData.setChatbotOpen(false);
  };

  if (sub_section_type == 'PrepAI' || sub_section_type == 'quiz') {
    sharedData && sharedData.setHideChatbot(true);
  } else if (sub_section_type == 'exercise' || sub_section_type == 'paper') {
    sharedData.setshowAssistant(false);
  } else {
    sharedData.setshowAssistant(true);
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className='sub-section-content'>
      {type && (
        <>
          {type && type === 'iframe' && (
            <React.Fragment>
              {definition && (
                <div
                  style={{
                    backgroundColor: '#ccf2ff',
                    padding: '10px',
                    margin: '10px 0',
                    borderRadius: '10px',
                  }}
                >
                  <div dangerouslySetInnerHTML={{ __html: definition }}></div>
                </div>
              )}
              {props.isModalOpen && tutorial_link && (
                <>
                  <div
                    className='h4 d-block bg-gradient text-center mx-auto p-2 my-2 text-uppercase rounded-3 fw-bold'
                    style={{ backgroundColor: '#a1d8a0' }}
                  >
                    Watch it
                  </div>
                  <iframe
                    width='800'
                    height='450'
                    src={tutorial_link}
                    allowfullscreen
                    title='YouTube video player'
                    frameBorder='0'
                    style={{ display: 'block', margin: '0 auto' }}
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                  ></iframe>
                  <div
                    className='h4 d-block bg-gradient text-center mx-auto p-2 my-2 text-uppercase rounded-3 fw-bold'
                    style={{ backgroundColor: '#a1d8a0' }}
                  >
                    Do it yourself
                  </div>
                </>
              )}

              <div className='iframe-container'>
                {iframe || url ? (
                  <iframe
                    src={iframe ? iframe : url}
                    width='100%'
                    style={{ height: '100%' }}
                    title='Quiz'
                    className='special-iframe'
                    onLoad={onIframeLoad}
                  />
                ) : (
                  console.warn('iframe needed')
                )}
              </div>
              {description && (
                <div
                  style={{
                    padding: '10px',
                    margin: '10px 0',
                    borderRadius: '10px',
                  }}
                  dangerouslySetInnerHTML={{ __html: description }}
                ></div>
              )}
            </React.Fragment>
          )}

          {sub_section_type &&
            (sub_section_type === 'animation' || sub_section_type === 'paper') && (
              <React.Fragment>
                {definition && (
                  <div
                    style={{
                      backgroundColor: '#ccf2ff',
                      padding: '10px',
                      margin: '10px 0',
                      borderRadius: '10px',
                    }}
                  >
                    <div dangerouslySetInnerHTML={{ __html: definition }}></div>
                  </div>
                )}
                {props.isModalOpen && tutorial_link && (
                  <>
                    <div
                      className='h4 d-block bg-gradient text-center mx-auto p-2 my-2 text-uppercase rounded-3 fw-bold'
                      style={{ backgroundColor: '#a1d8a0' }}
                    >
                      Watch it
                    </div>
                    <iframe
                      width='800'
                      height='450'
                      src={tutorial_link}
                      allowfullscreen
                      title='YouTube video player'
                      frameBorder='0'
                      style={{ display: 'block', margin: '0 auto' }}
                      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                    ></iframe>
                    <div
                      className='h4 d-block bg-gradient text-center mx-auto p-2 my-2 text-uppercase rounded-3 fw-bold'
                      style={{ backgroundColor: '#a1d8a0' }}
                    >
                      Do it yourself
                    </div>
                  </>
                )}
                <Animation figure={props.data} />
                {information && (
                  <div
                    id='animationInfo'
                    style={{
                      padding: '10px 10px 120px 10px',
                      margin: '10px 0',
                      borderRadius: '10px',
                    }}
                    dangerouslySetInnerHTML={{ __html: information }}
                  ></div>
                )}
              </React.Fragment>
            )}

          {section_type && section_type === 'quiz' && props.userDetails && (
            <iframe
              src={
                props.role === 'student'
                  ? iframe +
                    `&user_name=${props.userDetails.student_name}&user_email=${props.userDetails.student_email}&user_id=${props.userDetails.teacher_id}&passkey=04dcbc1a4bcdafe7c3d266342c38b3ac`
                  : iframe +
                    `&user_name=${props.userDetails.teacher_name}&user_email=${props.userDetails.teacher_email}&user_id=${props.userDetails.teacher_id}&passkey=04dcbc1a4bcdafe7c3d266342c38b3ac`
              }
              width='100%'
              title='Quiz'
              className='quiz-iframe'
              style={{ height: '70vh' }}
              onLoad={handleQuizLoad}
            />
          )}

          {sub_section_type && sub_section_type === 'quiz' && (
            <>
              {props.quizDetails.length > 0 ? (
                <QuizWrapper quiz={props.data} askGPT={props.askGPT} data={props} />
              ) : (
                <iframe
                  src={
                    props.role === 'student'
                      ? tutorial_link +
                        `&user_name=${props.userDetails?.student_name || ''}&user_email=${
                          props.userDetails?.student_email || ''
                        }&user_id=${
                          props.userDetails?.teacher_id || ''
                        }&passkey=04dcbc1a4bcdafe7c3d266342c38b3ac`
                      : tutorial_link +
                        `&user_name=${props.userDetails?.teacher_name || ''}&user_email=${
                          props.userDetails?.teacher_email || ''
                        }&user_id=${
                          props.userDetails?.teacher_id || ''
                        }&passkey=04dcbc1a4bcdafe7c3d266342c38b3ac`
                  }
                  width='100%'
                  title='Quiz'
                  className='quiz-iframe'
                  style={{ height: '70vh' }}
                  onLoad={handleQuizLoad}
                />
              )}
            </>
          )}

          {sub_section_type && sub_section_type === 'exercise' && (
            <>
              <div className='exercise' dangerouslySetInnerHTML={{ __html: information }} />
            </>
          )}
          {sub_section_type && sub_section_type === 'english' && (
            <div>
              {definition && (
                <div
                  style={{
                    backgroundColor: '#ccf2ff',
                    padding: '10px',
                    margin: '10px 0',
                    borderRadius: '10px',
                  }}
                >
                  <div dangerouslySetInnerHTML={{ __html: definition }}></div>
                </div>
              )}
              <PluralChecker sub_section_id={sub_section_id} />
              <div
                style={{
                  padding: '10px',
                  margin: '10px 0',
                  borderRadius: '10px',
                }}
                dangerouslySetInnerHTML={{ __html: information }}
              />
            </div>
          )}
          {sub_section_type && sub_section_type === 'PrepAI' && (
            <>
              <Chatbot
                className='col px-4 mx-2'
                answerPrompt={isNEET ? chatProp : ''}
                questionPrompt={isNEET ? questionChatPrompt : ''}
                isFullScreen={true}
                tokenSize={500}
                temprature={0.5}
                chapterId={props.chapterId}
                chapterTitle={props.chapterTitle}
                title={sub_section_title}
                suggestedQuestions={suggestedQuestionList}
                setChat={props.setChat}
                quiz_title={quiz_title}
                drawerStatus={sharedData.open}
              />
            </>
          )}

          {sub_section_type !== 'animation' &&
            sub_section_type !== 'paper' &&
            sub_section_type !== 'quiz' &&
            sub_section_type !== 'exercise' &&
            sub_section_type !== 'iframe' &&
            sub_section_type !== 'english' &&
            sub_section_type !== 'PrepAI' &&
            section_type !== 'paper' &&
            section_type !== 'iframe' &&
            section_type !== 'quiz' && (
              <React.Fragment>
                {definition && (
                  <div
                    style={{
                      backgroundColor: '#ccf2ff',
                      padding: '10px',
                      margin: '10px 0',
                      borderRadius: '10px',
                    }}
                  >
                    <div dangerouslySetInnerHTML={{ __html: definition }}></div>
                  </div>
                )}
                {props.isModalOpen && tutorial_link && (
                  <>
                    <div
                      className='h4 d-block bg-gradient text-center mx-auto p-2 my-2 text-uppercase rounded-3 fw-bold'
                      style={{ backgroundColor: '#a1d8a0' }}
                    >
                      Watch it
                    </div>
                    <iframe
                      width='800'
                      height='450'
                      src={tutorial_link}
                      allowfullscreen
                      title='YouTube video player'
                      frameBorder='0'
                      style={{ display: 'block', margin: '0 auto' }}
                      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                    ></iframe>
                    <div
                      className='h4 d-block bg-gradient text-center mx-auto p-2 my-2 text-uppercase rounded-3 fw-bold'
                      style={{ backgroundColor: '#a1d8a0' }}
                    >
                      Do it yourself
                    </div>
                  </>
                )}
                <EquationModal data={props.data} chapter_id={props.data.chapter_id} />
                {information && (
                  <div
                    style={{
                      padding: '10px',
                      margin: '10px 0',
                      borderRadius: '10px',
                    }}
                    dangerouslySetInnerHTML={{ __html: information }}
                  ></div>
                )}
              </React.Fragment>
            )}
        </>
      )}
    </div>
  );
};
const arePropsEqual = (prevProps, nextProps) => {
  return (
    JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data) &&
    prevProps.isModalOpen === nextProps.isModalOpen
  );
};
export const MemoizedRenderContent = React.memo(RenderContent, arePropsEqual);

RenderContent.propTypes = {
  data: PropTypes.any,
  type: PropTypes.any,
  figure: PropTypes.any,
  sharedData: PropTypes.any,
  role: PropTypes.any,
  isModalOpen: PropTypes.bool,
};
