import React, { useState, useEffect, useContext } from 'react';
import { Box, Button } from '@mui/material';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import { ChapterDisplay } from './ChapterDisplay';
import { SectionDisplay } from './SectionDisplay';
import { MemoizedRenderContent as RenderContent } from '_components/chapters/RenderContent';
import { Loader } from '_components/navigation/Loader';
import { MyContext } from 'pages/chapter/Chapter';
import { useSelector } from 'react-redux';
import { fetchWrapper } from '_helpers';
import doYouKnowImage from '_assets/images/didYouKnow_icon.webp';
import tutorial_icon from '_assets/images/tutorial_icon.webp';
import tryIt_icon from '_assets/images/tryIt_icon.webp';
import OpenAIQuestionGenerator from '../chatbot/OpenAIQuestionGenerator';
import download from '_assets/images/download.webp';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import VectorAIQuestionGenerator from '_components/chatbot/VectorAIQuestionGenerator';
import { VectorSearchs } from '_components/chatbot/VectorSearchs';
function SubSections() {
  const { standard, subject, chapterid, sectionid, subsectionid, activityid } = useParams();
  const navigate = useNavigate();
  const itemsPerPage = 1;
  const [data, setData] = useState('');
  const [subSectionsArray, setSubSectionsArray] = useState('');
  const [sectionsArray, setSectionsArray] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [chapterData, setChapterData] = useState({});
  const [type, setType] = useState('');
  const [prevSectionId, setPrevSectionId] = useState(sectionid);
  const [userDetails, setUserDetails] = useState('');
  const [doYouKnow, setDoYouKnow] = useState(false);
  const [inProgressData, setInProgressData] = useState();
  const [chapterTitle, setChapterTitle] = useState('');
  const [chat, setChat] = useState('');
  const [challengeME, setChallengeME] = useState(true);
  const [quizDetail, setQuizDetails] = useState([]);
  const [sectionsTitleData, setSectionsTitleData] = useState([]);
  const [analysisChartData, setAnalysisChartData] = useState([]);

  const sharedData = useContext(MyContext);
  const user = useSelector((x) => x.auth.value);
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  let board = user && user.board;
  let School_id = user && user.relatedData.sch_id;
  inProgressData && console.log(inProgressData);

  useEffect(() => {
    async function fetchData() {
      const email = user.email;
      try {
        let res;
        if (user.role === 'student') {
          res = await fetchWrapper.get(`${baseUrl}/api/v1/getSingleStudent?student_email=${email}`);
        } else if (user.role === 'teacher') {
          res = await fetchWrapper.get(
            `${baseUrl}/api/v1/getSingleTeacherData?teacher_email=${email}`,
          );
        } else {
          // Handle unrecognized roles here
          console.log(`Unrecognized role: ${user.role}`);
          res = {
            name: 'User with Unrecognized Role',
            role: 'Unrecognized',
          };
        }

        setUserDetails(res);
      } catch (error) {
        console.error('Error:', error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function quizData() {
      try {
        let quizChapter = chapterid;
        let quizSection = sectionid;
        let quizGrade = standard;
        let quizSubject = subject;
        const input = subsectionid;
        const quizSubsectionId = input.replace(/q$/, '');

        let response;
        if (location.pathname.includes('NEET')) {
          response = await fetchWrapper.get(
            `${baseUrl}/api/v1/getSingleQuizzes?chapter=${quizChapter}&section=${quizSection}&subsection=${quizSubsectionId}&subject=${quizSubject}`,
          );
        } else if (location.pathname.includes('NEET_TEST')) {
          response = await fetchWrapper.get(
            `${baseUrl}/api/v1/getSingleQuizzes?chapter=${quizChapter}&section=${quizSection}&subsection=${quizSubsectionId}&subject=${quizSubject}`,
          );
        } else if (location.pathname.includes('SAT')) {
          response = await fetchWrapper.get(
            `${baseUrl}/api/v1/getSingleQuizzes?chapter=${quizChapter}&section=${quizSection}&subsection=${quizSubsectionId}&subject=${quizSubject}`,
          );
        } else {
          response = await fetchWrapper.get(
            `${baseUrl}/api/v1/getSingleQuizzes?board=${board}&school_id=${School_id}&grade=${quizGrade}&chapter=${quizChapter}&section=${quizSection}&subsection=${quizSubsectionId}&subject=${quizSubject}`,
          );
        }

        setQuizDetails(response.QuizInfo);
      } catch (error) {
        console.error('Error:', error);
      }
    }

    if (quizDetail.length === 0) {
      console.log('no data ');
      // toast.error(' no data found ');
    }

    quizData();
  }, [subsectionid]);

  useEffect(() => {
    if (!sharedData) {
      setisLoading(true);
      return <Loader />;
    } else {
      setisLoading(false);
    }
    if (sectionsArray && sectionsArray.section_id !== prevSectionId) {
      setPrevSectionId(sectionsArray.section_id);
    }
  }, [sharedData, sectionid]);

  const urlData = {
    grade: standard,
    subject: subject,
  };

  useEffect(() => {
    setTimeout(() => {
      if (document.querySelector('.doYouKnowWrapper')) {
        setDoYouKnow(true);
      }
    }, 1000);
    return () => {
      setDoYouKnow(false);
    };
  }, [standard, subject, chapterid, sectionid, subsectionid, activityid]);

  useEffect(() => {
    setChallengeME(true);
    setModalOpen(false);
    if (subsectionid && subsectionid !== undefined) {
      setType('subSection');
    } else if (sectionid) {
      setType('section');
    } else if (!subsectionid && !sectionid) {
      setType('chapter');
    }
    location && location.state ? setChapterData(location.state) : setChapterData(urlData);
    chapterid && setData(sharedData.chapters[chapterid - 1]);
    setCurrentIndex(activityid ? activityid - 1 : 0);
  }, [standard, subject, chapterid, sectionid, subsectionid, data, sharedData.chapters]);

  const handlePageChange = (pageNumber) => {
    setCurrentIndex(pageNumber);
    if (location.pathname.includes('NEET')) {
      navigate(
        `/dashboard/NEET/subject/${subject}/chapter/${chapterid}/section/${sectionid}/subsection/${subsectionid}/${
          pageNumber + 1
        }`,
      );
    } else if (location.pathname.includes('NEET_TEST')) {
      navigate(
        `/dashboard/NEET_TEST/subject/${subject}/chapter/${chapterid}/section/${sectionid}/subsection/${subsectionid}/${
          pageNumber + 1
        }`,
      );
    } else if (location.pathname.includes('SAT')) {
      navigate(
        `/dashboard/SAT/subject/${subject}/chapter/${chapterid}/section/${sectionid}/subsection/${subsectionid}/${
          pageNumber + 1
        }`,
      );
    } else {
      navigate(
        `/dashboard/standard/${standard}/subject/${subject}/chapter/${chapterid}/section/${sectionid}/subsection/${subsectionid}/${
          pageNumber + 1
        }`,
      );
    }
  };

  const openModal = () => {
    setModalOpen(!isModalOpen);
  };

  async function findObject(obj, sectionId, subSectionId) {
    if (type === 'section') {
      if (chapterData.subject === 'maths') {
        let res = obj.section.filter((obj) => obj.section_id === sectionId);
        setSectionsArray(res[0]);
      } else {
        setSectionsArray(obj.section.find((obj) => obj.section_id === sectionId));
      }
    } else if (type === 'subSection' && sectionId && obj && subSectionId) {
      let res = await obj.section.find((obj) => obj.section_id === sectionId);
      obj['chapter_id'] == chapterid
        ? setSubSectionsArray(res.subsection.filter((obj) => obj.sub_section_id === subSectionId))
        : '';
    }
  }
  const handleDynamicClick = (event) => {
    const clickedElement = event.target;
    const action = clickedElement.getAttribute('data-action');
    if (action && action.startsWith('askGPT(')) {
      // for calling chatGPT
      const dynamicData = action.slice(8, -2).replace(/-/g, ' ');
      askGPT(dynamicData);
    } else if (action && action.startsWith('link(')) {
      const dynamicData = action.slice(6, -2).replace('-', ' ');
      navigate(dynamicData);
    }
  };

  



  const downloadChatAsPDF = (chat, title) => {
    const chatContainer = document.getElementById('chatContainer');
  
    html2canvas(chatContainer, { scale: 3, useCORS: true }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
  
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 297; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const borderWidth = 5; // Width of the border
  
      let heightLeft = imgHeight;
      let position = 0;
  
      // Watermark settings
      const watermarkText = 'Classroom AI';
      const watermarkX = pdf.internal.pageSize.getWidth() / 2;
      const watermarkY = pdf.internal.pageSize.getHeight() / 2;
      const watermarkAngle = 45;
  
      // Function to add watermark
      const addWatermark = () => {
        pdf.setTextColor(240, 240, 240); // Light grey color for watermark
        pdf.setFontSize(50);
        pdf.text(watermarkText, watermarkX, watermarkY, {
          angle: watermarkAngle,
          align: 'center',
          baseline: 'middle'
        });
      };
  
      // Function to draw border around the page
      const drawBorder = () => {
        pdf.setLineWidth(borderWidth); // Set the line width for the border
        pdf.rect(borderWidth / 2, borderWidth / 2, pdf.internal.pageSize.width - borderWidth, pdf.internal.pageSize.height - borderWidth); // Draw the border
      };
  
      // First Page
      addWatermark(); // Add watermark first
      pdf.addImage(imgData, 'PNG', borderWidth, borderWidth, imgWidth - 2 * borderWidth, imgHeight, '', 'FAST');
      drawBorder(); // Draw border around the whole page
  
      heightLeft -= pageHeight;
  
      // Additional Pages
      while (heightLeft > 0) {
        pdf.addPage();
        addWatermark(); // Add watermark on each page before image
        position = heightLeft - imgHeight;
  
        pdf.addImage(imgData, 'PNG', borderWidth, position, imgWidth - 2 * borderWidth, imgHeight, '', 'FAST');
        drawBorder(); // Draw border around the whole page
  
        heightLeft -= pageHeight;
      }
  
      // Save the PDF
      pdf.save(`${title || 'chat_conversation'}.pdf`);
    });
  };
  

  
  // const intervalId = setInterval(handleLoading, 20000);
  const askGPT = (message) => {
    sharedData.setHideChatbot(false);
    sharedData.setChatbotOpen(true);
    sharedData.setMessage(message);
  };

  useEffect(() => {
    document.addEventListener('click', handleDynamicClick);
    return () => {
      document.removeEventListener('click', handleDynamicClick);
    };
  }, [subsectionid, currentIndex]);

  useEffect(() => {
    const handleChapterCheckboxChange = async () => {
      let chapter_completion_status = 'inprogress';
      let section_completion_status = 'inprogress';
      let subsection_completion_status = 'inprogress';

      if (!sectionid) {
        chapter_completion_status = 'completed';
      }
      if (sectionid && !subsectionid) {
        chapter_completion_status = 'completed';
        section_completion_status = 'completed';
      }

      if (chapterid) {
        const selectedChapter = sharedData.chapters.find(
          (chapter) => chapter.chapter_id === parseInt(chapterid, 10),
        );
        setChapterTitle(selectedChapter);

        const sectionTitles = selectedChapter && selectedChapter.section && selectedChapter.section.map((section) => section.section_title);
        setSectionsTitleData(sectionTitles);
      }

      if (chapterid && sectionid && subsectionid) {
        let isSubsectionCompleted;
        try {
          let filteredSubsections = subSectionsArray.filter(
            (subsection) => subsection.section_id === sectionid,
          );
          let totalSubsectionPages = filteredSubsections.length;

          isSubsectionCompleted = filteredSubsections.every((page, index) => {
            return (
              page.page_status === 'completed' ||
              index !== totalSubsectionPages - 1 ||
              index === currentIndex
            );
          });
          subsection_completion_status = isSubsectionCompleted ? 'completed' : 'inprogress';

          const selectedChapter = sharedData.chapters.find(
            (chapter) => chapter.chapter_id === parseInt(chapterid, 10),
          );
          setChapterTitle(selectedChapter);
          if (selectedChapter) {
            if (
              selectedChapter.section &&
              Array.isArray(selectedChapter.section) &&
              selectedChapter.section.length > 0
            ) {
              const sections = selectedChapter.section;
              const sectionIds = sections.map((section) => section.section_id);
              const selectedSection = sectionIds.includes(sectionid) ? sectionid : null;

              if (selectedSection) {
                const firstMatchingSubsection = sections.find(
                  (e) => e['subsection'][0] && e['subsection'][0]['section_id'] == selectedSection,
                );
                if (firstMatchingSubsection) {
                  const uniqueSubsectionIds = new Set();
                  firstMatchingSubsection['subsection'].forEach((e) => {
                    if (e['sub_section_id']) {
                      uniqueSubsectionIds.add(e['sub_section_id']);
                    }
                  });

                  // Handle the completion status of the subsection based on the completion status of its pages
                  // Note: You might need to adjust this part according to your logic
                  // Example:
                  // let uniqueSubsectionId = Array.from(uniqueSubsectionIds);
                  // let getProgress = progressData[0]?.completedSubsectionIds?.map((id) => id);

                  // const matchingSubsectionIds = uniqueSubsectionId.filter((subsectionId) =>
                  //   getProgress.includes(subsectionId),
                  // );
                  // let totalSectionPages = matchingSubsectionIds.length;
                  // const isSectionCompleted = matchingSubsectionIds.every((page, index) => {
                  //   return (
                  //     index !== totalSectionPages - 1 ||
                  //     index === totalSectionPages
                  //   );
                  // });
                  // section_completion_status = isSectionCompleted ? 'completed' : 'inprogress';
                } else {
                  console.log('No matching subsection found with section_id:', selectedSection);
                }
              } else {
                console.log("No 'section' found with section_id:", sectionid);
              }
            } else {
              console.log(
                "No 'section' array found in the selected chapter or empty:",
                selectedChapter,
              );
            }
          } else {
            console.log('Chapter not found with chapter_id:', chapterid);
          }
        } catch (error) {
          console.error('Error in handleChapterCheckboxChange:', error);
        }
      }
      const requestBody = {
        grade: standard,
        chapter_id: chapterid,
        section_id: sectionid,
        subsection_id: subsectionid,
        user: user.email,
        page: currentIndex,
        subject: subject,
        chapter_completion_status: chapter_completion_status,
        section_completion_status: section_completion_status,
        subsection_completion_status: subsection_completion_status,
        school_id: user.relatedData.sch_id,
      };

      console.log('requestBody', requestBody);
      // try {
      //   const response = await fetchWrapper.post(`${baseUrl}/api/v1/CompletedStatus`, requestBody);
      //   const data = await response;
      //   console.log('API Response:', data);
      //   // setCompletionData(data)
      //   // Handle the response data here
      // } catch (error) {
      //   console.error('API Error:', error);
      // }
    };

    const timeoutId = setTimeout(handleChapterCheckboxChange, 3000);

    setAnalysisChartData(sharedData.chapters[chapterid - 1]);

    return () => clearTimeout(timeoutId);
  }, [currentIndex, chapterid, subsectionid, sectionid, data, sharedData.chapters]);

  useEffect(() => {
    const handleInprogressChange = async () => {
      try {
        const response = await fetchWrapper.get(
          `${baseUrl}/api/v1/inProgressStatus?grade=${standard}&user=${user?.email}&subject=${subject}`,
        );
        const data = await response.json();
        setInProgressData(data);
      } catch (error) {
        console.error('API Error:', error);
      }
    };
    if (user && user.role === 'teacher') {
      const timeoutId = setTimeout(handleInprogressChange, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [currentIndex, subsectionid]);

  useEffect(() => {
    if (chapterData.subject === 'maths' && data) {
      data && findObject(data, sectionid, subsectionid);
    }
    if (
      (type === 'subSection' && chapterData.subject === 'science') ||
      (type === 'subSection' && chapterData.subject === 'Physics') ||
      (type === 'subSection' && chapterData.subject === 'Chemistry') ||
      (type === 'subSection' && chapterData.subject === 'Biology') ||
      chapterData.subject === 'english'
    ) {
      let subSec = data && findObject(data, sectionid, subsectionid);
      subSec && subSec.length && setSubSectionsArray(Object.values(subSec));
    }
    return () => {
      setSubSectionsArray([]);
    };
  }, [type, chapterData, data, subsectionid, sectionid]);

  const newUser = { ...user };

  if (type === 'chapter' && data) {
    {
      newUser['title'] = data.name;
    }
    {
      localStorage.setItem('auth', JSON.stringify(newUser));
    }
    return <ChapterDisplay data={data} askGPT={askGPT} />;
  } else if (
    type === 'section' &&
    (chapterData.subject === 'science' ||
      chapterData.subject === 'Physics' ||
      chapterData.subject === 'Chemistry' ||
      chapterData.subject === 'Biology' ||
      chapterData.subject === 'maths' ||
      chapterData.subject === 'english') &&
    data &&
    sectionid &&
    data.chapter_id == chapterid
  ) {
    let sectionData =
      sectionsArray && sectionsArray.section_id === sectionid
        ? sectionsArray
        : findObject(data, sectionid);
    {
      newUser['title'] = sectionData.section_title;
    }
    {
      localStorage.setItem('auth', JSON.stringify(newUser));
    }

    return (
      <SectionDisplay
        data={sectionData}
        chapterid={chapterid}
        subject={subject}
        subsectionid={subsectionid}
        askGPT={askGPT}
        sectionid={sectionid}
        user={user}
        chapterData={chapterData}
        board={location.pathname.includes('NEET') ? 'NEET' : user.board}
        sectionTitle={sectionsTitleData}
        analysisChartData={analysisChartData}
        quizTitle={subSectionsArray && subSectionsArray[0]?.quiz_title}
      />
    );
  }

  const handleTestMe = async (subsectionid) => {
    const requestBody = {
      chapter: chapterid,
      section: sectionid,
      subSection: subsectionid,
      type: 'quiz',
      grade: user.grade,
      board: location.pathname.includes('NEET') ? 'NEET' : user.board,
      state: user.state,
    };
    try {
      const response = await fetchWrapper.post(
        `${baseUrl}/api/v1/getSubsectionByType`,
        requestBody,
      );
      const data = await response;
      const correctedSubSectionId = subsectionid.slice(0, -1) + 'q';

      if (data) {
        if (location.pathname.includes('NEET')) {
          navigate(
            `/dashboard/NEET/subject/${subject}/chapter/${chapterid}/section/${sectionid}/subsection/${correctedSubSectionId}`,
          );
        } else if (location.pathname.includes('NEET_TEST')) {
          navigate(
            `/dashboard/NEET_TEST/subject/${subject}/chapter/${chapterid}/section/${sectionid}/subsection/${correctedSubSectionId}`,
          );
        } else if (location.pathname.includes('SAT')) {
          navigate(
            `/dashboard/SAT/subject/${subject}/chapter/${chapterid}/section/${sectionid}/subsection/${correctedSubSectionId}`,
          );
        } else {
          navigate(
            `/dashboard/standard/${standard}/subject/${subject}/chapter/${chapterid}/section/${sectionid}/subsection/${correctedSubSectionId}`,
          );
        }
      } else {
        alert('No quiz found.');
      }
    } catch (error) {
      console.error('API Error:', error);
    }
  };

  if (isLoading) {
    return <Loader />;
  }
  const updateTitleAndLocalStorage = (newUser, e) => {
    newUser['title'] = newUser && e.sub_section_title ? e.sub_section_title : e.section_title;
    localStorage.setItem('auth', JSON.stringify(newUser));
  };
  return (
    <Box style={{ height: '100%' }}>
      {sharedData.chapters.length === 0 ? (
        <Loader />
      ) : (
        subSectionsArray &&
        Object.values(subSectionsArray).map((e, index) => (
          <React.Fragment key={index}>
            {index === currentIndex && (
              <React.Fragment>
                <div
                  style={{
                    padding: e.sub_section_type !== 'PrepAI' ? '0px 20px' : '',
                  }}
                >
                  {
                    <div
                      className={
                        e.sub_section_type !== 'PrepAI'
                          ? 'pb-4 mt-4 d-flex align-items-center justify-content-between'
                          : 'd-flex p-2 text-light border-rounded  align-items-center justify-content-between bg-dark'
                      }
                      style={{ padding: 0 }}
                    >
                      <h2
                        className={e.sub_section_type == 'PrepAI' ? 'w-75' : ''}
                        style={{ marginBottom: '0px' }}
                      >
                        {updateTitleAndLocalStorage(newUser, e)}
                        {e.sub_section_type !== 'quiz' && e.sub_section_type !== 'PrepAI'
                          ? `${e.sub_section_id ? e.sub_section_id + ' ' : e.section_id}`
                          : ''}
                        {e.sub_section_title ? e.sub_section_title : e.section_title}
                      </h2>
                      {e.sub_section_type == 'quiz' && e.tutorial_link && (
                        <div style={{ marginLeft: 'auto' }}>
                          <Button
                            variant='contained'
                            color='success'
                            onClick={() => {
                              setChallengeME((prevState) => !prevState) &&
                                sharedData.setChatbotOpen(false);
                            }}
                          >
                            {!challengeME ? 'Prepare Me' : 'Challenge Me'}
                          </Button>
                        </div>
                      )}
                      {/* // ): setChallengeME(false)} */}

                      <div className='d-flex px-2'>
                        {doYouKnow ? (
                          <h4
                            className=' px-2 mb-0 mx-2 pt-1 text-uppercase'
                            style={{
                              color: 'aliceblue',
                              fontWeight: '700',
                              borderRadius: '5px',
                            }}
                            onClick={() =>
                              document.querySelector('.doYouKnowWrapper').scrollIntoView()
                            }
                          >
                            <img className='doYouKnow-img' alt='doYouKnow' src={doYouKnowImage} />
                          </h4>
                        ) : (
                          ''
                        )}
                        {e.tutorial_link &&
                          (e.sub_section_type === 'animation' ||
                            e.sub_section_type === 'paper') && (
                            <h4
                              onClick={openModal}
                              style={{
                                color: 'black',
                                fontWeight: '700',
                                backgroundColor: '#a9dba8',
                                borderRadius: '5px',
                                textAlign: 'end',
                              }}
                              className=' px-2 mb-0 mx-2 pt-1 blinking text-uppercase bg-gradient'
                            >
                              {!isModalOpen ? (
                                <img
                                  className='doYouKnow-img'
                                  alt='tutorial_icon'
                                  src={tutorial_icon}
                                />
                              ) : (
                                <img className='doYouKnow-img' alt='tryIt_icon' src={tryIt_icon} />
                              )}
                            </h4>
                          )}
  {e.sub_section_type && e.sub_section_type === 'PrepAI' && (
  <>
    <Button
      size='large'
      color='error'
      variant='contained'
      alt='Download Chat'
      style={{ padding: '5px', marginRight: '10px' }}
      onClick={() => handleTestMe(e.sub_section_id)}
    >
      Test Me!
    </Button>
    {chat && chat.length ? (
      <img
        className='download-img'
        src={download}
        alt='Download Chat'
        onClick={() => downloadChatAsPDF(chat, e.sub_section_title)}
      />
    ) : (
      ''
    )}
  </>
)}

                      </div>
                    </div>
                  }
                  {
                    (!e.tutorial_link && e.sub_section_type === 'quiz') ||
                    (userDetails &&
                      sharedData &&
                      chapterTitle &&
                      challengeME &&
                      e.sub_section_type === 'quiz') ? (
                      <OpenAIQuestionGenerator
                        topic={e.sub_section_title}
                        board={location.pathname.includes('NEET') ? 'NEET' : user.board}
                        setHideChatbot={sharedData.setHideChatbot}
                        setChat={setChat}
                        askGPT={askGPT}
                        setChatbotOpen={sharedData.setChatbotOpen}
                        quizDetails={quizDetail}
                        subject={subject}
                        standard={standard}
                        chapterTitle={chapterTitle && chapterTitle['name']}
                        subSectionTitle={e.sub_section_title}
                        chapterId={chapterid}
                        sectionId={sectionid}
                        subsectionId={subsectionid}
                        schoolId={user.relatedData.sch_id}
                        sectionTitle={sectionsArray && sectionsArray.section_title}
                      />
                    ) : e.sub_section_type === 'vectorQuiz' ? (
                      <VectorSearchs
                        topic={e.sub_section_title}
                        board={location.pathname.includes('NEET') ? 'NEET' : user.board}
                        // Pass additional props here
                        setHideChatbot={sharedData.setHideChatbot}
                        setChat={setChat}
                        askGPT={askGPT}
                        quizDetails={quizDetail}
                        subject={subject}
                        standard={standard}
                        chapterTitle={chapterTitle && chapterTitle['name']}
                        chapterId={chapterid}
                        sectionId={sectionid}
                        subsectionId={subsectionid}
                        schoolId={user.relatedData.sch_id}
                        sectionTitle={sectionsArray && sectionsArray.section_title}
                        quizTitle={subSectionsArray && subSectionsArray[0]?.quiz_title}
                      />
                    ) : e.sub_section_type === 'vectorQuiz' ? (
                      <>
                        <VectorAIQuestionGenerator
                          topic={e.sub_section_title}
                          // board={location.pathname.includes('NEET') ? 'NEET' : user.board}
                          // board={location.pathname.includes('NEET') ? 'NEET' : user.board}
                        />
                      </>
                    ) : (
                      chapterTitle && (
                        <RenderContent
                          data={e}
                          subject={subject}
                          chapterId={chapterid}
                          grade={standard}
                          chapterTitle={chapterTitle && chapterTitle['name']}
                          role={user.role}
                          userDetails={userDetails}
                          subsectionid={subsectionid}
                          isModalOpen={isModalOpen}
                          setModalOpen={setModalOpen}
                          setChat={setChat}
                          askGPT={askGPT}
                          quizDetails={quizDetail}
                        />
                      )
                    )
                    // )
                  }
                  {subSectionsArray.length / itemsPerPage > 1 && (
                    <div className='d-flex justify-content-center'>
                      <ul className='pagination'>
                        {Array.from(
                          { length: Math.ceil(subSectionsArray.length / itemsPerPage) },
                          (_, index) => (
                            <li
                              key={index}
                              className={`page-item ${index === currentIndex ? 'active' : ''}`}
                            >
                              <button className='page-link' onClick={() => handlePageChange(index)}>
                                {index + 1}
                              </button>
                            </li>
                          ),
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        ))
      )}
    </Box>
  );
}

SubSections.propTypes = {
  subsection: PropTypes.object,
  data: PropTypes.object,
  type: PropTypes.string,
  chapterData: PropTypes.string,
  askGPT: PropTypes.func,
};

export { SubSections };
