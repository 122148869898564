import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from './_store';
import { App } from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import { HelmetProvider } from 'react-helmet-async';
const container = document.getElementById('root');
const root = createRoot(container);
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PaymentProvider } from '../src/account/individual/Product/PaymentContext';

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <HelmetProvider>
          <PaymentProvider>
            <App />
          </PaymentProvider>
        </HelmetProvider>
        <ToastContainer autoClose={3000} position='top-right' />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
);
