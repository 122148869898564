import { fetchWrapper } from '_helpers';
import React, { useState } from 'react';
import {
  Typography,
  Card,
  CardContent,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormGroup,
  Button,
  Divider,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { QuizSummaryDetails } from '../../pages/teacherDashboard/dashboard/pages/quizPanel/QuizSummary';
import { LinearChartProgress } from '../../pages/teacherDashboard/dashboard/pages/quizPanel/LinearProgress';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import { AnswerDistribution } from '_components/common/AnswerDistribution';
import { QuizSummary } from '_components/common/QuizSummary';
const QuizWrapper = (quiz) => {
  const { askGPT } = quiz.data;

  const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [quizStarted, setQuizStarted] = useState(false);
  const [showQuizSummary, setShowQuizSummary] = useState(false);
  const [fullMarks, setFullMarks] = useState('');

  const user = useSelector((x) => x.auth.value);

  const handleGptClick = (value) => {
    quiz.data.setHideChatbot(false);
    askGPT(value);
  };
  const startQuiz = () => {
    quiz.data.setHideChatbot(true);
    quiz.data.setChatbotOpen(false);
    setQuizStarted(true);
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    quiz.data.setHideChatbot(true);
  };
  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleOptionSelect = (option) => {
    if (!selectedOptions[currentQuestionIndex]) {
      setSelectedOptions((prevOptions) => ({
        ...prevOptions,
        [currentQuestionIndex]: option,
      }));
    }
  };

  const calculateObtainedMarks = () => {
    let obtainedMarks = 0;
    const isNEET = location.pathname.includes('NEET');
    const rightMarks = isNEET ? 4 : 1;
    const wrongMarks = isNEET ? -1 : 0; // No negative marking if not NEET
    Object.keys(selectedOptions).forEach((index) => {
      if (selectedOptions[index] === quiz.data.quizDetails[index].correct_answer) {
        obtainedMarks += rightMarks;
      } else {
        if (isNEET) {
          obtainedMarks += wrongMarks;
        }
      }
    });
    return obtainedMarks;
  };

  const calculatePercentageMarks = () => {
    const isNEET = location.pathname.includes('NEET');
    const totalQuestions = quiz.data.quizDetails.length;
    const totalMarks = isNEET ? totalQuestions * 4 : totalQuestions;
    return ((calculateObtainedMarks() / totalMarks) * 100).toFixed(2); // Return percentage with two decimal places
  };

  //TODO:
  // const calculateUnansweredQuestions = () => {
  //   return quizDetails.length - Object.keys(selectedOptions).length;
  // };

  // const calculateTimeTaken = () => {
  //   // Assuming you have a start time and end time to calculate the total time taken
  //   const endTime = new Date();
  //   const startTime = quizDetails[0].start_time; // Assuming start_time is recorded when the quiz started
  //   const diff = endTime.getTime() - startTime.getTime();
  //   const seconds = Math.floor(diff / 1000);
  //   return formatTime(seconds); // Implement a function to format time in 'HH:MM:SS' format
  // };

  // const formatTime = (seconds) => {
  //   const hours = Math.floor(seconds / 3600);
  //   const minutes = Math.floor((seconds % 3600) / 60);
  //   const remainingSeconds = seconds % 60;
  //   return `${hours}:${minutes}:${remainingSeconds}`;
  // };

  const calculateCorrectAnswers = () => {
    let correctAnswers = 0;

    Object.keys(selectedOptions).forEach((index) => {
      const currentQuestion = quiz.data.quizDetails[index];
      const keys = Object.keys(currentQuestion);

      // Find the option keys
      const option1Key = keys.find((key) => key === 'option_1');
      const option2Key = keys.find((key) => key === 'option_2');
      const option3Key = keys.find((key) => key === 'option_3');
      const option4Key = keys.find((key) => key === 'option_4');

      if (
        (selectedOptions[index] === currentQuestion[option4Key] &&
          option4Key === currentQuestion.correct_answer) ||
        (selectedOptions[index] === currentQuestion[option3Key] &&
          option3Key === currentQuestion.correct_answer) ||
        (selectedOptions[index] === currentQuestion[option2Key] &&
          option2Key === currentQuestion.correct_answer) ||
        (selectedOptions[index] === currentQuestion[option1Key] &&
          option1Key === currentQuestion.correct_answer) ||
        selectedOptions[index] === currentQuestion.correctAnswerImage
      ) {
        correctAnswers++;
      }
    });

    return correctAnswers;
  };

  const calculateWrongAnswers = () => {
    let wrongAnswers = 0;

    Object.keys(selectedOptions).forEach((index) => {
      const currentQuestion = quiz.data.quizDetails[index];
      const keys = Object.keys(currentQuestion);

      // Find the option keys
      const option1Key = keys.find((key) => key === 'option_1');
      const option2Key = keys.find((key) => key === 'option_2');
      const option3Key = keys.find((key) => key === 'option_3');
      const option4Key = keys.find((key) => key === 'option_4');

      const isCorrect =
        (selectedOptions[index] === currentQuestion[option4Key] &&
          option4Key === currentQuestion.correct_answer) ||
        (selectedOptions[index] === currentQuestion[option3Key] &&
          option3Key === currentQuestion.correct_answer) ||
        (selectedOptions[index] === currentQuestion[option2Key] &&
          option2Key === currentQuestion.correct_answer) ||
        (selectedOptions[index] === currentQuestion[option1Key] &&
          option1Key === currentQuestion.correct_answer) ||
        selectedOptions[index] === currentQuestion.correctAnswerImage;

      if (!isCorrect) {
        wrongAnswers++;
      }
    });

    return wrongAnswers;
  };

  const calculateUnansweredQuestions = () => {
    let unAnsweredQuestion =
      quiz.data.quizDetails.length - calculateCorrectAnswers() - calculateWrongAnswers();

    return unAnsweredQuestion;
  };
  //TODO:
  // const calculateTimeTakenInSeconds = () => {
  //   const endTime = new Date();
  //   const startTime = quizDetails[0].start_time; // Assuming start_time is recorded when the quiz started
  //   const diff = endTime.getTime() - startTime.getTime();
  //   const seconds = Math.floor(diff / 1000);
  //   return seconds;
  // };
  const currentDate = new Date();
  const unixTimestamp = Math.floor(currentDate.getTime() / 1000);

  const handleSubmit = async () => {
    const quizInfo = quiz.data.quizDetails[0]; // Assuming quizDetails is an array with at least one item
    const isNEET = location.pathname.includes('NEET');
    const totalQuestions = quiz.data.quizDetails.length;
    const totalMarks = isNEET ? totalQuestions * 4 : totalQuestions;
    setFullMarks(totalMarks);

    const requestBody = {
      total_marks: totalMarks,
      attempt_date: unixTimestamp, // Use the current timestamp for the attempt date
      user_obtained_marks: calculateObtainedMarks(), // Implement a function to calculate obtained marks
      user_percent_marks: calculatePercentageMarks(), // Implement a function to calculate percentage marks
      user_totalcorrect_answers: calculateCorrectAnswers(), // Implement a function to calculate correct answers
      user_totalwrong_answers: calculateWrongAnswers(), // Implement a function to calculate wrong answers
      user_Id: user && user.relatedData.teacher_id,
      user_name: user && user.first_name,
      user_Email: user && user.email,
      quiz_name: quizInfo.quiz_name,
      quiz_id: quizInfo.quiz_id,
      // time_taken_in_sec: calculateTimeTakenInSeconds(), // Implement a function to calculate time taken in seconds
      user_total_unanswered: calculateUnansweredQuestions(), // Implement a function to calculate unanswered questions
      quiz_title: quizInfo.quiz_title,
      min_pass_marks: quizInfo.min_pass_marks,
    };
    if (isNEET) {
      requestBody.totalQuestion = totalQuestions;
      requestBody.wrongAnswers = calculateWrongAnswers();
      requestBody.rightAnswers = calculateCorrectAnswers();
      requestBody.obtainedMarks = calculateObtainedMarks();
      requestBody.obtainedScore = calculateObtainedMarks();
      requestBody.totalMarks = totalMarks;
      requestBody.level = quiz.data.level;
      requestBody.board = quiz.data.board;
      requestBody.class = quiz.data.standard;
      requestBody.subject = quiz.data.subject;
      requestBody.school = quiz.data.schoolId;
      requestBody.chapter = quiz.data.chapterTitle;
      requestBody.section_id = quiz.data.sectionId;
      requestBody.sub_section_id = quiz.data.subsectionId;
      requestBody.chapter_id = quiz.data.chapterId;
      requestBody.subsection = quiz.data.subSectionTitle;
      requestBody.section = quiz.data.sectionTitle;
      requestBody.email = user.email;
      requestBody.user_id = user.id;
      requestBody.percentScore = calculatePercentageMarks();
      requestBody.quiz_title = quizInfo.quiz_title;
      requestBody.quiz_name = quizInfo.quiz_name;
    }
    try {
      const endpoint = isNEET
        ? `${baseUrl}/ai_performance_analysis`
        : `${baseUrl}/testQuizzesResult`;
      await fetchWrapper.post(endpoint, requestBody);
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setShowQuizSummary(true);
      toast.success('Quiz stored successfully');
    } catch (error) {
      console.error('Error submitting quiz result:', error.message);
    }
  };
  const chartData = {
    series: [calculateCorrectAnswers(), calculateWrongAnswers(), calculateUnansweredQuestions()],
    options: {
      chart: {
        type: 'pie',
      },
      labels: ['Right Answers', 'Wrong Answers', 'Unanswered'],
      colors: ['#4CAF50', '#F44336', '#8a2be2'],
      legend: {
        position: 'bottom',
        fontSize: '12px',
        fontWeight: 'bold',
      },
      tooltip: {
        y: {
          formatter: (val) => `${val} answers`,
        },
      },
    },
  };
  const selectedOption = selectedOptions[currentQuestionIndex];

  const renderQuestion = () => {
    if (
      currentQuestionIndex >= quiz.data.quizDetails.length ||
      !quiz.data.quizDetails[currentQuestionIndex]
    ) {
      return <Typography variant='h6'>Quiz completed!</Typography>;
    }

    const currentQuestion = quiz.data.quizDetails[currentQuestionIndex];
    const keys = Object.keys(currentQuestion);

    // Find the key "option_1"
    const option1Key = keys.find((key) => key === 'option_1');
    const option2Key = keys.find((key) => key === 'option_2');
    const option3Key = keys.find((key) => key === 'option_3');
    const option4Key = keys.find((key) => key === 'option_4');
    return (
      <div>
        <Typography variant='h6'>
          {' '}
          {currentQuestionIndex + 1}:{currentQuestion.questions}
        </Typography>

        {currentQuestion.questionImage && (
          <img
            alt='questionImage'
            width={500}
            height={350}
            style={{
              maxHeight: '500px',
              maxWidth: '350px',
              objectFit: 'contain',
              marginTop: '20px',
            }}
            src={currentQuestion.questionImage}
          ></img>
        )}
        <Divider component='li' />
        {currentQuestion.question_type === 'MCQ' && (
          <>
            <RadioGroup>
              {currentQuestion.option_1 == 'Image' ? (
                <div>
                  <FormControlLabel
                    control={<Radio />}
                    onChange={() =>
                      handleOptionSelect(currentQuestion.option1Image, currentQuestion)
                    }
                    checked={selectedOptions[currentQuestionIndex] === currentQuestion.option1Image}
                    style={{
                      color:
                        selectedOptions[currentQuestionIndex] === currentQuestion.option1Image &&
                        currentQuestion.option1Image === currentQuestion.correctAnswerImage
                          ? 'green'
                          : selectedOptions[currentQuestionIndex] === currentQuestion.option1Image
                          ? 'red'
                          : 'black',
                    }}
                  />
                  {currentQuestion.option1Image && (
                    <img
                      alt='option1Image'
                      width={500}
                      height={350}
                      style={{
                        maxHeight: '500px',
                        maxWidth: '350px',
                        objectFit: 'contain',
                        marginTop: '5px',
                      }}
                      src={currentQuestion.option1Image}
                    />
                  )}
                </div>
              ) : (
                currentQuestion.option_1 && (
                  <div>
                    <FormControlLabel
                      value={currentQuestion.option_1}
                      control={<Radio />}
                      label={currentQuestion.option_1}
                      onChange={() => handleOptionSelect(currentQuestion.option_1, currentQuestion)}
                      checked={
                        selectedOptions[currentQuestionIndex] === currentQuestion[option1Key]
                      }
                      style={{
                        color:
                          selectedOptions[currentQuestionIndex] === currentQuestion[option1Key] &&
                          option1Key === currentQuestion.correct_answer
                            ? 'green'
                            : selectedOptions[currentQuestionIndex] ===
                                currentQuestion[option1Key] &&
                              option1Key !== currentQuestion.correct_answer
                            ? 'red'
                            : 'black',
                      }}
                    />
                    {currentQuestion.option1Image && (
                      <img
                        alt='option1Image'
                        width={500}
                        height={350}
                        style={{
                          maxHeight: '500px',
                          maxWidth: '350px',
                          objectFit: 'contain',
                          marginTop: '5px',
                        }}
                        src={currentQuestion.option1Image}
                      />
                    )}
                  </div>
                )
              )}

              {!currentQuestion.option_1 && (
                <div>
                  <FormControlLabel
                    control={<Radio />}
                    onChange={() =>
                      handleOptionSelect(currentQuestion.option1Image, currentQuestion)
                    }
                    checked={selectedOptions[currentQuestionIndex] === currentQuestion.option1Image}
                    style={{
                      color:
                        selectedOptions[currentQuestionIndex] === currentQuestion.option1Image &&
                        currentQuestion.option1Image === currentQuestion.correctAnswerImage
                          ? 'green'
                          : selectedOptions[currentQuestionIndex] === currentQuestion.option1Image
                          ? 'red'
                          : 'black',
                    }}
                  />
                  {currentQuestion.option1Image && (
                    <img
                      alt='option1Image'
                      width={500}
                      height={350}
                      style={{
                        maxHeight: '500px',
                        maxWidth: '350px',
                        objectFit: 'contain',
                        marginTop: '5px',
                      }}
                      src={currentQuestion.option1Image}
                    />
                  )}
                </div>
              )}

              {currentQuestion.option_2 == 'Image' ? (
                <div>
                  <FormControlLabel
                    control={<Radio />}
                    onChange={() =>
                      handleOptionSelect(currentQuestion.option2Image, currentQuestion)
                    }
                    checked={selectedOptions[currentQuestionIndex] === currentQuestion.option2Image}
                    style={{
                      color:
                        selectedOptions[currentQuestionIndex] === currentQuestion.option2Image &&
                        currentQuestion.option2Image === currentQuestion.correctAnswerImage
                          ? 'green'
                          : selectedOptions[currentQuestionIndex] === currentQuestion.option2Image
                          ? 'red'
                          : 'black',
                    }}
                  />
                  {currentQuestion.option2Image && (
                    <img
                      alt='option2Image'
                      width={500}
                      height={350}
                      style={{
                        maxHeight: '500px',
                        maxWidth: '350px',
                        objectFit: 'contain',
                        marginTop: '5px',
                      }}
                      src={currentQuestion.option2Image}
                    />
                  )}
                </div>
              ) : (
                currentQuestion.option_2 && (
                  <div>
                    <FormControlLabel
                      value={currentQuestion.option_2}
                      control={<Radio />}
                      label={currentQuestion.option_2}
                      onChange={() => handleOptionSelect(currentQuestion.option_2, currentQuestion)}
                      checked={
                        selectedOptions[currentQuestionIndex] === currentQuestion[option2Key]
                      }
                      style={{
                        color:
                          selectedOptions[currentQuestionIndex] === currentQuestion[option2Key] &&
                          option2Key === currentQuestion.correct_answer
                            ? 'green'
                            : selectedOptions[currentQuestionIndex] ===
                                currentQuestion[option2Key] &&
                              option2Key !== currentQuestion.correct_answer
                            ? 'red'
                            : 'black',
                      }}
                    />
                    {currentQuestion.option2Image && (
                      <img
                        alt='option2Image'
                        width={500}
                        height={350}
                        style={{
                          maxHeight: '500px',
                          maxWidth: '350px',
                          objectFit: 'contain',
                          marginTop: '5px',
                        }}
                        src={currentQuestion.option2Image}
                      />
                    )}
                  </div>
                )
              )}

              {!currentQuestion.option_2 && (
                <div>
                  <FormControlLabel
                    control={<Radio />}
                    onChange={() =>
                      handleOptionSelect(currentQuestion.option2Image, currentQuestion)
                    }
                    checked={selectedOptions[currentQuestionIndex] === currentQuestion.option2Image}
                    style={{
                      color:
                        selectedOptions[currentQuestionIndex] === currentQuestion.option2Image &&
                        currentQuestion.option2Image === currentQuestion.correctAnswerImage
                          ? 'green'
                          : selectedOptions[currentQuestionIndex] === currentQuestion.option2Image
                          ? 'red'
                          : 'black',
                    }}
                  />
                  {currentQuestion.option2Image && (
                    <img
                      alt='option2Image'
                      width={500}
                      height={350}
                      style={{
                        maxHeight: '500px',
                        maxWidth: '350px',
                        objectFit: 'contain',
                        marginTop: '5px',
                      }}
                      src={currentQuestion.option2Image}
                    />
                  )}
                </div>
              )}

              {currentQuestion.option_3 == 'Image' ? (
                <div>
                  <FormControlLabel
                    control={<Radio />}
                    // label={currentQuestion.option3Image}
                    onChange={() =>
                      handleOptionSelect(currentQuestion.option3Image, currentQuestion)
                    }
                    checked={selectedOptions[currentQuestionIndex] === currentQuestion.option3Image}
                    style={{
                      color:
                        selectedOptions[currentQuestionIndex] === currentQuestion.option3Image &&
                        currentQuestion.option3Image === currentQuestion.correctAnswerImage
                          ? 'green'
                          : selectedOptions[currentQuestionIndex] === currentQuestion.option3Image
                          ? 'red'
                          : 'black',
                    }}
                  />
                  {currentQuestion.option3Image && (
                    <img
                      alt='option3Image'
                      width={500}
                      height={350}
                      style={{
                        maxHeight: '500px',
                        maxWidth: '350px',
                        objectFit: 'contain',
                        marginTop: '5px',
                      }}
                      src={currentQuestion.option3Image}
                    />
                  )}
                </div>
              ) : (
                currentQuestion.option_3 && (
                  <div>
                    <FormControlLabel
                      value={currentQuestion.option_3}
                      control={<Radio />}
                      label={currentQuestion.option_3}
                      onChange={() => handleOptionSelect(currentQuestion.option_3)}
                      checked={
                        selectedOptions[currentQuestionIndex] === currentQuestion[option3Key]
                      }
                      style={{
                        color:
                          selectedOptions[currentQuestionIndex] === currentQuestion[option3Key] &&
                          option3Key === currentQuestion.correct_answer
                            ? 'green'
                            : selectedOptions[currentQuestionIndex] ===
                                currentQuestion[option3Key] &&
                              option3Key !== currentQuestion.correct_answer
                            ? 'red'
                            : 'black',
                      }}
                    />
                    {currentQuestion.option3Image && (
                      <img
                        alt='option3Image'
                        width={500}
                        height={350}
                        style={{
                          maxHeight: '500px',
                          maxWidth: '350px',
                          objectFit: 'contain',
                          marginTop: '5px',
                        }}
                        src={currentQuestion.option3Image}
                      />
                    )}
                  </div>
                )
              )}

              {!currentQuestion.option_3 && (
                <div>
                  <FormControlLabel
                    control={<Radio />}
                    // label={currentQuestion.option3Image}
                    onChange={() =>
                      handleOptionSelect(currentQuestion.option3Image, currentQuestion)
                    }
                    checked={selectedOptions[currentQuestionIndex] === currentQuestion.option3Image}
                    style={{
                      color:
                        selectedOptions[currentQuestionIndex] === currentQuestion.option3Image &&
                        currentQuestion.option3Image === currentQuestion.correctAnswerImage
                          ? 'green'
                          : selectedOptions[currentQuestionIndex] === currentQuestion.option3Image
                          ? 'red'
                          : 'black',
                    }}
                  />
                  {currentQuestion.option3Image && (
                    <img
                      alt='option3Image'
                      width={500}
                      height={350}
                      style={{
                        maxHeight: '500px',
                        maxWidth: '350px',
                        objectFit: 'contain',
                        marginTop: '5px',
                      }}
                      src={currentQuestion.option3Image}
                    />
                  )}
                </div>
              )}

              {currentQuestion.option_3 == 'Image' ? (
                <div>
                  <FormControlLabel
                    control={<Radio />}
                    onChange={() =>
                      handleOptionSelect(currentQuestion.option4Image, currentQuestion)
                    }
                    checked={selectedOptions[currentQuestionIndex] === currentQuestion.option4Image}
                    style={{
                      color:
                        selectedOptions[currentQuestionIndex] === currentQuestion.option4Image &&
                        currentQuestion.option4Image === currentQuestion.correctAnswerImage
                          ? 'green'
                          : selectedOptions[currentQuestionIndex] === currentQuestion.option4Image
                          ? 'red'
                          : 'black',
                    }}
                  />
                  {currentQuestion.option4Image && (
                    <img
                      alt='option4Image'
                      width={500}
                      height={350}
                      style={{
                        maxHeight: '500px',
                        maxWidth: '350px',
                        objectFit: 'contain',
                        marginTop: '5px',
                      }}
                      src={currentQuestion.option4Image}
                    />
                  )}
                </div>
              ) : (
                currentQuestion.option_4 && (
                  <div>
                    <FormControlLabel
                      value={currentQuestion.option_4}
                      control={<Radio />}
                      label={currentQuestion.option_4}
                      onChange={() => handleOptionSelect(currentQuestion.option_4)}
                      checked={
                        selectedOptions[currentQuestionIndex] === currentQuestion[option4Key]
                      }
                      style={{
                        color:
                          selectedOptions[currentQuestionIndex] === currentQuestion[option4Key] &&
                          option4Key === currentQuestion.correct_answer
                            ? 'green'
                            : selectedOptions[currentQuestionIndex] ===
                                currentQuestion[option4Key] &&
                              option4Key !== currentQuestion.correct_answer
                            ? 'red'
                            : 'black',
                      }}
                    />
                    {currentQuestion.option4Image && (
                      <img
                        alt='option4Image'
                        width={500}
                        height={350}
                        style={{
                          maxHeight: '500px',
                          maxWidth: '350px',
                          objectFit: 'contain',
                          marginTop: '5px',
                        }}
                        src={currentQuestion.option4Image}
                      />
                    )}
                  </div>
                )
              )}

              {!currentQuestion.option_4 && (
                <div>
                  <FormControlLabel
                    control={<Radio />}
                    onChange={() =>
                      handleOptionSelect(currentQuestion.option4Image, currentQuestion)
                    }
                    checked={selectedOptions[currentQuestionIndex] === currentQuestion.option4Image}
                    style={{
                      color:
                        selectedOptions[currentQuestionIndex] === currentQuestion.option4Image &&
                        currentQuestion.option4Image === currentQuestion.correctAnswerImage
                          ? 'green'
                          : selectedOptions[currentQuestionIndex] === currentQuestion.option4Image
                          ? 'red'
                          : 'black',
                    }}
                  />
                  {currentQuestion.option4Image && (
                    <img
                      alt='option4Image'
                      width={500}
                      height={350}
                      style={{
                        maxHeight: '500px',
                        maxWidth: '350px',
                        objectFit: 'contain',
                        marginTop: '5px',
                      }}
                      src={currentQuestion.option4Image}
                    />
                  )}
                </div>
              )}
            </RadioGroup>
            {selectedOptions[currentQuestionIndex] && (
              <>
                <div>
                  <b>Result</b>:{' '}
                  {(selectedOptions[currentQuestionIndex] === currentQuestion[option4Key] &&
                    option4Key === currentQuestion.correct_answer) ||
                  (selectedOptions[currentQuestionIndex] === currentQuestion[option3Key] &&
                    option3Key === currentQuestion.correct_answer) ||
                  (selectedOptions[currentQuestionIndex] === currentQuestion[option2Key] &&
                    option2Key === currentQuestion.correct_answer) ||
                  (selectedOptions[currentQuestionIndex] === currentQuestion[option1Key] &&
                    option1Key === currentQuestion.correct_answer) ||
                  selectedOptions[currentQuestionIndex] === currentQuestion.correctAnswerImage
                    ? 'Correct'
                    : 'Incorrect'}
                  {selectedOptions[currentQuestionIndex] !== currentQuestion.correct_answer && (
                    <>
                      <p>
                        <b>Correct Answer</b>: {currentQuestion.correct_answer}
                      </p>
                      <div>
                        {currentQuestion.correctAnswerImage && (
                          <img
                            alt='correctAnswerImage'
                            width={500}
                            height={350}
                            style={{
                              maxHeight: '500px',
                              maxWidth: '350px',
                              objectFit: 'contain',
                              marginTop: '5px',
                            }}
                            src={currentQuestion.correctAnswerImage}
                          />
                        )}
                      </div>
                    </>
                  )}
                  {selectedOptions[currentQuestionIndex] !== currentQuestion.feedback && (
                    <>
                      <Typography variant='body1' style={{ color: 'red' }}>
                        FeedBack:
                      </Typography>
                      <div>
                        <Typography className='fw-bold badge bg-success text-wrap'>
                          {currentQuestion.feedback}
                          <b
                            style={{ color: '#a83269' }}
                            onClick={() => handleGptClick(currentQuestion.questions)}
                          >
                            {' '}
                            ...Learn more
                          </b>
                        </Typography>
                        <div>
                          <div>
                            {currentQuestion.feedbackImage && (
                              <img
                                alt='feedbackImage'
                                width={500}
                                height={350}
                                style={{
                                  maxHeight: '500px',
                                  maxWidth: '350px',
                                  objectFit: 'contain',
                                  marginTop: '20px',
                                }}
                                src={currentQuestion.feedbackImage}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </>
        )}

        {currentQuestion.question_type === 'Checkbox' && (
          <>
            <FormGroup>
              <FormControlLabel
                label={currentQuestion.option_1}
                control={<Checkbox />}
                onChange={() => handleOptionSelect(currentQuestion.option_1)}
                checked={selectedOptions[currentQuestionIndex]?.includes(currentQuestion.option_1)}
                style={{
                  color: currentQuestion.correct_answer?.includes(currentQuestion.option_1)
                    ? selectedOptions[currentQuestionIndex]?.includes(currentQuestion.option_1)
                      ? 'green'
                      : 'black'
                    : selectedOptions[currentQuestionIndex]?.includes(currentQuestion.option_1)
                    ? 'red'
                    : 'black',
                }}
              />
              <FormControlLabel
                label={currentQuestion.option_2}
                control={<Checkbox />}
                onChange={() => handleOptionSelect(currentQuestion.option_2)}
                checked={selectedOptions[currentQuestionIndex]?.includes(currentQuestion.option_2)}
                style={{
                  color: currentQuestion.correct_answer?.includes(currentQuestion.option_2)
                    ? selectedOptions[currentQuestionIndex]?.includes(currentQuestion.option_2)
                      ? 'green'
                      : 'black'
                    : selectedOptions[currentQuestionIndex]?.includes(currentQuestion.option_2)
                    ? 'red'
                    : 'black',
                }}
              />
              {currentQuestion.option_3 && (
                <FormControlLabel
                  control={<Checkbox />}
                  label={currentQuestion.option_3}
                  onChange={() => handleOptionSelect(currentQuestion.option_3)}
                  checked={selectedOptions[currentQuestionIndex]?.includes(
                    currentQuestion.option_3,
                  )}
                  style={{
                    color: currentQuestion.correct_answer?.includes(currentQuestion.option_3)
                      ? selectedOptions[currentQuestionIndex]?.includes(currentQuestion.option_3)
                        ? 'green'
                        : 'black'
                      : selectedOptions[currentQuestionIndex]?.includes(currentQuestion.option_3)
                      ? 'red'
                      : 'black',
                  }}
                />
              )}
              {currentQuestion.option_4 && (
                <FormControlLabel
                  control={<Checkbox />}
                  label={currentQuestion.option_4}
                  onChange={() => handleOptionSelect(currentQuestion.option_4)}
                  checked={selectedOptions[currentQuestionIndex]?.includes(
                    currentQuestion.option_4,
                  )}
                  style={{
                    color: currentQuestion.correct_answer?.includes(currentQuestion.option_4)
                      ? selectedOptions[currentQuestionIndex]?.includes(currentQuestion.option_4)
                        ? 'green'
                        : 'black'
                      : selectedOptions[currentQuestionIndex]?.includes(currentQuestion.option_4)
                      ? 'red'
                      : 'black',
                  }}
                />
              )}
            </FormGroup>

            {/* {selectedOptions[currentQuestionIndex] &&
              Array.isArray(selectedOptions[currentQuestionIndex]) && (
                <>
                  <div>
                    <p>
                      Result:{' '}
                      {selectedOptions[currentQuestionIndex].some((option) =>
                        currentQuestion.correct_answer?.includes(option),
                      )
                        ? 'Correct'
                        : 'Incorrect'}
                    </p>
                    {Array.isArray(currentQuestion.correct_answer) &&
                      selectedOptions[currentQuestionIndex].some(
                        (option) => !currentQuestion.correct_answer?.includes(option),
                      ) && <p>Correct Answers: {currentQuestion.correct_answer?.join(', ')}</p>}
                    {Array.isArray(currentQuestion.feedback) &&
                      selectedOptions[currentQuestionIndex].some(
                        (option) => !currentQuestion.feedback?.includes(option),
                      ) && <p>Feedback: {currentQuestion.feedback?.join(', ')}</p>}
                  </div>
                </>
              )} */}
          </>
        )}

        <Button
          variant='contained'
          onClick={handlePreviousQuestion}
          sx={{ margin: '10px' }}
          disabled={currentQuestionIndex === 0} // Disable button when at first question
        >
          Previous
        </Button>
        {currentQuestionIndex === quiz.data.quizDetails.length - 1 && (
          <button type='button' className='btn btn-primary' onClick={handleSubmit}>
            Submit Your Answer
          </button>
        )}
        {currentQuestionIndex !== quiz.data.quizDetails.length - 1 && (
          <Button variant='contained' onClick={handleNextQuestion} sx={{ margin: '10px' }}>
            Next
          </Button>
        )}
      </div>
    );
  };
  const progress = Math.round((currentQuestionIndex / quiz.data.quizDetails.length) * 100);

  return (
    <div className='py-4 my-4'>
      <LinearChartProgress progress={progress} />
      <Typography variant='caption' align='center'>{`${progress}% Completed`}</Typography>{' '}
      <div className='d-flex justify-content-between' style={{ margin: '50px' }}>
        <div>
          <div disabled className=' btn btn-success border-rounded mx-2'>
            Total Questions:{quiz.data.quizDetails.length}
          </div>
          <div disabled className=' btn btn-warning border-rounded mx-2'>
            Correct:{calculateCorrectAnswers()}
          </div>
          <div disabled className=' btn btn-danger border-rounded mx-2'>
            Wrong:{calculateWrongAnswers()}
          </div>
        </div>
      </div>
      {quizStarted ? (
        <>
          <Card variant='outlined' sx={{ marginLeft: '40px' }}>
            <CardContent>{renderQuestion()}</CardContent>
          </Card>

          {showQuizSummary && (
            <>
              <div className='d-flex justify-content-between' style={{ margin: '50px' }}>
                <div>
                  {' '}
                  {quiz.data.quizDetails.length > 0 && (
                    <QuizSummaryDetails
                      quizDetails={quiz.data.quizDetails}
                      selectedOption={selectedOption}
                    />
                  )}
                </div>
                <div>
                  {' '}
                  <QuizSummary
                    totalQuestions={quiz.data.quizDetails.length}
                    wrongAnswers={calculateWrongAnswers()}
                    rightAnswers={calculateCorrectAnswers()}
                    totalMarks={fullMarks}
                    obtainedMarks={calculateObtainedMarks()}
                  />
                  <AnswerDistribution chartData={chartData} />
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <div style={{ margin: '50px' }}>
            <Typography variant='h4' gutterBottom>
              {quiz.data.quizDetails && quiz.data.quizDetails[0]?.quiz_name
                ? quiz.data.quizDetails[0]?.quiz_name
                : ''}
            </Typography>

            <img
              alt='Cbse/G10/Chapter-9/9.1 REFLECTION OF LIGHT'
              src='https://media.proprofs.com/images/QM/user_images/1452023/1480324711.jpg'
            ></img>
          </div>
          <div>
            <button className='btn btn-primary' onClick={startQuiz} style={{ marginLeft: '50px' }}>
              Start
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default QuizWrapper;
