import PropTypes from 'prop-types';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, CardHeader, Box } from '@mui/material';
import { useChart } from '../../../components/chart';

AppWebsiteVisits.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function AppWebsiteVisits({ title, subheader, chartLabels, chartData, ...other }) {
  if (!chartData) {
    return null;
  }
  const colors =
    chartData && chartData.length && chartData ? generateRandomColors(chartData.length) : 0 || [];

  const chartOptions = useChart({
    plotOptions: { bar: { columnWidth: '16%' } },
    fill: { type: chartData.map((i) => i.fill) },
    labels: chartLabels,
    xaxis: {
      type: 'category',
      labels: {
        rotate: 0,
      },
    },

    yaxis: { max: 100 },
    tooltip: {
      shared: true,
      intersect: false,
      x: {
        show: true,
        formatter: undefined,
      },
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} % `;
          }
          return y;
        },
      },
    },
  });

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />
      <Box sx={{ p: 3, pb: 1 }} dir='ltr'>
        <ReactApexChart
          type='bar'
          series={
            chartData &&
            chartData.length > 0 &&
            chartData.map((series, index) => ({
              ...series,
              color: colors[index],
            }))
          }
          options={chartOptions}
          height={364}
        />
      </Box>
    </Card>
  );
}

function generateRandomColors(count) {
  const colors = [];
  for (let i = 0; i < count; i++) {
    const color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    colors.push(color);
  }
  return colors;
}
