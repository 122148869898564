import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box, Grid } from '@mui/material';
import ProductCardBG from '../../../_assets/images/productCardBg.jpg';
import ProductCardBGHover from '../../../_assets/images/productCardBgHover.png';

export default function ProductCard({ onCategorySelect, selectedCategory }) {
  const categories = [
    { id: 'CLASS_10', label: 'CLASS 10', subject: 'SCIENCE & MATH' },
    { id: 'CLASS_9', label: 'CLASS 9', subject: 'SCIENCE & MATH' },
    { id: 'CLASS_8', label: 'CLASS 8', subject: 'SCIENCE & MATH' },
    { id: 'NEET_PREPARATION', label: 'NEET', subject: 'PREPARATION' },
  ];

  return (
    <Box
      sx={{
        margin: {
          xs: '25px', // 80% width on mobile screens
          md: '50px', // 95% width on desktop screens
        },
        width: {
          xs: '85%', // 80% width on mobile screens
          md: '95%', // 95% width on desktop screens
        },
      }}
    >
      <Grid
        container
        spacing={{
          xs: 0, // 5 spacing on mobile screens
          md: 20, // 20 spacing on desktop screens
        }}
        justifyContent='center'
      >
        {categories.map((category) => (
          <Grid item xs={9} sm={6} md={3} key={category.id}>
            <Card
              sx={{
                borderRadius: '16px',
                backgroundImage:
                  selectedCategory === category.id
                    ? `url(${ProductCardBGHover})`
                    : `url(${ProductCardBG})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                color: selectedCategory === category.id ? '#fe6f61' : '#808080',
                border: '1px solid #fff',
                cursor: 'pointer',
                transform: selectedCategory === category.id ? 'scale(1.05)' : 'none',
                transition: 'transform 0.3s',
                width: '100%',
                height: '75%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '&:hover': {
                  backgroundImage: `url(${ProductCardBGHover})`,
                },
              }}
              onClick={() => onCategorySelect(category.id)}
            >
              <CardContent sx={{ textAlign: 'center' }}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: 14,
                      md: 16,
                    },
                  }}
                >
                  {category.label}
                </Typography>
                <Typography
                  sx={{
                    fontSize: {
                      xs: 14,
                      md: 16,
                    },
                  }}
                >
                  {category.subject}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
