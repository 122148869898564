import React from 'react';
import { splitFromFirstSpace } from '_helpers';
import { Button, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper } from '@mui/material';

export const ReportTable = ({TestData, onHandleClick}) => {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Test ID</TableCell>
              <TableCell align="center">Quiz Name</TableCell>
              <TableCell align="center">Attempt Date</TableCell>
              <TableCell align="center">Obtained Marks</TableCell>
              <TableCell align="center">Total Correct Answers</TableCell>
              <TableCell align="center">Total Wrong Answers</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {TestData.map((data, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">{data.test_id}</TableCell>
                <TableCell align="center">{splitFromFirstSpace(data.quiz_name)}</TableCell>
                <TableCell align="center">{new Date(data.attempt_date).toLocaleString()}</TableCell>
                <TableCell align="center">{data.user_obtained_marks}</TableCell>
                <TableCell align="center">{data.user_totalcorrect_answers}</TableCell>
                <TableCell align="center">{data.user_totalwrong_answers}</TableCell>
                <TableCell align="center" ><Button onClick={()=>onHandleClick(data.quiz_name, data.quiz_title)}>HELP ME!</Button></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )}
    