import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Slide } from '@mui/material';
import { checkEmptyData } from '_helpers';
import { ProgressBar } from './ProgressBar.jsx';

export { Figure };

function Figure({ data }) {
  let { CSS, HTML, JS, subsection_id } = data[0];
  const [resetKey, setResetKey] = useState(0);
  const [resetCounter, setResetCounter] = useState(0);
  const [status, setStatus] = useState(true);
  const htmlRef = useRef(null);

  useEffect(() => {
    let res = checkEmptyData({ CSS, HTML, JS });
    setStatus(res);
    handleResetClick();
    const styleElement = document.createElement('style');
    styleElement.setAttribute('data-name', name);
    styleElement.textContent = CSS;
    document.head.appendChild(styleElement);
    return () => {
      const styleElement = document.querySelector(`style[data-name="${name}"]`);
      if (styleElement) {
        document.head.removeChild(styleElement);
      }
    };
  }, [CSS]);

  useEffect(() => {
    const script = document.createElement('script');
    script.textContent = `
      (function() {
        ${JS}
      })();
    `;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [JS, resetKey]);

  const handleResetClick = () => {
    setResetKey((prevResetKey) => prevResetKey + 1);
    setResetCounter((prevResetCounter) => prevResetCounter + 1);
    window.stopAnimation();
  };

  const handleComponentDestroy = () => {
    const styleElement = document.querySelector(`style[data-name="${name}"]`);
    if (styleElement) {
      document.head.removeChild(styleElement);
    }
  };

  useEffect(() => {
    return () => {
      handleComponentDestroy();
    };
  }, []);

  return (
    <>
      <ProgressBar htmlRef={htmlRef} key={`progress-${resetCounter}`} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
        key={`box-${resetCounter}`}
      >
        <Slide direction='down' in={true} timeout={500}>
          <div alt='index'>
            <div className=''>{subsection_id}</div>
            <div
              ref={htmlRef}
              dangerouslySetInnerHTML={{ __html: HTML }}
              style={{ height: '100%' }}
            ></div>
          </div>
        </Slide>

        {status && (
          <div>
            <button id='resetButton-02' className='btn btn-primary' onClick={handleResetClick}>
              Reset
            </button>
          </div>
        )}
      </Box>
    </>
  );
}

Figure.propTypes = {
  data: PropTypes.any.isRequired,
  info: PropTypes.any.isRequired,
};
