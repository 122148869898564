import { fetchWrapper } from '_helpers';

export const getAtoms = (value) => {
  const req = { name: value };
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  let result = fetchWrapper.post(`${baseUrl}/api/v1/chemEquation`, req);
  return result;
};

export const getMolecules = async (value) => {
  const req = { equation: value };
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  const result = await fetchWrapper.post(`${baseUrl}/api/v1/getAtoms`, req);
  return result;
};

export const convertToSubscript = (input) => {
  const subscriptOffset = 8320;

  return input.replace(/\d/g, (match) => {
    const codePoint = parseInt(match) + subscriptOffset;
    return String.fromCodePoint(codePoint);
  });
};

export const convertObjToStr = (obj) => {
  return Object.entries(obj)
    .map(([key, value]) => (value !== 1 ? key + value : key))
    .join('');
};

// utils.js

export function getMarginTop(type, ...arr) {
  let highest = Math.max(...arr)
  if (type !== 'text') {
    if  (highest <=2) {
      return '60%';
    } else if (highest <=3) {
      return '70%';
    } else if (highest <=4) {
      return '90%';
    } else if (highest <=5) {
      return '75%';
    } else {
      return '30%';
    }
  } else {
    return '10%';
  }
}
