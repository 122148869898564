import React, { useEffect } from 'react';
import './style.css';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
export { ChapterDisplay };

function ChapterDisplay({ data, askGPT }) {
  const navigate = useNavigate();

  if (!data) {
    return (
      <div className='alert alert-danger' role='alert' style={{ paddingLeft: '1vw' }}>
        Please select Chapter
      </div>
    );
  }
  const handleDynamicClick = (event) => {
    const clickedElement = event.target;
    const action = clickedElement.getAttribute('data-action');

    if (action && action.startsWith('askGPT(')) {
      // for calling chatGPT
      const dynamicData = action.slice(8, -2).replace('-', ' ');
      askGPT(dynamicData);
    } else if (action && action.startsWith('link(')) {
      const dynamicData = action.slice(6, -2).replace('-', ' ');
      navigate(dynamicData);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleDynamicClick);

    return () => {
      document.removeEventListener('click', handleDynamicClick);
    };
  }, []);

  return (
    <div className='container-fluid'>
      <div className='m-3'>
        <div className='h3'>{data.chapter_id + '. ' + data.name}</div>
        {data.introduction ? (
          <div
            dangerouslySetInnerHTML={{ __html: data.introduction }}
            className='h6 intro'
            style={{ height: '100%', background: '#ccf2ff' }}
          ></div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

ChapterDisplay.propTypes = {
  data: PropTypes.any.isRequired,
  askGPT: PropTypes.func,
};
