import React, { useState } from 'react';
import {
  Box,
  Typography,
  Divider,
  Grid,
  FormControlLabel,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Container,
} from '@mui/material';
import { ArrowBack, CheckBox } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { fetchWrapper } from '_helpers';
import { Country, State, City } from 'country-state-city';
import { Formik, Form, ErrorMessage, Field } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import paymentBackground from '_assets/images/paymentBackground.png';
import paymentPart from '_assets/images/paymentPart.svg';
import paymenticon from '_assets/images/paymenticon.svg';
import { TextField as MuiTextField, Select as MuiSelect } from '@mui/material';
import { usePayment } from './Product/PaymentContext';
import { useSelector } from 'react-redux';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email format')
    .test('email-required', 'Email is Required', function (value) {
      return !!this.options.context.user?.relatedData?.student_email || !!value;
    }),
  parentName: Yup.string().test('parentName-required', 'Parent Name is Required', function (value) {
    return !!this.options.context.user?.relatedData?.parent_name || !!value;
  }),
  studentName: Yup.string().test(
    'studentName-required',
    'Student Name is Required',
    function (value) {
      return !!this.options.context.user?.relatedData?.student_name || !!value;
    },
  ),
  pinCode: Yup.string().test('pinCode-required', 'Pin Code is Required', function (value) {
    return !!this.options.context.user?.relatedData?.pincode || !!value;
  }),
  address: Yup.string().test('address-required', 'Address is Required', function (value) {
    return !!this.options.context.user?.relatedData?.address || !!value;
  }),
  mobileNumber: Yup.string().test('mobileNumber-required', 'Mobile is Required', function (value) {
    return !!this.options.context.user?.relatedData?.student_mobile || !!value;
  }),
});

const PaymentPage = () => {
  const { paymentData } = usePayment();
  let title = paymentData.title;
  let price = paymentData.price;
  let subtitle = paymentData.subtitle;
  let planValue = paymentData.plan_value;
  let planInterval = paymentData.planInterval;
  const [checked, setChecked] = useState(false);

  const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;
  const navigate = useNavigate();
  const user = useSelector((x) => x.auth.value);
  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  // const { user } = location.state || {};
  console.log(user, 'formData', user?.relatedData?.student_email);
  const handleCountryChange = (event) => {
    const newCountry = event.target.value;
    setCountry(newCountry);
    setState('');
    setCity('');
  };

  const handleStateChange = (event) => {
    setState(event.target.value);
    setCity(''); // Reset city when state changes
  };

  const handleCityChange = (event) => {
    setCity(event.target.value);
  };

  if (!paymentData) return <div>No payment data available.</div>;

  const checkoutHandler = async (amount, values) => {
    try {
      // Fetch the Razorpay key from your backend
      const response = await fetchWrapper.get(`${baseUrl}/getKey`);

      if (!response.key) {
        throw new Error('Key not found in response');
      }

      const { key } = response;

      // Create the order on your backend
      const data = await fetchWrapper.post(`${baseUrl}/checkouts`, { amount });

      if (!data.order) {
        throw new Error('Order not found in response');
      }

      const { order } = data;

      const countryCode = country; // Country code should be in `values.country`
      const stateCode = state; // State code should be in `values.state`

      // Fetch the country data based on the country code
      const countryData = Country.getAllCountries().find(
        (country) => country.isoCode === countryCode,
      );
      const countryName = countryData ? countryData.name : '';

      // Fetch the state data based on the state code
      const stateData = countryData
        ? State.getStatesOfCountry(countryCode).find((state) => state.isoCode === stateCode)
        : null;
      const stateName = stateData ? stateData.name : '';
      // Prepare the data for the Razorpay options
      const formData = {
        email: values.email,
        parentName: values.parentName,
        studentName: values.studentName,
        country: countryName,
        state: stateName,
        city: city,
        pinCode: values.pinCode,
        address: values.address,
        mobileNumber: values.mobileNumber,
        amount: order.amount,
        orderId: order.id,
        plan_amount: price,
        plan_courses: planValue,
        planValue: planValue,
        planInterval: planInterval,
      };

      // Setup Razorpay options
      const options = {
        key,
        amount: order.amount,
        currency: 'INR',
        name: 'ClassRoom Ai',
        description: 'Tutorial of RazorPay',
        image: 'https://www.classroom-ai.com/static/media/classroom-ai.c400a11fb27d36432328.gif',
        order_id: order.id,
        prefill: {
          name: values.parentName,
          email: values.email,
          contact: values.mobileNumber,
        },

        notes: {
          address: 'Razorpay Corporate Office',
        },
        theme: {
          color: '#092141',
          width: '100vh',
        },
        handler: async function (response) {
          // Combine formData and response data for verification
          const verificationData = {
            ...formData,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
          };

          // Validate the payment on your server
          const verifyResponse = await fetchWrapper.post(
            `${baseUrl}/paymentVerification`,
            verificationData,
          );

          console.log('Payment verification response:', verifyResponse);
          // https://www.classroom-ai.com/PaymentSuccess?reference=pay_Ot269Zhyq9wXAi&plan_courses=NEET_PREPARATION
          navigate(`/PaymentSuccess?plan_courses=${verificationData.planValue}`);
        },
      };

      // Initialize Razorpay with the options
      const razor = new window.Razorpay(options);

      // Add event listener for payment failure
      razor.on('payment.failed', function (response) {
        console.error('Payment failed:', response);
      });

      razor.open();
    } catch (error) {
      console.error('Error during checkout:', error);
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      // Then, call checkoutHandler to initiate the payment
      await checkoutHandler(price, values); // Replace 10 with the actual amount
    } catch (error) {
      console.error('Error during form submission or checkout:', error);
    }
    setSubmitting(false);
  };
  const handleBack = () => {
    navigate('/account/signUp'); // This will navigate back to the previous route.
  };

  const CustomTextField = styled(MuiTextField)(() => ({
    backgroundColor:
      'linear-gradient(to right, rgb(18 39 67) 0%, rgb(20 36 57) 50%, rgb(44 54 67) 100%) !important',
    '& .MuiOutlinedInput-root': {
      backgroundColor:
        'linear-gradient(to right, rgb(18 39 67) 0%, rgb(20 36 57) 50%, rgb(44 54 67) 100%) !important',
      '& fieldset': {
        borderColor: '#736d604d',
        borderRadius: '15px',
      },
      '&:hover fieldset': {
        borderColor: '#736d604d',
      },
    },
    '& .MuiInputLabel-root': {
      color: '#fff',
    },
    '& .MuiInputBase-input': {
      color: '#fff',
    },
    '& input:-webkit-autofill': {
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': '#ffffff !important',
    },
  }));
  const CustomSelect = styled(MuiSelect)(() => ({
    backgroundColor:
      'linear-gradient(to right, rgb(18 39 67) 0%, rgb(20 36 57) 50%, rgb(44 54 67) 100%) !important',
    borderColor: '#736d604d !important',
    border: '1px solid white', // Direct border style for the root
    borderRadius: '15px',
    color: '#fff',
    '& .MuiSelect-icon': {
      color: '#fff',
    },
    '& .MuiSelect-root': {
      borderColor: '#736d604d !important',
      border: '2px solid white',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#736d604d !important',
      },
      '&:hover fieldset': {
        borderColor: '#736d604d !important',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#ffffff !important', // Set border color to white when focused
      },
    },
    '& .MuiInputBase-input': {
      color: '#fff',
    },
    // Autofill styles
    '& input:-webkit-autofill': {
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': '#ffffff !important',
    },
  }));

  const StyledSection = styled('div')(({ theme }) => ({
    width: '50%',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'space-between', // Ensures content is spaced between top and bottom
    alignItems: 'center', // Center content horizontally
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      marginTop: '2vh',
      width: '100%',
      height: '100%',
    },
  }));

  const UpperText = styled(Typography)(({ theme }) => ({
    position: 'absolute',
    top: '25%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#ff6363',
    textAlign: 'center',
    fontSize: '1.7rem', // Default font size
    fontWeight: 'bold',
    zIndex: 1,
    [theme.breakpoints.down('md')]: {
      // Tablet
      fontSize: '13px',
      top: '25%',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '9px', // Font size for small screens
      top: '25%',
      left: '50%',
    },
    '& span': {
      fontSize: '2rem', // Default font size for span
      color: '#fff',
      [theme.breakpoints.down('md')]: {
        // Mobile
        fontSize: '15px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '11px', // Font size for small screens
      },
    },
  }));
  const LowerText = styled(Typography)(({ theme }) => ({
    position: 'absolute',
    bottom: '20%',
    left: '50%',
    transform: 'translate(-50%, 50%)',
    color: '#ff6363',
    fontSize: '1.7rem', // Default font size
    textAlign: 'center',
    fontWeight: 'bold',
    zIndex: 1,
    [theme.breakpoints.down('md')]: {
      // Tablet
      fontSize: '13px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '8px', // Font size for small screens
      bottom: '15%',
      left: '50%',
    },
    '& span': {
      fontSize: '2rem', // Default font size for span
      color: '#fff',
      [theme.breakpoints.down('md')]: {
        // Tablet
        fontSize: '15px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '11px', // Font size for small screens
      },
    },
  }));

  return (
    <Box
      sx={{
        display: 'flex',
        backgroundImage: `url(${paymentBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '92vh',
        overflow: 'hidden',
        '@media (max-width: 600px)': {
          overflow: 'hidden',
          flexDirection: 'column',
          margin: '0 0px',
        },
      }}
    >
      <IconButton
        aria-label='back'
        size='large'
        onClick={handleBack}
        sx={{
          position: 'fixed',
          top: 70,
          left: 10,
          zIndex: 1000,
          backgroundColor: 'transparent',
          color: '#ffffff',
        }}
      >
        <ArrowBack fontSize='inherit' style={{ color: '#fff', marginTop: '0' }} />
      </IconButton>
      <StyledSection>
        <UpperText variant='h6' component='div'>
          START YOUR LEARNING JOURNEY
          <br></br>
          WITH <span>CLASSROOM AI!</span>
        </UpperText>
        <img
          src={paymentPart}
          width={'100%'}
          height={'95%'}
          alt='login'
          onError={(e) => {
            console.warn('Error loading image:', e);
            e.target.style.display = 'none';
          }}
          style={{ marginTop: '45px' }}
        />
        <LowerText variant='h6' component='div'>
          DIVE INTO <span>INTERACTIVE </span>NCERT
          <br></br>
          <span>SCIENCE AND MATH </span> & <span>NEET </span>
          <br></br>
          <span>PREPARATION </span>TODAY!
        </LowerText>
      </StyledSection>

      <Container
        maxWidth='sm'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          padding: '10px !important',
          border: '1px solid #736d604d',
          background:
            'linear-gradient(to right, rgb(18 39 67) 0%, rgb(20 36 57) 50%, rgb(44 54 67) 100%)',

          borderRadius: '35px',
          margin: '30px 40px 25px auto!important',
        }}
      >
        <Box
          sx={{
            maxWidth: 1000,
            margin: 'auto',
            padding: '1px 30px !important',
            overflowY: 'auto',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          <Formik
            initialValues={{
              email: user?.relatedData?.student_email || '',
              parentName: user?.relatedData?.parent_name || '',
              studentName: user?.relatedData?.student_name || '',
              country: user?.relatedData?.student_country || '',
              state: user?.relatedData?.state || '',
              city: user?.relatedData?.city || '',
              pinCode: user?.relatedData?.pincode || '',
              address: user?.relatedData?.address || '',
              mobileNumber: user?.relatedData?.student_mobile || '',
              discountCode: '', // Add discount code if applicable
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            context={{ user }}
          >
            {({ values, handleChange, handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <Box display='flex'>
                  <Divider />
                  <Grid container spacing={6} marginTop='1px'>
                    {/* Left part: Contact, Delivery, and Payment */}

                    <Grid item sx={{ paddingTop: '10px !important' }}>
                      <div>
                        <Typography
                          variant='h5'
                          component='div'
                          gutterBottom
                          sx={{
                            color: '#fff',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginBottom: '20px',
                            bgcolor: 'Green',
                          }}
                        >
                          Selected Course :{subtitle}-{title}
                        </Typography>
                        <Typography
                          variant='h5'
                          component='div'
                          gutterBottom
                          sx={{
                            color: '#fff',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                          }}
                        >
                          Contact Information
                        </Typography>
                        <Field
                          as={CustomTextField}
                          fullWidth
                          value={values.email}
                          label='Email'
                          variant='outlined'
                          type='email'
                          name='email'
                        />
                        <ErrorMessage name='email' component='div' className='error' />
                        <FormControlLabel
                          control={
                            <CheckBox
                              checked={checked}
                              onChange={handleCheckboxChange}
                              name='terms'
                            />
                          }
                          label='Email me with news and offers'
                          sx={{
                            marginTop: 2,
                            marginBottom: 2,
                            color: '#fff',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            marginLeft: '2px',
                          }}
                        />

                        <Typography
                          variant='h5'
                          component='div'
                          gutterBottom
                          sx={{
                            color: '#fff',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                          }}
                        >
                          Delivery Information
                        </Typography>

                        <Grid container spacing={2} marginBottom='20px'>
                          <Grid item xs={12} sm={6}>
                            <Field
                              as={CustomTextField}
                              name='parentName'
                              fullWidth
                              label='Parent Name'
                              variant='outlined'
                            />
                            <ErrorMessage name='parentName' component='div' className='error' />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Field
                              as={CustomTextField}
                              fullWidth
                              label='Student Name'
                              name='studentName'
                              variant='outlined'
                              value={values.studentName}
                              // value={values.studentName}
                            />
                            <ErrorMessage name='studentName' component='div' className='error' />
                          </Grid>
                        </Grid>
                        <FormControl fullWidth margin='normal' sx={{ marginTop: '2px' }}>
                          <InputLabel id='country-select-label' sx={{ color: '#fff' }}>
                            Country*
                          </InputLabel>
                          <CustomSelect
                            labelId='country-select-label'
                            id='country-select'
                            // value={country}
                            value={values.country}
                            // value={values.country}
                            label='Country'
                            onChange={(event) => {
                              handleChange(event);
                              handleCountryChange(event);
                            }}
                          >
                            <MenuItem value=''>
                              <em>None</em>
                            </MenuItem>
                            {Country.getAllCountries().map((country) => (
                              <MenuItem key={country.isoCode} value={country.isoCode}>
                                {country.name}
                              </MenuItem>
                            ))}
                          </CustomSelect>
                          <ErrorMessage name='country' component='div' className='error' />
                        </FormControl>

                        <Grid container spacing={2} marginTop='5px'>
                          <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                              <InputLabel id='state-select-label' sx={{ color: '#fff' }}>
                                State*
                              </InputLabel>
                              <CustomSelect
                                labelId='state-select-label'
                                id='state-select'
                                // value={state}
                                value={values?.state}
                                label='State'
                                onChange={(event) => {
                                  handleChange(event);
                                  handleStateChange(event);
                                }}
                                disabled={!country} // Disable if no country is selected
                              >
                                <MenuItem value=''>
                                  <em>None</em>
                                </MenuItem>
                                {State.getStatesOfCountry(country).map((state) => (
                                  <MenuItem key={state.isoCode} value={state.isoCode}>
                                    {state.name}
                                  </MenuItem>
                                ))}
                              </CustomSelect>
                              <ErrorMessage name='state' component='div' className='error' />
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                              <InputLabel id='city-select-label' sx={{ color: '#fff' }}>
                                City*
                              </InputLabel>
                              <CustomSelect
                                labelId='city-select-label'
                                id='city-select'
                                // value={city}

                                label='City'
                                onChange={(event) => {
                                  handleChange(event);
                                  handleCityChange(event);
                                }}
                                value={values?.city}
                                disabled={!state} // Disable if no state is selected
                              >
                                <MenuItem value=''>
                                  <em>None</em>
                                </MenuItem>
                                {City.getCitiesOfState(country, state).map((city) => (
                                  <MenuItem key={city.name} value={city.name}>
                                    {city.name}
                                  </MenuItem>
                                ))}
                              </CustomSelect>
                              <ErrorMessage name='city' component='div' className='error' />
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                              <Field
                                as={CustomTextField}
                                fullWidth
                                label='Pin Code'
                                name='pinCode'
                                variant='outlined'
                                type='text'
                                value={values?.pincode}
                              />
                              <ErrorMessage name='pinCode' component='div' className='error' />
                            </FormControl>
                          </Grid>
                        </Grid>

                        <Field
                          as={CustomTextField}
                          fullWidth
                          label='Address'
                          name='address'
                          variant='outlined'
                          type='text'
                          margin='normal'
                          value={values?.address}
                        />
                        <ErrorMessage name='address' component='div' className='error' />

                        <Field
                          as={CustomTextField}
                          fullWidth
                          label='Mobile Number'
                          name='mobileNumber'
                          variant='outlined'
                          type='number'
                          margin='normal'
                          value={values?.mobileNumber}
                        />
                        <ErrorMessage name='mobileNumber' component='div' className='error' />
                        <Field
                          as={CustomTextField}
                          fullWidth
                          label='Discount Code'
                          name='discountCode'
                          variant='outlined'
                          type='text'
                          margin='normal'
                        />
                        {/* Add Discount Information here */}

                        <FormControlLabel
                          control={
                            <CheckBox
                              checked={checked}
                              onChange={handleCheckboxChange}
                              name='terms'
                            />
                          }
                          label='Save this information for next time'
                          sx={{
                            marginY: 1,
                            color: '#fff',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            marginLeft: '2px',
                          }}
                        />

                        {/* Add Delivery Information form here */}
                        <LoadingButton
                          fullWidth
                          size='large'
                          type='submit'
                          variant='contained'
                          loading={isSubmitting}
                          sx={{ backgroundColor: '#008942', marginTop: '10px', maxHeight: '30vh' }}
                          // onClick={() => checkoutHandler(10)}
                        >
                          Pay now ₹{price}
                        </LoadingButton>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Grid container spacing={2} marginTop='5px'>
                            <Grid item xs={12} sm={4}>
                              <img
                                src={paymenticon}
                                //   width={'100%'}
                                //   height={'95%'}
                                alt='login'
                                onError={(e) => {
                                  console.warn('Error loading image:', e);
                                  e.target.style.display = 'none';
                                }}
                                style={{ marginTop: '5px' }}
                              />
                            </Grid>

                            <Grid item xs={12} sm={4} sx={{ paddingLeft: '0px !important' }}>
                              <Typography
                                variant='h7'
                                component='div'
                                gutterBottom
                                sx={{
                                  color: '#ffffffbd',
                                }}
                              >
                                100% SECURE PAYMENTS
                              </Typography>
                            </Grid>
                          </Grid>
                          <Typography variant='body2' color='#ffffffbd' sx={{ paddingTop: '10px' }}>
                            Your First Subscription! Unlock Interactive Learning and Save on Your
                            Journey to Success!
                          </Typography>
                        </Box>
                      </div>
                    </Grid>
                    {/* Right part: Discount and Total */}
                  </Grid>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Container>
    </Box>
  );
};

export default PaymentPage;
