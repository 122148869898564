import React from 'react';
import { Box, Button } from '@mui/material';

const QuestionActions = ({
  onDeselect,
  onPrevious,
  onSkip,
  onSaveAndNext,
  onMarkedAndReview,
  currentQuestionIndex,
  updateQuestionState,
  answers,
}) => {
  const handleDeselect = () => {
    updateQuestionState(currentQuestionIndex, 'visited'); // Update question state to 'visited'
    onDeselect();
  };

  const handleSaveAndNext = () => {
    updateQuestionState(currentQuestionIndex, 'answered'); // Update question state to 'answered'
    onSaveAndNext();
  };

  const handleMarkAndReview = () => {
    updateQuestionState(currentQuestionIndex, 'marked'); // Update question state to 'marked'
    onMarkedAndReview();
  };

  const handleSkip = () => {
    updateQuestionState(currentQuestionIndex, 'visited'); // Update question state to 'visited'
    onSkip();
  };

  return (
    <Box
      sx={{
        marginLeft: '20px',
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Box>
        <Button variant='contained' color='warning' sx={{ margin: '5px' }} onClick={handleDeselect}>
          DESELECT
        </Button>
        <Button variant='contained' color='secondary' sx={{ margin: '5px' }} onClick={onPrevious}>
          PREVIOUS
        </Button>
        <Button
          variant='contained'
          color='info'
          sx={{ margin: '5px', backgroundColor: '#6082B6' }}
          onClick={handleSkip}
        >
          SKIP
        </Button>
      </Box>
      <Box>
        <Button
          variant='contained'
          color='secondary'
          sx={{ margin: '5px', backgroundColor: '#4615b2' }}
          onClick={handleMarkAndReview}
          disabled={!answers[currentQuestionIndex]}
        >
          MARK & REVIEW
        </Button>
        <Button
          variant='contained'
          color='success'
          sx={{ margin: '5px' }}
          onClick={handleSaveAndNext}
          disabled={!answers[currentQuestionIndex]}
        >
          SAVE & NEXT
        </Button>
      </Box>
    </Box>
  );
};

export default QuestionActions;
