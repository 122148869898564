import React, { useState, useEffect } from 'react';
import {
  IconButton,
  InputAdornment,
  TextField,
  Tab,
  Tabs,
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { styled } from '@mui/material';
import Logo from '../_assets/images/classroom-ai.gif';
import { authActions } from '_store';
import { boardConfig } from '../pages/teacherDashboard/nav/config';
import { fetchWrapper } from '_helpers';
import { useNavigate } from 'react-router-dom';

export { Login };

// ... (StyledRoot and other styled components remain unchanged)

function CBSEForm({ onSubmit }) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        email: Yup.string().required('Username is required'),
        password: Yup.string().required('Password is required'),
      }),
    ),
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleClick = (data) => {
    data.board = 'CBSE';
    data.state = null;
    onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(handleClick)}>
      <TextField
        className='login-field'
        name='email'
        label='Email address'
        variant='outlined'
        fullWidth
        required
        {...register('email')}
        error={!!errors.email}
        helperText={errors.email?.message}
        sx={{ marginTop: '10px' }}
      />

      <TextField
        name='password'
        label='Password'
        type={showPassword ? 'text' : 'password'}
        variant='outlined'
        fullWidth
        required
        {...register('password')}
        error={!!errors.password}
        helperText={errors.password?.message}
        sx={{ marginTop: '10px' }}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton onClick={() => setShowPassword(!showPassword)} sx={{ padding: 0 }}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <LoadingButton
        fullWidth
        size='large'
        type='submit'
        variant='contained'
        loading={isSubmitting}
        sx={{ backgroundColor: '#092141', marginTop: '10px' }}
      >
        Login
      </LoadingButton>
    </form>
  );
}
function SATFrom({ onSubmit }) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        email: Yup.string().required('Username is required'),
        password: Yup.string().required('Password is required'),
      }),
    ),
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleClick = (data) => {
    data.board = 'SAT';
    data.state = null;
    onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(handleClick)}>
      <TextField
        className='login-field'
        name='email'
        label='Email address'
        variant='outlined'
        fullWidth
        required
        {...register('email')}
        error={!!errors.email}
        helperText={errors.email?.message}
        sx={{ marginTop: '10px' }}
      />

      <TextField
        name='password'
        label='Password'
        type={showPassword ? 'text' : 'password'}
        variant='outlined'
        fullWidth
        required
        {...register('password')}
        error={!!errors.password}
        helperText={errors.password?.message}
        sx={{ marginTop: '10px' }}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton onClick={() => setShowPassword(!showPassword)} sx={{ padding: 0 }}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <LoadingButton
        fullWidth
        size='large'
        type='submit'
        variant='contained'
        loading={isSubmitting}
        sx={{ backgroundColor: '#092141', marginTop: '10px' }}
      >
        Login
      </LoadingButton>
    </form>
  );
}
function MatricForm({ onSubmit }) {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;

  const [statesList, setStatesList] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        let res = await fetchWrapper.get(`${baseUrl}/api/v1/states`);
        setStatesList(res);
      } catch (error) {
        console.error('Error:', error);
      }
    }
    if (statesList.length === 0) {
      fetchData();
    }
  }, [statesList, baseUrl]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        email: Yup.string().required('Username is required'),
        password: Yup.string().required('Password is required'),
        state: Yup.string().required('State is required'),
      }),
    ),
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleClick = (data) => {
    data.board = 'Matric';
    onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(handleClick)}>
      <TextField
        className='login-field'
        name='email'
        label='Email address'
        variant='outlined'
        fullWidth
        required
        {...register('email')}
        error={!!errors.email}
        helperText={errors.email?.message}
        sx={{ marginTop: '10px' }}
      />

      <TextField
        name='password'
        label='Password'
        type={showPassword ? 'text' : 'password'}
        variant='outlined'
        fullWidth
        required
        {...register('password')}
        error={!!errors.password}
        helperText={errors.password?.message}
        sx={{ marginTop: '10px' }}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton onClick={() => setShowPassword(!showPassword)} sx={{ padding: 0 }}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      {/* Use the Select component for the state dropdown */}
      <FormControl fullWidth variant='outlined' sx={{ marginTop: '10px' }}>
        <InputLabel htmlFor='state'>State</InputLabel>
        <Select
          name='state'
          label='State'
          fullWidth
          required
          defaultValue='Tamil Nadu'
          {...register('state')}
          error={!!errors.state}
          onChange={(e) => register('state').onChange(e)}
        >
          {statesList.map((e) => (
            <MenuItem key={e.id} value={e.state}>
              {e.state}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText error>{errors.state?.message}</FormHelperText>
      </FormControl>

      <LoadingButton
        fullWidth
        size='large'
        type='submit'
        variant='contained'
        loading={isSubmitting}
        sx={{ backgroundColor: '#092141', marginTop: '10px' }}
      >
        Login
      </LoadingButton>
    </form>
  );
}

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useSelector((x) => x.alert.value);
  const [activeTab, setActiveTab] = useState(0);
  const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      backgroundColor: '#eaeef2',
      height: '92vh',
      overflow: 'hidden',
    },
    [theme.breakpoints.down('sm')]: {
      overflow: 'hidden',
    },
  }));

  const StyledSection = styled('div')(({ theme }) => ({
    width: '50%',
    height: '92vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // boxShadow: theme.customShadows.card,
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('sm')]: {
      marginTop: '7vh',
      width: '100%',
      height: '100%',
    },
  }));
  const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(0, 0),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '60%',
      minHeight: '20vh',
    },
  }));

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleSubmit = (data) => {
    const { email, password, state, board } = data;

    dispatch(authActions.login({ email, password, state, board }));
  };

  return (
    <StyledRoot>
      <StyledSection>
        <img
          src={Logo}
          alt='login'
          onError={(e) => {
            console.warn('Error loading image:', e);
            e.target.style.display = 'none';
          }}
        />
      </StyledSection>
      <Container
        maxWidth='sm'
        sx={{
          backgroundColor: '#eaeef2',
          height: 'auto',
          '@media (max-width: 600px)': {
            height: '420px',
          },
        }}
      >
        <StyledContent>
          <div className={`alert ${alert && alert.type}`}>{alert && alert.message}</div>

          <Tabs value={activeTab} onChange={handleTabChange} centered>
            {boardConfig.map((e) => (
              <Tab label={e['title']} key={e['title']} />
            ))}
          </Tabs>
          {activeTab === 0 && <CBSEForm onSubmit={handleSubmit} />}
          {activeTab === 1 && <MatricForm onSubmit={handleSubmit} />}
          {activeTab === 2 && <SATFrom onSubmit={handleSubmit} />}
          <Typography sx={{ marginTop: '10px', textAlign: 'center' }}>
                        {' '}
                        <Typography
                            component="span"
                            sx={{ color: 'primary.main', cursor: 'pointer' }}
                            onClick={() => navigate('/account/forgotPassword')}
                        >
                            Forgot Password
                        </Typography>
                    </Typography>
        </StyledContent>
      </Container>
    </StyledRoot>
  );
}
