// src/components/Message.tsx
import React, { Fragment } from 'react';
import MathJaxWrapper from './MathJaxWrapper';

const Message = ({ message }) => {
  return (
    <div style={{ textAlign: message.isUser ? 'right' : 'left', margin: '8px' }}>
      <div
        style={{
          color: message.isUser ? '#ffffff' : '#000000',
          backgroundColor: message.isUser ? '#be4539' : '#ffccc7',
          padding: '15px',
          borderRadius: '8px',
        }}
      >
        <MathJaxWrapper>
          {message.content.split('\n').map((text, index) => (
            <Fragment key={index}>
              {text}
              <br />
            </Fragment>
          ))}
        </MathJaxWrapper>
      </div>
    </div>
  );
};

export { Message };
