import {
  Box,
  Grid,
  Typography,
  Input,
  Button,
  Card,
  CardContent,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  IconButton,
} from '@mui/material';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { fetchWrapper } from '_helpers';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { useSelector } from 'react-redux';
import moment from 'moment';

export const AddMockQuiz = () => {
  const navigate = useNavigate();
  const [previewData, setPreviewData] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectImageFile, setImageFile] = useState({});
  const [imagePreviews, setImagePreviews] = useState({});
  const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;
  const user = useSelector((x) => x.auth.value);
  let teacher_email = user && user.relatedData.teacher_email;

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const formattedData = jsonData.slice(1).map((row, index) => ({
        id: index + 1,
        grade: row[1],
        created_by: teacher_email,
        created_at: moment().format('YYYY-MM-DD HH:mm:ss'),
        imageUrl: '',
        subject: row[2],
        board: row[3],
        section_name: row[4],
        subsection_name: row[5],
        question_type: row[6] || 'MCQ',
        questions: row[7] || '',
        option_1: row[8] || '',
        option_2: row[9] || '',
        option_3: row[10] || '',
        option_4: row[11] || '',
        correct_answer: row[12] || '',
        test_level: row[13] || '',
        feedback: row[14] || '',
        question_image: row[15] || '',
        option_1_image: row[16] || '',
        option_2_image: row[17] || '',
        option_3_image: row[18] || '',
        option_4_image: row[19] || '',
        correct_answer_image: row[20] || '',
        feedback_image: row[21] || '',
      }));

      // Filter out rows with empty questions, section, or subsection
      const validData = formattedData.filter(
        (data) =>
          data.questions &&
          data.section_name &&
          data.subsection_name
      );

      setPreviewData(validData);
      setCurrentQuestionIndex(0);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleImageFileChange = async (event, index, imageType) => {
    const file = event.target.files[0];
    setImageFile({
      ...selectImageFile,
      [`${imageType}_${index}`]: file,
    });

    // Create a preview URL for the image
    const reader = new FileReader();
    reader.onload = () => {
      setImagePreviews((prevPreviews) => ({
        ...prevPreviews,
        [`${imageType}_${index}`]: reader.result,
      }));
    };
    reader.readAsDataURL(file);

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetchWrapper.post(`${baseUrl}/Mockupload1`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setPreviewData((prevData) => {
        const updatedData = [...prevData];
        updatedData[index] = {
          ...updatedData[index],
          [`${imageType}_image`]: response.urls[0],
        };
        return updatedData;
      });

      await toast.success('Image uploaded successfully');
    } catch (error) {
      toast.error(error.message || 'An error occurred');
    }
  };

  const handleSubmit = async () => {
    try {
      const requestBody = {
        quizData: previewData,
      };

      await fetchWrapper.post(`${baseUrl}/createMockQuestionQuizzes`, requestBody);
      toast.success('Quiz created successfully');
      navigate('/AddMockQuiz');
    } catch (error) {
      toast.error(error.message || 'An error occurred');
    }
  };

  const handleReset = () => {
    setPreviewData([]);
    setCurrentQuestionIndex(0);
    setImageFile({});
    setImagePreviews({});
    document.getElementById('QuizzesExcel').value = '';
  };

  const nextQuestion = () => {
    if (currentQuestionIndex < previewData.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setImageFile({});
      setImagePreviews({});
    }
  };

  const prevQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setImageFile({});
      setImagePreviews({});
    }
  };

  return (
    <div>
      <Helmet>
        <title>Upload Quiz</title>
      </Helmet>
      <Box sx={{ padding: 2 }}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate('/getQuizSummary')}
          variant='outlined'
          sx={{ marginBottom: 2 }}
        >
          Back
        </Button>
        <Typography variant='h4' gutterBottom>
          Upload Quiz
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                border: '1px solid #ccc',
                borderRadius: 1,
                padding: '5px 10px',
                backgroundColor: '#f9f9f9',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Input
                type='file'
                fullWidth
                id='QuizzesExcel'
                name='QuizzesExcel'
                onChange={handleFileChange}
                inputProps={{ accept: '.xlsx, .xls' }}
                sx={{ border: 'none' }}
              />
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', marginTop: 4 }}>
          <Button
            onClick={handleSubmit}
            variant='contained'
            sx={{ marginRight: 2, padding: '10px 20px', backgroundColor: '#4caf50', color: 'white' }}
          >
            Submit
          </Button>
          <Button
            onClick={handleReset}
            variant='contained'
            sx={{ marginRight: 2, padding: '10px 20px', backgroundColor: '#f44336', color: 'white' }}
          >
            Reset
          </Button>
          <Button
            onClick={() => navigate('/ManualQuizEntry')}
            variant='contained'
            sx={{ padding: '10px 20px', backgroundColor: '#2196f3', color: 'white' }}
          >
            Manual Entry
          </Button>
        </Box>
        {previewData.length > 0 && (
          <Box sx={{ marginTop: 4 }}>
            <Typography variant='h6'>Preview Data</Typography>
            <div>
              {previewData[currentQuestionIndex] && (
                <>
                  <Card variant='outlined' sx={{ marginBottom: 2, border: '2px solid #ddd' }}>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <h2 style={{ color: '#3f51b5' }}>
                            {previewData[currentQuestionIndex].id}.{' '}
                            {previewData[currentQuestionIndex].quizName}
                          </h2>
                          {previewData[currentQuestionIndex].question_type === 'MCQ' && (
                            <>
                              <Typography>
                                <b>Question</b>: {previewData[currentQuestionIndex].questions}
                                <label htmlFor={`icon-button-file-question-${currentQuestionIndex}`}>
                                  <IconButton
                                    color='primary'
                                    aria-label='upload picture'
                                    component='span'
                                    sx={{ marginLeft: 1 }}
                                  >
                                    <PhotoCamera />
                                  </IconButton>
                                </label>
                                <input
                                  accept='image/*'
                                  style={{ display: 'none' }}
                                  id={`icon-button-file-question-${currentQuestionIndex}`}
                                  type='file'
                                  onChange={(e) => handleImageFileChange(e, currentQuestionIndex, 'question')}
                                />
                              </Typography>
                              {imagePreviews[`question_${currentQuestionIndex}`] && (
                                <img
                                  src={imagePreviews[`question_${currentQuestionIndex}`]}
                                  alt='Question'
                                  style={{ width: '100px', height: '100px', marginTop: '10px' }}
                                />
                              )}
                              <FormControl component='fieldset'>
                                <RadioGroup>
                                  <Grid container spacing={2}>
                                    <Grid item xs={11}>
                                      <FormControlLabel
                                        value={previewData[currentQuestionIndex].option_1}
                                        control={<Radio />}
                                        label={previewData[currentQuestionIndex].option_1}
                                      />
                                    </Grid>
                                    <Grid item xs={1}>
                                      <label htmlFor={`icon-button-file-option_1-${currentQuestionIndex}`}>
                                        <IconButton
                                          color='primary'
                                          aria-label='upload picture'
                                          component='span'
                                        >
                                          <PhotoCamera />
                                        </IconButton>
                                      </label>
                                      <input
                                        accept='image/*'
                                        style={{ display: 'none' }}
                                        id={`icon-button-file-option_1-${currentQuestionIndex}`}
                                        type='file'
                                        onChange={(e) => handleImageFileChange(e, currentQuestionIndex, 'option_1')}
                                      />
                                      {imagePreviews[`option_1_${currentQuestionIndex}`] && (
                                        <img
                                          src={imagePreviews[`option_1_${currentQuestionIndex}`]}
                                          alt='Option 1'
                                          style={{ width: '50px', height: '50px', marginTop: '10px' }}
                                        />
                                      )}
                                    </Grid>
                                    <Grid item xs={11}>
                                      <FormControlLabel
                                        value={previewData[currentQuestionIndex].option_2}
                                        control={<Radio />}
                                        label={previewData[currentQuestionIndex].option_2}
                                      />
                                    </Grid>
                                    <Grid item xs={1}>
                                      <label htmlFor={`icon-button-file-option_2-${currentQuestionIndex}`}>
                                        <IconButton
                                          color='primary'
                                          aria-label='upload picture'
                                          component='span'
                                        >
                                          <PhotoCamera />
                                        </IconButton>
                                      </label>
                                      <input
                                        accept='image/*'
                                        style={{ display: 'none' }}
                                        id={`icon-button-file-option_2-${currentQuestionIndex}`}
                                        type='file'
                                        onChange={(e) => handleImageFileChange(e, currentQuestionIndex, 'option_2')}
                                      />
                                      {imagePreviews[`option_2_${currentQuestionIndex}`] && (
                                        <img
                                          src={imagePreviews[`option_2_${currentQuestionIndex}`]}
                                          alt='Option 2'
                                          style={{ width: '50px', height: '50px', marginTop: '10px' }}
                                        />
                                      )}
                                    </Grid>
                                    {previewData[currentQuestionIndex].option_3 && (
                                      <>
                                        <Grid item xs={11}>
                                          <FormControlLabel
                                            value={previewData[currentQuestionIndex].option_3}
                                            control={<Radio />}
                                            label={previewData[currentQuestionIndex].option_3}
                                          />
                                        </Grid>
                                        <Grid item xs={1}>
                                          <label htmlFor={`icon-button-file-option_3-${currentQuestionIndex}`}>
                                            <IconButton
                                              color='primary'
                                              aria-label='upload picture'
                                              component='span'
                                            >
                                              <PhotoCamera />
                                            </IconButton>
                                          </label>
                                          <input
                                            accept='image/*'
                                            style={{ display: 'none' }}
                                            id={`icon-button-file-option_3-${currentQuestionIndex}`}
                                            type='file'
                                            onChange={(e) =>
                                              handleImageFileChange(e, currentQuestionIndex, 'option_3')
                                            }
                                          />
                                          {imagePreviews[`option_3_${currentQuestionIndex}`] && (
                                            <img
                                              src={imagePreviews[`option_3_${currentQuestionIndex}`]}
                                              alt='Option 3'
                                              style={{ width: '50px', height: '50px', marginTop: '10px' }}
                                            />
                                          )}
                                        </Grid>
                                      </>
                                    )}
                                    {previewData[currentQuestionIndex].option_4 && (
                                      <>
                                        <Grid item xs={11}>
                                          <FormControlLabel
                                            value={previewData[currentQuestionIndex].option_4}
                                            control={<Radio />}
                                            label={previewData[currentQuestionIndex].option_4}
                                          />
                                        </Grid>
                                        <Grid item xs={1}>
                                          <label htmlFor={`icon-button-file-option_4-${currentQuestionIndex}`}>
                                            <IconButton
                                              color='primary'
                                              aria-label='upload picture'
                                              component='span'
                                            >
                                              <PhotoCamera />
                                            </IconButton>
                                          </label>
                                          <input
                                            accept='image/*'
                                            style={{ display: 'none' }}
                                            id={`icon-button-file-option_4-${currentQuestionIndex}`}
                                            type='file'
                                            onChange={(e) =>
                                              handleImageFileChange(e, currentQuestionIndex, 'option_4')
                                            }
                                          />
                                          {imagePreviews[`option_4_${currentQuestionIndex}`] && (
                                            <img
                                              src={imagePreviews[`option_4_${currentQuestionIndex}`]}
                                              alt='Option 4'
                                              style={{ width: '50px', height: '50px', marginTop: '10px' }}
                                            />
                                          )}
                                        </Grid>
                                      </>
                                    )}
                                  </Grid>
                                </RadioGroup>
                              </FormControl>
                            </>
                          )}
                          <Accordion className='m-4'>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls='panel1-content'
                              id='panel1-header'
                            >
                              <Typography>Feedback</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>{previewData[currentQuestionIndex].feedback}</Typography>
                              <label htmlFor={`icon-button-file-feedback-${currentQuestionIndex}`}>
                                <IconButton
                                  color='primary'
                                  aria-label='upload picture'
                                  component='span'
                                  sx={{ marginLeft: 1 }}
                                >
                                  <PhotoCamera />
                                </IconButton>
                              </label>
                              <input
                                accept='image/*'
                                style={{ display: 'none' }}
                                id={`icon-button-file-feedback-${currentQuestionIndex}`}
                                type='file'
                                onChange={(e) => handleImageFileChange(e, currentQuestionIndex, 'feedback')}
                              />
                              {imagePreviews[`feedback_${currentQuestionIndex}`] && (
                                <img
                                  src={imagePreviews[`feedback_${currentQuestionIndex}`]}
                                  alt='Feedback'
                                  style={{ width: '50px', height: '50px', marginTop: '10px' }}
                                />
                              )}
                            </AccordionDetails>
                          </Accordion>
                          <Accordion className='m-4'>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls='panel1-content'
                              id='panel1-header'
                            >
                              <Typography>Correct Answer</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>{previewData[currentQuestionIndex].correct_answer}</Typography>
                              <label htmlFor={`icon-button-file-correct_answer-${currentQuestionIndex}`}>
                                <IconButton
                                  color='primary'
                                  aria-label='upload picture'
                                  component='span'
                                  sx={{ marginLeft: 1 }}
                                >
                                  <PhotoCamera />
                                </IconButton>
                              </label>
                              <input
                                accept='image/*'
                                style={{ display: 'none' }}
                                id={`icon-button-file-correct_answer-${currentQuestionIndex}`}
                                type='file'
                                onChange={(e) => handleImageFileChange(e, currentQuestionIndex, 'correct_answer')}
                              />
                              {imagePreviews[`correct_answer_${currentQuestionIndex}`] && (
                                <img
                                  src={imagePreviews[`correct_answer_${currentQuestionIndex}`]}
                                  alt='Correct Answer'
                                  style={{ width: '50px', height: '50px', marginTop: '10px' }}
                                />
                              )}
                            </AccordionDetails>
                          </Accordion>
                          <Divider />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 4 }}>
                    <Button
                      onClick={prevQuestion}
                      disabled={currentQuestionIndex === 0}
                      variant='contained'
                      sx={{ backgroundColor: '#757de8', color: 'white' }}
                    >
                      Previous
                    </Button>
                    <Button
                      onClick={nextQuestion}
                      disabled={currentQuestionIndex === previewData.length - 1}
                      variant='contained'
                      sx={{ backgroundColor: '#757de8', color: 'white' }}
                    >
                      Next
                    </Button>
                  </Box>
                </>
              )}
            </div>
          </Box>
        )}
      </Box>
    </div>
  );
};


