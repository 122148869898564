import React, { Suspense, useState, useEffect } from 'react';
import { MathJax, MathJaxContext } from 'better-react-mathjax';

// Configuration for MathJax to support a wider range of formulas
const config = {
  loader: { load: ['input/tex', 'output/chtml'] },
  tex: {
    packages: { '[+]': ['mhchem', 'color', 'amsmath', 'graphicx'] }, // Add any necessary packages here
  },
};

const MathJaxWrapper = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // Simulate loading process or check for errors
    const loadMathJax = async () => {
      try {
        // You might add more logic here to check if MathJax is loaded correctly
        setIsLoaded(true);
      } catch (error) {
        console.error('MathJax failed to load:', error);
        setIsLoaded(false);
      }
    };

    loadMathJax();
  }, []);

  return (
    <Suspense fallback={<div>Loading MathJax...</div>}>
      {isLoaded ? (
        <MathJaxContext config={config}>
          <MathJax>{children}</MathJax>
        </MathJaxContext>
      ) : (
        <div>Error loading MathJax. Please try again later.</div>
      )}
    </Suspense>
  );
};

export default MathJaxWrapper;
