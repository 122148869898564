import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Card, Stack, Button, Container, Typography } from '@mui/material';
import { ListTable } from '_components';
import { Loader } from '_components/navigation/Loader';

import { getTeacherAccounts, teacherAccounts } from '_store/teacher.slice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import { debounce } from 'lodash';
import 'colors.css';
import Pagination from '@mui/material/Pagination';
import { Page404 } from './../Page404';

export const TeacherList = () => {
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(true);
  const dispatch = useDispatch();
  const _teacherAccount = useSelector(teacherAccounts);

  const [totalPages, setTotalPages] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1); // Start from page 1
  const [searchTerm, setSearchTerm] = useState('');
  let pageSize = 10;
  // todo
  useEffect(() => {
    if (_teacherAccount && _teacherAccount.pagination) {
      setTotalPages(_teacherAccount.pagination.totalPages);
      setisLoading(false); // Set isLoading to false if results are found
    } else {
      setTotalPages(0);
      setisLoading(true); // Set isLoading to true if no results are found
    }
  }, [_teacherAccount, currentPage]);

  useEffect(() => {
    dispatch(getTeacherAccounts(currentPage, pageSize, searchTerm));
  }, [currentPage, searchTerm]);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
    const trimedValue = term.trim()
    setCurrentPage(0);
    if(!term){
      dispatch(getTeacherAccounts(0,10, trimedValue));
    }
    trimedValue && dispatch(getTeacherAccounts(0,10, trimedValue));
  };
  if (!_teacherAccount || _teacherAccount.length === 0) {
    return (
      <>
        <Helmet>
          <title> Teachers List </title>
        </Helmet>
        <Container>
          <Typography variant='h4' gutterBottom>
            <Page404 message='No Teacher found ' name={'NoDataFound'} />
          </Typography>
        </Container>
      </>
    );
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title> Teachers List </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant='h4' gutterBottom>
          Teachers List
        </Typography>
        <Stack direction='row' alignItems='center' justifyContent='space-between' mb={5}>
          <div className='d-flex' style={{ justifyContent: 'flex-start' }}>
            <input
              type='text'
              placeholder='Search...'
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
          <Button
            className='mx-2'
            startIcon=''
            sx={{
              backgroundColor: `var(--Peach)`,
              '&:hover': {
                backgroundColor: 'var(--Blue)',
              },
              color: `var(--White)`,
            }}
            onClick={() => navigate('/addTeacher')}
          >
            Add New Teacher
          </Button>
        </Stack>

        <Card>
          <>
            <ListTable
              data={_teacherAccount['results']}
              headers={[
                'teacher_id',
                'teacher_name',
                'teacher_email',
                'grade',
                'section',
                'teacher_phone',
              ]}
              columnHeaders={[
                'Teacher Id ',
                'teacher Name',
                'Teacher Email',
                'Grade',
                'Section',
                'Teacher Phone',
              ]}
            />
          </>
          <Container align='center' justify='center' p={10} w='full'>
            <Pagination
              count={totalPages}
              shape='rounded'
              className={`controls ${currentPage}`}
              sx={{ justifyContent: 'center' }}
              onChange={(e, n) => handlePageChange(e, n)}
            />
          </Container>
        </Card>
      </Container>
    </>
  );
};
