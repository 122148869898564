import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Grid,
  TextField,
  Button,
  Box,
  IconButton,
  Stack,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { capitalizeFirstLetter } from '_helpers';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchWrapper } from '_helpers';
import { GradeSelector } from './GradeSelector';

import OutlinedInput from '@mui/material/OutlinedInput';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = ['Science', 'English', 'Maths'];

const AddStudent = ({ editMode, teacherData }) => {
  console.log('teacherData', teacherData);

  const user = useSelector((x) => x.auth.value);
  const navigate = useNavigate();
  const [subject, setSubjectName] = useState(() => {
    if (editMode && teacherData.subject) {
      return Array.isArray(teacherData.subject)
        ? teacherData.subject
        : [capitalizeFirstLetter([teacherData.subject])];
    } else {
      return [];
    }
  });

  const [gradePairs, setGradePairs] = useState('');
  const [isImageSelected, setIsImageSelected] = useState(false);
  const [imageFile, setImageFile] = useState(() => {
    if (editMode && teacherData.subject) {
      return teacherData.teacher_image;
    } else {
      return null;
    }
  });

  const [formValues, setFormValues] = useState(() => {
    if (editMode) {
      return {
        sch_id: user.relatedData.sch_id,
        board: user.board,
        matric_state: user.matric_state,
        teacher_email: teacherData.teacher_email || '',
        teacher_password: teacherData.teacher_password || '',
        teacher_name: teacherData.teacher_name || '',
        grade: teacherData.grade || [],
        teacher_phone: teacherData.teacher_phone || '',
        subject: subject || [],
      };
    }

    return {
      sch_id: user.relatedData.sch_id,
      teacher_email: '',
      teacher_password: '',
      teacher_name: '',
      grade: [],
      teacher_phone: '',
      subject: [],
    };
  });
  const validationSchema = Yup.object().shape({
    sch_id: Yup.string().required('School Name is required'),
    teacher_email: Yup.string().email('Invalid email').required('Teacher Email is required'),
    teacher_password: Yup.string().when('editMode', {
      is: false,
      then: Yup.string()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          'Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and be 8-16 characters long',
        )
        .required('Teacher Password is required'),
    }),
    teacher_name: Yup.string().required('Teacher Name is required'),
    teacher_phone: Yup.string()
      .matches(/^[+\d]+$/, 'Invalid phone number format. Use numbers and "+" only.')
      .required('Teacher Phone Number is required'),
  });

  useEffect(() => {}, [isImageSelected, imageFile]);
  useEffect(() => {
    async function fetchData() {
      let req = {
        teacher_id: teacherData.teacher_id,
      };
      let res = await fetchWrapper.post(`${baseUrl}/teacher-data`, req);
      const uniqueCombinations = [
        ...new Set(
          res.map((entry) => JSON.stringify({ grade: entry.grade, section: entry.section_id })),
        ),
      ].map((str) => JSON.parse(str));
      uniqueCombinations && setGradePairs(uniqueCombinations);
    }
    fetchData();
  }, []);

  const handleChangeSelect = (event) => {
    const {
      target: { value },
    } = event;
    setSubjectName(value);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleAddPair = (pair) => {
    const isPairUnique = !gradePairs.some(
      (p) => p.standard === pair.standard && p.section === pair.section,
    );

    if (isPairUnique) {
      setGradePairs([...gradePairs, pair]);
      setFormValues((prevValues) => ({
        ...prevValues,
        grade: [...prevValues.grade, pair],
      }));
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        grade: [],
      }));
    }
  };

  const handleRemovePair = (pairToRemove) => {
    const updatedPairs = gradePairs.filter(
      (pair) => pair.standard !== pairToRemove.standard || pair.section !== pairToRemove.section,
    );
    setGradePairs(updatedPairs);
  };

  const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;
  const handleSubmit = async (values, actions) => {
    values['grade'] = formValues['grade'];
    values.subject = subject;

    const formData = new FormData();
    imageFile !== teacherData.teacher_image ? formData.append('teacher_image', imageFile) : '';

    Object.entries(values).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.delete('teacher_password');

    try {
      if (editMode && formData) {
        delete values['teacher_password'];
        await fetchWrapper.put(`${baseUrl}/updateTeacher/${teacherData.teacher_id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      } else {
        await fetchWrapper.post(`${baseUrl}/schoolAdmin/createTeacher`, values, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      }

      setFormValues({
        teacher_email: '',
        teacher_password: '',
        teacher_name: '',
        grade: [],
        teacher_phone: '',
        subject: [],
      });
      setGradePairs([]);
      setSubjectName(editMode ? capitalizeFirstLetter([teacherData.subject]) : []);
      setIsImageSelected(false);
      setImageFile(editMode ? teacherData.teacher_image : '');
      actions.resetForm();
      toast.success(`${editMode ? 'Teacher updated' : 'Teacher created'} successfully`);
      handleRemovePair();
    } catch (error) {
      console.error('An error occurred', error);
      toast.error(error);
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <div>
      <Helmet>
        <title> Add New Teacher </title>
      </Helmet>
      <Stack direction='row' alignItems='center' mb={2}>
        <IconButton aria-label='back' size='large' onClick={handleBack}>
          <ArrowBackIcon fontSize='inherit' style={{ color: '#092141' }} />
        </IconButton>
        <Typography variant='h4' gutterBottom>
          {editMode ? 'Edit Teacher' : 'Add New Teacher'}
        </Typography>
      </Stack>
      <div>
        <Box sx={{ padding: 2 }}>
          <Formik
            initialValues={formValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form>
                <div className='d-flex'>
                  <Box
                    sx={{
                      minWidth: '200px',
                      border: '1px solid #f6f6f6',
                      background: 'white',
                      borderRadius: '10px',
                      margin: '10px',
                      padding: '10px',
                    }}
                  >
                    <span className='component-image MuiBox-root css-fnjgej'>
                      <span className='component-image-wrapper lazy-load-image-background blur lazy-load-image-loaded d-block'>
                        <img
                          className='MuiBox-root css-3j6ntj  rounded'
                          alt='avatar'
                          style={{
                            maxHeight: '200px',
                            maxWidth: '150px',
                            objectFit: 'contain',
                            margin: ' 20px auto ',
                          }}
                          src={
                            isImageSelected
                              ? URL.createObjectURL(imageFile)
                              : editMode
                              ? `${process.env.REACT_APP_API_URL}/images/${imageFile}`
                              : 'https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_25.jpg'
                          }
                        />
                      </span>
                    </span>
                    <label
                      htmlFor='image-upload'
                      className='custom-upload-button'
                      style={{
                        display: 'inlineBlock',
                        padding: '10px 15px',
                        backgroundColor: '#4caf50',
                        color: '#fff',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        marginTop: '100px',
                      }}
                    >
                      Upload Image
                    </label>
                    <input
                      id='image-upload'
                      accept='image/*'
                      type='file'
                      style={{ display: 'none', backgroundColor: '#45a049', objectFit: 'cover' }}
                      onChange={(e) => {
                        const selectedImage = e.target.files[0];
                        setImageFile(selectedImage);
                        setIsImageSelected(true);
                      }}
                    />
                  </Box>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      minWidth: '200px',
                      border: '1px solid #f6f6f6',
                      background: 'white',
                      borderRadius: '10px',
                      margin: '10px',
                      padding: '20px',
                    }}
                  >
                    <Grid item xs={6}>
                      <Field
                        as={TextField}
                        label='Teacher Email'
                        variant='outlined'
                        fullWidth
                        name='teacher_email'
                        disabled={editMode}
                      />
                      <ErrorMessage name='teacher_email' component='div' className='error' />
                    </Grid>
                    {!editMode ? (
                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          label='Teacher Password'
                          type='password'
                          variant='outlined'
                          fullWidth
                          name='teacher_password'
                        />
                        <ErrorMessage name='teacher_password' component='div' className='error' />
                      </Grid>
                    ) : (
                      ''
                    )}

                    <Grid item xs={6}>
                      <Field
                        as={TextField}
                        label='Teacher Name'
                        variant='outlined'
                        fullWidth
                        name='teacher_name'
                      />
                      <ErrorMessage name='teacher_name' component='div' className='error' />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        as={TextField}
                        label='Teacher Phone Number'
                        variant='outlined'
                        fullWidth
                        name='teacher_phone'
                      />
                      <ErrorMessage name='teacher_phone' component='div' className='error' />
                    </Grid>

                    <Grid item xs={6}>
                      <GradeSelector
                        onAdd={handleAddPair}
                        onRemove={handleRemovePair}
                        grades={gradePairs}
                        name='grade'
                        editMode={editMode ? true : false}
                      />
                      <ErrorMessage name='grade' component='div' className='error' />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl sx={{ width: '100%' }}>
                        <InputLabel id='demo-multiple-name-label'>Subject</InputLabel>
                        <Field
                          as={Select}
                          labelId='demo-multiple-name-label'
                          id='demo-multiple-name'
                          multiple
                          value={subject}
                          onChange={handleChangeSelect}
                          input={<OutlinedInput label='subject' />}
                          MenuProps={MenuProps}
                        >
                          {names.map((name) => (
                            <MenuItem key={name} value={name}>
                              {name}
                            </MenuItem>
                          ))}
                        </Field>
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
                <Button
                  type='submit'
                  variant='contained'
                  sx={{ marginTop: 4, padding: '10px 20px', backgroundColor: '#fe6f61' }}
                >
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
      </div>
      <ToastContainer />
    </div>
  );
};

export { AddStudent };
