import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export { Loader };

function Loader() {
  return (
    <Box style={{padding:"40vh 0", textAlign: "center", height:"100vh"}}>
      <CircularProgress
        size={80}
        sx={{ height: "100vh", textAlign: "center" }}
      />
    </Box>
  );
}
