import React, { useState, useEffect } from 'react';
import { Button, Box, Grid } from '@mui/material';
import SubjectButtons from './SubjectButtons';
import QuestionDisplay from './QuestionDisplay';
import QuestionActions from './QuestionActions';
import TimerAndEndTest from './TimerAndEndTest';
import NavigationButtons from './NavigationButtons';
import TestResultsDialog from './TestResultsDialog';
import { useSelector } from 'react-redux';
import { store } from '_store';
import { useNavigate } from 'react-router-dom';
import { Loader } from '_components/navigation/Loader';

const NEETTestQuiz = ({ board, selectedLevel }) => {
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [error, setError] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [isTestStarted, setIsTestStarted] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [resultDialogOpen, setResultDialogOpen] = useState(false); // State for dialog visibility
  const [testResult, setTestResult] = useState(null);
  const [physicsData, setPhysicsData] = useState({});
  const [chemistryData, setChemistryData] = useState({});
  const [biologyData, setBiologyData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [questionStates, setQuestionStates] = useState([]);
  const [isTestSubmitted, setIsTestSubmitted] = useState(false);
  const user = useSelector((x) => x.auth.value);

  const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;
  const token = authToken();
  function authToken() {
    return store.getState().auth.value?.token;
  }

  const handleSelectSubject = (subject) => {
    setSelectedSubject(subject);
    filterQuestions(subject);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const url = `${baseUrl}/fetchNeetQuestions?board=${board}&test_level=${selectedLevel}`;
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          const errorMessage = await response.text();
          throw new Error(errorMessage || 'Failed to fetch questions');
        }

        const data = await response.json();

        setQuestions(data);
        setFilteredQuestions(data);

        setAnswers(new Array(data.length).fill(''));
        setIsTestStarted(true);
        navigate(
          `/dashboard/NEET_TEST/subject/All_Subjects?board=${board}&test_level=${selectedLevel}`,
        );
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [baseUrl, token, board, selectedLevel]);

  const filterQuestions = (subject) => {
    if (subject) {
      const filtered = questions.filter((question) => question.subject === subject);
      setFilteredQuestions(filtered);
    } else {
      setFilteredQuestions(questions); // Show all questions if no subject is selected
    }
    setCurrentQuestionIndex(0); // Reset to the first question of the filtered list
  };

  const handleAnswerChange = (event) => {
    const { value } = event.target;
    const newAnswers = [...answers];
    newAnswers[currentQuestionIndex] = value;
    setAnswers(newAnswers);
    const questionId = currentQuestionIndex; // Assuming you track question ID
    updateQuestionState(questionId, 'answered'); // Call update function
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleSkipQuestion = () => {
    const updatedQuestionStates = [...questionStates];
    updatedQuestionStates[currentQuestionIndex] = 'visited';
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
    const newAnswers = [...answers];
    newAnswers[currentQuestionIndex] = '';
    setAnswers(newAnswers);
  };

  const updateQuestionState = (questionId, newQuestionState) => {
    const updatedQuestionStates = [...questionStates];
    updatedQuestionStates[questionId] = newQuestionState;
    setQuestionStates(updatedQuestionStates);
  };

  const handleSaveAndNext = () => {
    const updatedQuestionStates = [...questionStates];
    updatedQuestionStates[currentQuestionIndex] = 'answered'; // Update question state to 'answered'
    setQuestionStates(updatedQuestionStates);
    setCurrentQuestionIndex(currentQuestionIndex + 1); // Move to the next question
  };

  const handleMarkAndReview = () => {
    updateQuestionState(currentQuestionIndex, 'marked');
    const newAnswers = [...answers];
    newAnswers[currentQuestionIndex] = answers[currentQuestionIndex];
    setAnswers(newAnswers);
    if (currentQuestionIndex < filteredQuestions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handleDeselect = () => {
    const updatedQuestionStates = [...questionStates];
    updatedQuestionStates[currentQuestionIndex] = 'visited';
    const newAnswers = [...answers];
    newAnswers[currentQuestionIndex] = '';
    setAnswers(newAnswers);
  };

  const handleQuestionClick = (questionId) => {
    setCurrentQuestionIndex(questionId);
    if (questionStates[questionId] === 'default') {
      updateQuestionState(questionId, 'visited');
    }
  };

  const handleSubmitTest = async () => {
    setIsTestStarted(false);
    setIsTestSubmitted(true);
    const correctAnswers = questions.filter((question, index) => {
      return question.correct_answer === answers[index];
    });

    const wrongAnswers = questions.filter((question, index) => {
      return question.correct_answer !== answers[index] && answers[index] !== '';
    });

    const attemptedQuestions = answers.filter((answer) => answer !== '').length;
    const unattemptedQuestions = questions.length - attemptedQuestions;
    const totalScore = ((correctAnswers.length / questions.length) * 100).toFixed(2);

    const resultData = {
      correct: correctAnswers.length,
      wrong: wrongAnswers.length,
      attempted: attemptedQuestions,
      unattempted: unattemptedQuestions,
      totalScore: totalScore,
    };

    setTestResult(resultData); // Store result data in state
    setAnswers(new Array(filteredQuestions.length).fill(''));

    // Calculate subject-wise data
    const physicsData = calculateSubjectData('Physics');
    const chemistryData = calculateSubjectData('Chemistry');
    const biologyData = calculateSubjectData('Biology');

    setPhysicsData(physicsData);
    setChemistryData(chemistryData);
    setBiologyData(biologyData);

    setResultDialogOpen(true); // Open result dialog

    // Check if section_name, subsection_name, and test_level exist
    const sectionName = questions[0]?.section_name || 'Unknown Section';
    const subsectionName = questions[0]?.subsection_name || 'Unknown Subsection';
    const testLevel = questions[0]?.test_level || selectedLevel;
    const attemptedDate = new Date().toISOString().slice(0, 19).replace('T', ' ');
    const physicsScore = physicsData.correct * 4 - physicsData.wrong * 1;
    const chemistryScore = chemistryData.correct * 4 - chemistryData.wrong * 1;
    const biologyScore = biologyData.correct * 4 - biologyData.wrong * 1;
    const percentScore = ((resultData.correct * 4 - resultData.wrong * 1) * 100) / questions.length;
    const totalMarks = resultData.correct * 4 - resultData.wrong * 1;

    // Prepare the form data for submission
    const formData = {
      total_correct_answer: resultData.correct,
      total_wrong_answer: resultData.wrong,
      total_attempted: resultData.attempted,
      total_unattempted: resultData.unattempted,
      physics_correct_answer: physicsData.correct,
      physics_wrong_answer: physicsData.wrong,
      physics_attempted: physicsData.attempted,
      physics_unattempted: physicsData.unattempted,
      chemistry_correct_answer: chemistryData.correct,
      chemistry_wrong_answer: chemistryData.wrong,
      chemistry_attempted: chemistryData.attempted,
      chemistry_unattempted: chemistryData.unattempted,
      biology_correct_answer: biologyData.correct,
      biology_wrong_answer: biologyData.wrong,
      biology_attempted: biologyData.attempted,
      biology_unattempted: biologyData.unattempted,
      section_name: sectionName,
      subsection_name: subsectionName,
      test_level: testLevel,
      attempted_date: attemptedDate,
      physics_score: physicsScore,
      chemistry_score: chemistryScore,
      biology_score: biologyScore,
      percent_score: percentScore,
      total_marks: totalMarks,
      subject: board,
      student_email: user.email,
      school_id: user.relatedData.sch_id,
    };

    const studentData = filteredQuestions.map((question, index) => ({
      student_email: user.email,
      quiz_id: question.id,
      date: new Date().toISOString().slice(0, 10),
      right_wrong: answers[index] === question.correct_answer ? 1 : 0,
      selected_ans: answers[index],
    }));

    console.log('Submitting student data:', studentData);
    console.log('Submitting form data:', formData);
    await submitMockTestResults(formData);
    for (const row of studentData) {
      await submitStudentInfo(row);
    }
  };

  const submitStudentInfo = async (studentData) => {
    try {
      const response = await fetch(`${baseUrl}/mock_quiz_taker`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(studentData), // Ensure studentData is correctly formatted
      });

      const responseData = await response.json();

      if (!response.ok) {
        console.error('Backend error:', responseData);
        throw new Error(responseData.error || 'Failed to submit data');
      }

      console.log('Student Data inserted successfully. ID:', responseData.id);
      // Optionally, show a success message or redirect the user
    } catch (error) {
      console.error('Error inserting Student data:', error.message);
      // Handle error, show an error message, etc.
    }
  };

  const submitMockTestResults = async (formData) => {
    try {
      const response = await fetch(`${baseUrl}/mock_test_results`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });

      const responseData = await response.json();

      if (!response.ok) {
        console.error('Backend error:', responseData); // Log full backend response
        throw new Error(responseData.error || 'Failed to submit data');
      }

      console.log('Form Data inserted successfully. ID:', responseData.id);
      // Optionally, show a success message or redirect the user
    } catch (error) {
      console.error('Error inserting Form data:', error.message);
      // Handle error, show an error message, etc.
    }
  };

  const calculateSubjectData = (subject) => {
    const filteredBySubject = questions.filter((question) => question.subject === subject);
    const correct = filteredBySubject.filter((question, index) => {
      return question.correct_answer === answers[index];
    }).length;
    const wrong = filteredBySubject.filter((question, index) => {
      return question.correct_answer !== answers[index] && answers[index] !== '';
    }).length;
    const attempted = answers.filter(
      (answer, index) => answer !== '' && filteredBySubject[index],
    ).length;
    const unattempted = filteredBySubject.length - attempted;

    return { correct, wrong, attempted, unattempted };
  };

  if (isLoading) {
    return <Loader />; // Display loading indicator while fetching data
  }

  if (error) {
    return (
      <div className='alert alert-danger m-2' role='alert'>
        Please select Mock Test
      </div>
    );
  }

  const currentQuestion = filteredQuestions[currentQuestionIndex];
  const handleNEETFeedback = () => {
    navigate('/feedback');
  };
  return (
    <div className='App'>
      <div className='container-fluid'>
        <div className='m-3'>
          <div className='h3'>{selectedLevel}</div>
          <div className='h6 intro' style={{ height: '100%', background: '#ccf2ff' }}>
            {selectedLevel}
          </div>
        </div>
      </div>
      <Box
        sx={{
          paddingTop: '10px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            setIsTestStarted(true);
            setQuestionStates(new Array(filteredQuestions.length).fill('default')); // Reset question states on start
          }}
        >
          Start Test
        </Button>
      </Box>
      {!isTestStarted && (
        <Box sx={{ opacity: '50%' }}>
          {/* Subject Buttons and Timer */}
          <Box
            sx={{
              padding: '20px',
              margin: '20px',
              display: 'flex',
              justifyContent: 'space-between',
              backgroundColor: '#D3D3D3',
            }}
          >
            <SubjectButtons onSelectSubject={handleSelectSubject} />
            <TimerAndEndTest isTestStarted={isTestStarted} handleSubmitTest={handleSubmitTest} />
          </Box>

          {/* Main Quiz Area */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={9}>
              {currentQuestion && (
                <>
                  <QuestionDisplay
                    currentQuestion={currentQuestion}
                    currentQuestionIndex={currentQuestionIndex}
                    answers={answers}
                    handleAnswerChange={handleAnswerChange}
                  />
                  <QuestionActions
                    onDeselect={handleDeselect}
                    onSkip={handleSkipQuestion}
                    onPrevious={handlePreviousQuestion}
                    onSaveAndNext={handleSaveAndNext}
                    onMarkedAndReview={handleMarkAndReview}
                    currentQuestionIndex={currentQuestionIndex}
                    updateQuestionState={updateQuestionState}
                    answers={answers}
                  />
                </>
              )}
            </Grid>
            <Grid item xs={12} sm={3}>
              <NavigationButtons
                totalQuestions={filteredQuestions.length}
                onQuestionClick={handleQuestionClick}
                selectedSubject={selectedSubject}
                questionStates={questionStates}
                isTestSubmitted={isTestSubmitted}
              />
            </Grid>
          </Grid>

          {/* Submit Test Button */}
          <Box sx={{ textAlign: 'center', marginTop: '50px' }}>
            <Button variant='contained' color='primary' onClick={handleSubmitTest}>
              Submit Test
            </Button>
          </Box>
        </Box>
      )}
      {isTestStarted && (
        <Box sx={{ opacity: '100%' }}>
          {/* Subject Buttons and Timer */}

          <Box
            sx={{
              padding: '20px',
              margin: '20px',
              display: 'flex',
              justifyContent: 'space-between',
              backgroundColor: '#D3D3D3',
            }}
          >
            <SubjectButtons onSelectSubject={handleSelectSubject} />
            <TimerAndEndTest isTestStarted={isTestStarted} handleSubmitTest={handleSubmitTest} />
          </Box>

          {/* Main Quiz Area */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={9}>
              {currentQuestion && (
                <>
                  <QuestionDisplay
                    currentQuestion={currentQuestion}
                    currentQuestionIndex={currentQuestionIndex}
                    answers={answers}
                    handleAnswerChange={handleAnswerChange}
                  />
                  <QuestionActions
                    onDeselect={handleDeselect}
                    onSkip={handleSkipQuestion}
                    onPrevious={handlePreviousQuestion}
                    onSaveAndNext={handleSaveAndNext}
                    onMarkedAndReview={handleMarkAndReview}
                    currentQuestionIndex={currentQuestionIndex}
                    updateQuestionState={updateQuestionState}
                    answers={answers}
                  />
                </>
              )}
            </Grid>
            <Grid item xs={12} sm={3}>
              <NavigationButtons
                totalQuestions={filteredQuestions.length}
                onQuestionClick={handleQuestionClick}
                selectedSubject={selectedSubject}
                questionStates={questionStates}
                isTestSubmitted={isTestSubmitted}
              />
            </Grid>
          </Grid>

          {/* Submit Test Button */}
          <Box sx={{ textAlign: 'center', marginTop: '50px' }}>
            <Button variant='contained' color='primary' onClick={handleSubmitTest}>
              Submit Test
            </Button>
          </Box>
        </Box>
      )}

      {/* Test Results Dialog */}
      <TestResultsDialog
        resultDialogOpen={resultDialogOpen}
        handleResultDialogClose={() => setResultDialogOpen(false)}
        result={testResult}
        physicsData={physicsData}
        chemistryData={chemistryData}
        biologyData={biologyData}
        onNEETFeedback={handleNEETFeedback}
      />
    </div>
  );
};

export default NEETTestQuiz;
