import React from 'react';
import { Table, TableBody, TableHead, TableRow, Typography } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { ListRow } from './ListRow';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    border: '0px',
    color: theme.palette.common.white,
    textAlign: 'center',
  },
  border: '0px',
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const ListTable = ({ data, headers, columnHeaders }) => {
  if (!data || !Array.isArray(data)) {
    return (
      <Table>
        <TableHead>
          <TableRow sx={{ background: 'linear-gradient(270deg, #FE7061, #E5A978)' }}>
            {columnHeaders.map((header) => (
              <TableCell key={header} className='fs-5 fw-bold'>
                {header.toUpperCase()}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell colSpan={columnHeaders.length}>
              <Typography variant='h6' textAlign='center'>
                No Data Found
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }

  return (
    <Table sx={{ minWidth: 700, border: '0px' }} aria-label='customized table'>
      <TableHead>
        <TableRow sx={{ background: 'linear-gradient(270deg, #FE7061, #E5A978)' }}>
          {columnHeaders.map((header) => (
            <StyledTableCell className='fs-6 fw-bold' key={header}>
              {header.toUpperCase()}
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>

      <TableBody>
        {!data || data.length === 0 ? (
          <TableRow>
            <StyledTableCell colSpan={headers.length}>
              <Typography variant='h6' textAlign='center'>
                No Data Found
              </Typography>
            </StyledTableCell>
          </TableRow>
        ) : (
          data &&
          data.length &&
          data.map((row, rowIndex) => {
            row = {
              ...row,
              student_progress: 'Progress Report',
              sch_start_date: formatDate(row.sch_start_date),
              sch_end_date: formatDate(row.sch_end_date),
              // product_Image: row.product_Image,
            };
            return (
              <ListRow row={row} headers={headers} rowIndex={rowIndex} key={rowIndex}></ListRow>
            );
          })
        )}
      </TableBody>
    </Table>
  );
};

export { ListTable };
