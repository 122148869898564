import PropTypes from 'prop-types';
import React from 'react';
import { useEffect } from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom'; // Import Link as RouterLink
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Drawer, Typography } from '@mui/material';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import useResponsive from '../hooks/useResponsive';

import Scrollbar from '../scrollbar/scrollbar';
import NavSection from '../components/nav-section/NavSection';
//
import { schoolAdminConfig, adminConfig } from './config';
import { teacherConfig } from './config';

import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

const linkStyle = {
  textDecoration: 'none',
  ':hover': {
    textDecoration: 'underline !important',
  },
};
// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav, user }) {
  const { pathname } = useLocation();
  const auth = useSelector((x) => x.auth.value);
  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
  }, [pathname]);

  const imageUrl = auth.relatedData
    ? `${auth.relatedData.sch_img}`
    : '';
  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      {auth.relatedData && auth.relatedData.sch_img && (
        <img
          src={imageUrl}
          alt='school Logo'
          style={{ maxWidth: '100%', height: 'auto', margin: '11px', padding: '1' }}
        />
      )}
      <Box sx={{ mb: 5, mx: 2.5 }}>
        {/* Use RouterLink to wrap StyledAccount and set 'to' prop to the desired path */}
        {/* <RouterLink to={`/user/${auth.first_name + auth.last_name}`}> */}
        <RouterLink
          to={
            auth.role === 'teacher'
              ? `/edit/teacher/${auth.relatedData.teacher_id}`
              : auth.role === 'school_admin'
              ? `/edit/school/${auth && auth.relatedData?.sch_id}`
              : `/edit/student/${auth && auth.relatedData?.student_id}`
          }
          style={linkStyle}
        >
          {/* <RouterLink to={`/EditUser/${auth.relatedData.teacher_id}`} style={linkStyle}> */}
          <StyledAccount>
            <AccountCircleIcon fontSize='large' sx={{ color: '#092141' }} />
            <Box sx={{ ml: 2 }}>
              <Typography underline='hover' variant='subtitle2' sx={{ color: 'text.primary' }}>
                {auth.first_name} {auth.last_name}
              </Typography>

              <Typography underline='hover' variant='body2' sx={{ color: 'text.secondary' }}>
                {auth.role}
              </Typography>
            </Box>
          </StyledAccount>
        </RouterLink>
      </Box>
      {user && user.role === 'teacher' ? (
        <NavSection data={teacherConfig} />
      ) : user && user.role === 'school_admin' ? (
        <NavSection data={schoolAdminConfig} />
      ) : user && user.role === 'admin' ? (
        <NavSection data={adminConfig} />
      ) : (
        <NavSection data={schoolAdminConfig} />
      )}
      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      component='nav'
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant='permanent'
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              marginTop: '8vh',
              paddingTop: '20px',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
