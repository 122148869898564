import { configureStore } from '@reduxjs/toolkit';

import { alertReducer } from './alert.slice';
import { authReducer } from './auth.slice';
import studentReducer from './student.slice';
import teacherReducer from './teacher.slice';
import schoolReducer from './school.slice';
import { usersReducer } from './users.slice';
import quizReducer from './quiz.slice';
import thunk from 'redux-thunk';
import ProductReducer from './product.slice';
export * from './alert.slice';
export * from './auth.slice';
// export * from './student.slice';

export const store = configureStore({
  reducer: {
    alert: alertReducer,
    auth: authReducer,
    student: studentReducer,
    teacher: teacherReducer,
    user: usersReducer,
    school: schoolReducer,
    quiz: quizReducer,
    products: ProductReducer,
  },
  middleware: [thunk],
});
