import React from 'react';

export const QuizSummaryDetails = (quizData) => {
  if (!quizData) {
    return <div>No quiz data available.</div>;
  }

  return (
    <div>
      <h2>Quiz Summary</h2>
      {quizData &&
        quizData.quizDetails.map((question) => (
          <div key={question.id}>
            <h4>{question.questions}</h4>
            <p>Correct Answer: {question.correct_answer}</p>

            <p>Feedback: {question.feedback}</p>
          </div>
        ))}
    </div>
  );
};
