import React from 'react';
import Logo from '_assets/images/logo.gif';
import Icon from '@mui/material/Icon';
export const Footer = () => {
  return (
    <footer className='bd-footer text-white' style={{ backgroundColor: '#041327' }}>
      <div className='container-fluid py-3 px-4 px-md-4 py-md-4 mx-4 text-body-secondary'>
        <div className='row'>
          {/* First Column */}
          <div className='col-lg-3 mb-2'>
            <a
              className='d-inline-flex align-items-center mb-2 text-body-emphasis text-decoration-none'
              href='/'
              aria-label='Bootstrap'
            >
              <img
                src={Logo}
                alt='login'
                style={{ height: '100px', marginRight: '10px' }}
                onError={(e) => {
                  console.warn('Error loading image:', e);
                  e.target.style.display = 'none';
                }}
              ></img>
              <title>Bootstrap</title>
              <span className='fs-5 text-white'>Classroom-ai</span>
            </a>
            <ul className='list-unstyled'>
              <li className='mb-2 text-white lh-lg'>
                <div> We built hundreds of Interactive models to give life to the figures and concepts of Math and Science Curriculum of CBSE,State Boards and SAT.</div>
              </li>
              <li className='mb-2 text-white py-2'>
                <div className='d-flex'>
                  <Icon sx={{ color: 'white', marginRight: '8px' }}>placeholder</Icon>
                  <div> Tirunelveli, Tamil Nadu 627114</div>
                </div>
              </li>
              <li className='mb-2 text-white'>
                <div className='d-flex'>
                  <Icon sx={{ color: 'white', marginRight: '8px' }}>call</Icon>
                  <div> +91 8148493200</div>
                </div>
              </li>
              <li className='mb-2 text-white'>
                <div className='d-flex'>
                  <Icon sx={{ color: 'white', marginRight: '8px' }}>mail</Icon>
                  <div> info@newgenedu.com</div>
                </div>
              </li>
            </ul>
          </div>
          {/* Second Column */}
          <div className='col-6 col-lg-2 offset-lg-1 mb-3 mt-4'>
            <h5 className='text-white text-center mt-4'>Links</h5>
            <ul className='list-unstyled d-flex px-3' style={{ marginTop: '2.3rem' }}>
              <li className='mb-2 text-white px-3'>
                <a className='text-white text-decoration-none' href='/'>Home</a>
              </li>
              <li className='mb-2 text-white px-3'>
                <a className='text-white text-decoration-none' href='/NEET'>NEET</a>
              </li>
              <li className='mb-2 text-white px-3'>
                <a className='text-white text-decoration-none' href='/NCERT'>NCERT</a>
              </li>
            </ul>
          </div>
          {/* Third Column */}
          {/* <div className="col-6 col-lg-2 mb-3">
<h5>Guides</h5>
<ul className="list-unstyled">
<li className="mb-2"><a href="/docs/5.3/getting-started/">Getting started</a></li>
<li className="mb-2"><a href="/docs/5.3/examples/starter-template/">Starter template</a></li>
<li className="mb-2"><a href="/docs/5.3/getting-started/webpack/">Webpack</a></li>
<li className="mb-2"><a href="/docs/5.3/getting-started/parcel/">Parcel</a></li>
<li className="mb-2"><a href="/docs/5.3/getting-started/vite/">Vite</a></li>
</ul>
</div> */}
          {/* Fourth Column */}
          {/* <div className="col-6 col-lg-2 mb-3">
<h5>Projects</h5>
<ul className="list-unstyled">
<li className="mb-2"><a href="https://github.com/twbs/bootstrap" target="_blank" rel="noopener noreferrer">Bootstrap 5</a></li>
<li className="mb-2"><a href="https://github.com/twbs/bootstrap/tree/v4-dev" target="_blank" rel="noopener noreferrer">Bootstrap 4</a></li>
<li className="mb-2"><a href="https://github.com/twbs/icons" target="_blank" rel="noopener noreferrer">Icons</a></li>
<li className="mb-2"><a href="https://github.com/twbs/rfs" target="_blank" rel="noopener noreferrer">RFS</a></li>
<li className="mb-2"><a href="https://github.com/twbs/examples/" target="_blank" rel="noopener noreferrer">Examples repo</a></li>
</ul>
</div> */}
          {/* Fifth Column */}
          {/* <div className="col-6 col-lg-2 mb-3">
<h5>Community</h5>
<ul className="list-unstyled">
<li className="mb-2"><a href="https://github.com/twbs/bootstrap/issues" target="_blank" rel="noopener noreferrer">Issues</a></li>
<li className="mb-2"><a href="https://github.com/twbs/bootstrap/discussions" target="_blank" rel="noopener noreferrer">Discussions</a></li>
<li className="mb-2"><a href="https://github.com/sponsors/twbs" target="_blank" rel="noopener noreferrer">Corporate sponsors</a></li>
<li className="mb-2"><a href="https://opencollective.com/bootstrap" target="_blank" rel="noopener noreferrer">Open Collective</a></li>
<li className="mb-2"><a href="https://stackoverflow.com/questions/tagged/bootstrap-5" target="_blank" rel="noopener noreferrer">Stack Overflow</a></li>
</ul>
</div> */}
        </div>
      </div>
      <div className='d-flex  justify-content-between align-items-center py-4 px-4' style={{ backgroundColor: '#282b38' }}>
        <div className='px-4'>
          All Rights Reserved @ 3i Academy 2024
        </div>
        <ul className='d-flex list-unstyled justify-content-end align-items-center px-4 mb-0'>
          <li className='px-4'>
            <a className='text-white text-decoration-none' href="/">Terms & Conditions</a>
          </li>
          <li className='px-4'>
            <a className='text-white text-decoration-none' href="/"> Claim</a>
          </li>
          <li className='px-4'>
            <a className='text-white text-decoration-none' href="/">Privacy & Policy</a>
          </li>
        </ul>
      </div>
    </footer>
  );
};
