import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Box, TextField, Button, Typography } from '@mui/material';
import { Loader } from '_components';

function PluralChecker({ sub_section_id }) {
  const [inputWord, setInputWord] = useState('');
  const [result, setResult] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [prompt, setPrompt] = useState('');
  const [type, setType] = useState('');
  const [paragraph, setParagraph] = useState('');
  const [question, setQuestion] = useState('');
  const [letterTopic, setLetterTopic] = useState('');
  const [letterFrom, setLetterFrom] = useState('');
  const [letterTo, setLetterTo] = useState('');
  const [letterSubject, setLetterSubject] = useState('');
  const [letterContent, setLetterContent] = useState('');

  useEffect(() => {
    setTimeout(() => {
      handleLoading();
    }, 0);
    return () => {
      setInputWord('');
      setResult('');
      setIsLoading(false);
      setTitle('');
      setPrompt('');
    };
  }, [sub_section_id]);

  const handleReset = () => {
    setParagraph('');
    setQuestion('');
    setResult('');
  };
  const handleLetterSubmit = async (event) => {
    event.preventDefault();

    if (!letterTopic || !letterFrom || !letterTo || !letterSubject || !letterContent) {
      return;
    }

    try {
      setIsLoading(true);
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          messages: [
            {
              role: 'system',
              content: 'You are a language model for generating letters with format.',
            },
            {
              role: 'user',
              content: `write letter with this information in 100 words in html css: Topic: "${letterTopic}" From: "${letterFrom}" To: "${letterTo}" Subject: "${letterSubject}" Content: "${letterContent}"`,
            },
          ],
          max_tokens: 400,
          temperature: 0.2,
          model: 'gpt-3.5-turbo-0125',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
        },
      );

      const answer = response.data.choices[0].message.content;
      setResult(answer);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setResult('API request failed. Please try again later.');
      console.error(error);
    }
  };

  function handleLoading() {
    const grammer = document.getElementById('grammer-topic');
    const composition = document.getElementById('composition-topic');
    const letter = document.getElementById('letter-topic');

    if (grammer) {
      const action = grammer.getAttribute('data-action');
      if (action && action.startsWith('topic(')) {
        let dynamicData = action.slice(6, -1).replace('-', ' ');
        dynamicData = dynamicData.split(',');
        setTitle(dynamicData[0]);
        setPrompt(dynamicData[1]);
        setType('grammer');
      }
    } else if (composition) {
      const action = composition.getAttribute('data-action');
      if (action && action.startsWith('topic(')) {
        let dynamicData = action.slice(6, -1).replace('-', ' ');
        dynamicData = dynamicData.split(',');
        setTitle(dynamicData[0]);
        setPrompt(dynamicData[1]);
        setType('composition');
      }
    } else if (letter) {
      const action = letter.getAttribute('data-action');
      if (action && action.startsWith('topic(')) {
        let dynamicData = action.slice(6, -1).replace('-', ' ');
        dynamicData = dynamicData.split(',');
        setTitle(dynamicData[0]);
        setPrompt(dynamicData[1]);
        setType('letter');
      }
    }
  }

  const handleGrammerSubmit = async () => {
    if (!inputWord) {
      return;
    }

    try {
      setIsLoading(true);
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          messages: [
            { role: 'system', content: `You are a language model that checks ${title}.` },
            { role: 'user', content: `${prompt} : "${inputWord}"` },
          ],
          max_tokens: 20,
          temperature: 0.2,
          model: 'gpt-3.5-turbo-0125',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
        },
      );

      const answer = response.data.choices[0].message.content;
      setResult(answer);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setResult('API request failed. Please try again later.');
      console.error(error);
    }
  };
  if (isLoading) {
    <Loader />;
  }
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!paragraph || !question) {
      return;
    }

    try {
      setIsLoading(true);
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          messages: [
            { role: 'system', content: `You are a language model that checks ${title}.` },
            {
              role: 'user',
              content: `${prompt} : "question :${question}" ; paragraph :${paragraph}`,
            },
          ],
          max_tokens: 100,
          temperature: 0.2,
          model: 'gpt-3.5-turbo-0125',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
        },
      );

      const answer = response.data.choices[0].message.content;
      setResult(answer);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setResult('API request failed. Please try again later.');
      console.error(error);
    }
  };
  return (
    <Container maxWidth='xl'>
      <Typography className='text-align-center' variant='h4' gutterBottom>
        {title}
      </Typography>
      {type && type === 'composition' && (
        <div>
          <form onSubmit={handleSubmit}>
            <label htmlFor='paragraph' className='h4'>
              Enter the paragraph:
            </label>
            <br />
            <textarea
              id='paragraph'
              name='paragraph'
              rows='8'
              value={paragraph}
              onChange={(e) => setParagraph(e.target.value)}
              className='w-100 bg-white rounded text-secondary border'
            ></textarea>
            <br />

            <label htmlFor='question' className='h4'>
              Ask a question:
            </label>
            <br />
            <input
              type='text'
              id='question'
              name='question'
              value={question}
              className=' border'
              onChange={(e) => setQuestion(e.target.value)}
            />
            <br />
            <div className='mt-4'>
              <Button type='submit' variant='contained' color='primary' className='mx-2'>
                Submit
              </Button>

              <Button type='button' variant='contained' color='secondary' onClick={handleReset}>
                Reset
              </Button>
            </div>
          </form>
          {isLoading && <Loader />}
          {result && (
            <Box mt={2} display='flex' justifyContent='space-between' alignItems='center'>
              <Typography variant='subtitle1'>
                <strong>Result:</strong> <span className='text-success'>{result}</span>
              </Typography>
            </Box>
          )}
        </div>
      )}
      {type && type === 'letter' && (
        <form onSubmit={handleLetterSubmit}>
          <label htmlFor='topic' className='h4'>
            Topic: (Example: Leave letter)
          </label>
          <br />
          <input
            type='text'
            id='topic'
            name='topic'
            value={letterTopic}
            onChange={(e) => setLetterTopic(e.target.value)}
            required
          />
          <br />
          <br />

          <label htmlFor='from' className='h4'>
            From: (Example: Name: surya Address: xxxx, street, country)
          </label>
          <br />
          <input
            type='text'
            id='from'
            name='from'
            value={letterFrom}
            onChange={(e) => setLetterFrom(e.target.value)}
            required
          />
          <br />
          <br />

          <label htmlFor='to' className='h4'>
            To: (Example: To: The Class Teacher, school name)
          </label>
          <br />
          <input
            type='text'
            id='to'
            name='to'
            value={letterTo}
            onChange={(e) => setLetterTo(e.target.value)}
            required
          />
          <br />
          <br />

          <label htmlFor='subject' className='h4'>
            Subject: (Example: Subject: Leave letter)
          </label>
          <br />
          <input
            type='text'
            id='subject'
            name='subject'
            value={letterSubject}
            onChange={(e) => setLetterSubject(e.target.value)}
            required
          />
          <br />
          <br />

          <label htmlFor='content' className='h4'>
            Content: (Example: Due to fever)
          </label>
          <br />
          <textarea
            id='content'
            name='content'
            value={letterContent}
            onChange={(e) => setLetterContent(e.target.value)}
            required
            className='w-100 bg-white rounded text-secondary border'
          ></textarea>
          <br />
          <br />

          <div className='mt-4'>
            <Button type='submit' variant='contained' color='primary' className='mx-2'>
              Generate Letter
            </Button>
            <Button type='button' variant='contained' color='secondary' onClick={handleReset}>
              Reset
            </Button>
          </div>
        </form>
      )}
      {isLoading && <Loader />}
      {result && (
        <Typography variant='subtitle1' className='pt-3'>
          <strong>Result:</strong>{' '}
          <span className='text-success' dangerouslySetInnerHTML={{ __html: result }}></span>
        </Typography>
      )}
      {type && type === 'grammer' && (
        <>
          <TextField
            fullWidth
            label={title === 'ESSAY WRITING' ? 'Enter your essay topic' : 'Enter sentence'}
            variant='outlined'
            value={inputWord}
            onChange={(e) => setInputWord(e.target.value)}
            margin='normal'
          />
          <Button variant='contained' color='primary' onClick={handleGrammerSubmit}>
            Check
          </Button>
          {isLoading && <Loader />}
          <Box mt={2} display='flex' justifyContent='space-between' alignItems='center'>
            <Typography variant='subtitle1'>
              <strong>Input:</strong> {inputWord}
            </Typography>
            <Typography variant='subtitle1'>
              <strong>Result:</strong> <span className='text-success'>{result}</span>
            </Typography>
          </Box>
        </>
      )}
    </Container>
  );
}

export default PluralChecker;
