import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Card, Stack, Button, Container, Typography } from '@mui/material';
import { Loader } from '_components/navigation/Loader';
import { ListTable } from '_components';
import { getQuizAccounts, quizAccounts } from '_store/quiz.slice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import 'colors.css';
import Pagination from '@mui/material/Pagination';
import { Page404 } from '../Page404';

const QuizList = () => {
  const [isLoading, setisLoading] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const _quizAccount = useSelector(quizAccounts);

  const [totalPages, setTotalPages] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  let pageSize = 10;
  const handlePageChange = (event, n) => {
    setCurrentPage(n);
    dispatch(getQuizAccounts(n, pageSize, searchTerm));
  };

  useEffect(() => {
    if (_quizAccount && _quizAccount.length !== 0) {
      if (_quizAccount.pagination && _quizAccount.pagination.totalPages !== undefined) {
        setTotalPages(_quizAccount && _quizAccount?.pagination?.totalPages);
        setisLoading(false);
      } else {
        console.error(
          'Error: _quizAccount.pagination or _quizAccount.pagination.totalPages is undefined',
        );
        setisLoading(true);
      }
    } else {
      setisLoading(true);
    }
  }, [_quizAccount, currentPage]);

  useEffect(() => {
    dispatch(getQuizAccounts(currentPage, pageSize));
  }, [currentPage]);

  const handleSearch = (term) => {
    setSearchTerm(term);
    const trimedValue = term.trim();
    setCurrentPage(0);
    if (!term) {
      dispatch(getQuizAccounts(0, 10, trimedValue));
    }
    trimedValue && dispatch(getQuizAccounts(0, 10, trimedValue));
  };

  if (!_quizAccount || _quizAccount.length === 0) {
    return (
      <>
        <Helmet>
          <title> Quiz List </title>
        </Helmet>
        <Container>
          <Typography variant='h4' gutterBottom>
            <Page404 message='No  Quiz found ' name={'NoDataFound'} />
          </Typography>
        </Container>
      </>
    );
  }
  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <Helmet>
        <title> Quiz List </title>
      </Helmet>
      <Container maxWidth='xl'>
        <Typography variant='h4' gutterBottom>
          Quiz List
        </Typography>

        <Stack direction='row' alignItems='center' justifyContent='space-between' mb={5}>
          <div className='d-flex' style={{ justifyContent: 'flex-start' }}>
            <input
              type='text'
              placeholder='Search...'
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
          <div className='d-flex'>
            <Button
              className='mx-2'
              startIcon=''
              sx={{
                backgroundColor: `var(--Peach)`,
                '&:hover': {
                  backgroundColor: 'var(--Blue)',
                },
                color: `var(--White)`,
              }}
              onClick={() => navigate('/AddQuiz')}
            >
              New Quiz
            </Button>
            <Button
              className='mx-2'
              startIcon=''
              sx={{
                backgroundColor: `var(--Peach)`,
                '&:hover': {
                  backgroundColor: 'var(--Blue)',
                },
                color: `var(--White)`,
              }}
              onClick={() => navigate('/AddMockQuiz')}
            >
              New Mock Quizzes
            </Button>
          </div>
        </Stack>

        <Card sx={{ boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' }}>
          <ListTable
            data={_quizAccount['results']}
            headers={[
              'grade',
              'subject',
              'board',
              'state',
              'school_id',
              'chapter_id',
              'section_id',
              'subsection_id',
              'teacher_email',
              'quiz_name',
              'quiz_title',
            ]}
            columnHeaders={[
              'Grade',
              'Subject',
              'Board',
              'state',
              'School Id ',
              'Chapter Id',
              'Section Id',
              'Subsection Id ',
              'Teacher Email',
              'Quiz Name',
              'Quiz Title ',
            ]}
          />

          <Container align='center' justify='center' p={10} w='full'>
            <Pagination
              count={totalPages}
              shape='rounded'
              className={`controls ${currentPage}`}
              sx={{ justifyContent: 'center' }}
              onChange={(e, n) => handlePageChange(e, n)}
            />
          </Container>
        </Card>
      </Container>
    </>
  );
};

export { QuizList };
