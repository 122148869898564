import React, { useState } from 'react';
import './Ch3f3115.css'; // Import CSS for styling

export const Ch3f3115 = () => {
  const [selectedFamily, setSelectedFamily] = useState('none'); // State for selected family

  // Function to handle family change
  const handleFamilyChange = (event) => {
    setSelectedFamily(event.target.value);
  };

  // Function to get background color based on selected family
  const getBackgroundColor = (ductilityLevel) => {
    switch (selectedFamily) {
      case 'highlyDuctile':
        return ductilityLevel === 'Highly' ? 'gold' : 'none';
      case 'quiteDuctile':
        return ductilityLevel === 'Quite' ? 'yellow' : 'none';
      case 'lessDuctile':
        return ductilityLevel === 'Less' ? 'lightblue' : 'none';
      case 'moderatelyDuctile':
        return ductilityLevel === 'Moderately' ? 'lightgreen' : 'none';
      case 'fairlyDuctile':
        return ductilityLevel === 'Fairly' ? 'gray' : 'none';
      default:
        return 'none'; // Reset background color to "none" when selectedFamily is "none"
    }
  };

  // Sample wire data
  const wireData = [
    {
      name: 'Copper',
      usage:
        'Copper wires are widely used in electrical applications due to their excellent electrical conductivity. They are commonly found in electrical wiring, power cables, household appliances, and electronics.',
      ductility: 'Highly',
    },
    {
      name: 'Aluminium',
      usage:
        'Aluminum wires are also used in electrical applications, particularly in power transmission and distribution lines. They are lightweight and have good conductivity, making them suitable for long-distance transmission.',
      ductility: 'Quite',
    },
    {
      name: 'Steel',
      usage:
        'Steel wires are used in a variety of applications, including construction, automotive, and industrial sectors. They are often used for reinforcing structures, such as in concrete or as support cables in bridges.',
      ductility: 'Less',
    },
    {
      name: 'Iron',
      usage:
        'Iron wires are less common in daily life compared to other metals. However, they may be found in certain applications such as fencing, artwork, or DIY projects.',
      ductility: 'Less',
    },
    {
      name: 'Nickel',
      usage:
        'Nickel wires are used in specialized applications, including heating elements, thermocouples, and certain electronic components.',
      ductility: 'Moderately',
    },
    {
      name: 'Brass',
      usage:
        'Brass wires, which are composed of copper and zinc, are used in jewelry making, craftwork, and musical instruments.',
      ductility: 'Fairly',
    },
  ];

  return (
    <div>
      {/* Dropdown to select ductility level */}
      <div className='drop-down-div'>
        <label className='family-name' htmlFor='family'>
          Choose a Ductility Level:{' '}
        </label>
        <select className='drop-down' name='family' id='family' onChange={handleFamilyChange}>
          <option className='drop-down' value='none'>
            Choose a Ductility Level
          </option>
          <option className='drop-down' value='highlyDuctile'>
            Highly Ductile
          </option>
          <option className='drop-down' value='quiteDuctile'>
            Quite Ductile
          </option>
          <option className='drop-down' value='lessDuctile'>
            Less Ductile
          </option>
          <option className='drop-down' value='moderatelyDuctile'>
            Moderately Ductile
          </option>
          <option className='drop-down' value='fairlyDuctile'>
            Fairly Ductile
          </option>
        </select>
      </div>
      {/* Table to display wire information */}
      <div className='table-div'>
        <table>
          <tbody>
            <tr>
              <th>Wire Name</th>
              <th>Usage</th>
              <th>Ductility Level</th>
            </tr>
            {/* Rows for wire information */}
            {wireData.map((wire, index) => (
              <tr key={index} style={{ background: getBackgroundColor(wire.ductility) }}>
                <td>{wire.name}</td>
                <td>{wire.usage}</td>
                <td>{wire.ductility}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
