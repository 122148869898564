import React from 'react';
import VideoAI from './video/videoAI.mp4';

export const NEET = () => {
  return (
    <div>
      <video controls autoPlay loop muted>
        <source src={VideoAI} type='video/mp4' />
      </video>
    </div>
  );
};
