import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { useState, useEffect } from 'react';
import { fetchWrapper } from '_helpers';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Button, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function PerformanceAnalysis(props) {
  const user = useSelector((x) => x.auth.value);
  const [aiDetail, setAiDetails] = useState([]);
  const { subject, chapterId, analysisChartData } = props;
  const [dropdown1Value, setDropdown1Value] = useState('');
  const [dropdown2Value, setDropdown2Value] = useState('');
  const [dropdown1SectionId, setDropdown1SectionId] = useState('');
  const [selectedlevel, setSelectedLevel] = useState([]);
  const [selectedAttempt, setSelectedAttempt] = useState('');
  const navigate = useNavigate();

  // Function to split the subsection title and extract the part after the colon
  const splitFromFirstSpace = (str) => {
    if (typeof str === 'string') {
      const parts = str.split(':');
      return parts.length > 1 ? parts[1].trim() : str;
    } else {
      return str;
    }
  };

  const subsectionTitle = analysisChartData.section.map((section) => splitFromFirstSpace(section.subsection));

  const handleDropdown1Change = (event) => {
    const selectedSectionTitle = event.target.value;
    const selectedSection = analysisChartData.section.find((section) => section.section_title === selectedSectionTitle);
    if (selectedSection) {
      const selectedSectionId = selectedSection.section_id;
      setDropdown1Value(selectedSectionTitle);
      setDropdown2Value('');
      setDropdown1SectionId(selectedSectionId);
    }
  };

  const handleDropdown2Change = (event) => {
    setDropdown2Value(event.target.value);
  };

  useEffect(() => {
    async function aiData() {
      try {
        let aiChapter = chapterId;
        let aiEmail = user.email;
        let aiSubject = subject;

        const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;
        let response = await fetchWrapper.get(
          `${baseUrl}/getAiPerformance?chapter_id=${aiChapter}&email=${aiEmail}&subject=${aiSubject}`
        );
        setAiDetails(response.aiPerformance);
      } catch (error) {
        console.error('Error fetching AI data:', error);
      }
    }
    if (aiDetail.length === 0) {
      aiData();
    }
  }, [aiDetail.length, chapterId, subject]);

  const sectionTitlesArray = analysisChartData.section
    .filter(section => {
      return section.subsection.some(sub => sub.sub_section_title.toLowerCase().includes('quiz'));
    })
    .map(section => section.section_title);

  const filteredSubsections = subsectionTitle.filter((subsectionGroup) =>
    subsectionGroup.some((sub) => sub.section_id === dropdown1SectionId)
  );

  const filteredSubsectionsWithQuiz = filteredSubsections.flatMap((subsectionGroup) =>
    subsectionGroup.filter((subsection) => subsection.sub_section_title.toLowerCase().includes('quiz'))
  );

  // Handle AI Performance Data
  const handleAiPerformance = (aiDetail, selectedSubsectionId) => {
    const attempts = {};
    aiDetail
      .filter(detail => detail.sub_section_id === selectedSubsectionId)
      .forEach(detail => {
        const { id, level, percent_score } = detail;
        if (!attempts[level]) {
          attempts[level] = [];
        }
        attempts[level].push({ id, percent_score });
      });

    // Ensure only the last four attempts are returned for each level
    for (const level in attempts) {
      attempts[level] = attempts[level].slice(-4);
    }
    return attempts;
  };

  const attemptsData = handleAiPerformance(aiDetail, dropdown2Value);

  // Initialize an object to hold data for all attempts
  const attemptData = {};

  // Loop through attempts and populate attempt data
  for (let i = 0; i < 4; i++) {
    const attemptNumber = i + 1;
    const attempt = {};
    for (const level in attemptsData) {
      if (attemptsData[level][i]) {
        attempt[level] = {
          id: attemptsData[level][i].id,
          percent_score: attemptsData[level][i].percent_score // Get the score for the corresponding attempt index
        };
      }
    }
    attemptData[`Attempt ${attemptNumber}`] = attempt;
  }

  const examNames = Object.keys(attemptsData);
  const xLabels = ['Attempt 1', 'Attempt 2', 'Attempt 3', 'Attempt 4'];

  // Define a color mapping for different levels
  const levelColors = {
    'easy': '#198754',
    'medium': '#ffc107',
    'hard': '#dc3545',
    'real-world': '#6c757d',
    'custom-quiz': '#2196f3'
  };

  const series = examNames.map((examName) => ({
    data: attemptsData[examName].map(item => item.percent_score),
    label: examName,
    color: levelColors[examName.toLowerCase()] || '#2196f3'
  }));

  // Function to handle filtering by difficulty level
  const handleLevelFilter = (level) => {
    if (selectedlevel.includes(level)) {
      setSelectedLevel(selectedlevel.filter((selected) => selected !== level));
    } else {
      setSelectedLevel([...selectedlevel, level]);
    }
  };

  // Function to handle filtering by attempt
  const handleAttemptFilter = (attempt) => {
    setSelectedAttempt(attempt);
  };
  const handleHelpClick = (sectionId, subSectionId) => {

    const { subject, chapterId } = props;
    const correctedSubSectionId = subSectionId.slice(0, -1) + 'p';
    const url = `/dashboard/NEET/subject/${subject}/chapter/${chapterId}/section/${sectionId}/subsection/${correctedSubSectionId}`;
    navigate(url);
  };

  return (
    <>
      <div>
        <Box className="d-flex">
          <FormControl fullWidth className='p-2'>
            <InputLabel>Section</InputLabel>
            <Select value={dropdown1Value} onChange={handleDropdown1Change}>
              {sectionTitlesArray.length === 0 && <MenuItem disabled>No section available</MenuItem>}
              <MenuItem value=''>None</MenuItem>
              {sectionTitlesArray.map((title, index) => (
                <MenuItem key={index} value={title}>
                  {title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth className="p-2">
            <InputLabel>Subsection</InputLabel>
            <Select value={dropdown2Value} onChange={handleDropdown2Change}>
              <MenuItem value="">None</MenuItem>
              {filteredSubsectionsWithQuiz.map((subsection) => (
                <MenuItem key={subsection.sub_section_id} value={subsection.sub_section_id}>
                  {splitFromFirstSpace(subsection.sub_section_title)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </div>
      {dropdown1Value && dropdown2Value && (
        <>
          <BarChart width={1000} height={300} series={series} xAxis={[{ data: xLabels, scaleType: 'band' }]} yAxis={[{ min: 0, max: 100 }]} />
          <div className='d-flex  mb-2'>
            {['easy', 'medium', 'hard', 'real-world', 'custom-quiz'].map((level) => (
              <Button size="small"
                key={level}
                variant={selectedlevel.includes(level) ? 'contained' : 'outlined'}
                onClick={() => handleLevelFilter(level)}
                sx={{ minWidth: 0, width: 100, padding: '2px 2px', height: 35, marginRight: '10px' }}
              >
                {level}
              </Button>
            ))}

            <FormControl fullWidth className="w-50" size="small">
              <InputLabel id="demo-simple-select-label">Attempt</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedAttempt}
                label='Attempt'
                style={{ textAlign: 'left' }}
                onChange={(e) => handleAttemptFilter(e.target.value)}
                sx={{
                  width: 140,
                }}
              >
                {xLabels.map((label) => (
                  <MenuItem key={label} value={label}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              sx={{
                minWidth: 0,
                width: 110,
                padding: '2px 4px !important',
                height: 35,
                backgroundColor: selectedlevel.length === 0 && selectedAttempt.length === 0 ? '#dc3545' : '#fff',
                color: selectedlevel.length === 0 && selectedAttempt.length === 0 ? '#fff' : '#dc3545',
                borderColor: selectedlevel.length === 0 && selectedAttempt.length === 0 ? '#dc3545' : '#dc3545',
                '&:hover': {
                  backgroundColor: '#dc3545',
                  color: '#fff',
                  border: '#dc3545'
                }
              }}
              variant={
                selectedlevel.length === 0 && selectedAttempt.length === 0
                  ? 'contained'
                  : 'outlined'
              }
              onClick={() => {
                setSelectedLevel([]);
                setSelectedAttempt('');
              }}
            >
              Clear Filter
            </Button>
          </div>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow >
                  <TableCell>Test ID</TableCell>
                  <TableCell align="center">Quiz Name</TableCell>
                  <TableCell align="center">Level</TableCell>
                  <TableCell align="center">Attempt</TableCell>
                  <TableCell align="center">Attempt Date</TableCell>
                  <TableCell align="center">Obtained Marks</TableCell>
                  <TableCell align="center">Total Correct Answers</TableCell>
                  <TableCell align="center">Total Wrong Answers</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {aiDetail
                  .filter(data => {
                    const selectedAttemptData = attemptData[selectedAttempt]; // Retrieve data for the selected attempt
                    const isAttemptMatch = !selectedAttempt || // If no attempt is selected, show all data
                      (selectedAttemptData && selectedAttemptData[data.level] && data.id === selectedAttemptData[data.level].id); // Match with the selected attempt's ID for the corresponding level
                    const attemptKeys = Object.keys(attemptData);
                    const isBarChartAttempt = attemptKeys.some(key => {
                      const attempt = attemptData[key][data.level];
                      return attempt && attempt.id === data.id;
                    });
                    return isAttemptMatch && isBarChartAttempt && // Match with selected attempt from dropdown and attempts shown in bar chart
                      data.sub_section_id === dropdown2Value && // Match subsection ID
                      (selectedlevel.length === 0 || selectedlevel.includes(data.level)); // Match selected levels
                  })
                  .map((data, index) => {
                    const attempt = Object.keys(attemptData).find(key => attemptData[key][data.level]?.id === data.id);
                    return (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">{data.id}</TableCell>
                        <TableCell align="center">{splitFromFirstSpace(data.subsection)}</TableCell>
                        <TableCell align="center">{data.level}</TableCell>
                        <TableCell align="center">{attempt}</TableCell>
                        <TableCell align="center">{new Date(data.created_at).toLocaleString()}</TableCell>
                        <TableCell align="center">{data.obtained_marks}</TableCell>
                        <TableCell align="center">{data.right_answers}</TableCell>
                        <TableCell align="center">{data.wrong_answers}</TableCell>
                        <TableCell align="center"><Button onClick={() => handleHelpClick(data.section_id, data.sub_section_id)}>HELP ME!</Button></TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}
