import React from 'react';
import { Box, Typography } from '@mui/material';

const FeedbackDisplay = ({ currentQuestion, chatbot }) => {
  if (
    !currentQuestion ||
    !currentQuestion.correct_answer ||
    !currentQuestion.section_name ||
    !currentQuestion.subsection_name ||
    (!currentQuestion.feedback && !currentQuestion.feedback_image)
  ) {
    return null; // Handle case where currentQuestion or its properties are undefined
  }

  const { correct_answer, section_name, subsection_name, feedback, feedback_image, options } =
    currentQuestion;
  const correctAnswerText = options[correct_answer - 1];
  const correctAnswerImage = correctAnswerText?.match(/\.(jpeg|jpg|gif|png)$/);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', margin: '20px' }}>
      <Typography>Section: {section_name}</Typography>
      <Typography>Subsection: {subsection_name}</Typography>
      <Typography>
        Correct answer: ({correct_answer}).{' '}
        {correctAnswerImage ? (
          <img
            src={correctAnswerText}
            alt={`Option ${correct_answer}`}
            style={{ maxWidth: '75%', maxHeight: '75%', margin: '10px' }}
          />
        ) : (
          correctAnswerText
        )}
      </Typography>
      <Typography sx={{ color: 'red' }}>Explanation: </Typography>
      <Box>
        {feedback && <span>{feedback}</span>}
        {feedback_image && (
          <Box
            component='img'
            sx={{ maxWidth: '75%', maxHeight: '75%', margin: '10px' }}
            src={feedback_image}
            alt='Question related'
          />
        )}
      </Box>
      <p style={{ color: 'red', cursor: 'pointer' }} onClick={() => chatbot(true)}>
        Learn More
      </p>
    </Box>
  );
};

export default FeedbackDisplay;
