export const Ch1_Modal1 = {
  CATEGORY_ONE: ['Hydrogen', 'Magnesium', 'Zinc', 'Iron', 'Calcium'],
  CATEGORY_TWO: ['Oxygen', 'Water', 'Sulfuric Acid', 'Chlorine', 'Nitrogen'],
  CATEGORY_THREE: [
    'Water',
    'Magnesium Oxide',
    'Iron(III) Oxide',
    'Hydrogen Chloride',
    'Magnesium Chloride',
    'Calcium Chloride',
    'Ammonia ',
  ],
  CATEGORY_FOUR: ['Hydrogen'],
};

export const Ch1_Modal2 = {
  CATEGORY_ONE: [
    'Iron',
    'Zinc',
    'Methane',
    'Sodium',
    'Hydrogen',
    'Hydrochloric Acid',
    'Calcium Hydroxide',
    'Hydrogen Sulphide ',
    'Barium Chloride',
    'Potassium',
    'Nitric Acid',
    'Sodium Hydroxide',
  ],
  CATEGORY_TWO: [
    'Water',
    'Sulfuric Acid',
    'Calcium Chloride',
    'Carbon Dioxide',
    'Oxygen',
    'Aluminum Sulfate',
    'Calcium Hydroxide',
  ],
  CATEGORY_THREE: [
    'Water',
    'Iron(III) Oxide',
    'Zinc Sulfate',
    'Carbon Dioxide',
    'Sodium',
    'Calcium Hydroxide',
    'Hydrochloric Sulfate',
    'Calcium Carbonate',
    'Sodium Hydroxide',
    'Aluminum Chloride',
    'Potassium Hydroxide',
    'Calcium Nitrate',
    'Sodium Sulfate',
  ],

  CATEGORY_FOUR: ['Hydrogen', 'Water', 'Hydroxide', 'Hydrogen Chloride', 'Barium Sulfate'],
};

export const Ch1_Modal3 = {
  CATEGORY_ONE: ['Methane', 'Sodium', 'Copper', 'Hydrogen', 'Iron', 'Magnesium'],
  CATEGORY_TWO: ['Oxygen'],
  CATEGORY_THREE: [
    'Carbon Dioxide',
    'Sodium Oxide',
    'Copper Oxide',
    'Water',
    'Iron Oxide',
    'Magnesium Oxide',
  ],
  CATEGORY_FOUR: ['Water'],
};

export const Ch1_Modal4 = {
  CATEGORY_ONE: ['Copper', 'Copper Oxide', 'Zinc Oxide', 'Manganese Oxide', 'Zinc'],
  CATEGORY_TWO: ['Oxygen', 'Hydrogen', 'Carbon', 'Hydrochloric Acid', 'Silver Nitrate'],
  CATEGORY_THREE: ['Copper Oxide', 'Copper', 'Zinc', 'Manganese Chloride', 'Zinc Nitrate'],
  CATEGORY_FOUR: ['Water', 'Carbon Monoxide', 'Chlorine', 'Silver'],
  CATEGORY_FIVE: ['Water'],
};

export const Ch1_Modal5 = {
  CATEGORY_ONE: ['Calcium Oxide', 'Calcium Hydroxide', 'Methane', 'Carbon', 'Hydrogen', 'Sodium'],
  CATEGORY_TWO: ['Water', 'Carbon Dioxide', 'Oxygen', 'Chlorine'],
  CATEGORY_THREE: [
    'Calcium Hydroxide',
    'Calcium Carbonate',
    'Carbon Dioxide',
    'Water',
    'Sodium Oxide',
    'Hydrochloric Acid',
  ],
  CATEGORY_FOUR: ['Heat', 'Water'],
};

export const Ch1_Modal6 = {
  CATEGORY_ONE: ['Iron', 'Zinc', 'Lead', 'Ferric Oxide', 'Aluminum', 'Magnesium'],
  CATEGORY_TWO: ['Copper Sulfate', 'Copper Chloride', 'Aluminum', 'Hydrochloric Acid'],
  CATEGORY_THREE: [
    'Iron Sulfate',
    'Zinc Sulfate',
    'Lead Chloride',
    'Aluminum Oxide',
    'Aluminum Chloride',
    'Magnesium Chloride',
  ],
  CATEGORY_FOUR: ['Copper', 'Iron', 'Hydrogen'],
};

export const Ch1_Modal7 = {
  CATEGORY_ONE: [
    'Sodium Sulfate',
    'Copper Sulfate',
    'Sodium Chloride',
    'Barium Chloride',
    'Potassium Bromide',
  ],
  CATEGORY_TWO: [
    'Barium Chloride',
    'Hydrogen Sulfide',
    'Silver Nitrate',
    'Hydrochloric Sulfate',
    'Potassium Sulfate',
    'Barium Chloride',
    'Potassium Chloride',
  ],
  CATEGORY_THREE: ['Barium Sulfate', 'Cupric Sulfide', 'Silver Chloride', 'Potassium Chloride'],
  CATEGORY_FOUR: [
    'Sodium Chloride',
    'Sulfuric Acid',
    'Sodium Nitrate',
    'Hydrochloric Acid',
    'Potassium Chloride',
    'Barium Bromide ',
  ],
};

export const Ch2_Modal1 = {
  CATEGORY_ONE: ['Hydrochloric Acid', 'Sulfuric Acid'],
  CATEGORY_TWO: ['Magnesium', 'Zinc', 'Aluminum', 'Iron', 'Sodium'],
  CATEGORY_THREE: [
    'Magnesium Chloride',
    'Zinc Sulfate',
    'Aluminum Sulfate',
    'Zinc Chloride',
    'Ferric Chloride',
    'Sodium Sulfate',
  ],
  CATEGORY_FOUR: ['Hydrogen'],
};

export const Ch2_Modal2 = {
  CATEGORY_ONE: ['Sodium Carbonate', 'Calcium Carbonate'],
  CATEGORY_TWO: ['Hydrochloric Acid', 'Sulfuric Acid', 'Nitric Acid'],
  CATEGORY_THREE: ['Sodium Chloride', 'Calcium Sulfate', 'Sodium Nitrate'],
  CATEGORY_FOUR: ['Carbon Dioxide'],
  CATEGORY_FIVE: ['Water'],
};

export const Ch2_Modal3 = {
  CATEGORY_ONE: ['Sulfuric Acid', 'Hydrochloric Acid'],
  CATEGORY_TWO: ['Sodium Hydroxide', 'Ammonium Hydroxide'],
  CATEGORY_THREE: ['Sodium Sulfate', 'Sodium Chloride', 'Ammonium Chloride'],
  CATEGORY_FOUR: ['Water'],
};
export const Ch2_Modal7 = {
  CATEGORY_ONE: [
    'Sodium Chloride',
    'Calcium Hydroxide',
    'Sodium Hydrogen Carbonate',
    'Anhydrous Sodium Carbonate',
    'Plaster of Paris',
  ],
  CATEGORY_TWO: ['Water', 'Chlorine'],
  CATEGORY_THREE: ['Sodium Hydroxide', 'Calcium Oxychloride', 'Sodium Carbonate', 'Gypsum'],
  CATEGORY_FOUR: ['Water', 'Chlorine'],
  CATEGORY_FIVE: ['Hydrogen', 'Carbon Dioxide'],
};
export const Ch2_Modal13 = {
  CATEGORY_ONE: ['Hydrochloric Acid', 'Sulfuric Acid'],
  CATEGORY_TWO: ['Calcium Oxide', 'Zinc Oxide'],
  CATEGORY_THREE: ['Calcium Chloride', 'Zinc Sulfate'],
  CATEGORY_FOUR: ['Water'],
};

export const Ch2_Modal12A = {
  CATEGORY_ONE: ['Sodium Hydroxide'],
  CATEGORY_TWO: ['Zinc', 'Aluminum'],
  CATEGORY_THREE: ['Water'],
  CATEGORY_FOUR: ['Sodium Zincate', 'Sodium Aluminate'],
  CATEGORY_FIVE: ['Hydrogen'],
};

export const Ch2_Modal12B = {
  CATEGORY_ONE: ['Sodium Hydroxide', 'Calcium Hydroxide'],
  CATEGORY_TWO: ['Carbon Dioxide'],
  CATEGORY_THREE: ['Sodium Carbonate', 'Calcium Carbonate'],
  CATEGORY_FOUR: ['Water'],
};

export const Ch3_Modal1 = {
  CATEGORY_ONE: ['Sodium', 'Magnesium', 'Copper', 'Aluminum'],
  CATEGORY_TWO: ['Oxygen'],
  CATEGORY_THREE: ['Sodium Oxide', 'Magnesium Oxide', 'Copper Oxide', 'Aluminum Oxide'],
};
export const Ch3_Modal1B = {
  CATEGORY_ONE: ['Aluminum Oxide'],
  CATEGORY_TWO: ['Sodium Hydroxide', 'Hydrochloric Acid'],
  CATEGORY_THREE: ['Aluminum Chloride', 'Sodium Aluminate'],
  CATEGORY_FOUR: ['Water'],
};

export const Ch3_Modal2 = {
  CATEGORY_ONE: ['Sodium', 'Potassium', 'Calcium', 'Magnesium', 'Aluminum', 'Iron'],
  CATEGORY_TWO: ['Water'],
  CATEGORY_THREE: [
    'Sodium Hydroxide',
    'Calcium Hydroxide',
    'Potassium Hydroxide',
    'Magnesium Hydroxide',
    'Aluminum Oxide',
    'Iron (II, III) Oxide',
  ],
  CATEGORY_FOUR: ['Hydrogen'],
};

export const Ch3_Modal3 = {
  CATEGORY_ONE: ['Iron', 'Magnesium', 'Zinc', 'Aluminum'],
  CATEGORY_TWO: ['Hydrochloric Acid', 'Nitric Acid'],
  CATEGORY_THREE: [
    'Ferrous Chloride',
    'Magnesium Chloride',
    'Zinc Chloride',
    'Aluminum Chloride',
    'Magnesium Nitrate',
  ],
  CATEGORY_FOUR: ['Hydrogen'],
};
export const Ch3_Modal4 = {
  CATEGORY_ONE: ['Iron', 'Zinc'],
  CATEGORY_TWO: ['Copper Sulfate'],
  CATEGORY_THREE: ['Iron Sulfate', 'Zinc Sulfate'],
  CATEGORY_FOUR: ['Copper'],
};
