import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Divider,
  Card,
  CardContent,
  Radio,
  RadioGroup,
  FormControlLabel,
  IconButton,
  Stack,
  Accordion,
  AccordionSummary,
  MenuItem,
  TextField,
  FormControl,
  FormGroup,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { fetchWrapper } from '_helpers';
import { Helmet } from 'react-helmet';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import { useSelector } from 'react-redux';
import Scrollbar from 'pages/teacherDashboard/scrollbar/scrollbar';

const QuizDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { rowData } = location.state;
  const [quizDetails, setQuizDetails] = useState([]);
  const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const user = useSelector((x) => x.auth.value);
  let board = user && user.board;
  let School_id = user && user.relatedData.sch_id;
  console.log(rowData, 'rowData');
  useEffect(() => {
    async function fetchData() {
      try {
        let grade = rowData.grade;
        let subject = rowData.subject;
        let section_id = rowData.section_id;
        let subsection_id = rowData.subsection_id;
        let chapter_id = rowData.chapter_id;
        let res;
        if (rowData && rowData.board == 'NEET') {
          res = await fetchWrapper.get(
            `${baseUrl}/getSingleQuizzes?chapter=${chapter_id}&section=${section_id}&subsection=${subsection_id}&subject=${subject}`,
          );
        } else {
          res = await fetchWrapper.get(
            `${baseUrl}/getSingleQuizzes?board=${board}&school_id=${School_id}&grade=${grade}&chapter=${chapter_id}&section=${section_id}&subsection=${subsection_id}&subject=${subject}`,
          );
        }

        setQuizDetails(res.QuizInfo);
      } catch (error) {
        console.error('Error:', error);
      }
    }
    if (quizDetails.length === 0) {
      fetchData();
    }
  }, [rowData, baseUrl]);
  const handleBack = () => {
    navigate(-1);
  };
  const handleChangeQuestion = (question) => {
    setSelectedQuestion(question);
  };

  return (
    <>
      <Helmet>
        <title> Quiz Details </title>
      </Helmet>
      <Stack direction='row' alignItems='center' mb={2}>
        <IconButton aria-label='back' size='large' onClick={handleBack}>
          <ArrowBackIcon fontSize='inherit' style={{ color: '#092141' }} />
        </IconButton>
        <Typography variant='h4' gutterBottom>
          Quiz Details
        </Typography>
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Card variant='outlined'>
            <Scrollbar>
              <CardContent>
                <Typography variant='h5'>{rowData.quiz_name}</Typography>
              </CardContent>
              {quizDetails.map((question, index) => (
                <MenuItem
                  key={index}
                  value={question}
                  onClick={() => handleChangeQuestion(question)}
                >
                  {index + 1}. {question.questions}
                </MenuItem>
              ))}
            </Scrollbar>
          </Card>
        </Grid>
        {/* Right side: Display question and options */}
        <Grid item xs={12} sm={6}>
          {selectedQuestion && (
            <Card variant='outlined'>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant='h6'>{selectedQuestion.questions}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {selectedQuestion.questionImage && (
                      <>
                        <img
                          alt='questionImage'
                          style={{
                            maxHeight: '500px',
                            maxWidth: '350px',
                            objectFit: 'contain',
                            margin: ' 20px auto ',
                          }}
                          width={500}
                          height={350}
                          src={selectedQuestion.questionImage}
                        ></img>
                      </>
                    )}
                  </Grid>
                </Grid>
                {selectedQuestion.question_type === 'Blank' ? (
                  <>
                    <TextField label='Enter your answer' variant='outlined' fullWidth />
                    <Accordion className='m-4'>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1-content'
                        id='panel1-header'
                      >
                        <Typography>Feedback</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {selectedQuestion.option_4 && (
                          <Typography value='option4' label={selectedQuestion.option_4}>
                            {selectedQuestion.option_4}
                          </Typography>
                        )}
                      </AccordionDetails>
                    </Accordion>
                    <Accordion className='m-4'>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1-content'
                        id='panel1-header'
                      >
                        <Typography>Correct Answer</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {selectedQuestion.correct_answer && (
                          <Typography value='option4' label={selectedQuestion.correct_answer}>
                            {selectedQuestion.correct_answer}
                          </Typography>
                        )}
                      </AccordionDetails>
                    </Accordion>
                    <Divider />
                  </>
                ) : selectedQuestion.question_type === 'Checkbox ' ? (
                  <>
                    <Typography variant='h6'>{selectedQuestion.questions}</Typography>

                    <FormControl component='fieldset'>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox />}
                          label={selectedQuestion.option_1}
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label={selectedQuestion.option_2}
                        />
                        {selectedQuestion.option_3 && (
                          <FormControlLabel
                            control={<Checkbox />}
                            label={selectedQuestion.option_3}
                          />
                        )}
                        {selectedQuestion.option_4 && (
                          <FormControlLabel
                            control={<Checkbox />}
                            label={selectedQuestion.option_4}
                          />
                        )}
                      </FormGroup>
                    </FormControl>
                    <Accordion className='m-4'>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1-content'
                        id='panel1-header'
                      >
                        <Typography>Feedback</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {selectedQuestion.option_4 && (
                          <Typography value='option4' label={selectedQuestion.option_4}>
                            {selectedQuestion.option_4}
                          </Typography>
                        )}
                      </AccordionDetails>
                    </Accordion>
                    <Accordion className='m-4'>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1-content'
                        id='panel1-header'
                      >
                        <Typography>Correct Answer</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {selectedQuestion.correct_answer && (
                          <Typography value='option4' label={selectedQuestion.correct_answer}>
                            {selectedQuestion.correct_answer}
                          </Typography>
                        )}
                      </AccordionDetails>
                    </Accordion>
                    <Divider />
                    {selectedQuestion.correct_answer && (
                      <Typography value='option4' label={selectedQuestion.correct_answer} />
                    )}
                  </>
                ) : selectedQuestion.question_type === 'MCQ' ||
                  selectedQuestion.question_type === 'mcq' ? (
                  <>
                    <RadioGroup>
                      {selectedQuestion && (
                        <>
                          <RadioGroup>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <FormControlLabel
                                  value={selectedQuestion.option_1}
                                  control={<Radio />}
                                  label={selectedQuestion.option_1}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                {selectedQuestion.option1Image && (
                                  <>
                                    <img
                                      alt='option1Image'
                                      style={{
                                        maxHeight: '500px',
                                        maxWidth: '350px',
                                        objectFit: 'contain',
                                        margin: ' 20px auto ',
                                      }}
                                      width={500}
                                      height={350}
                                      src={selectedQuestion.option1Image}
                                    ></img>
                                  </>
                                )}
                              </Grid>
                              <Grid item xs={6}>
                                <FormControlLabel
                                  value='option2'
                                  control={<Radio />}
                                  label={selectedQuestion.option_2}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                {selectedQuestion.option2Image && (
                                  <>
                                    <img
                                      alt='option2Image'
                                      style={{
                                        maxHeight: '500px',
                                        maxWidth: '350px',
                                        objectFit: 'contain',
                                        margin: ' 20px auto ',
                                      }}
                                      width={500}
                                      height={350}
                                      src={selectedQuestion.option2Image}
                                    ></img>
                                  </>
                                )}
                              </Grid>
                              <Grid item xs={6}>
                                {selectedQuestion.option_3 && (
                                  <FormControlLabel
                                    value='option3'
                                    control={<Radio />}
                                    label={selectedQuestion.option_3}
                                  />
                                )}
                              </Grid>
                              <Grid item xs={6}>
                                {selectedQuestion.option3Image && (
                                  <>
                                    <img
                                      alt='option3Image'
                                      style={{
                                        maxHeight: '500px',
                                        maxWidth: '350px',
                                        objectFit: 'contain',
                                        margin: ' 20px auto ',
                                      }}
                                      width={500}
                                      height={350}
                                      src={selectedQuestion.option3Image}
                                    ></img>
                                  </>
                                )}
                              </Grid>
                              <Grid item xs={6}>
                                {selectedQuestion.option_4 && (
                                  <FormControlLabel
                                    value='option4'
                                    control={<Radio />}
                                    label={selectedQuestion.option_4}
                                  />
                                )}
                              </Grid>
                              <Grid item xs={6}>
                                {selectedQuestion.option4Image && (
                                  <>
                                    <img
                                      alt='questionImage'
                                      style={{
                                        maxHeight: '500px',
                                        maxWidth: '350px',
                                        objectFit: 'contain',
                                        margin: ' 20px auto ',
                                      }}
                                      width={500}
                                      height={350}
                                      src={selectedQuestion.option4Image}
                                    ></img>
                                  </>
                                )}
                              </Grid>
                            </Grid>
                          </RadioGroup>
                          <Accordion className='m-4'>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls='panel1-content'
                              id='panel1-header'
                            >
                              <Typography>Feedback</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  {selectedQuestion.option_4 && (
                                    <Typography value='option4' label={selectedQuestion.feedback}>
                                      {selectedQuestion.feedback}
                                    </Typography>
                                  )}
                                </Grid>
                                <Grid item xs={6}>
                                  {selectedQuestion.feedbackImage && (
                                    <>
                                      <img
                                        alt='questionImage'
                                        style={{
                                          maxHeight: '500px',
                                          maxWidth: '350px',
                                          objectFit: 'contain',
                                          margin: ' 20px auto ',
                                        }}
                                        width={500}
                                        height={350}
                                        src={selectedQuestion.feedbackImage}
                                      ></img>
                                    </>
                                  )}
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion className='m-4'>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls='panel1-content'
                              id='panel1-header'
                            >
                              <Typography>Correct Answer</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  {selectedQuestion.correct_answer && (
                                    <Typography
                                      value='option4'
                                      label={selectedQuestion.correct_answer}
                                    >
                                      {selectedQuestion.correct_answer}
                                    </Typography>
                                  )}
                                </Grid>
                                <Grid item xs={6}>
                                  {selectedQuestion.correctAnswerImage && (
                                    <>
                                      <img
                                        alt='correctAnswerImage'
                                        style={{
                                          maxHeight: '500px',
                                          maxWidth: '350px',
                                          objectFit: 'contain',
                                          margin: ' 20px auto ',
                                        }}
                                        width={500}
                                        height={350}
                                        src={selectedQuestion.correctAnswerImage}
                                      ></img>
                                    </>
                                  )}
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                          <Divider />
                          {selectedQuestion.correct_answer && (
                            <Typography value='option4' label={selectedQuestion.correct_answer} />
                          )}
                        </>
                      )}
                    </RadioGroup>
                  </>
                ) : selectedQuestion.question_type === 'True/False' ? (
                  <RadioGroup>
                    <FormControlLabel
                      value={selectedQuestion.option_1}
                      control={<Radio />}
                      label={selectedQuestion.option_1}
                    />
                    <FormControlLabel
                      value='option2'
                      control={<Radio />}
                      label={selectedQuestion.option_2}
                    />
                    <Accordion className='m-4'>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1-content'
                        id='panel1-header'
                      >
                        <Typography>Feedback</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {selectedQuestion.option_4 && (
                          <Typography value='option4' label={selectedQuestion.option_4}>
                            {selectedQuestion.option_4}
                          </Typography>
                        )}
                      </AccordionDetails>
                    </Accordion>
                    <Accordion className='m-4'>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1-content'
                        id='panel1-header'
                      >
                        <Typography>Correct Answer</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {selectedQuestion.correct_answer && (
                          <Typography value='option4' label={selectedQuestion.correct_answer}>
                            {selectedQuestion.correct_answer}
                          </Typography>
                        )}
                      </AccordionDetails>
                    </Accordion>
                    <Divider />
                  </RadioGroup>
                ) : (
                  <Typography variant='body1'>Question type not supported</Typography>
                )}
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
    </>
  );
};
export { QuizDetails };
