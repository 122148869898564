import React, { useState, useEffect } from 'react';
import { Loader } from '_components/navigation/Loader';
import { fetchWrapper } from '_helpers';
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import { Page404 } from './Page404';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
} from '@mui/material';
import { orderBy, filter } from 'lodash';

export { Report };

function Report() {
  const [quizList, setQuizList] = useState('');
  const [filterQuizList, setFilterQuizList] = useState('');
  const [loading, setLoading] = useState(true);
  const [dropdown1Value, setDropdown1Value] = useState('');
  const [dropdown2Value, setDropdown2Value] = useState('');
  const [dropdown3Value, setDropdown3Value] = useState('');
  const [dropdown4Value, setDropdown4Value] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [userDetails, setUserDetails] = useState('');
  const [dataAvailable, setDataAvailable] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [uniqueSubSectionTitles, setUniqueSubSectionTitles] = useState([]);
  const [exams, setExam] = useState([]);
  const [uniqueSubjects, setUniqueSubjects] = useState([]);
  const [uniqueChaptersWithIds, setUniqueChaptersWithIds] = useState([]);

  const colors = ['#F080FB', '#00E396', '#FEB019', '#FF4560', '#775DD0'];

  const user = useSelector((x) => x.auth.value);

  useEffect(() => {
    async function fetchData() {
      const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;
      const email = user && user.email;
      try {
        if (user.role === 'teacher') {
          const res = await fetchWrapper.get(
            `${baseUrl}/getSingleTeacherData?teacher_email=${email}`,
          );
          setUserDetails(res);

          // eslint-disable-next-line no-inner-declarations
          async function fetchQuizListData() {
            try {
              const exams = await fetchWrapper.post(`${baseUrl}/examList`, {
                user_id: res.teacher_id,
              });

              if (exams.success && exams.error === 'No data found for the given user') {
                setDataAvailable(false);
                setLoading(false);
                return;
              }

              // Check if exams list is empty
              if (exams.results.length === 0) {
                setDataAvailable(false);
                setLoading(false);
              } else {
                setDataAvailable(true);
              }

              let filteredExam = Object.values(exams.results).filter((obj, index) => {
                return index === exams.results.findIndex((o) => obj.quiz_id === o.quiz_id);
              });

              setFilterQuizList(filteredExam);
              setQuizList(exams.results);
              setExam(exams);
              setLoading(false);
            } catch (error) {
              console.error('Error fetching quiz list data:', error);
            }
          }

          fetchQuizListData();
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }

    fetchData();
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredAndSortedQuizList = filter(filterQuizList, (exam) => {
    if (dropdown1Value && exam.grade !== dropdown1Value) return false;
    if (dropdown2Value && exam.subject !== dropdown2Value) return false;
    if (dropdown3Value && exam.chapter !== dropdown3Value) return false;
    if (dropdown4Value && exam.sub_section_title !== dropdown4Value) return false;
    if (searchTerm && !exam.quiz_name?.toLowerCase().includes(searchTerm.toLowerCase()))
      return false;
    return true;
  });

  const sortedQuizList = orderBy(filteredAndSortedQuizList, ['quiz_name'], [sortOrder]);
  const handleDropdown1Change = (event) => {
    const selectedStandard = event.target.value;
    setDropdown1Value(selectedStandard);
    const filteredSubjects = exams.results
      .filter((exam) => exam.grade === selectedStandard)
      .map((exam) => exam.subject);

    setUniqueSubjects(filteredSubjects);
    setDropdown2Value('');
    setDropdown3Value('');
    setDropdown4Value('');
  };

  const handleDropdown2Change = (event) => {
    if (dropdown1Value) {
      setDropdown2Value(event.target.value);
      setDropdown3Value('');
      setDropdown4Value('');

      const filteredChapters = exams.results
        .filter((exam) => exam.grade === dropdown1Value && exam.subject === event.target.value)
        .map((exam) => ({
          chapter_id: exam.chapter_id,
          chapter: exam.chapter.trim(),
        }));
      setUniqueChaptersWithIds(filteredChapters);
    }
  };

  const handleDropdown3Change = (event) => {
    const selectedChapter = event.target.value;
    setDropdown3Value(selectedChapter);
    const filteredQuizzes = quizList.filter((quiz) => quiz.chapter === selectedChapter);
    const uniqueSubSections = [...new Set(filteredQuizzes.map((quiz) => quiz.sub_section_title))];
    setDropdown4Value('');
    setUniqueSubSectionTitles(uniqueSubSections);
  };

  const handleDropdown4Change = (event) => {
    setDropdown4Value(event.target.value);
  };

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  if (loading) {
    return <Loader />;
  }

  const examNames =
    filteredAndSortedQuizList &&
    filteredAndSortedQuizList.length > 0 &&
    filteredAndSortedQuizList.map((exam) => exam.quiz_name || '');

  const examMarks =
    filteredAndSortedQuizList &&
    filteredAndSortedQuizList.map((exam) => {
      const filteredData = quizList.filter((item) => item.quiz_id === exam.quiz_id);
      const quizIdCount = filteredData?.length;
      return quizIdCount;
    });
  const examListChartOptions = {
    chart: {
      id: 'basic-bar',
    },
    xaxis: {
      categories: examNames,
    },
    stroke: {
      curve: 'smooth',
    },
    markers: {
      size: 0,
    },
    legend: {
      show: false,
    },
    colors: colors,
    plotOptions: {
      bar: {
        columnWidth: '47%',
        distributed: true,
      },
    },
    animation: {
      enabled: true,
      easing: 'easeinout',
      speed: 2000,
      animateGradually: {
        enabled: false,
        delay: 150,
      },
      dynamicAnimation: {
        enabled: false,
        speed: 350,
      },
    },
    tooltip: {
      x: {
        show: true, // Show x-axis tooltip
        formatter: function (value, { dataPointIndex }) {
          // Customize the tooltip content
          return 'X-Axis Label: ' + examNames[dataPointIndex];
        },
      },
    },
    type: 'bar',
    height: 100,
  };

  const examListChartData = [
    {
      name: 'Total Students',
      data: examMarks,
    },
  ];
  const resetDropdowns = () => {
    setDropdown1Value('');
    setDropdown2Value('');
    setDropdown3Value('');
    setDropdown4Value('');
    setSearchTerm('');
  };
  // Filter unique grades

  if (!dataAvailable) {
    return <Page404 message='No report found ' name={'NoDataFound'} />;
  }
  const uniqueGrades = [...new Set(exams.results.map((exam) => exam.grade))];
  const uniqueSubject = [...new Set(uniqueSubjects)];
  const uniqueChaptersWithId = Array.from(
    new Set(uniqueChaptersWithIds.map((chapter) => JSON.stringify(chapter))),
  ).map((stringifiedChapter) => JSON.parse(stringifiedChapter));
  return (
    <div>
      <div>
        <Box class='d-flex'>
          <FormControl fullWidth className='p-2'>
            <InputLabel>Standard</InputLabel>
            <Select value={dropdown1Value} onChange={handleDropdown1Change}>
              {uniqueGrades.length === 0 && <MenuItem disabled>No grades available</MenuItem>}
              <MenuItem value=''>None</MenuItem>
              {uniqueGrades &&
                uniqueGrades.length > 0 &&
                uniqueGrades.map((grade, index) => (
                  <MenuItem key={index} value={grade}>
                    {grade}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl fullWidth className='p-2'>
            <InputLabel>Subject</InputLabel>
            <Select value={dropdown2Value} onChange={handleDropdown2Change}>
              {uniqueSubject.length === 0 && <MenuItem disabled>No subjects available</MenuItem>}
              <MenuItem value=''>None</MenuItem>
              {uniqueSubject &&
                uniqueSubject.length > 0 &&
                uniqueSubject.map((subject, index) => (
                  <MenuItem key={index} value={subject}>
                    {subject}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
        <br></br>
        <br></br>
        <Box className='d-flex'>
          <FormControl fullWidth className='p-2'>
            <InputLabel>Chapter</InputLabel>
            <Select value={dropdown3Value} onChange={handleDropdown3Change}>
              {uniqueChaptersWithId.length === 0 && (
                <MenuItem disabled>No chapters available</MenuItem>
              )}
              <MenuItem value=''>None</MenuItem>
              {uniqueChaptersWithId &&
                uniqueChaptersWithId.length &&
                uniqueChaptersWithId
                  .sort((a, b) => a.chapter_id - b.chapter_id)
                  .map((chapter, index) => (
                    <MenuItem key={index} value={chapter.chapter}>
                      {chapter.chapter_id} - {chapter.chapter}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>

          <FormControl fullWidth className='p-2'>
            <InputLabel>Quiz</InputLabel>
            <Select value={dropdown4Value} onChange={handleDropdown4Change}>
              {uniqueSubSectionTitles.length === 0 && (
                <MenuItem disabled>No quizzes available</MenuItem>
              )}
              <MenuItem value=''>None</MenuItem>
              {uniqueSubSectionTitles &&
                uniqueSubSectionTitles.length &&
                uniqueSubSectionTitles.map((subSectionTitle, index) => {
                  if (subSectionTitle) {
                    const parts = subSectionTitle.split(':');
                    const label = parts.length > 1 ? parts[1].trim() : subSectionTitle;
                    return (
                      <MenuItem key={index} value={subSectionTitle}>
                        {label}
                      </MenuItem>
                    );
                  } else {
                    return null;
                  }
                })}
            </Select>
          </FormControl>
        </Box>

        <div>
          <button className='btn btn-primary' style={{ margin: '20px' }} onClick={resetDropdowns}>
            Reset
          </button>
        </div>

        <div className='d-flex justify-content-between'>
          <input
            className='col rounded'
            type='text'
            style={{ marginRight: '20px', marginLeft: '10px' }}
            placeholder='Search'
            onChange={handleSearch}
            value={searchTerm}
          />
          <button onClick={toggleSortOrder} className='btn btn-primary p-2'>
            <div className='d-flex'>
              Sort{' '}
              {sortOrder === 'asc' ? (
                <span className='material-icons'>arrow_upward</span>
              ) : (
                <span className='material-icons'>arrow_downward</span>
              )}
            </div>
          </button>
        </div>
        <div className='my-2 px-2 py-3'>
          <Grid xs={12} sm={12} md={12} className='m-1'>
            <Card
              sx={{
                py: 5,
                boxShadow: 0,
                textAlign: 'center',
                color: (theme) => theme.palette['primary'].darker,
                bgcolor: (theme) => theme.palette['primary'].lighter,
                transition: 'background-color 0.2s, box-shadow 0.2s',
                '&:hover': {
                  border: '1px solid #f6f6f6',
                  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                  color: (theme) => theme.palette.common.black,
                },
              }}
            >
              <CardContent>
                <ReactApexChart
                  options={examListChartOptions}
                  series={examListChartData}
                  type='bar'
                  height={200}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid container spacing={3}>
            {sortedQuizList &&
              sortedQuizList.length > 0 &&
              sortedQuizList.map((quiz, i) => (
                <Grid key={i} item xs={12} sm={6} md={6}>
                  <Card
                    sx={{
                      py: 5,
                      boxShadow: 0,
                      textAlign: 'center',
                      color: (theme) => theme.palette['primary'].darker,
                      bgcolor: (theme) => theme.palette['primary'].lighter,
                      transition: 'background-color 0.2s, box-shadow 0.2s',
                      '&:hover': {
                        border: '1px solid #f6f6f6',
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                        color: (theme) => theme.palette.common.black,
                      },
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant='h5'
                        sx={{
                          opacity: 0.72,
                          transition: 'opacity 0.2s',
                          '&:hover': {
                            opacity: 1,
                          },
                        }}
                      >
                        {quiz.quiz_name}
                      </Typography>
                      <div>
                        <ReactApexChart
                          options={{
                            chart: {
                              id: 'basic-bar',
                            },
                            xaxis: {
                              categories: quizList
                                .filter((result) => result.quiz_id === quiz.quiz_id)
                                .map((result) => result.user_name),
                            },
                            stroke: {
                              curve: 'smooth',
                            },
                            markers: {
                              size: 0,
                            },
                            legend: {
                              show: false,
                            },
                            colors: colors,
                            plotOptions: {
                              bar: {
                                columnWidth: '45%',
                                distributed: true,
                              },
                            },
                            animation: {
                              enabled: true,
                              easing: 'easeinout',
                              speed: 2000,
                              animateGradually: {
                                enabled: true,
                                delay: 150,
                              },
                              dynamicAnimation: {
                                enabled: true,
                                speed: 350,
                              },
                            },
                          }}
                          series={[
                            {
                              name: 'Marks',
                              data: quizList
                                .filter((result) => result.quiz_id === quiz.quiz_id)
                                .map((result) => result.user_obtained_marks),
                            },
                          ]}
                          type='bar'
                          height={200}
                        />
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </div>
      </div>
    </div>
  );
}
