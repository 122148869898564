import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, CardContent, Typography, Box } from '@mui/material';

export const AnswerDistribution = ({ chartData }) => (
  <Box sx={{ marginTop: 2 }}>
    <Card variant='outlined' sx={{ maxWidth: 450, margin: '20px auto' }}>
      <CardContent>
        <Typography variant='h6' gutterBottom>
          Answer Distribution
        </Typography>
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type='pie'
          width={350}
        />
      </CardContent>
    </Card>
  </Box>
);
