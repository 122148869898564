import React, { useState, useEffect } from 'react';
import FeedBackSubjectButtons from './FeedbackSubjectButtons';
import FeedbackQuestionDisplay from './FeedbackQuestionDisplay';
import { store } from '_store';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import FeedbackQuestionActions from './FeedbackQuestionActions';
import FeedbackDisplay from './FeedbackDisplay';
import { Chatbot, Loader } from '_components';
import { MyContext } from 'pages';
import FeedbackNavigationButtons from './FeedbackNavigationButtons';

const NEETFeedbackQuiz = ({ board, selectedLevel }) => {
  const navigate = useNavigate();
  const user = useSelector((x) => x.auth.value);
  const [hideChatbot, setHideChatbot] = useState(false);
  const [message, setMessage] = useState('');
  const [chatbotOpen, setChatbotOpen] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [error, setError] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;
  const token = authToken();
  function authToken() {
    return store.getState().auth.value?.token;
  }

  useEffect(() => {
    const fetchFeedbackData = async () => {
      setIsLoading(true);

      try {
        const url = `${baseUrl}/fetchNeetQuestions?board=${board}&test_level=${selectedLevel}`;
        console.log('Fetch URL:', url); // Log the URL
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          const errorMessage = await response.text();
          throw new Error(errorMessage || 'Failed to fetch questions');
        }

        const data = await response.json();
        setQuestions(data);
        setFilteredQuestions(data);

        setAnswers(new Array(data.length).fill(''));
        navigate(`/feedback?board=${board}&test_level=${selectedLevel}`);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFeedbackData();
  }, [baseUrl, token, board, selectedLevel]);

  useEffect(() => {
    const fetchSelectedAnswerData = async () => {
      setIsLoading(true);

      try {
        const url = `${baseUrl}/fetchSelectedAnswer?student_email=${user.email}`;
        console.log('Fetch URL:', url); // Log the URL
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          const errorMessage = await response.text();
          throw new Error(errorMessage || 'Failed to fetch questions');
        }

        const data = await response.json();
        setSelectedAnswers(Array.isArray(data) ? data : []);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSelectedAnswerData();
  }, [baseUrl, token]);

  const filterQuestions = (subject) => {
    if (subject) {
      const filtered = questions.filter((question) => question.subject === subject);
      setFilteredQuestions(filtered);
    } else {
      setFilteredQuestions(questions); // Show all questions if no subject is selected
    }
    setCurrentQuestionIndex(0); // Reset to the first question of the filtered list
  };

  const handleSelectSubject = (subject) => {
    setSelectedSubject(subject);
    filterQuestions(subject);
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handleQuestionClick = (questionId) => {
    setCurrentQuestionIndex(questionId);
  };

  if (isLoading) {
    return <Loader />; // Display loading indicator while fetching data
  }

  if (error) {
    return (
      <div className='alert alert-danger m-2' role='alert'>
        Please select Mock Test
      </div>
    );
  }

  const currentQuestion = filteredQuestions[currentQuestionIndex];

  const currentSelectedAnswer = selectedAnswers.find((answer) => {
    if (!answer || !answer.quiz_id) {
      console.error('Invalid answer object:', answer);
      return false;
    }
    return answer.quiz_id === currentQuestion?.id;
  });
  console.log('Current Selected Answer:', currentSelectedAnswer);

  return (
    <MyContext.Provider
      value={{
        setChatbotOpen,
        setMessage,
        setHideChatbot,
      }}
    >
      <div className='App'>
        <div className='container-fluid'>
          <div className='m-3'>
            <div className='h3'>{<p>{selectedLevel} Feedback</p>}</div>
            <div className='h6 intro' style={{ height: '100%', background: '#ccf2ff' }}>
              {<p>{selectedLevel} Feedback</p>}
            </div>
          </div>
        </div>
        <Box>
          <FeedBackSubjectButtons onSelectSubject={handleSelectSubject} />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={9}>
            <FeedbackQuestionDisplay
              currentQuestion={currentQuestion}
              currentQuestionIndex={currentQuestionIndex}
              answers={answers}
              selectedAnswer={currentSelectedAnswer ? currentSelectedAnswer.selected_ans : '0'}
            />
            <FeedbackQuestionActions
              currentQuestionIndex={currentQuestionIndex}
              onNextQuestion={handleNextQuestion}
              onPrevQuestion={handlePreviousQuestion}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FeedbackNavigationButtons
              totalQuestions={filteredQuestions.length}
              onQuestionClick={handleQuestionClick}
              selectedSubject={selectedSubject}
            />
          </Grid>
        </Grid>
        <FeedbackDisplay currentQuestion={currentQuestion} chatbot={setChatbotOpen} />
      </div>
      {chatbotOpen && !hideChatbot ? (
        <Chatbot
          className='col'
          message={message}
          style={{ display: !chatbotOpen && 'none' }}
          setChatbotOpen={setChatbotOpen}
          drawerStatus={open}
        />
      ) : (
        ''
      )}
    </MyContext.Provider>
  );
};

export default NEETFeedbackQuiz;
