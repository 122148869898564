import React, { useState, useEffect, Fragment, useRef } from 'react';
import { capitalizeFirstLetter, parseLatexContent } from '_helpers';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import { fetchWrapper } from '_helpers';
import PropTypes from 'prop-types';
import axios from 'axios';
import '../style.css';
import { Chip, Button, Dialog, DialogContent, DialogActions, DialogTitle } from '@mui/material';
import { useParams } from 'react-router-dom';
import Assistant from '_assets/images/Assistent.svg';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import MinimizeIcon from '@mui/icons-material/Minimize';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import AttachFileRoundedIcon from '@mui/icons-material/AttachFileRounded';
import CameraAltRoundedIcon from '@mui/icons-material/CameraAltRounded';
import AddPhotoAlternateRoundedIcon from '@mui/icons-material/AddPhotoAlternateRounded';
import MathJaxWrapper from './MathJaxWrapper';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import Webcam from 'react-webcam';

export { Chatbot };

const prohibitedKeywords = ['explicit', 'inappropriate', 'offensive', 'violative'];

// Update your API key and CSE ID here
const GOOGLE_API_KEY = 'AIzaSyAxq8k7G1wparcjTJ5hc1lyl4q8wok7lsU';
const GOOGLE_CX_ID = 'f71d6ff39365a4bcf';

async function moderateContent(message) {
  try {
    const response = await axios.post(
      'https://api.openai.com/v1/moderations',
      {
        input: message,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
        },
      },
    );

    return response.data;
  } catch (error) {
    console.error('Moderation error:', error);
    return { classification: 'error' };
  }
}

const downloadChatAsPDF = (chat, title) => {
  const chatContainer = document.getElementById('chatContainer');

  html2canvas(chatContainer, { scale: 3, useCORS: true }).then((canvas) => {
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'mm', 'a4');

    const imgWidth = 210; // A4 width in mm
    const pageHeight = 297; // A4 height in mm
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    const borderWidth = 5; // Width of the border

    let heightLeft = imgHeight;
    let position = 0;

    // Watermark settings
    const watermarkText = 'Classroom AI';
    const watermarkX = pdf.internal.pageSize.getWidth() / 2;
    const watermarkY = pdf.internal.pageSize.getHeight() / 2;
    const watermarkAngle = 45;

    // Function to add watermark
    const addWatermark = () => {
      pdf.setTextColor(240, 240, 240); // Light grey color for watermark
      pdf.setFontSize(50);
      pdf.text(watermarkText, watermarkX, watermarkY, {
        angle: watermarkAngle,
        align: 'center',
        baseline: 'middle'
      });
    };

    // Function to draw border around the page
    const drawBorder = () => {
      pdf.setLineWidth(borderWidth); // Set the line width for the border
      pdf.rect(borderWidth / 2, borderWidth / 2, pdf.internal.pageSize.width - borderWidth, pdf.internal.pageSize.height - borderWidth); // Draw the border
    };

    // First Page
    addWatermark(); // Add watermark first
    pdf.addImage(imgData, 'PNG', borderWidth, borderWidth, imgWidth - 2 * borderWidth, imgHeight, '', 'FAST');
    drawBorder(); // Draw border around the whole page

    heightLeft -= pageHeight;

    // Additional Pages
    while (heightLeft > 0) {
      pdf.addPage();
      addWatermark(); // Add watermark on each page before image
      position = heightLeft - imgHeight;

      pdf.addImage(imgData, 'PNG', borderWidth, position, imgWidth - 2 * borderWidth, imgHeight, '', 'FAST');
      drawBorder(); // Draw border around the whole page

      heightLeft -= pageHeight;
    }

    // Save the PDF
    pdf.save(`${title || 'chat_conversation'}.pdf`);
  });
};

function Chatbot(props) {
  const { standard, subject } = useParams();
  const [message, setMessage] = useState(props.message ? props.message : '');
  const [chats, setChats] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [createdAt, setCreatedAt] = useState('');
  const [sessionId, setSessionId] = useState('');
  const [prevProp, setPrevProp] = useState('');
  const [isFetchingSuggestions, setIsFetchingSuggestions] = useState(false);
  const [isPaper, setIsPaper] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [showScrollTopButton, setShowScrollTopButton] = useState(false); // New state for button visibility
  const webcamRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [suggestions, setSuggestions] = useState(
    props.suggestedQuestions || ['Chemical Equation', 'Number System'],
  );
  const { quiz_title } = props;
  let user = JSON.parse(localStorage.getItem('auth'));
  const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;
  const MAX_RETRIES = 3; // Maximum number of retries
  let retryCount = 0;

  useEffect(() => {
    if (props.message && props.message !== prevProp) {
      const newChats = [...chats];
      newChats.push({ role: 'user', content: props.message });
      setChats(newChats);
      chat({ key: 'Enter' }, props.message);
      setPrevProp(props.message);
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [props.message, prevProp]);

  useEffect(() => {
    props && props.setChat && props.setChat(chats);
    window.scrollTo(0, document.body.scrollHeight);
    props.subSection && props.subSection.sub_section_type === 'paper' && setIsPaper(true);
  }, [suggestions]);

  const chatSession = {
    userId: user.id,
    userRole: user.role,
    sessionId: sessionId,
    createdAt: createdAt,
    content: [...chats],
  };

  useEffect(() => {
    const initialMessage = {
      role: 'assistant',
      content: 'Hi! How can I help You?',
    };

    if (props.suggestedQuestions && props.suggestedQuestions.length > 0) {
      window.scrollTo(0, document.body.scrollHeight);
      setSuggestions(props.suggestedQuestions);
    } else if (
      props.subSection &&
      props.subSection.sub_section_type === 'paper' &&
      location.pathname.includes('NEET')
    ) {
      setSuggestions([
        `Ask a question regarding subject ${props.subSection.subject} in NEET`,
        `Ask similar questions like past years question paper in subject ${props.subSection.subject} in NEET`,
      ]);
    } else if (user && user.title) {
      setSuggestions([
        `explain "${user.title.trim()}"`,
        `Ask a question regarding ${user.title}`,
        `Provide examples of real-world applications for ${user.title}`,
      ]);
    } else {
      setSuggestions([]); // Clear suggestions if there's no title
    }

    setChats([initialMessage]);
  }, [props.suggestedQuestions]);

  const saveChatSession = async (chatSession) => {
    if (chatSession && chatSession['content'].length > 1 && sessionId) {
      try {
        await fetchWrapper.post(`${baseUrl}/saveSession`, chatSession);
        setPrevProp('');
        setMessage('');
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    return () => {
      // Scroll back to top on unmount
      window.scrollTo(0, 0);
    };
  }, []);

  useEffect(() => {
    chatSession['sessionId'] = sessionId;
    chatSession['createdAt'] = createdAt;
  }, [sessionId, createdAt, props.message]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset;
      setShowScrollTopButton(scrollPosition > 300); // Show button after scrolling 300px
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  function handleClose(chatSession) {
    saveChatSession(chatSession);
  }

  function applyContentFilter(message) {
    if (!message) {
      return JSON.stringify({ answer: 'Content filtered due to policy violation.' });
    }

    const startIndex = message.indexOf('{') + 1;
    const endIndex = message.lastIndexOf('}');
    let extractedText = '';
    if (startIndex !== -1 && endIndex !== -1) {
      extractedText = message.substring(startIndex, endIndex).trim();
    }

    extractedText = extractedText
      .replace(/\\\\n|\\n/g, '')
      .replace(/\\/g, '')
      .replace(/\n/g, '');

    console.log('Extracted Text:', extractedText);

    // Check if the text is properly formatted
    if (!extractedText.includes(':')) {
      return JSON.stringify({ answer: 'Content filtered due to policy violation.' });
    }

    let content = extractedText?.split(/:(.+)/);
    console.log('Content split result:', content);

    if (content.length < 2) {
      return JSON.stringify({ answer: 'Content filtered due to policy violation.' });
    }

    const key = content[0].trim().replace(/^"|"$/g, '');
    const value = content[1].trim().replace(/^"|"$/g, '');

    if (prohibitedKeywords.some((keyword) => value.toLowerCase().includes(keyword))) {
      return JSON.stringify({ answer: 'Content filtered due to policy violation.' });
    }

    const jsonObject = { [key]: value };
    console.log('JSON Object to be Parsed:', jsonObject);
    return JSON.stringify(jsonObject);
  }

  const [reactions, setReactions] = useState({});

  const handleReaction = (index, reaction) => {
    if (reaction) {
      setReactions((prevReactions) => ({
        ...prevReactions,
        [index]: reaction,
      }));

      // Send reaction to backend
      fetch('/api/reactions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ chatIndex: index, reaction }),
      });
    } else {
      setReactions({});
    }
    // Update state locally
  };

  // Function to fetch images using Google Custom Search API
  const fetchImagesFromGoogle = async (query) => {
    try {
      const response = await axios.get('https://www.googleapis.com/customsearch/v1', {
        params: {
          key: GOOGLE_API_KEY,      // Use your Google API key here
          cx: GOOGLE_CX_ID,         // Use your Custom Search Engine ID here
          searchType: 'image',
          q: query,                 // The search query
          num: 1,              // Number of images to return
        },
      });

      // Return the list of image URLs
      return response.data.items.map(item => item.link);
    } catch (error) {
      console.error('Error fetching images from Google Custom Search:', error);
      return [];
    }
  };

  // Function to generate title and caption for a response
  const generateTitleAndCaption = async (responseContent) => {
    try {
      // Use OpenAI to generate a title and caption based on the response content
      const prompt = `Extract the key figure or data point from the following response: "${responseContent}". Summarize its significance into a concise caption bsed , focusing solely on the most relevant information. Then, generate a clear and impactful title that reflects the essence of the data. Format your response as follows:\nTitle: [Your Title]\nCaption: [Your Caption]`;
      
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          messages: [{ role: 'system', content: prompt }],
          max_tokens: 100,
          temperature: 0.5,
          model: 'gpt-4o-mini',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
        }
      );

      // Parse the result
      const titleAndCaption = response.data.choices[0].message.content.split("\n");

      const generatedTitle = titleAndCaption[0];
      const caption = titleAndCaption[1] || generatedTitle; // If caption isn't generated, use title

      return { generatedTitle, caption };
    } catch (error) {
      console.error('Error generating title and caption:', error);
      return { generatedTitle: 'No Title', caption: 'No Caption Available' };
    }
  };

  const chat = async (e, message) => {
    await window.scrollTo(0, document.body.scrollHeight);
    if (!message) return;
    setIsTyping(true);

    let newChats = [...chats];
    newChats.push({ role: 'user', content: message });
    setMessage('');
    const systemAnswerMessage = props.answerPrompt
    ? props.answerPrompt
    : `You are an AI assistant. Your task is to help students in ${user.board}, grade ${standard}, understand the subject matter in chapter ${props.chapterId}-${props.chapterTitle}. Provide precise answers to given questions within 50 words using only grade ${standard} textbooks.
  For each response, include an illustrative image related to the answer, if applicable. The image should provide visual support for the answer and help students understand the concept. If there is no relevant image, leave the "image" field as an empty string.

  Your answers should be formatted as a JSON object with the following structure:
  {
    "answer": "Your concise answer here."
  }

  For numerical problems, follow these steps and get in neat HTML table:
  <table >
<tr>
  <th>Step</th>
  <th>Description</th>
</tr>
<tr>
  <td>1</td>
  <td>State the problem you need to solve.</td>
</tr>
<tr>
  <td>2</td>
  <td>Identify the given data and the unknown variable: List out all the provided data and the variable(s) you need to find.</td>
</tr>
<tr>
  <td>3</td>
  <td>Write down the relevant formulas or equations: Include the equations or formulas you will use to solve the problem.</td>
</tr>
<tr>
  <td>4</td>
  <td>Substitute the given data into the equations: Plug the given values into the equations.</td>
</tr>
<tr>
  <td>5</td>
  <td>Solve the equations step by step to find the unknown variable: Show the calculation process clearly to find the unknown value.</td>
</tr>
<tr>
  <td>6</td>
  <td>Verify the solution and ensure it meets the conditions of the problem: Double-check your calculations and confirm that the solution satisfies the problem requirements.</td>
</tr>
</table>


  For Assertion and Reason questions, format the response in an HTML table as follows:
  <table>
    <tr>
      <th>Assertion</th>
      <th>Reason</th>
      <th>Conclusion Options</th>
    </tr>
    <tr>
      <td>The assertion statement goes here.</td>
      <td>The reason statement goes here.</td>
      <td>
        <ol>
          <li>Both statements are true, but the reason does not apply to the assertion.</li>
          <li>The assertion is true, but the reason is not.</li>
          <li>The reason is true, but the assertion is wrong.</li>
          <li>Both statements are false.</li>
        </ol>
      </td>
    </tr>
  </table>

  For matching questions, format the response in an HTML table where each row contains a pair of matched items:
  <table>
    <tr>
      <th>Item</th>
      <th>Match</th>
    </tr>
    <tr>
      <td>Item 1</td>
      <td>Match for Item 1</td>
    </tr>
    <tr>
      <td>Item 2</td>
      <td>Match for Item 2</td>
    </tr>
    <tr>
      <td>Item 3</td>
      <td>Match for Item 3</td>
    </tr>
    <tr>
      <td>Item 4</td>
      <td>Match for Item 4</td>
    </tr>
    <tr>
      <td>Item 5</td>
      <td>Match for Item 5</td>
    </tr>
    <tr>
      <td>Item 6</td>
      <td>Match for Item 6</td>
    </tr>
  </table>

  For competency-focused practical questions, format the response as follows and get in neat HTML table:

  <b>Question: </b>The practical question you generate here.
  <b>Answer: </b>Provide a detailed solution here.

  Avoid overly verbose responses. Be concise and to the point.`;

  const systemQuestionMessage =
    (props.questionPrompt
      ? props.questionPrompt
      : isPaper && location.pathname.includes('NEET') && !props.message
      ? `Generate 2 concise questions from ${props.subSection.subject} previous year NEET exam. The format should be: {"question": ["question1", "question2"]}. Avoid overly verbose questions`
      : props.message
      ? `Generate 2 concise questions from ${props.subSection.subject} related to ${
          props.message
        } from ${
          location.pathname.includes('NEET') ? 'NEET' : user.board
        } like previous year exams. The format should be: {"question": ["question1", "question2"]}. Avoid overly verbose questions. `
      : `Generate 2 concise questions related to the topic in ${user.board}, grade ${standard} chapter ${props.chapterId}-${props.chapterTitle}. The format should be: {"question": ["question1", "question2"]}. Avoid overly verbose questions.`) +
    `Avoid ${suggestions}`;

if (!props.message) {
  try {
    const moderationResult = await moderateContent(message);
    let res = moderationResult.results[0].flagged;

    if (res) {
      newChats.push({ role: 'assistant', content: 'Content violation: content not allowed.' });
      setChats(newChats);
      setIsTyping(false);
      setMessage('');
      window.scrollTo(0, document.body.scrollHeight);
      return;
    }
  } catch (error) {
    console.error('Moderation error:', error);
    return;
  }
}

    try {
      const lastFourMessages = newChats.slice(-4);
      const selectedMessages = lastFourMessages.map((chat) => ({
        role: chat.role,
        content: typeof chat.content === 'string' ? chat.content : JSON.stringify(chat.content),
      }));
      selectedMessages.push({ role: 'system', content: systemAnswerMessage });

      const maxtokens = props.tokenSize ? props.tokenSize : 100;
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          messages: selectedMessages,
          max_tokens: maxtokens,
          temperature: props.temprature ? props.temprature : 1,
          model: props.chatEngine ? props.chatEngine : 'gpt-4o-mini',
          n: 1,
          top_p: 0.02,
          frequency_penalty: 0,
          presence_penalty: 0,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
        },
      );

      if (response.headers['content-type']?.includes('application/json')) {
        const generatedMessage = response.data.choices[0].message.content;
        const filteredMessage = JSON.parse(applyContentFilter(generatedMessage)).answer;

        let imageUrls = [];
        const isNumericalProblem = filteredMessage.toLowerCase().includes('numerical problem') ||
          filteredMessage.toLowerCase().includes('solve') ||
          filteredMessage.toLowerCase().includes('calculate') ||
          filteredMessage.toLowerCase().includes('find the value');

        if (!filteredMessage.toLowerCase().includes('assertion') &&
          !filteredMessage.toLowerCase().includes('reason') &&
          !filteredMessage.toLowerCase().includes('match the following') &&
          !isNumericalProblem) {

          // Generate title and caption for the response
          const { generatedTitle, caption } = await generateTitleAndCaption(filteredMessage);

          // Fetch images based on the caption using Google Custom Search API
          imageUrls = await fetchImagesFromGoogle(generatedTitle);
          newChats.push({
            role: 'assistant',
            content: filteredMessage,
          });
          if (imageUrls.length > 0) {
            // const imageExplanations = await Promise.all(
            //   imageUrls.map(async () => {
            //     const explanationPrompt = `Generate a concise title and describe it: ${imageUrls}.`;
            //     const explanationResponse = await axios.post(
            //       'https://api.openai.com/v1/chat/completions',
            //       {
            //         messages: [{ role: 'system', content: explanationPrompt }],
            //         max_tokens: 500,
            //         temperature: 0.5,
            //         model: props.chatEngine || 'gpt-4o-mini',
            //       },
            //       {
            //         headers: {
            //           'Content-Type': 'application/json',
            //           Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            //         },
            //       },
            //     );
            //     return explanationResponse.data.choices[0].message.content;
            //   })
            // );

            newChats.push({
              role: 'assistant',
              content: (
                <div>
                <h3 style={{ color: '#1E90FF', fontFamily: 'Arial, sans-serif', fontWeight: 'bold' }}>{generatedTitle}</h3>
                {imageUrls.map((url, index) => (
                  <div key={index}>
                    <img src={url} alt={`Visual related to ${generatedTitle}`} style={{ maxWidth: '100%', height: 'auto', marginBottom: '10px' }} />
                    <p style={{ fontSize: '16px', fontFamily: 'Arial, sans-serif', color: '#555' }}>{caption}</p>
                  </div>
                  ))}
                </div>
              ),
            });
          } else {
            newChats.push({
              role: 'assistant',
              content: filteredMessage,
            });
          }

        } else {
          newChats.push({
            role: 'assistant',
            content: filteredMessage,
          });
        }

        setChats(newChats);
        setIsTyping(false);
        setMessage('');
        window.scrollTo(0, document.body.scrollHeight);
        setCreatedAt(response.data.created);
        setSessionId(response.data.id);

        if (!isFetchingSuggestions) {
          setIsFetchingSuggestions(true);

          const suggestionsResponse = await axios.post(
            'https://api.openai.com/v1/chat/completions',
            {
              messages: [{ role: 'system', content: systemQuestionMessage }],
              max_tokens: maxtokens,
              temperature: props.temperature ? props.temperature : 0.5,
              model: props.chatEngine ? props.chatEngine : 'gpt-4o-mini',
            },
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
              },
            },
          );

          const suggestionsMessage = suggestionsResponse.data.choices[0].message.content;
          const suggestionsArray =
            suggestionsMessage.length > 0
              ? JSON.parse(suggestionsMessage).question
              : ['Chemical Equation', 'Number System'];
    
            if (location.pathname.includes('NEET') && props.isFullScreen) {
              if (subject === 'Physics' || subject === 'Chemistry') {
                props.title &&
                  suggestionsArray.push(
                    `Explain a numerical problem involving ${
                      quiz_title.replace(/^\d+\.\d+\.\d+\s*/, '')?.split(':')[0]
                    } with a detailed solution<span class="hide-phrase">in neat HTML table.</span>.`,
                  );
              }
              suggestionsArray.push(
                `Example for Assertion and Reason question involving ${
                  quiz_title.replace(/^\d+\.\d+\.\d+\s*/, '')?.split(':')[0]
                } with a detailed solution<span class="hide-phrase">in neat HTML table.</span>.`,
              );
    
              suggestionsArray.push(
                `Generate any match the following type question involving ${
                  quiz_title.replace(/^\d+\.\d+\.\d+\s*/, '')?.split(':')[0]
                } with detailed solution <span class="hide-phrase">in neat HTML table.</span>`,
              );
            } else if (location.pathname.includes('standard') && props.isFullScreen) {
              suggestionsArray.push(
                `Generate "competency focused practical questions" for <span class="hide-phrase">CBSE grade 10</span>
                ${quiz_title} in ${props.chapterTitle} with detailed solution <span class="hide-phrase">imilar to questions found in
                the vector store "CFPQ_Science10.pdf"</span>`,
              );
              suggestionsArray.push(
                `Example for Assertion and Reason question involving ${
                  quiz_title.replace(/^\d+\.\d+\.\d+\s*/, '')?.split(':')[0]
                } in ${
                  props.chapterTitle
                } with a detailed solution<span class="hide-phrase">in neat HTML table.</span>`,
              );
    
              suggestionsArray.push(
                `Generate any match the following type question involving ${
                  quiz_title.replace(/^\d+\.\d+\.\d+\s*/, '')?.split(':')[0]
                } in ${
                  props.chapterTitle
                } with detailed solution <span class="hide-phrase">in neat HTML table.</span>`,
              );
            }
          setSuggestions(suggestionsArray);
          setIsFetchingSuggestions(false);
        }
      } else {
        console.error('Unexpected response format:', response.data);
        newChats.push({
          role: 'assistant',
          content: 'Unexpected response format. Please try again later.',
        });
        setChats(newChats);
      }
    } catch (error) {
      console.error('API request failed:', error);

      if (retryCount < MAX_RETRIES) {
        retryCount++;
        setTimeout(async () => {
          await chat(e, message);
        }, 2000);
      } else {
        newChats.push({
          role: 'assistant',
          content: 'API request failed. Please try again later.',
        });
        setChats(newChats);
        setIsTyping(false);
        setMessage('');
        window.scrollTo(0, document.body.scrollHeight);
      }
    }
  };

  const handleChooseOpenCaptureAndImageUpload = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChooseCloseCaptureAndImageUpload = () => {
    setAnchorEl(null);
  };

  const handleCameraCapture = () => {
    setIsCameraOpen(true);
  };

  const handleCameraClose = () => {
    setIsCameraOpen(false);
  };

  const handleCaptureImage = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    if (imageSrc) {
      processImage(imageSrc);
    }
    handleCameraClose();
  };

  const handleMenuItemClick = () => {
    document.getElementById('fileInput').click();
  };

  const handleAttachFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const fileDataUrl = reader.result;
      processImage(fileDataUrl);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    handleChooseCloseCaptureAndImageUpload();
  };

  const processImage = async (imageDataUrl) => {
    try {
      // Extract the Base64 string from the data URL
      const base64Image = imageDataUrl?.split(',')[1].trim();
  
      // Log the Base64 string for debugging
      console.log('Base64 Image:', base64Image);
  
      // Show image preview in chat without using dangerouslySetInnerHTML
      const newChats = [
        ...chats, 
        { 
          role: 'user', 
          content: (
            <img 
              src={`data:image/jpeg;base64,${base64Image}`} 
              alt="Uploaded Image" 
              style={{ maxWidth: '300px', height: 'auto' }} 
            />
          )
        }
      ];
      setChats(newChats);
  
      const payload = {
        model: 'gpt-4o-mini',
        messages: [
          {
            role: 'user',
            content: [
              {
                type: 'text',
                text: "What's in this image?",
              },
              {
                type: 'image_url',
                image_url: {
                  url: `data:image/jpeg;base64,${base64Image}`,
                },
              },
            ],
          },
        ],
        max_tokens: 300,
      };
  
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
        }
      );
  
      const result = response.data.choices[0].message.content;
      const newAssistantChats = [...newChats, { role: 'assistant', content: result }];
      setChats(newAssistantChats);
      window.scrollTo(0, document.body.scrollHeight);
    } catch (error) {
      console.error('Error processing image:', error);
    }
  };

  return (
    <div
      className={
        props.isFullScreen
          ? 'fullscreen-wrapper'
          : isMinimized && !props.isFullScreen
          ? 'minimised-chat'
          : 'wrapper'
      }
    >
      {!props.isFullScreen && (
        <div
          className='d-flex justify-content-between heading'
          style={{ width: isMinimized ? '27%' : '' }}
        >
          <div className='h4 sticky-top'>Student&apos;s corner</div>
          <div>
            {chats && chats.length > 0 && (
              <FileDownloadIcon
                className='download-img'
                alt='Download Chat'
                onClick={() => downloadChatAsPDF(chats, props.chapterTitle)}
                style={{ cursor: 'pointer', marginRight: '5px', color: '#00bfff' }}
              />
            )}
            {isMinimized ? (
              <ArrowUpwardIcon
                onClick={() => setIsMinimized(!isMinimized)}
                style={{ cursor: 'pointer', marginLeft: '5px', marginRight: '5px' }}
              />
            ) : (
              <MinimizeIcon
                onClick={() => setIsMinimized(!isMinimized)}
                style={{ cursor: 'pointer', marginLeft: '5px', marginRight: '5px' }}
              />
            )}
            <CloseIcon
              onClick={() => {
                handleClose(chatSession);
                props.setChatbotOpen(false);
              }}
              style={{ cursor: 'pointer', color: '#ff0000' }}
            />
          </div>
        </div>
      )}
      {!isMinimized && (
        <section id='chatContainer' style={{ padding: '57px 10px 30px 10px' }}>
          {chats && chats.length > 0 ? (
            chats.map((chat, index) => (
              <div
                className={chat.role === 'user' ? 'd-flex flex-row-reverse' : 'd-flex'}
                key={index}
              >
                {chat.role !== 'user' ? (
                  <img
                    className=''
                    src={Assistant}
                    alt='Assistant'
                    style={{ height: '60px', marginRight: '5px' }}
                  />
                ) : (
                  ''
                )}
                <div
                  className={chat.role === 'user' ? 'user_msg chatMsg-user' : 'assistant chatMsg'}
                >
                  {chat.role === 'user' && (
                    <>
                      <span>
                        <b>{capitalizeFirstLetter(chat.role)}</b>
                      </span>
                      <span>:</span>
                    </>
                  )}
                  <MathJaxWrapper>
                    {typeof chat.content === 'string' ? (
                      chat.content.split('\n').map((text, idx) => (
                        <Fragment key={idx}>
                          <span
                            className={chat.role === 'user' ? 'text-justify' : ''}
                            dangerouslySetInnerHTML={{ __html: parseLatexContent(text) }}
                          />
                          <br />
                        </Fragment>
                      ))
                    ) : (
                      // If the content is not  a string, render it directly (assumes it's JSX or another format)
                      <div>{chat.content}</div>
                    )}
                  </MathJaxWrapper>
                  <div className='reactions'>
                    <button
                      className='reaction-button'
                      onClick={() => handleReaction(index, 'thumbs_up', chat.content)}
                    >
                      👍
                    </button>
                    <button
                      className='reaction-button'
                      onClick={() => handleReaction(index, 'thumbs_down', chat.content)}
                    >
                      👎
                    </button>
                  </div>
                  {reactions[index] && (
                    <span
                      className='selected-reaction'
                      onClick={() => handleReaction(index, '', chat.content)}
                    >
                      {reactions[index] === 'thumbs_up' ? '👍' : '👎'}
                    </span>
                  )}
                </div>
              </div>
            ))
          ) : (
            ''
          )}
        </section>
      )}
      {!isMinimized && !isFetchingSuggestions && suggestions.length > 0 && !isTyping && (
        <div style={{ paddingBottom: '90px' }}>
          <h5 className={props.isFullScreen ? 'p-2 text-light' : 'p-2'}>Suggestions:</h5>
          <ul className={props.isFullScreen ? 'w-75' : 'w-100 py-4 mb-4'}>
            {suggestions.map((suggestion, index) => (
              <Chip
                sx={{
                  height: 'auto',
                  '& .MuiChip-label': {
                    display: 'block',
                    whiteSpace: 'normal',
                  },
                  '&:hover': {
                    backgroundColor: '#e4fff3',
                  },
                  margin: '2px',
                  backgroundColor: '#ADEFD1FF',
                  padding: '2px',
                  fontSize: '14px',
                  fontWeight: '600',
                  border: '1px solid #00203FFF',
                  color: '#00203FFF',
                }}
                key={index}
                label={<span dangerouslySetInnerHTML={{ __html: parseLatexContent(suggestion) }} />}
                onClick={() => chat(suggestion, suggestion)}
              />
            ))}
          </ul>
        </div>
      )}

      {!isMinimized && (
        <div className={isTyping ? 'pb-4 mb-4' : 'hide'}>
          <div>
            <div
              style={{ marginBottom: props.isFullScreen ? '50px' : '' }}
              className={props.isFullScreen ? 'typing text-dark ' : 'typing'}
            >
              {isTyping ? 'Typing...' : ''}
            </div>
          </div>
        </div>
      )}
      {!isMinimized && (
        <>
          {console.log('props.drawerStatus', props.isFullScreen, props.drawerStatus)}
          <div
            className='footer bg-dark text-white text-center py-3'
            style={{
              position: 'fixed',
              bottom: '0',
              width:
                props.isFullScreen && props.drawerStatus
                  ? '75%'
                  : !props.drawerStatus
                  ? '100%'
                  : '30%',
            }}
          >
            <div className='d-flex'>
              <input
                type='text/image'
                name='message'
                value={message}
                className='chat-input'
                style={{
                  borderRadius: '2px',
                  padding: '0 50px 0 30px',
                }}
                placeholder='Type here'
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    try {
                      chat(e, message);
                    } catch (error) {
                      console.error('Error:', error);
                    }
                  }
                }}
              />
              <div className='d-flex align-items-center'>
                <AddPhotoAlternateRoundedIcon
                  onClick={handleChooseOpenCaptureAndImageUpload}
                  sx={{
                    width: '30px',
                    cursor: 'pointer',
                    height: 'auto',
                    marginLeft: '10px',
                    marginRight: '50px',
                  }}
                  color='primary'
                />
                <Menu
                  id='basic-menu'
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleChooseCloseCaptureAndImageUpload}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={handleCameraCapture}>
                    <CameraAltRoundedIcon
                      sx={{ marginRight: '10px', cursor: 'pointer' }}
                      color='primary'
                    />
                    Take a Photo
                  </MenuItem>
                  <MenuItem onClick={handleMenuItemClick}>
                    <AttachFileRoundedIcon
                      sx={{ marginRight: '10px', cursor: 'pointer' }}
                      color='primary'
                    />
                    Upload Image from Computer
                  </MenuItem>
                  <input
                    type='file'
                    id='fileInput'
                    style={{ display: 'none' }}
                    accept='image/*'
                    onChange={handleAttachFileUpload}
                  />
                </Menu>
                <SendIcon
                  sx={{ height: 'auto', marginLeft: '-30px', transform: 'rotate(-36deg)' }}
                  color='primary'
                  onClick={(e) => chat(e, message)}
                />
              </div>
            </div>
            <Dialog open={isCameraOpen} onClose={() => setIsCameraOpen(false)}>
              <DialogTitle>Capture Image</DialogTitle>
              <DialogContent>
                <Webcam
                  audio={false}
                  screenshotFormat='image/jpeg'
                  width='100%'
                  ref={webcamRef}
                  videoConstraints={{ facingMode: 'user' }}
                  onUserMediaError={(error) => console.error('Webcam error:', error)}
                  onUserMedia={() => console.log('Webcam loaded')}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setIsCameraOpen(false)}>Cancel</Button>
                <Button onClick={handleCaptureImage}>Capture</Button>
              </DialogActions>
            </Dialog>
            <div className='text-light text-center pt-1'>
              AI may make mistake in the Maths calculation
            </div>
          </div>
        </>
      )}
      {showScrollTopButton && (
        <button
          className="scroll-to-top-btn"
          onClick={scrollToTop}
        >
           <div className="top-arrow"></div>
        </button>
      )}
    </div>
  );
}

// new changes
Chatbot.propTypes = {
  setChatbotOpen: PropTypes.func.isRequired,
  message: PropTypes.string,
  isFullScreen: PropTypes.bool,
};

Chatbot.defaultProps = {
  isFullScreen: false,
};
