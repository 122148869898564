import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';

const GradeWisePieChart = ({ gradeData, subject }) => {

const colors =[ `#5ad45a`, `#7eb0d5`, `#ea5545`]

const convertedData =  gradeData.map((item, index) => ({
  id: index,
  value: item.value,
  label: item.label,
  color : colors[index]
}));

  return (
    <>
    <h3 className='text-center text-uppercase'>{subject}</h3>
    <PieChart
      series={[
        {
          data: convertedData,
          highlightScope: { faded: 'global', highlighted: 'item' },
          faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
        },
      ]}
      height={200}
    />
    </>
  );
}

export default GradeWisePieChart;