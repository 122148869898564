import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Login, Register } from '.';
import ForgotPassword from './forgotPassword';
import UpdatePassword from './updatePassword';
import { AdminLogin } from '../pages/teacherDashboard/dashboard/pages/_schoolAdmin/AdminLogin';
import { SignUp } from './individual/SignUp';
import { MobileLogin } from './individual/MobileLogin';

export { AccountLayout };

function AccountLayout() {
  const auth = useSelector((x) => x.auth.value);

  // redirect to home if already logged in
  if (auth) {
    return <Navigate to='/' />;
  }

  return (
    <Routes>
      <Route path='login' element={<Login />} />
      <Route path='signUp' element={<SignUp />} />
      <Route path='register' element={<Register />} />
      <Route path='forgotPassword' element={<ForgotPassword />} />
      <Route path='updatePassword' element={<UpdatePassword />} />
      <Route path='adminLogin' element={<AdminLogin />} />
      <Route path='individual/MobileLogin' element={<MobileLogin />} />
    </Routes>
  );
}
