import {
  Box,
  Grid,
  IconButton,
  Stack,
  Typography,
  TextField,
  Button,
  MenuItem,
  Input,
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { Field, Formik, ErrorMessage, Form } from 'formik';
import { fetchWrapper } from '_helpers';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Divider from '@mui/material/Divider';

const validationSchema = Yup.object().shape({
  grade: Yup.string().required('grade is required'),
  subject: Yup.string().required('subject is required'),
  board: Yup.string().required('board is required'),
  chapter_id: Yup.number()
    .typeError('chapter_id must be a number')
    .required('Chapter Id is required'),
  section_id: Yup.number()
    .typeError('section_id must be a number')
    .required('Section Id  is required'),
  subsection_id: Yup.string()
    .matches(/^\d+(\.\d+)*$/, 'subsection_id must be a valid format')
    .required('Subsection Id is required'),
});

export const AddQuiz = () => {
  const navigate = useNavigate();
  const [schoolTypes, setSchoolTypes] = useState([]);
  const [statesList, setStatesList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [selectedFileChange, setSelectedFileChange] = useState(null);
  const [selectedFileOption1Change, setSelectedFileOption1Change] = useState([]);
  const [selectedFileOption2Change, setSelectedFileOption2Change] = useState([]);
  const [selectedFileOption3Change, setSelectedFileOption3Change] = useState([]);
  const [selectedFileOption4Change, setSelectedFileOption4Change] = useState([]);
  const [selectedFileFeedBackChange, setSelectedFileFeedBackChange] = useState([]);
  const [selectedFileCorrectChange, setSelectedFileCorrectChange] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [previewData, setPreviewData] = useState([]); // State to store preview data
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0); // State to keep track of current question
  const [selectImageFile, setImageFile] = useState([]);
  const [isImageSelected, setIsImageSelected] = useState(false);
  const [validationMessages, setValidationMessages] = useState({});
  const user = useSelector((x) => x.auth.value);
  let teacher_email = user && user.relatedData.teacher_email;
  let school_id = user && user.relatedData.sch_id;
  let teacher_id = user && user.relatedData.teacher_id;
  let board = user && user.board;
  let metric_state = user && user.matric_state;
  const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;
  const [gradesList, setGradesList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    getActivatedSchool();
  }, []);
  const getActivatedSchool = async () => {
    try {
      const response = await fetchWrapper.get(`${baseUrl}/getSchoolType`);
      if (response === null || response.length === 0) {
        toast.info('No school types found');
      } else {
        setSchoolTypes(response.data);
      }
    } catch (error) {
      toast.error(error.message || 'An error occurred');
    }
  };
  useEffect(() => {
    async function fetchData() {
      try {
        let res = await fetchWrapper.get(`${baseUrl}/states`);
        setStatesList(res);
      } catch (error) {
        console.error('Error:', error);
      }
    }
    if (statesList.length === 0) {
      fetchData();
    }
  }, [statesList, baseUrl]);

  const [formValues, setFormValues] = useState({
    grade: '',
    subject: '',
    board: board,
    state: '',
    chapter_id: '',
    created_by: '',
    section_id: '',
    school_id: school_id,
    subsection_id: '',
    teacher_email: teacher_email,
  });

  const handleSubmit = async (values) => {
    try {
      const requestBody = {
        grade: values.grade,
        subject: values.subject,
        board: values.board,
        state: values.state,
        chapter_id: values.chapter_id,
        section_id: values.section_id,
        school_id: values.school_id,
        subsection_id: values.subsection_id,
        teacher_email: values.teacher_email,
        created_by: values.teacher_id,
        quizData: previewData,
      };

      await fetchWrapper.post(`${baseUrl}/createQuestionQuizzes`, requestBody);

      toast.success('Quiz created successfully');
      setFormValues({
        grade: '',
        subject: '',
        board: '',
        state: metric_state,
        chapter_id: '',
        created_by: teacher_id,
        section_id: '',
        school_id: '',
        subsection_id: '',
        teacher_email: '',
      });
      navigate('/quizList');
    } catch (error) {
      toast.error(error.message || 'An error occurred');
    }
  };

  useEffect(() => {
    async function fetchGradeData() {
      try {
        let res;
        res = await fetchWrapper.get(`${baseUrl}/grades`);
        setGradesList(res);
      } catch (error) {
        console.error('Error:', error);
      }
    }
    fetchGradeData();
  }, []);
  useEffect(() => {
    async function fetchSubjectData() {
      try {
        let res;
        res = await fetchWrapper.get(`${baseUrl}/subjects`);
        setSubjectList(res);
      } catch (error) {
        console.error('Error:', error);
      }
    }
    fetchSubjectData();
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFileChange(file);

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      // Filter out empty rows
      const nonEmptyRows = jsonData.filter((row, index) => {
        if (index === 0) return true; // Keep the header row
        return row.some((cell) => cell !== null && cell !== undefined && cell !== '');
      });

      // Process nonEmptyRows to match the required format
      const formattedData = nonEmptyRows.slice(1).map((row, index) => ({
        id: index + 1,
        quizName: row[3],
        questionType: row[2] || 'MCQ',
        question: row[1] || '',
        option1: row[5] || '',
        option2: row[6] || '',
        option3: row[7] || '',
        option4: row[8] || '',
        CorrectAnswer: row[9] || '',
        Feedback: row[10] || '',
        QuizTitle: row[4] || '',
      }));

      setPreviewData(formattedData);
      setCurrentQuestionIndex(0);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleCorrectAnswerChange = (event, questionIndex) => {
    const newCorrectAnswer = event.target.value;
    const updatedPreviewData = [...previewData];
    updatedPreviewData[questionIndex].CorrectAnswer = newCorrectAnswer;

    setPreviewData(updatedPreviewData);

    // Reset validation message for the current question
    setValidationMessages((prev) => ({
      ...prev,
      [questionIndex]: '',
    }));
  };

  const nextQuestion = () => {
    const currentQuestion = previewData[currentQuestionIndex];
    const selectedAnswer = currentQuestion.CorrectAnswer;

    if (!selectedAnswer) {
      setValidationMessages((prev) => ({
        ...prev,
        [currentQuestionIndex]:
          'Please select a correct answer before proceeding to the next question.',
      }));
    } else {
      setErrorMessage('');
      if (currentQuestionIndex < previewData.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      }
    }
  };

  const prevQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleImageUpload = async (currentQuestionIndex) => {
    const formData = new FormData();

    // Append files to formData
    formData.append('questionImage', selectImageFile[currentQuestionIndex]);

    formData.append('option1Image', selectedFileOption1Change[currentQuestionIndex]);

    formData.append('option2Image', selectedFileOption2Change[currentQuestionIndex]);

    formData.append('option3Image', selectedFileOption3Change[currentQuestionIndex]);

    formData.append('option4Image', selectedFileOption4Change[currentQuestionIndex]);

    formData.append('correctAnswerImage', selectedFileCorrectChange[currentQuestionIndex]);

    formData.append('feedbackImage', selectedFileFeedBackChange[currentQuestionIndex]);

    try {
      const response = await fetchWrapper.post(`${baseUrl}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Update the previewData state with the new image URLs
      setPreviewData((prevData) => {
        const updatedData = [...prevData];
        updatedData[currentQuestionIndex] = {
          ...updatedData[currentQuestionIndex],
          ...response.urls, // Assuming response.urls is an object with keys matching the field names
        };

        return updatedData;
      });

      toast.success('Images uploaded successfully');
    } catch (error) {
      toast.error(error.message || 'An error occurred');
    }
  };

  const handleImageFileChange = (event, index) => {
    const file = event.target.files[0];
    const updatedImageFiles = [...selectImageFile];
    updatedImageFiles[index] = file;
    setImageFile(updatedImageFiles);
  };

  const handleImageOption1Change = (event, index) => {
    const file = event.target.files && event.target.files[0]; // Check if files exist and get the first file
    if (file) {
      const updatedImageFiles = [...selectedFileOption1Change];
      updatedImageFiles[index] = file;
      setSelectedFileOption1Change(updatedImageFiles);
    } else {
      // Handle case where no file is selected (optional)
      console.error('No file selected');
      // Optionally reset the selected file state or show a message to the user
    }
  };
  const handleImageOption2Change = (event, index) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const updatedImageFiles = [...selectedFileOption2Change];
      updatedImageFiles[index] = file;
      setSelectedFileOption2Change(updatedImageFiles);
    } else {
      // Handle case where no file is selected (optional)
      console.error('No file selected');
      // Optionally reset the selected file state or show a message to the user
    }
  };

  const handleImageOption3Change = (event, index) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const updatedImageFiles = [...selectedFileOption3Change];
      updatedImageFiles[index] = file;
      setSelectedFileOption3Change(updatedImageFiles);
    } else {
      console.error('No file selected');
    }
  };

  const handleImageOption4Change = (event, index) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const updatedImageFiles = [...selectedFileOption4Change];
      updatedImageFiles[index] = file;
      setSelectedFileOption4Change(updatedImageFiles);
    } else {
      console.error('No file selected');
    }
  };

  const handleImageFeedBackChange = (event, index) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const updatedImageFiles = [...selectedFileFeedBackChange];
      updatedImageFiles[index] = file;
      setSelectedFileFeedBackChange(updatedImageFiles);
    } else {
      console.error('No file selected');
    }
  };

  const handleImageCorrectChange = (event, index) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const updatedImageFiles = [...selectedFileCorrectChange];
      updatedImageFiles[index] = file;
      setSelectedFileCorrectChange(updatedImageFiles);
    } else {
      console.error('No file selected');
    }
  };

  return (
    <div>
      <Helmet>
        <title> Add New Quiz </title>
      </Helmet>
      <Stack direction='row' alignItems='center' mb={2}>
        <IconButton aria-label='back' size='large' onClick={handleBack}>
          <ArrowBackIcon fontSize='inherit' style={{ color: '#092141' }} />
        </IconButton>
        <Typography variant='h4' gutterBottom>
          Add New Quiz
        </Typography>
      </Stack>
      <div>
        <Box sx={{ padding: 2 }}>
          <Formik
            initialValues={formValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <div className='d-flex'>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Field
                      as={TextField}
                      label='Grade'
                      variant='outlined'
                      fullWidth
                      name='grade'
                      select
                    >
                      <MenuItem value=''>select</MenuItem>
                      {Object.values(gradesList).map((grade) => (
                        <MenuItem key={grade.grade_id} value={grade.grade_name}>
                          {grade.grade_name}
                        </MenuItem>
                      ))}
                    </Field>
                    <ErrorMessage name='grade' component='div' className='error' />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      as={TextField}
                      label='Subject'
                      variant='outlined'
                      fullWidth
                      name='subject'
                      select
                    >
                      {Object.values(subjectList).map((subject) => (
                        <MenuItem key={subject.subject_id} value={subject.subject_name}>
                          {subject.subject_name}
                        </MenuItem>
                      ))}
                    </Field>
                    <ErrorMessage name='subject' component='div' className='error' />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      as={TextField}
                      label='Board'
                      variant='outlined'
                      fullWidth
                      name='board'
                      select
                    >
                      {schoolTypes &&
                        schoolTypes.map((option) => (
                          <MenuItem key={option.id} value={option.name}>
                            {option.school_type}
                          </MenuItem>
                        ))}
                    </Field>
                    <ErrorMessage name='board' component='div' className='error' />
                  </Grid>
                  {board === 'Matric' && metric_state && (
                    <Grid item xs={6}>
                      <Field
                        as={TextField}
                        label='State'
                        variant='outlined'
                        fullWidth
                        name='state'
                        select
                      >
                        {statesList &&
                          statesList.map((e) => (
                            <MenuItem key={e.id} value={e.state}>
                              {e.state}
                            </MenuItem>
                          ))}
                      </Field>
                      <ErrorMessage name='state' component='div' className='error' />
                    </Grid>
                  )}

                  <Grid item xs={6}>
                    <Field
                      as={TextField}
                      label='Chapter ID'
                      variant='outlined'
                      fullWidth
                      name='chapter_id'
                    />
                    <ErrorMessage name='chapter_id' component='div' className='error' />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      as={TextField}
                      label='Section ID'
                      variant='outlined'
                      fullWidth
                      name='section_id'
                    />
                    <ErrorMessage name='section_id' component='div' className='error' />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      as={TextField}
                      label='Subsection ID'
                      variant='outlined'
                      fullWidth
                      name='subsection_id'
                    />
                    <ErrorMessage name='subsection_id' component='div' className='error' />
                  </Grid>

                  <Grid item xs={6}>
                    <Input
                      type='file'
                      fullWidth
                      id='QuizzesExcel'
                      name='QuizzesExcel'
                      onChange={handleFileChange}
                      inputProps={{ accept: '.xlsx, .xls' }}
                    />
                  </Grid>
                </Grid>
              </div>
              <Button
                type='submit'
                variant='contained'
                sx={{ marginTop: 4, padding: '10px 20px', backgroundColor: '#fe6f61' }}
              >
                Submit
              </Button>
            </Form>
          </Formik>
          {previewData.length > 0 &&
            currentQuestionIndex >= 0 &&
            currentQuestionIndex < previewData.length && (
              <Box sx={{ marginTop: 4 }}>
                <Typography variant='h6'>Preview Data</Typography>
                <div>
                  {previewData[currentQuestionIndex] && (
                    <>
                      <Card variant='outlined'>
                        <CardContent>
                          <h2>
                            {previewData[currentQuestionIndex].id}.
                            {console.log(previewData[currentQuestionIndex])}
                            {previewData[currentQuestionIndex].quizName}
                          </h2>
                          {previewData[currentQuestionIndex].questionType === 'MCQ' && (
                            <>
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <Typography>
                                    {' '}
                                    <b>Question</b> : {previewData[currentQuestionIndex].question}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <div>
                                    {selectImageFile[currentQuestionIndex] ? (
                                      <span>{selectImageFile[currentQuestionIndex].name}</span>
                                    ) : (
                                      <span>No file selected</span>
                                    )}
                                    <span className='component-image MuiBox-root css-fnjgej'>
                                      <span className='component-image-wrapper lazy-load-image-background blur lazy-load-image-loaded d-block'>
                                        <img
                                          className='MuiBox-root css-3j6ntj  rounded'
                                          alt='avatar'
                                          style={{
                                            maxHeight: '500px',
                                            maxWidth: '350px',
                                            objectFit: 'contain',
                                            margin: ' 20px auto ',
                                          }}
                                          width={500}
                                          height={350}
                                          src={
                                            isImageSelected &&
                                            selectImageFile[currentQuestionIndex] instanceof File
                                              ? URL.createObjectURL(
                                                  selectImageFile[currentQuestionIndex],
                                                )
                                              : 'https://cdn.pixabay.com/photo/2016/10/18/18/19/question-mark-1750942_1280.png'
                                          }
                                        />
                                      </span>
                                    </span>
                                    <input
                                      id={`image-upload-${currentQuestionIndex}`}
                                      accept='image/*'
                                      type='file'
                                      style={{ display: 'none' }}
                                      onChange={(e) => {
                                        handleImageFileChange(e, currentQuestionIndex);
                                        setIsImageSelected(true);
                                      }}
                                    />
                                    <label
                                      htmlFor={`image-upload-${currentQuestionIndex}`}
                                      className='custom-upload-button'
                                      style={{
                                        display: 'inline-block',
                                        padding: '10px 15px',
                                        backgroundColor: '#4caf50',
                                        color: '#fff',
                                        borderRadius: '4px',
                                        cursor: 'pointer',
                                        pointerEvents: 'auto', // Ensure pointer events are enabled
                                        opacity: 1, // Ensure opacity is at full to avoid a disabled appearance
                                      }}
                                    >
                                      Question Image
                                    </label>
                                  </div>
                                </Grid>
                              </Grid>

                              {/* <Divider component='li' /> */}
                              <FormControl component='fieldset' margin='11px'>
                                <RadioGroup>
                                  <Grid container spacing={11} marginTop={'4px'}>
                                    <Grid item xs={6}>
                                      <FormControlLabel
                                        value={previewData[currentQuestionIndex].option1}
                                        control={<Radio />}
                                        label={previewData[currentQuestionIndex].option1}
                                        style={{
                                          marginTop: '120px',
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={6}>
                                      <div>
                                        {selectedFileOption1Change[currentQuestionIndex] ? (
                                          <span>
                                            {selectedFileOption1Change[currentQuestionIndex].name}
                                          </span>
                                        ) : (
                                          <span>No file selected</span>
                                        )}
                                        <span className='component-image MuiBox-root css-fnjgej'>
                                          <span className='component-image-wrapper lazy-load-image-background blur lazy-load-image-loaded d-block'>
                                            <img
                                              className='MuiBox-root css-3j6ntj rounded'
                                              alt='avatar'
                                              style={{
                                                maxHeight: '500px',
                                                maxWidth: '350px',
                                                objectFit: 'contain',
                                                margin: ' 20px auto ',
                                              }}
                                              width={500}
                                              height={350}
                                              src={
                                                selectedFileOption1Change[
                                                  currentQuestionIndex
                                                ] instanceof File
                                                  ? URL.createObjectURL(
                                                      selectedFileOption1Change[
                                                        currentQuestionIndex
                                                      ],
                                                    )
                                                  : 'https://cdn.pixabay.com/photo/2016/10/18/18/19/question-mark-1750942_1280.png'
                                              }
                                            />
                                          </span>
                                        </span>
                                        <input
                                          id={`image-upload-${currentQuestionIndex}-option1`}
                                          accept='image/*'
                                          type='file'
                                          style={{ display: 'none' }}
                                          onChange={(e) =>
                                            handleImageOption1Change(e, currentQuestionIndex)
                                          }
                                        />
                                        <label
                                          htmlFor={`image-upload-${currentQuestionIndex}-option1`}
                                          className='custom-upload-button'
                                          style={{
                                            display: 'inline-block',
                                            padding: '10px 15px',
                                            backgroundColor: '#4caf50',
                                            color: '#fff',
                                            borderRadius: '4px',
                                            cursor: 'pointer',
                                          }}
                                        >
                                          Option 1 Image
                                        </label>
                                      </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <FormControlLabel
                                        value={previewData[currentQuestionIndex].option2}
                                        control={<Radio />}
                                        label={previewData[currentQuestionIndex].option2}
                                        style={{
                                          margin: '80px auto ',
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={6}>
                                      <div>
                                        {selectedFileOption2Change[currentQuestionIndex] ? (
                                          <span>
                                            {selectedFileOption2Change[currentQuestionIndex].name}
                                          </span>
                                        ) : (
                                          <span>No file selected</span>
                                        )}
                                        <span className='component-image MuiBox-root css-fnjgej'>
                                          <span className='component-image-wrapper lazy-load-image-background blur lazy-load-image-loaded d-block'>
                                            <img
                                              className='MuiBox-root css-3j6ntj rounded'
                                              alt='avatar'
                                              style={{
                                                maxHeight: '500px',
                                                maxWidth: '350px',
                                                objectFit: 'contain',
                                                margin: ' 20px auto ',
                                              }}
                                              width={500}
                                              height={350}
                                              src={
                                                selectedFileOption2Change[
                                                  currentQuestionIndex
                                                ] instanceof File
                                                  ? URL.createObjectURL(
                                                      selectedFileOption2Change[
                                                        currentQuestionIndex
                                                      ],
                                                    )
                                                  : 'https://cdn.pixabay.com/photo/2016/10/18/18/19/question-mark-1750942_1280.png'
                                              }
                                            />
                                          </span>
                                        </span>
                                        <input
                                          id={`image-upload-${currentQuestionIndex}-option2`}
                                          accept='image/*'
                                          type='file'
                                          style={{ display: 'none' }}
                                          onChange={(e) =>
                                            handleImageOption2Change(e, currentQuestionIndex)
                                          }
                                        />
                                        <label
                                          htmlFor={`image-upload-${currentQuestionIndex}-option2`}
                                          className='custom-upload-button'
                                          style={{
                                            display: 'inline-block',
                                            padding: '10px 15px',
                                            backgroundColor: '#4caf50',
                                            color: '#fff',
                                            borderRadius: '4px',
                                            cursor: 'pointer',
                                          }}
                                        >
                                          Option 2 Image
                                        </label>
                                      </div>
                                    </Grid>

                                    <hr
                                      style={{
                                        marginTop: '170px',
                                      }}
                                    ></hr>
                                    <Grid item xs={6}>
                                      {previewData[currentQuestionIndex].option3 && (
                                        <FormControlLabel
                                          value={previewData[currentQuestionIndex].option3}
                                          control={<Radio />}
                                          label={previewData[currentQuestionIndex].option3}
                                          style={{
                                            margin: ' 80px auto ',
                                          }}
                                        />
                                      )}
                                    </Grid>
                                    <Grid item xs={6}>
                                      <div>
                                        {/* Option3 Code   */}
                                        {selectedFileOption3Change[currentQuestionIndex] ? (
                                          <span>
                                            {selectedFileOption3Change[currentQuestionIndex].name}
                                          </span>
                                        ) : (
                                          <span>No file selected</span>
                                        )}
                                        <span className='component-image MuiBox-root css-fnjgej'>
                                          <span className='component-image-wrapper lazy-load-image-background blur lazy-load-image-loaded d-block'>
                                            <img
                                              className='MuiBox-root css-3j6ntj rounded'
                                              alt='avatar'
                                              style={{
                                                maxHeight: '500px',
                                                maxWidth: '350px',
                                                objectFit: 'contain',
                                                margin: ' 20px auto ',
                                              }}
                                              width={500}
                                              height={350}
                                              src={
                                                selectedFileOption3Change[
                                                  currentQuestionIndex
                                                ] instanceof File
                                                  ? URL.createObjectURL(
                                                      selectedFileOption3Change[
                                                        currentQuestionIndex
                                                      ],
                                                    )
                                                  : 'https://cdn.pixabay.com/photo/2016/10/18/18/19/question-mark-1750942_1280.png'
                                              }
                                            />
                                          </span>
                                        </span>
                                        <input
                                          id={`image-upload-${currentQuestionIndex}-option3`}
                                          accept='image/*'
                                          type='file'
                                          style={{ display: 'none' }}
                                          onChange={(e) =>
                                            handleImageOption3Change(e, currentQuestionIndex)
                                          }
                                        />
                                        <label
                                          htmlFor={`image-upload-${currentQuestionIndex}-option3`}
                                          className='custom-upload-button'
                                          style={{
                                            display: 'inline-block',
                                            padding: '10px 15px',
                                            backgroundColor: '#4caf50',
                                            color: '#fff',
                                            borderRadius: '4px',
                                            cursor: 'pointer',
                                          }}
                                        >
                                          Option 3 Image
                                        </label>
                                      </div>
                                    </Grid>
                                    <hr
                                      style={{
                                        marginTop: '170px',
                                      }}
                                    ></hr>
                                    <Grid item xs={6}>
                                      {previewData[currentQuestionIndex].option4 && (
                                        <FormControlLabel
                                          value={previewData[currentQuestionIndex].option4}
                                          control={<Radio />}
                                          label={previewData[currentQuestionIndex].option4}
                                          style={{
                                            margin: ' 80px auto ',
                                          }}
                                        />
                                      )}
                                    </Grid>
                                    <Grid item xs={6}>
                                      <div>
                                        {/*  Option4 code  */}
                                        {selectedFileOption4Change[currentQuestionIndex] ? (
                                          <span>
                                            {selectedFileOption4Change[currentQuestionIndex].name}
                                          </span>
                                        ) : (
                                          <span>No file selected</span>
                                        )}
                                        <span className='component-image MuiBox-root css-fnjgej'>
                                          <span className='component-image-wrapper lazy-load-image-background blur lazy-load-image-loaded d-block'>
                                            <img
                                              className='MuiBox-root css-3j6ntj rounded'
                                              alt='avatar'
                                              style={{
                                                maxHeight: '500px',
                                                maxWidth: '350px',
                                                objectFit: 'contain',
                                                margin: ' 20px auto ',
                                              }}
                                              width={500}
                                              height={350}
                                              src={
                                                selectedFileOption4Change[
                                                  currentQuestionIndex
                                                ] instanceof File
                                                  ? URL.createObjectURL(
                                                      selectedFileOption4Change[
                                                        currentQuestionIndex
                                                      ],
                                                    )
                                                  : 'https://cdn.pixabay.com/photo/2016/10/18/18/19/question-mark-1750942_1280.png'
                                              }
                                            />
                                          </span>
                                        </span>
                                        <input
                                          id={`image-upload-${currentQuestionIndex}-option4`}
                                          accept='image/*'
                                          type='file'
                                          style={{ display: 'none' }}
                                          onChange={(e) =>
                                            handleImageOption4Change(e, currentQuestionIndex)
                                          }
                                        />
                                        <label
                                          htmlFor={`image-upload-${currentQuestionIndex}-option4`}
                                          className='custom-upload-button'
                                          style={{
                                            display: 'inline-block',
                                            padding: '10px 15px',
                                            backgroundColor: '#4caf50',
                                            color: '#fff',
                                            borderRadius: '4px',
                                            cursor: 'pointer',
                                          }}
                                        >
                                          Option 4 Image
                                        </label>
                                      </div>
                                    </Grid>
                                  </Grid>{' '}
                                </RadioGroup>
                              </FormControl>
                            </>
                          )}
                          <hr
                            style={{
                              marginTop: '210px',
                            }}
                          ></hr>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Accordion
                                className='m-11'
                                style={{
                                  margin: ' 120px auto ',
                                }}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls='panel1-content'
                                  id='panel1-header'
                                >
                                  <Typography>Feedback</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography>
                                    {previewData[currentQuestionIndex].Feedback}
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            </Grid>
                            <Grid item xs={6}>
                              <div>
                                {/*  Feed back code  */}
                                {selectedFileFeedBackChange[currentQuestionIndex] ? (
                                  <span>
                                    {selectedFileFeedBackChange[currentQuestionIndex].name}
                                  </span>
                                ) : (
                                  <span>No file selected</span>
                                )}
                                <span className='component-image MuiBox-root css-fnjgej'>
                                  <span className='component-image-wrapper lazy-load-image-background blur lazy-load-image-loaded d-block'>
                                    <img
                                      className='MuiBox-root css-3j6ntj rounded'
                                      alt='avatar'
                                      style={{
                                        maxHeight: '500px',
                                        maxWidth: '350px',
                                        objectFit: 'contain',
                                        margin: ' 20px auto ',
                                      }}
                                      width={500}
                                      height={350}
                                      src={
                                        selectedFileFeedBackChange[currentQuestionIndex] instanceof
                                        File
                                          ? URL.createObjectURL(
                                              selectedFileFeedBackChange[currentQuestionIndex],
                                            )
                                          : 'https://cdn.pixabay.com/photo/2016/10/18/18/19/question-mark-1750942_1280.png'
                                      }
                                    />
                                  </span>
                                </span>
                                <input
                                  id={`image-upload-feedback-${currentQuestionIndex}`}
                                  accept='image/*'
                                  type='file'
                                  style={{ display: 'none' }}
                                  onChange={(e) =>
                                    handleImageFeedBackChange(e, currentQuestionIndex)
                                  }
                                />
                                <label
                                  htmlFor={`image-upload-feedback-${currentQuestionIndex}`}
                                  className='custom-upload-button'
                                  style={{
                                    display: 'inline-block',
                                    padding: '10px 15px',
                                    backgroundColor: '#4caf50',
                                    color: '#fff',
                                    borderRadius: '4px',
                                    cursor: 'pointer',
                                  }}
                                >
                                  Feedback Image
                                </label>
                              </div>
                            </Grid>

                            <hr
                              style={{
                                marginTop: '185px',
                              }}
                            ></hr>
                            <Grid item xs={6}>
                              <Accordion
                                className='m-4'
                                style={{
                                  margin: ' 120px auto ',
                                }}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls='panel1-content'
                                  id='panel1-header'
                                >
                                  <Typography>Correct Answer</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Select
                                    value={previewData[currentQuestionIndex].CorrectAnswer}
                                    onChange={(e) =>
                                      handleCorrectAnswerChange(e, currentQuestionIndex)
                                    }
                                    sx={{ width: '100%' }}
                                    displayEmpty
                                  >
                                    <MenuItem value=''>-</MenuItem>
                                    <MenuItem value='option_4'>
                                      {previewData[currentQuestionIndex].option4}
                                    </MenuItem>
                                    <MenuItem value='option_3'>
                                      {previewData[currentQuestionIndex].option3}
                                    </MenuItem>
                                    <MenuItem value='option_2'>
                                      {previewData[currentQuestionIndex].option2}
                                    </MenuItem>
                                    <MenuItem value='option_1'>
                                      {previewData[currentQuestionIndex].option1}
                                    </MenuItem>
                                  </Select>
                                  {validationMessages[currentQuestionIndex] && (
                                    <Typography color='error'>
                                      {validationMessages[currentQuestionIndex]}
                                    </Typography>
                                  )}
                                </AccordionDetails>
                              </Accordion>
                            </Grid>
                            <Grid item xs={6}>
                              <div>
                                {selectedFileCorrectChange[currentQuestionIndex] ? (
                                  <span>
                                    {selectedFileCorrectChange[currentQuestionIndex].name}
                                  </span>
                                ) : (
                                  <span>No file selected</span>
                                )}
                                <span className='component-image MuiBox-root css-fnjgej'>
                                  <span className='component-image-wrapper lazy-load-image-background blur lazy-load-image-loaded d-block'>
                                    <img
                                      className='MuiBox-root css-3j6ntj rounded'
                                      alt='avatar'
                                      style={{
                                        maxHeight: '500px',
                                        maxWidth: '350px',
                                        objectFit: 'contain',
                                        margin: ' 20px auto ',
                                      }}
                                      width={500}
                                      height={350}
                                      src={
                                        selectedFileCorrectChange[currentQuestionIndex] instanceof
                                        File
                                          ? URL.createObjectURL(
                                              selectedFileCorrectChange[currentQuestionIndex],
                                            )
                                          : 'https://cdn.pixabay.com/photo/2016/10/18/18/19/question-mark-1750942_1280.png'
                                      }
                                    />
                                  </span>
                                </span>
                                <input
                                  id={`image-upload-correct-${currentQuestionIndex}`}
                                  accept='image/*'
                                  type='file'
                                  style={{ display: 'none' }}
                                  onChange={(e) =>
                                    handleImageCorrectChange(e, currentQuestionIndex)
                                  }
                                />
                                <label
                                  htmlFor={`image-upload-correct-${currentQuestionIndex}`}
                                  className='custom-upload-button'
                                  style={{
                                    display: 'inline-block',
                                    padding: '10px 15px',
                                    backgroundColor: '#4caf50',
                                    color: '#fff',
                                    borderRadius: '4px',
                                    cursor: 'pointer',
                                  }}
                                >
                                  Correct Image
                                </label>
                              </div>
                            </Grid>
                          </Grid>
                          <Divider />

                          <button
                            type='button'
                            className='btn btn-primary'
                            onClick={() => handleImageUpload(currentQuestionIndex)}
                          >
                            Add Images
                          </button>
                        </CardContent>
                      </Card>
                    </>
                  )}
                  <div>
                    <Button onClick={prevQuestion} disabled={currentQuestionIndex <= 0}>
                      Previous
                    </Button>
                    {errorMessage && <Typography color='error'>{errorMessage}</Typography>}
                    <Button
                      onClick={nextQuestion}
                      disabled={currentQuestionIndex >= previewData.length - 1}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </Box>
            )}
        </Box>
      </div>
    </div>
  );
};
