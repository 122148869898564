import { createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '_helpers';
let user = JSON.parse(localStorage.getItem('auth'));

const studentReducer = createSlice({
  name: 'student',
  initialState: {
    studentAccount: [],
    fetchingStudent: false,
    totalItems: -1,
  },
  reducers: {
    setStudentAccounts: (state, action) => {
      state.studentAccount = action.payload;
    },

    setFetchingStudent: (state, action) => {
      state.fetchingStudent = action.payload;
    },

    setTotalItems: (state, action) => {
      state.totalItems = action.payload;
    },
  },
});

export const getStudentAccounts =
  (pageNo, pageSize, search = '', grade = null, section = null) =>
  async (dispatch) => {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;
    const apiURL = `${baseUrl}/fetchAllStudentInfo?pageNo=${pageNo ? pageNo : 1}&pageSize=${
      pageSize ? pageSize : 10
    }&search=${search}&grade=${grade ? grade : ''}&section=${section ? section : ''}`;

    try {
      const school_id = user && user.relatedData && user.relatedData.sch_id;
      const req = { school_id: school_id };
      const response = await fetchWrapper.post(apiURL, req);
      const data = await response;
      dispatch(setStudentAccounts(data));
    } catch (error) {
      console.error('Error fetching student accounts:', error);
    }
  };

export const { setStudentAccounts, setFetchingStudent, setTotalItems } = studentReducer.actions;

export const studentAccounts = (state) => state.student.studentAccount;
export const fetchingStudent = (state) => state.student.fetchingStudent;

export const totalStudent = (state) => state.student.totalItems;

export default studentReducer.reducer;
