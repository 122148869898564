import React, { useEffect, useState } from 'react';
import {
  IconButton,
  InputAdornment,
  TextField,
  Button,
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';
import GoogleButton from 'react-google-button';
import { styled } from '@mui/material/styles';
import { fetchWrapper } from '_helpers';

export { IndividualForm };

function IndividualForm({ onSubmit }) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        email: Yup.string().required('Username is required'),
        password: Yup.string().required('Password is required'),
      }),
    ),
  });
  const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;

  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [planList, setPlanList] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [board, setBoard] = useState(''); // Manage board state

  const handleClick = (data) => {
    data.board = board; // Use the board state value
    data.state = null; // Set state to null regardless of the plan

    // Call the onSubmit function with the updated data
    onSubmit(data);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        let res = await fetchWrapper.get(`${baseUrl}/getPlanCourses`);
        console.log(res, 'res');
        setPlanList(res.result);
      } catch (error) {
        console.error('Error:', error);
      }
    }
    if (planList.length === 0) {
      fetchData();
    }
  }, []);

  const handleGoogleCallback = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/api/v1/auth/google`;
  };
  const handleMobileLoginClick = () => {
    navigate('/account/individual/MobileLogin');
  };

  const CustomTextField = styled(TextField)(() => ({
    '& .MuiInputLabel-root': {
      color: '#ffffff', // White label color
    },
    '& .MuiInputBase-input': {
      color: '#ffffff', // White text color inside input
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor:
        'linear-gradient(to right, rgb(18 39 67) 0%, rgb(20 36 57) 50%, rgb(44 54 67) 100%)',
      borderRadius: '15px',
      '& fieldset': {
        borderColor: '#736d604d', // White border color
      },
      '&:hover fieldset': {
        borderColor: '#736d604d', // White border color on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: '#736d604d', // White border color when focused
      },
    },
    // Autofill styles
    '& input:-webkit-autofill': {
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': '#ffffff !important',
    },
  }));

  const CustomLoadingButton = styled(LoadingButton)(() => ({
    color: '#fff', // Dark blue text color
    borderColor: '#736d604d',
    '&:hover': {
      backgroundColor: '#092141', // Light gray on hover
    },
    marginTop: '30px',
  }));

  return (
    <form onSubmit={handleSubmit(handleClick)} style={{ position: 'relative' }}>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        flexDirection={{ xs: 'column', sm: 'row' }}
        gap={{ xs: '10px' }}
      >
        <GoogleButton onClick={handleGoogleCallback} />
        <Button
          variant='outlined'
          color='primary'
          onClick={handleMobileLoginClick}
          sx={{
            marginLeft: 2,
            cursor: 'pointer',
            fontSize: '16px',
            background: '#da4040',
            color: 'white',
            borderRadius: 'none',
            borderColor: 'none',
            '&:hover': {
              backgroundColor: '#b43b3b',
            },
          }}
        >
          Mobile Login
        </Button>
      </Box>

      <CustomTextField
        className='login-field'
        name='email'
        label='Email address'
        variant='outlined'
        fullWidth
        required
        {...register('email')}
        error={!!errors.email}
        helperText={errors.email?.message}
        sx={{ marginTop: '20px' }}
      />

      <CustomTextField
        name='password'
        label='Password'
        type={showPassword ? 'text' : 'password'}
        variant='outlined'
        fullWidth
        required
        {...register('password')}
        error={!!errors.password}
        helperText={errors.password?.message}
        sx={{ marginTop: '20px' }}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton onClick={() => setShowPassword(!showPassword)} sx={{ padding: 0 }}>
                {showPassword ? (
                  <VisibilityIcon sx={{ color: '#ffffff' }} />
                ) : (
                  <VisibilityOffIcon sx={{ color: '#ffffff' }} />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <FormControl fullWidth sx={{ marginTop: '20px' }} error={!!errors.plan}>
        <InputLabel id='plan-label' sx={{ color: '#ffffff' }}>
          Select Plan
        </InputLabel>
        <Select
          labelId='plan-label'
          id='plan-select'
          value={selectedPlan}
          {...register('plan')}
          onChange={(e) => {
            const selectedPlan = e.target.value;
            setSelectedPlan(selectedPlan);

            // Set the board value based on the selected plan
            if (['CLASS 10', 'CLASS 9', 'CLASS 8'].includes(selectedPlan)) {
              setBoard('CBSE');
            } else {
              setBoard('NEET');
            }
          }}
          sx={{
            color: '#ffffff',
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: '#736d604d',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#736d604d',
            },
            '& .MuiSvgIcon-root': {
              color: '#ffffff',
            },
          }}
        >
          {planList.map((plan) => (
            <MenuItem key={plan.id} value={plan.plan_name}>
              {plan.plan_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <CustomLoadingButton
        fullWidth
        size='large'
        type='submit'
        variant='contained'
        loading={isSubmitting}
        sx={{
          backgroundColor: '#008942',
          marginTop: '20px',
          border: '1px solid #736d604d',
          fontSize: '17px',
        }}
      >
        Sign In
      </CustomLoadingButton>
    </form>
  );
}
