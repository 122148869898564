import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Modal,
  Box,
  TextField,
  Paper,
  IconButton,
  Grid,
  Typography,
  Stack
} from '@mui/material';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; // Import the back arrow icon
import moment from 'moment';

import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const baseUrl = process.env.REACT_APP_API_URL + '/api/v1';

const Container = styled('div')({
  padding: '20px',
});

const Title = styled('h1')({
  textAlign: 'center',
  color: '#1976d2',
  marginBottom: '20px',
});

const StyledTable = styled(Table)({
  minWidth: 650,
});

const StyledTableCell = styled(TableCell)({
  backgroundColor: '#1976d2',
  color: '#fff',
  fontWeight: 'bold',
});

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
  },
});

export const GetQuizSummary = () => {
  const [quizSummaries, setQuizSummaries] = useState([]);
  const [selectedQuiz, setSelectedQuiz] = useState(null);
  const [quizQuestions, setQuizQuestions] = useState([]);
  const [open, setOpen] = useState(false);
  const [expandedQuestion, setExpandedQuestion] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    fetchQuizSummaries();
  }, []);

  const fetchQuizSummaries = async () => {
    try {
      const response = await axios.get(`${baseUrl}/getQuizSummary`);
      setQuizSummaries(response.data.quizSummaries);
    } catch (error) {
      console.error('Error fetching quiz summaries:', error);
    }
  };

  const fetchQuizQuestions = async (quizId) => {
    try {
      const response = await axios.get(`${baseUrl}/getQuizQuestions/${quizId}`);
      const fetchedQuestions = response.data.quizQuestions;

      if (Array.isArray(fetchedQuestions)) {
        setQuizQuestions(fetchedQuestions);
      } else {
        console.error('Fetched quiz questions data is not an array:', fetchedQuestions);
        setQuizQuestions([]);
      }
    } catch (error) {
      console.error('Error fetching quiz questions:', error);
      setQuizQuestions([]);
    }
  };

  const handleOpen = (quiz) => {
    setSelectedQuiz(quiz);
    setQuizQuestions([]); // Reset quizQuestions before fetching new ones
    fetchQuizQuestions(quiz.id);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedQuiz(null);
    setQuizQuestions([]);
    setOpen(false);
  };

  const handleSave = async () => {
    try {
      const response = await axios.put(`${baseUrl}/updateQuizQuestions`, {
        quizId: selectedQuiz.id,
        questions: quizQuestions,
      });
      fetchQuizSummaries();
      handleClose();
      const { token } = response.data;
      toast.success('Quiz Updated successfully');
      console.log('Token:', token);
    } catch (error) {
      console.error('Error saving quiz data:', error);
      toast.error(error.message || 'An error occurred');
    }
  };

  const handleQuestionChange = (index, e) => {
    const { name, value } = e.target;
    setQuizQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[index] = { ...updatedQuestions[index], [name]: value };
      return updatedQuestions;
    });
  };

  const handleImageChange = (index, name, file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setQuizQuestions((prevQuestions) => {
        const updatedQuestions = [...prevQuestions];
        updatedQuestions[index] = { ...updatedQuestions[index], [name]: reader.result };
        return updatedQuestions;
      });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const toggleQuestionExpand = (index) => {
    setExpandedQuestion(expandedQuestion === index ? null : index);
  };

  const handleCloseModal = () => {
    setSelectedQuiz(null);
    setQuizQuestions([]);
    setOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Stack direction='row' alignItems='center' justifyContent='space-between' mb={5}>
          <IconButton onClick={() => navigate('/')} aria-label="back">
            <ArrowBackIcon />
          </IconButton>
          <Title>Quiz Summaries</Title>
          <div className='d-flex'>
            <Button
              className='mx-2'
              sx={{
                backgroundColor: '#ff9800',
                '&:hover': {
                  backgroundColor: '#f57c00',
                },
                color: '#fff',
              }}
              onClick={() => navigate('/AddMockQuiz')}
            >
              New Mock Quiz
            </Button>
          </div>
        </Stack>
        <Paper elevation={3}>
          <StyledTable>
            <TableHead>
              <TableRow>
                <StyledTableCell>Grade</StyledTableCell>
                <StyledTableCell>Subject</StyledTableCell>
                <StyledTableCell>Board</StyledTableCell>
                <StyledTableCell>Created By</StyledTableCell>
                <StyledTableCell>Created At</StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {quizSummaries.map((quiz) => (
                <TableRow key={quiz.id}>
                  <TableCell>{quiz.grade}</TableCell>
                  <TableCell>{quiz.subject}</TableCell>
                  <TableCell>{quiz.board}</TableCell>
                  <TableCell>{quiz.created_by}</TableCell>
                  <TableCell>{moment(quiz.created_at).format('MMMM Do YYYY, h:mm:ss a')}</TableCell>
                  <TableCell>
                    <Button variant='contained' color='primary' onClick={() => handleOpen(quiz)}>
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </StyledTable>
        </Paper>

        <Modal open={open} onClose={handleCloseModal}>
          <Box sx={modalStyle}>
            <Typography variant='h4' color='primary' gutterBottom>
              Edit Quiz Questions
            </Typography>
            <Box sx={{ maxHeight: 400, overflowY: 'auto' }}>
              {quizQuestions.length === 0 ? (
                <Typography>No questions available for this quiz.</Typography>
              ) : (
                quizQuestions.map((question, index) => (
                  <Box key={index} sx={{ mb: 2, p: 2, border: '1px solid #ddd', borderRadius: 4 }}>
                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='space-between'
                      sx={{ mb: 1 }}
                    >
                      <Typography variant='h6'>Question {index + 1}</Typography>
                      <IconButton onClick={() => toggleQuestionExpand(index)}>
                        {expandedQuestion === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton>
                    </Box>
                    {expandedQuestion === index && (
                      <Box>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <TextField
                              name='questions'
                              label='Question'
                              value={question.questions}
                              onChange={(e) => handleQuestionChange(index, e)}
                              fullWidth
                              margin='normal'
                            />
                            {question.question_image && (
                              <img
                                src={question.question_image}
                                alt='Question'
                                style={{ width: '100%', height: 'auto', maxHeight: 250 }}
                              />
                            )}
                            <Button
                              variant='contained'
                              component='label'
                              fullWidth
                              sx={{ mt: 1 }}
                            >
                              Upload Question Image
                              <input
                                type='file'
                                hidden
                                onChange={(e) =>
                                  handleImageChange(index, 'question_image', e.target.files[0])
                                }
                              />
                            </Button>
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              name='option_1'
                              label='Option 1'
                              value={question.option_1}
                              onChange={(e) => handleQuestionChange(index, e)}
                              fullWidth
                              margin='normal'
                            />
                            {question.option_1_image && (
                              <img
                                src={question.option_1_image}
                                alt='Option 1'
                                style={{ width: '100%', height: 'auto', maxHeight: 250 }}
                              />
                            )}
                            <Button
                              variant='contained'
                              component='label'
                              fullWidth
                              sx={{ mt: 1 }}
                            >
                              Upload Option 1 Image
                              <input
                                type='file'
                                hidden
                                onChange={(e) =>
                                  handleImageChange(index, 'option_1_image', e.target.files[0])
                                }
                              />
                            </Button>
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              name='option_2'
                              label='Option 2'
                              value={question.option_2}
                              onChange={(e) => handleQuestionChange(index, e)}
                              fullWidth
                              margin='normal'
                            />
                            {question.option_2_image && (
                              <img
                                src={question.option_2_image}
                                alt='Option 2'
                                style={{ width: '100%', height: 'auto', maxHeight: 250 }}
                              />
                            )}
                            <Button
                              variant='contained'
                              component='label'
                              fullWidth
                              sx={{ mt: 1 }}
                            >
                              Upload Option 2 Image
                              <input
                                type='file'
                                hidden
                                onChange={(e) =>
                                  handleImageChange(index, 'option_2_image', e.target.files[0])
                                }
                              />
                            </Button>
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              name='option_3'
                              label='Option 3'
                              value={question.option_3}
                              onChange={(e) => handleQuestionChange(index, e)}
                              fullWidth
                              margin='normal'
                            />
                            {question.option_3_image && (
                              <img
                                src={question.option_3_image}
                                alt='Option 3'
                                style={{ width: '100%', height: 'auto', maxHeight: 250 }}
                              />
                            )}
                            <Button
                              variant='contained'
                              component='label'
                              fullWidth
                              sx={{ mt: 1 }}
                            >
                              Upload Option 3 Image
                              <input
                                type='file'
                                hidden
                                onChange={(e) =>
                                  handleImageChange(index, 'option_3_image', e.target.files[0])
                                }
                              />
                            </Button>
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              name='option_4'
                              label='Option 4'
                              value={question.option_4}
                              onChange={(e) => handleQuestionChange(index, e)}
                              fullWidth
                              margin='normal'
                            />
                            {question.option_4_image && (
                              <img
                                src={question.option_4_image}
                                alt='Option 4'
                                style={{ width: '100%', height: 'auto', maxHeight: 250 }}
                              />
                            )}
                            <Button
                              variant='contained'
                              component='label'
                              fullWidth
                              sx={{ mt: 1 }}
                            >
                              Upload Option 4 Image
                              <input
                                type='file'
                                hidden
                                onChange={(e) =>
                                  handleImageChange(index, 'option_4_image', e.target.files[0])
                                }
                              />
                            </Button>
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              name='correct_answer'
                              label='Correct Answer'
                              value={question.correct_answer}
                              onChange={(e) => handleQuestionChange(index, e)}
                              fullWidth
                              margin='normal'
                            />
                            {question.correct_answer_image && (
                              <img
                                src={question.correct_answer_image}
                                alt='Correct Answer'
                                style={{ width: '100%', height: 'auto', maxHeight: 250 }}
                              />
                            )}
                            <Button
                              variant='contained'
                              component='label'
                              fullWidth
                              sx={{ mt: 1 }}
                            >
                              Upload Correct Answer Image
                              <input
                                type='file'
                                hidden
                                onChange={(e) =>
                                  handleImageChange(index, 'correct_answer_image', e.target.files[0])
                                }
                              />
                            </Button>
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              name='feedback'
                              label='Feedback'
                              value={question.feedback}
                              onChange={(e) => handleQuestionChange(index, e)}
                              fullWidth
                              margin='normal'
                            />
                            {question.feedback_image && (
                              <img
                                src={question.feedback_image}
                                alt='Feedback'
                                style={{ width: '100%', height: 'auto', maxHeight: 250 }}
                              />
                            )}
                            <Button
                              variant='contained'
                              component='label'
                              fullWidth
                              sx={{ mt: 1 }}
                            >
                              Upload Feedback Image
                              <input
                                type='file'
                                hidden
                                onChange={(e) =>
                                  handleImageChange(index, 'feedback_image', e.target.files[0])
                                }
                              />
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                  </Box>
                ))
              )}
            </Box>
            <Box display='flex' justifyContent='flex-end' mt={2}>
              <Button variant='contained' color='primary' onClick={handleSave}>
                Save
              </Button>
            </Box>
          </Box>
        </Modal>
      </Container>
    </ThemeProvider>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  maxHeight: '80vh',
  overflowY: 'auto',
};

